import React from 'react';
import moment, { relativeTimeThreshold } from 'moment';
import _ from 'lodash';
import LoginService from "../../pages/Login/Login.service";
import { Trans, Translation } from "react-i18next";

export default class MonthlyScheduler extends React.PureComponent {

    pages=null;
    state={
        workDate:moment(),
        resources: [],
        currentPage:0,
        groups:[]
    };

    constructor(props){
        super(props);
        this._init(props);
    }

    _init(props){
        this.setState({
            workDate:props.workDate || moment(),
            resources: props.resources?[...props.resources]:[],
            currentPage:1,
            groups:props.groups
        },()=>{
            this.pages=this.getMonthPage().map(p=>{
                p.daysOfWeek=this.getWeekPages(p.pageNum);
                return p;
            });
        });

    }

    componentDidUpdate(){
        if(!this.pages || JSON.stringify(this.props.resources)!==JSON.stringify(this.state.resources)
            || this.state.workDate.format("DDMMYYYY")!==this.props.workDate.format("DDMMYYYY"))
            this._init(this.props);
    }



    getWeekPages(weekNumber){
        const firstDayOfMonth=this.state.workDate.clone().startOf('month');
        const lastDayOfMonth=this.state.workDate.clone().endOf('month');
        let currentDate=firstDayOfMonth.clone().startOf('week').add((weekNumber-1)*7,'d');
        const lastDayOfWeek=currentDate.clone().endOf('week');
        let ret=[];
        while(currentDate.isSameOrBefore(lastDayOfWeek)){

            /*if(currentDate.isSameOrAfter(firstDayOfMonth))
            {
               ret.push({
                    type:'date',
                    date:currentDate.clone(),
                    day:currentDate.format('DD')
                });
            }*/

            ret.push({
                type:'date',
                date:currentDate.clone(),
                day:currentDate.format('DD'),
                disabled: currentDate.isBefore(firstDayOfMonth) || currentDate.isAfter(lastDayOfMonth)
            });
            currentDate.add(1,'day');
        }
        if(this.props.renderTotalResource && _.isFunction(this.props.renderTotalResource)){
          ret.push({
              type:'total',
              render:this.props.renderTotalResource
          });
        }
        return ret;
    }

    getMonthPage(){

      const firstDayOfMonth=this.state.workDate.clone().startOf('month');
      const lastDayOfMonth=this.state.workDate.clone().endOf('month');
      let counter=0;
      let currentDate=firstDayOfMonth.clone().startOf('week').subtract(1,'d');
      let ret=[];
      while(currentDate.isSameOrBefore(lastDayOfMonth)){
        currentDate.add(1,'d');
        if(currentDate.weekday()===1){
        counter++;
        ret.push({
            pageNum:counter
        });
        }
      }
      return ret;
    }

    getCurrentPage = () => this.pages.find( p => p.pageNum === this.state.currentPage );

    renderPaginator = () => (
        <div className="row d-flex justify-content-between">
            <button disabled={this.state.currentPage===1} className="btn btn-outline-secondary border-0" onClick={()=>this.goToWeek(this.state.currentPage-1)}><i className="fas fa-arrow-left"></i></button>
            <span><Trans i18nKey="Week" /> {this.state.currentPage}</span>
            <button  disabled={this.state.currentPage===this.pages.length} className="btn btn-outline-secondary border-0" onClick={()=>this.goToWeek(this.state.currentPage+1)}><i className="fas fa-arrow-right"></i></button>
        </div>
    )

    goToWeek(week){
        this.setState({currentPage:week});
    }

    renderResource(resource){
        if(this.props.renderResource && _.isFunction(this.props.renderResource)){
            return this.props.renderResource(resource);
        }
        return <p>{resource.toString()}</p>
    }

    renderCell(resource,date){
        if(this.props.renderCell && _.isFunction(this.props.renderCell)){
            return this.props.renderCell(resource,date);
        }
        return <p>Test</p>
    }

    renderTotalDays(){
        if(this.props.renderTotalDays && _.isFunction(this.props.renderTotalDays)){

            return <tr>
                    <td><strong><Trans i18nKey="Total" /></strong></td>
                    {this.getCurrentPage().daysOfWeek.map(p=>
                        p.type==="date"?
                        <td key={"lld"+p.date.format("dddd DD")} data-label={p.date.format("dddd DD")}><strong>{this.props.renderTotalDays(p,this.getCurrentPage().daysOfWeek)}</strong></td>
                        :<td key={"lldtotal"} data-label="totale"><strong>{this.props.renderTotalDays(p,this.getCurrentPage().daysOfWeek.filter(s=>s.type==='date' && !s.disabled ))}</strong></td>
                    )}
                </tr>

        }
        return null;
    }

    toogleGroup(group){
        if(group){
            const groups=this.state.groups.map(g=>{
                if(g.id===group.id){
                    g.expanded=!g.expanded
                }
                return g;
            })
            this.setState({groups:groups});
        }
    }

    render = () => {
        if(this.state.currentPage===0 || !this.pages)
            return "";
        return (
            <React.Fragment>
                {this.renderPaginator()}

                <table className="w-100 responsivetable">
                    <thead>
                        <tr>
                            <th className="w-25"></th>
                            {
                                this.getCurrentPage().daysOfWeek.map(p=>p.type==="date"?
                                                    <th key={"h"+p.date.format("ddddMM")}>{p.date.format("dddd DD")}</th>:
                                                    <th key={"htotale"}><Trans i18nKey="Total" /></th>)
                            }
                        </tr>
                    </thead>
                    <tbody>

                    {
                        !this.props.SAL && this.state.groups.map(group=>{
                            return (
                                <React.Fragment>
                                    <tr style={{background:'#f0f0f0',borderBottom:'3px solid #fff'}}>
                                        <td colSpan="1000" style={{'textAlign':"left",fontSize:18}}>
                                            <button className="btn btn-sm btn-link" onClick={()=>this.toogleGroup(group)}>
                                                {
                                                group.expanded?
                                                <i className="fas fa-caret-down"></i>:
                                                <i className="fas fa-caret-right"></i>
                                                }
                                            </button>
                                            {group.label}
                                        </td>
                                    </tr>
                                    {
                                        group.expanded &&

                                        this.state.resources.filter(r=> _.get(r,group.resourceField)===group.id)
                                            .map(resource=>(
                                            <tr key={group.id}>
                                                <td className="text-left" data-label={this.props.resourceLabel || ''}>{this.renderResource(resource)}</td>
                                                {this.getCurrentPage().daysOfWeek.map(p=>
                                                    p.type==="date"?
                                                    <td key={"d"+p.date.format("ddddMM")} data-label={p.date.format("dddd DD")}>{this.renderCell(resource,p,group)}</td>
                                                    :<td key={"dtotale"} data-label="totale"><strong>{p.render(resource,this.getCurrentPage().daysOfWeek.filter(s=>s.type==='date' && !s.disabled))}</strong></td>
                                                )}
                                            </tr>
                                            )
                                        )


                                    }
                                </React.Fragment>
                                )
                        })
                    }

                    { this.props.SAL && this.state.resources
                        .map(resource=>(
                            <React.Fragment>
                                <tr key={resource.toString()}>
                                    <td className="text-left" data-label={this.props.resourceLabel || ''}>{this.renderResource(resource)}</td>
                                    {this.getCurrentPage().daysOfWeek.map(p=>
                                        p.type==="date"?
                                            <td key={"d"+p.date.format("ddddMM")} data-label={p.date.format("dddd DD")}>{this.renderCell(resource,p)}</td>
                                            :<td key={"dtotale"} data-label="totale"><strong>{p.render(resource,this.getCurrentPage().daysOfWeek.filter(s=>s.type==='date' && !s.disabled))}</strong></td>
                                    )}
                                </tr>
                            </React.Fragment>
                            )
                        )}


                    {
                        this.renderTotalDays()
                    }

                    </tbody>
                </table>

            </React.Fragment>
        )
    }


}
