import StubRepo from "../../repos/StubRepo";
import ProvinciaMockRepo from "./repositories/Provincia.mock";
import ProvinciaHttpRepo from "./repositories/Provincia.http";

export default class Provincia extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Provincia repo";
  }

  static getLocal() {
    if (Provincia._localInstance == null) {
      Provincia._localInstance = new ProvinciaMockRepo();
    }
    return Provincia._localInstance;
  }

  static getService() {
    if (Provincia._serviceInstance == null) {
      Provincia._serviceInstance = new ProvinciaHttpRepo();
    }
    return Provincia._serviceInstance;
  }
}
