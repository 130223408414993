import StubRepo from "../../repos/StubRepo";
import RelTipoDurataFasciaOrariaMockRepo from "./repositories/RelTipoDurataFasciaOraria.mock";
import RelTipoDurataFasciaOrariaHttpRepo from "./repositories/RelTipoDurataFasciaOraria.http";

export default class RelTipoDurataFasciaOraria extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "RelTipoDurataFasciaOraria repo";
  }

  static getLocal() {
    if (RelTipoDurataFasciaOraria._localInstance == null) {
      RelTipoDurataFasciaOraria._localInstance = new RelTipoDurataFasciaOrariaMockRepo();
    }
    return RelTipoDurataFasciaOraria._localInstance;
  }

  static getService() {
    if (RelTipoDurataFasciaOraria._serviceInstance == null) {
      RelTipoDurataFasciaOraria._serviceInstance = new RelTipoDurataFasciaOrariaHttpRepo();
    }
    return RelTipoDurataFasciaOraria._serviceInstance;
  }
}
