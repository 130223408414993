import StubRepo from "../../repos/StubRepo";
import ComuneBannerMockRepo from "./repositories/ComuneBanner.mock";
import ComuneBannerHttpRepo from "./repositories/ComuneBanner.http";

export default class ComuneBanner extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "ComuneBanner repo";
  }

  static getLocal() {
    if (ComuneBanner._localInstance == null) {
      ComuneBanner._localInstance = new ComuneBannerMockRepo();
    }
    return ComuneBanner._localInstance;
  }

  static getService() {
    if (ComuneBanner._serviceInstance == null) {
      ComuneBanner._serviceInstance = new ComuneBannerHttpRepo();
    }
    return ComuneBanner._serviceInstance;
  }
}
