import StubRepo from "../../repos/StubRepo";
import TipologiaVeicoloMockRepo from "./repositories/TipologiaVeicolo.mock";
import TipologiaVeicoloHttpRepo from "./repositories/TipologiaVeicolo.http";

export default class TipologiaVeicolo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaVeicolo repo";
  }

  static getLocal() {
    if (TipologiaVeicolo._localInstance == null) {
      TipologiaVeicolo._localInstance = new TipologiaVeicoloMockRepo();
    }
    return TipologiaVeicolo._localInstance;
  }

  static getService() {
    if (TipologiaVeicolo._serviceInstance == null) {
      TipologiaVeicolo._serviceInstance = new TipologiaVeicoloHttpRepo();
    }
    return TipologiaVeicolo._serviceInstance;
  }
}
