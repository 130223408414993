import StubRepo from "../../repos/StubRepo";
import RelTipoPrenotazioneTipoDurataMockRepo from "./repositories/RelTipoPrenotazioneTipoDurata.mock";
import RelTipoPrenotazioneTipoDurataHttpRepo from "./repositories/RelTipoPrenotazioneTipoDurata.http";

export default class RelTipoPrenotazioneTipoDurata extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "RelTipoPrenotazioneTipoDurata repo";
  }

  static getLocal() {
    if (RelTipoPrenotazioneTipoDurata._localInstance == null) {
      RelTipoPrenotazioneTipoDurata._localInstance = new RelTipoPrenotazioneTipoDurataMockRepo();
    }
    return RelTipoPrenotazioneTipoDurata._localInstance;
  }

  static getService() {
    if (RelTipoPrenotazioneTipoDurata._serviceInstance == null) {
      RelTipoPrenotazioneTipoDurata._serviceInstance = new RelTipoPrenotazioneTipoDurataHttpRepo();
    }
    return RelTipoPrenotazioneTipoDurata._serviceInstance;
  }
}
