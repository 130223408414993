export const ROLES = {
  SUPER_ADMIN: "SA",
  ADMIN_COMUNE: "AC",
  UTENTE_GENERICO: "U",
  GESTORE_AREA: "GA",
  CENSORE_AREA: "CA",
  CENSORE_PARCHEGGI: "CP",
  ADDETTO_SICUREZZA: "AS",
  CENTRALE_OPERATIVA_SICUREZZA: "COS",
  RESPONSABILE_VARCHI_STRADALI: "RVS",
  RESPONSABILI_VARCHI_AREE: "RVA",
  DIPENDENTE_AREA: "DP",
  ADDETTO_INFOPOINT: "AI",
  AUSILIARE_TRAFFICO: "AT",
};
