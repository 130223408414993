export const ON_GOOGLE_LOGIN = "ON_GOOGLE_LOGIN";
export const SUCCESS_GOOGLE_LOGIN = "SUCCESS_GOOGLE_LOGIN";
export const ON_GOOGLE_LOGOUT = "ON_GOOGLE_LOGOUT";
export const SUCCESS_GOOGLE_LOGOUT = "SUCCESS_GOOGLE_LOGOUT";

export const ON_HR_LOGIN = "ON_HR_LOGIN";
export const SUCCESS_HR_LOGIN = "SUCCESS_HR_LOGIN";
export const ERROR_HR_LOGIN = "ERROR_HR_LOGIN";
export const ON_HR_LOGOUT = "ON_HR_LOGIN";

export const ON_TAAL_LOGIN = "ON_TAAL_LOGIN";
export const SUCCESS_TAAL_LOGIN = "SUCCESS_TAAL_LOGIN";
export const ERROR_TAAL_LOGIN = "ERROR_TAAL_LOGIN";
export const ON_TAAL_LOGOUT = "ON_TAAL_LOGOUT";
export const TRIGGER_LOGOUT_NO_LOGIN = "TRIGGER_LOGOUT_NO_LOGIN";

export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";

export const READ_ROLES = "READ_ROLES";
export const SUCCESS_ROLES = "SUCCESS_ROLES";
export const ERROR_ROLES = "ERROR_ROLES";

export const TRIGGER_REGISTER = "TRIGGER_REGISTER";
export const SUCCESS_REGISTER = "SUCCESS_REGISTER";
export const ERROR_REGISTER = "ERROR_REGISTER";

export const TRIGGER_OTP = "TRIGGER_OTP";
export const SUCCESS_OTP = "SUCCESS_OTP";
export const ERROR_OTP = "ERROR_OTP";

export const TRIGGER_SEND_OTP = "TRIGGER_SEND_OTP";
export const TRIGGER_LOGOUT = "TRIGGER_LOGOUT";
export const SUCCESS_LOGOUT = "SUCCESS_LOGOUT";

export const TRIGGER_PASSWORD_RECOVERY = "TRIGGER_PASSWORD_RECOVERY";
export const SUCCESS_PASSWORD_RECOVERY = "SUCCESS_PASSWORD_RECOVERY";
export const ERROR_PASSWORD_RECOVERY = "ERROR_PASSWORD_RECOVERY";

export const TRIGGER_CHANGE_PASSWORD = "TRIGGER_CHANGE_PASSWORD";
export const SUCCESS_CHANGE_PASSWORD = "SUCCESS_CHANGE_PASSWORD";
export const ERROR_CHANGE_PASSWORD = "ERROR_CHANGE_PASSWORD";
export const TRIGGER_CHANGE_PASSWORD_SAFE = "TRIGGER_CHANGE_PASSWORD_SAFE";

export const TRIGGER_REGISTER_VARCO = "TRIGGER_REGISTER_VARCO";
