import React from "react";
import RxComponent from "../../../redux/RxComponent";
import AreaDetails from "../../Area/components/Area.details";
import PrenotazioneTipoPrenotazione from "./Prenotazione.tipoprenotazione";
import {
  triggerInsertPrenotazione,
  triggerUpdatePrenotazione,
} from "../../../redux/Prenotazione/Prenotazione.actions";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Trans } from "react-i18next";
import PrenotazioneVeicoloForm from "./Prenotazione.veicolo.form";
import { Row, Col } from "reactstrap";
import PrenotazioneInsertAnagrafica from "./Prenotazione.insertAnagrafica";
import { onError, onSuccess } from "../../../redux/Shared/Shared.actions";
import { ON_SUCCESS_UPDATE_PRENOTAZIONE } from "../../../redux/Prenotazione/Prenotazione.types";
import SettingsBlock from "../../../components/Settings/SettingsBlock";
import { ROLES } from "../../../constants/Roles";
import {AREE} from "../../../constants/Aree";

class Comp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorsAll: null,
      resAll: null,
      loading: true,
      readOnly: this.props.readOnly,
    };
  }

  componentDidUpdate() {
    if (
      this.props.Prenotazione.type === ON_SUCCESS_UPDATE_PRENOTAZIONE &&
      this.handleUpdate
    ) {
      this.handleUpdate = false;
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 2000);
  }

  validateAll = () => {
    let errors = [];
    let errorsTipoPrenotzaione = this.prenotazioneTipoPrenotazioneChild ?
        this.prenotazioneTipoPrenotazioneChild.validate()
        :[];
    let errorsVeicolo = this.prenotazioneVeicoloFormChild
      ? this.prenotazioneVeicoloFormChild.validate()
      : [];
    let errorsAnagrafica = this.prenotazioneInsertAnagraficaChild.validate();
    errors.push(
      ...errorsTipoPrenotzaione,
      ...errorsVeicolo,
      ...errorsAnagrafica
    );
    this.setState({
      errorsAll: errors,
    });
    return errors;
  };

  validateAllUpdate = () => {
    let errors = [];
    let errorsVeicolo = this.prenotazioneVeicoloFormChild
      ? this.prenotazioneVeicoloFormChild.validate()
      : [];
    let errorsAnagrafica = this.prenotazioneInsertAnagraficaChild.validate();
    let errorsTipoPrenotzaione = this.prenotazioneTipoPrenotazioneChild ?
        this.prenotazioneTipoPrenotazioneChild.validateUpd()

        :[];
    if (!this.props.Prenotazione.selectedFields.idPrenotazione) {
      errors.push(this.props.t("NO_ID_PRENOTAZIONE"));
    }
    errors.push(
      ...errorsVeicolo,
      ...errorsAnagrafica,
      ...errorsTipoPrenotzaione
    );
    this.setState({
      errorsAll: errors,
    });
    return errors;
  };

  collectAllData = () => {
    const err = this.validateAll();
    if (err.length) {
      this.props.onError(err.join("\n"));
      return;
    }

    let res = {
      numeroOccupanti:
        this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
        ROLES.GESTORE_AREA
          ? this.props.numeroOccupanti
          : this.props.Prenotazione.selectedFields.numeroOccupanti,

      //idArea: this.props.Prenotazione.selectedArea.IDAREA,
      idArea:
        this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
        ROLES.GESTORE_AREA
          ? this.props.Prenotazione.selectedArea.IDAREA
          : this.props.idArea,
      //emailArea: this.props.Prenotazione.selectedArea.EMAIL,
      emailArea:
        this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
        ROLES.GESTORE_AREA
          ? this.props.Prenotazione.selectedArea.EMAIL
          : this.props.email,
      selectedTipologiaPrenotazione: null,
      selectedTipologiaPrenotazioneId: null,
      selectedFasciaOraria: null,
      selectedFasciaOrariaId: null,
      selectedDataInizio: null,
      selectedDataFine: null,
      selectedTipoVeicolo: null,
      selectedTipoVeicoloId: null,
      targaVeicolo: null,
      anagrafiche: [],
      isEvento : this.props.Prenotazione.selectedArea.CODICETIPOLOGIAAREA === AREE.EVENTO
    };

    let resAnagrafica = this.prenotazioneInsertAnagraficaChild.getData();

    if (this.prenotazioneTipoPrenotazioneChild) {

      let resTipoPrenotzaione = this.prenotazioneTipoPrenotazioneChild.getData();
      res.selectedTipologiaPrenotazione =
          resTipoPrenotzaione.selectedTipologiaPrenotazione;
      res.selectedTipologiaPrenotazioneId =
          resTipoPrenotzaione.selectedTipologiaPrenotazione.idTipologiaPrenotazione;
      res.selectedFasciaOraria = resTipoPrenotzaione.selectedFasciaOraria;
      res.selectedFasciaOrariaId =
          resTipoPrenotzaione.selectedFasciaOraria.idFasciaOraria;
      res.selectedDataInizio = resTipoPrenotzaione.dataInizioCompleta;
      res.selectedDataFine = resTipoPrenotzaione.dataFineCompleta;
    } else {
      res.selectedDataInizio = this.props.Prenotazione.selectedFields.dataPrenotazione + "0000"
      res.selectedDataFine = this.props.Prenotazione.selectedFields.dataPrenotazione + "2359"
    }

    if (this.prenotazioneVeicoloFormChild) {
      let resVeicolo = this.prenotazioneVeicoloFormChild.getData();
      if (
        resVeicolo.selectedTipoVeicolo[0] &&
        resVeicolo.selectedTipoVeicolo[0].idTipologiaVeicolo
      ) {
        res.selectedTipoVeicolo = resVeicolo.selectedTipoVeicolo[0];
        res.selectedTipoVeicoloId =
          resVeicolo.selectedTipoVeicolo[0].idTipologiaVeicolo;
        res.targaVeicolo = resVeicolo.targaVeicolo;
        if (
          resVeicolo.selectedParcheggio &&
          resVeicolo.selectedParcheggio.length &&
          resVeicolo.selectedParcheggio[0] &&
          resVeicolo.selectedParcheggio[0].IDPARCHEGGIO
        ) {
          res.idParcheggio = resVeicolo.selectedParcheggio[0].IDPARCHEGGIO;
        }
      }
    }

    res.anagrafiche.push(...resAnagrafica.listPax);

    /*if (
      this.props.Auth.user.TipologiaProfilo.codiceProfilo === ROLES.GESTORE_AREA
    ) {
      res.tipologiaDocumento = this.props.tipologiaDocumentoUserAssegnato;
      res.numeroDocumento = this.props.numeroDocumentoUserAssegnato;
    }*/

    if (window.location.href.indexOf("/dashboard") >= 0) {
      res.IDUTENTEPRENOTATO =
        this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
        ROLES.GESTORE_AREA
          ? this.props.idUtenteAssegnato
          : this.props.Prenotazione.userAssegnato;
    }

    res.idComune = this.props.Prenotazione.selectedFields.idComune;

    this.props.triggerInsertPrenotazione(res);
    this.setState({ resAll: res });
  };

  collectAllDataForUpdate = () => {
    const err = this.validateAllUpdate();
    if (err.length) {
      this.props.onError(err.join("\n"));
      return;
    }
    let res = {};
    let resAnagrafica = this.prenotazioneInsertAnagraficaChild.getData();
    if (this.props.fromVarco) {
      res = {
        idPrenotazione: this.props.Prenotazione.selectedFields.idPrenotazione,
        numeroOccupanti: this.props.Prenotazione.selectedFields.numeroOccupanti,
        idVeicolo: this.props.Prenotazione.selectedFields.veicolo
          ? this.props.Prenotazione.selectedFields.veicolo.idVeicolo
          : null,
        idUtente: this.props.Auth.user.idUtente,
        selectedTipoVeicolo: null,
        selectedTipoVeicoloId: null,
        targaVeicolo: null,
        anagrafiche: [],
        dataInizioCompleta: null,
      };
    } else {
      res = {
        idPrenotazione: this.props.prenotazioneId,
        numeroOccupanti: this.props.Prenotazione.selectedFields.numeroOccupanti,
        idVeicolo: this.props.veicoloId,
        idUtente: this.props.Auth.user.idUtente,
        selectedTipoVeicolo: null,
        selectedTipoVeicoloId: null,
        targaVeicolo: null,
        anagrafiche: [],
        dataInizioCompleta: null,
      };
    }
    if (this.prenotazioneTipoPrenotazioneChild) {
      let resTipoPrenotzaione = this.prenotazioneTipoPrenotazioneChild.getData();
      res.dataInizioCompleta = resTipoPrenotzaione.dataInizioCompleta;
    } else {
      res.dataInizioCompleta = this.props.Prenotazione.selectedFields.dataPrenotazione + "0000"
    }

    if (this.prenotazioneVeicoloFormChild) {
      let resVeicolo = this.prenotazioneVeicoloFormChild.getData();
      if (
        resVeicolo.selectedTipoVeicolo[0] &&
        resVeicolo.selectedTipoVeicolo[0].idTipologiaVeicolo
      ) {
        res.selectedTipoVeicolo = resVeicolo.selectedTipoVeicolo[0];
        res.selectedTipoVeicoloId =
          resVeicolo.selectedTipoVeicolo[0].idTipologiaVeicolo;
        res.targaVeicolo = resVeicolo.targaVeicolo;
        if (
          resVeicolo.selectedParcheggio &&
          resVeicolo.selectedParcheggio.length &&
          resVeicolo.selectedParcheggio[0] &&
          resVeicolo.selectedParcheggio[0].IDPARCHEGGIO
        ) {
          res.idParcheggio = resVeicolo.selectedParcheggio[0].IDPARCHEGGIO;
        }
      } else {
        res.idVeicolo = null;
      }
    }

    /*res.selectedTipoVeicolo = resVeicolo.selectedTipoVeicolo[0];
    res.selectedTipoVeicoloId =
      resVeicolo.selectedTipoVeicolo[0].idTipologiaVeicolo;
    res.targaVeicolo = resVeicolo.targaVeicolo;*/
    res.anagrafiche.push(...resAnagrafica.listPax);
    res.numeroOccupanti = resAnagrafica.numeroOccupanti;

    res.idComune = this.props.Prenotazione.selectedFields.idComune;

    this.handleUpdate = true;
    this.props.triggerUpdatePrenotazione(res);
  };

  render() {
    console.log(this.props)
    const codiceProfilo = this.props.Auth.user.TipologiaProfilo.codiceProfilo;
    if (this.state.loading) return <p>{this.props.t("Loading")} ...</p>;

    return (
        <>
          <Row className="d-flex justify-content-center">
            <Col md={12}>
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={[
                  "DETTAGLIO_VEICOLO",
                  "DETTAGLIO_AREA",
                  "DETTAGLIO_PRENOTAZIONE",
                  "DETTAGLIO_ANAGRAFICA",
                ]}
              >
                <SettingsBlock
                  idComune={
                    this.props.Prenotazione.selectedFields
                      ? this.props.Prenotazione.selectedFields.idComune
                      : this.props.Auth.user.idComune
                  }
                  block="prenotazioneDetail"
                >
                  <AccordionItem
                    uuid="DETTAGLIO_AREA"
                    section="dettaglioArea"
                    hidden={
                      this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
                      ROLES.GESTORE_AREA
                    }
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {this.props.t("DETTAGLIO_AREA")}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <AreaDetails
                        idArea={
                          this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
                          ROLES.GESTORE_AREA
                            ? this.props.Prenotazione.selectedArea.IDAREA
                            : this.props.idArea
                        }
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  {(this.props.Prenotazione.selectedArea.CODICETIPOLOGIAAREA !== AREE.EVENTO
                      &&
                      this.props.Prenotazione.selectedArea.IDTIPOLOGIAAREA !== 9) && (
                      <AccordionItem
                        uuid="DETTAGLIO_PRENOTAZIONE"
                        section="dettaglioPrenotazione"
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {this.props.t("DETTAGLIO_PRENOTAZIONE")}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <PrenotazioneTipoPrenotazione
                            onRef={(ref) =>
                              (this.prenotazioneTipoPrenotazioneChild = ref)
                            }
                            data={
                              codiceProfilo !== ROLES.GESTORE_AREA
                                ? this.props.Prenotazione.selectedFields
                                    .dataPrenotazione
                                : this.props.dataPrenotazione
                            }
                            numeroOccupanti={
                              codiceProfilo !== ROLES.GESTORE_AREA
                                ? this.props.Prenotazione.selectedFields.numeroOccupanti
                                : this.props.numeroOccupanti
                            }
                            idArea={
                              codiceProfilo !== ROLES.GESTORE_AREA
                                ? this.props.Prenotazione.selectedArea.IDAREA
                                : this.props.idArea
                            }
                            fasciaOraria={
                              this.props.Prenotazione.selectedFields.fasciaOraria
                            }
                            tipologiaPrenotazione={
                              this.props.Prenotazione.selectedFields
                                .tipologiaPrenotazione
                            }
                            isEdit={this.props.isEdit}
                            dataInizio={this.props.dataInizio}
                            readOnly={this.state.readOnly}
                            isAusiliareTraffico={this.props.isAusiliareTraffico}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                  )}
                  {this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
                    ROLES.GESTORE_AREA && (
                    <AccordionItem
                      uuid="DETTAGLIO_VEICOLO"
                      section="dettaglioVeicolo"
                      hidden={
                        this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
                        ROLES.GESTORE_AREA
                      }
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {this.props.t("DETTAGLIO_VEICOLO")}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <PrenotazioneVeicoloForm
                          onRef={(ref) => (this.prenotazioneVeicoloFormChild = ref)}
                          selectedTipoVeicolo={
                            this.props.Prenotazione.selectedFields.veicolo
                              ? [
                                  this.props.Prenotazione.selectedFields.veicolo
                                    .TipologiaVeicolo,
                                ]
                              : null
                          }
                          selectedTarga={
                            this.props.Prenotazione.selectedFields.veicolo
                              ? this.props.Prenotazione.selectedFields.veicolo.targa
                              : null
                          }
                          readOnly={this.state.readOnly}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  )}
                  <AccordionItem
                    hidden={
                      this.props.isEdit
                        ? false
                        : this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
                          ROLES.GESTORE_AREA
                        ? this.props.numeroOccupanti === 1
                        : this.props.Prenotazione.selectedFields.numeroOccupanti ===
                          1
                    }
                    uuid="DETTAGLIO_ANAGRAFICA"
                    section="dettaglioAnagrafica"
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {this.props.t("DETTAGLIO_ANAGRAFICA")}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <PrenotazioneInsertAnagrafica
                        onRef={(ref) =>
                          (this.prenotazioneInsertAnagraficaChild = ref)
                        }
                        numeroPasseggeri={
                          codiceProfilo === ROLES.GESTORE_AREA
                            ? this.props.numeroOccupanti
                            : this.props.numeroPax
                            ? this.props.numeroPax
                            : this.props.Prenotazione.selectedFields.numeroOccupanti
                        }
                        data={
                          this.props.Prenotazione.selectedFields.listaPasseggeri
                        }
                        isEdit={this.props.isEdit}
                        maxOccupanti={
                          this.props.isEdit ? this.props.maxOccupanti : null
                        }
                        readOnly={this.state.readOnly}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <div className="d-flex w-100 justify-content-end py-3 px-3">
                    <button
                      hidden={this.props.isWrongComune || this.props.readOnly}
                      onClick={
                        this.props.isEdit
                          ? this.collectAllDataForUpdate
                          : this.collectAllData
                      }
                      className="btn btn-secondary btn-submit"
                    >
                      {this.props.isEdit
                        ? this.props.t("EDIT")
                        : this.props.t("BOOK_NOW")}
                    </button>
                  </div>
                </SettingsBlock>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <span className="m-3">
              {this.props.t("THESE_FIELDS_ARE_REQUIRED")}
            </span>
          </Row>
        </>
    );
  }
}

const PrenotazioneDetailForm = RxComponent({
  triggerInsertPrenotazione,
  onError,
  triggerUpdatePrenotazione,
})(Comp);
export default PrenotazioneDetailForm;
