import HttprepoTAAL from "../../../repos/HttpRepoTaal";

export default class CentraleOperativaHttpRepo extends HttprepoTAAL {
  
    getTable() {
        return "NotificaCentraleOperativa";
    }

    getStatisticByDateAndComune(date,comune){
        return this.exec('fn_GetCentraleReportComune',{
            idComune:comune,
            data: date
        });
    }

}
