import React from "react";
import _ from "lodash";
import $ from "jquery";
import "../../template/dashboard/js/bootstrap-datepicker";
import "../../template/dashboard/js/bootstrap-datepicker.it";
import moment from "moment";
import { SelectPicker } from "../Content/SelectPicker";
import { Trans } from "react-i18next";

export const FILTER_TEXT = "text";
export const FILTER_NUMBER = "number";
export const FILTER_LIST = "select";
export const FILTER_RANGE = "range";
export const FILTER_MULTIPLE = "multiple";
export const FILTER_NOT_NULL = "not_null";
export const FILTER_NULL = "null";
export const FILTER_TEXT_EQUALS = "text_equals";

export default class FilterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      type: "",
      values: [],
      data: "",
      options: [],
      multipleValues: [],
    };
  }

  componentDidMount() {
    let changed = {};
    if (this.state.label !== this.props.label) {
      changed.label = this.props.label;
    }
    if (this.state.type !== this.props.type) {
      changed.type = this.props.type;
    }
    if (this.props.values && this.state.values !== this.props.values) {
      changed.values = this.props.values;
    }
    if (this.state.data !== this.props.data) {
      changed.data = this.props.data;
    }
    if (this.state.options !== this.props.options) {
      changed.options = this.props.options;
    }
    if (!_.isEmpty(changed)) this.setState(changed);
  }

  componentDidUpdate(prevProps, prevState) {
    let changed = {};
    if (this.state.label !== this.props.label) {
      changed.label = this.props.label;
    }
    if (this.state.type !== this.props.type) {
      changed.type = this.props.type;
    }
    if (this.props.values && this.state.values !== this.props.values) {
      changed.values = this.props.values;
    }
    if (this.state.data !== this.props.data) {
      changed.data = this.props.data;
    }
    if (this.state.options !== this.props.options) {
      changed.options = this.props.options;
    }
    if (!_.isEmpty(changed)) this.setState(changed);
  }

  textInput(label, data, value) {
    return (
      <React.Fragment>
        <label htmlFor={data} className="mr-2">
          {label}
        </label>
        <input
          onChange={this.onChangeInput.bind(this)}
          type="text"
          className="form-control"
          id={data}
          value={value}
        />
      </React.Fragment>
    );
  }

  numberInput(label, data, value) {
    return (
      <React.Fragment>
        <label htmlFor={data} className="mr-2">
          {label}
        </label>
        <input
          onChange={this.onChangeInput.bind(this)}
          type="number"
          className="form-control"
          id={data}
          value={value}
        />
      </React.Fragment>
    );
  }

  multipleInput(label, data, options, values) {
    return (
      <React.Fragment>
        <label htmlFor={data} className="mr-2">
          {label}
        </label>
        <SelectPicker
          values={this.state.multipleValues}
          options={options}
          displayValue="text"
          multiple
          onChange={(ev) => {
            this.onChangeMultiple(ev);
          }}
        />
      </React.Fragment>
    );
  }

  listInput(label, data, options, values) {
    return (
      <React.Fragment>
        <label htmlFor={data} className="mr-2">
          {label}
        </label>
        <select
          className="form-control"
          id={data}
          value={values}
          onChange={this.onChangeInput.bind(this)}
        >
          {
            //!values || !values.length?
            <option value="">&nbsp;</option>
            //<option>&nbsp;</option>
          }
          {options && options.length
            ? options.map((o) => {
                return (
                  <option key={`${o.key}-${o.text}`} value={o.key}>
                    {o.text}
                  </option>
                );
              })
            : null}
        </select>
      </React.Fragment>
    );
  }

  rangeInput(label, data, da, a) {
    return (
      <fieldset>
        <legend>
          {label}
          <span className="sr-only">
            {" "}
            <Trans i18nKey="FormatDateGG" />
          </span>
        </legend>
        <div className="row">
          <div className="col-12 col-sm">
            <div className="form-group">
              <label htmlFor={`${data}-da`}>
                <Trans i18nKey="From" />:
              </label>
              <div className="input-group date da">
                <input
                  id={`${data}-da`}
                  type="text"
                  className="form-control"
                  maxLength="10"
                />
                <div className="input-group-append" aria-hidden="true">
                  <button
                    type="button"
                    className="btn btn-primary"
                    tabIndex="-1"
                  >
                    <i className="far fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm">
            <div className="form-group">
              <label htmlFor={`${data}-a`}>
                <Trans i18nKey="To" />:
              </label>
              <div className="input-group date a">
                <input
                  id={`${data}-a`}
                  type="text"
                  className="form-control"
                  maxLength="10"
                />
                <div className="input-group-append" aria-hidden="true">
                  <button
                    type="button"
                    className="btn btn-primary"
                    tabIndex="-1"
                  >
                    <i className="far fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }

  onChange() {
    if (this.props.onChangeFilter && _.isFunction(this.props.onChangeFilter)) {
      this.props.onChangeFilter(
        `${this.state.type}&&&${this.state.data}`,
        this.state.values
      );
    }
  }

  onChangeInput(event) {
    let value = event.target.value;
    if (!value || !value.length) {
      value = null;
    }
    if (!_.isArray(value)) {
      value = [value];
    }
    this.setState(
      {
        values: [...value],
      },
      this.onChange.bind(this)
    );
  }

  onChangeDate = (cc, index) => {
    let date = moment(cc);
    let dates = [this.state.values[0], this.state.values[1]];
    if (
      index === 1 &&
      this.state.values[0] &&
      moment(this.state.values[0], "DD/MM/YYYY").isAfter(date)
    ) {
      dates = [date.format("DD/MM/YYYY"), date.format("DD/MM/YYYY")];
      $(".input-group.date.da").datepicker("setDate", dates[0]);
      dates[0] = date.format("DD/MM/YYYY");
    } else if (
      index === 0 &&
      this.state.values[1] &&
      date.isAfter(moment(this.state.values[1], "DD/MM/YYYY"))
    ) {
      dates = [date.format("DD/MM/YYYY"), date.format("DD/MM/YYYY")];
      $(".input-group.date.a").datepicker("setDate", dates[1]);
      dates[1] = date.format("DD/MM/YYYY");
    }
    //if(dates[index]!==moment(date).format('DD/MM/YYYY')){
    dates[index] = moment(date).format("DD/MM/YYYY");
    this.setState(
      {
        values: dates,
      },
      _.debounce(this.onChange.bind(this), 200)
    );
    // }
  };

  onChangeMultiple(ev) {
    this.setState(
      { multipleValues: ev, values: ev.map((e) => e.key) },
      this.onChange.bind(this)
    );
  }

  getItem = () => {
    let ret = null;
    switch (this.state.type) {
      case FILTER_TEXT:
        ret = this.textInput(
          this.state.label,
          this.state.data,
          this.state.values[0]
        );
        break;
      case FILTER_NUMBER:
        ret = this.numberInput(
          this.state.label,
          this.state.data,
          this.state.values[0]
        );
        break;
      case FILTER_LIST:
        ret = this.listInput(
          this.state.label,
          this.state.data,
          this.state.options,
          this.state.values[0]
        );
        break;
      case FILTER_MULTIPLE:
        ret = this.multipleInput(
          this.state.label,
          this.state.data,
          this.state.options,
          this.state.values[0]
        );
        break;
      case FILTER_RANGE:
        ret = this.rangeInput(
          this.state.label,
          this.state.data,
          this.state.values[0],
          this.state.values.length > 1 ? this.state.values[1] : ""
        );
        setTimeout(() => {
          $(".input-group.date").datepicker("destroy");
          $(".input-group.date").off("changeDate");
          $(".input-group.date")
            .datepicker({
              format: "dd/mm/yyyy",
              maxViewMode: 2,
              language: "it",
              orientation: "auto right",
              keyboardNavigation: false,
              forceParse: false,
              autoclose: true,
              todayHighlight: true,
              showOnFocus: false,
              templates: {
                leftArrow: '<i  className="fas fa-chevron-left"></i>',
                rightArrow: '<i  className="fas fa-chevron-right"></i>',
              },
            })
            .on("changeDate", (ev) => {
              let c = $(ev.target).hasClass("da") ? 0 : 1;
              this.onChangeDate(ev.date, c);
            });
          /*$('.input-group.date-to').datepicker({
                            format: 'dd/mm/yyyy',
                            maxViewMode: 2,
                            language: 'it',
                            orientation: 'auto right',
                            keyboardNavigation: false,
                            forceParse: false,
                            autoclose: true,
                            todayHighlight: true,
                            showOnFocus: false,
                            templates: {
                                leftArrow: '<i  className="fas fa-chevron-left"></i>',
                                rightArrow: '<i  className="fas fa-chevron-right"></i>'
                            }
                        }).on('changeDate', (ev) =>{
                            this.onChangeDate(ev.date,1);
                        });*/
        }, 200);
        break;
      default:
        ret = this.textInput(
          this.state.label,
          this.state.data,
          this.state.values[0]
        );
        break;
    }
    return ret;
  };

  render() {
    return this.getItem();
  }
}
