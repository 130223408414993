import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import _ from "lodash";

export class SelectPickerOld extends React.Component {

    constructor(props) {
        super(props);
        this.values = _.cloneDeep(props.values) || [];
        this.state = {
            update: 0
        };
    }

    onSelect(optionsList, selectedItem) {
        if (this.props.multiple) this.values.push(selectedItem);
        else this.values = [selectedItem];
        this.notifyChange();
    }

    onRemove(optionList, removedItem) {
        this.values = this.values.filter(
            j => j[this.props.displayValue] !== removedItem[this.props.displayValue]
        );
        this.notifyChange();
    }

    notifyChange = () => {
        if (this.props.onChange && _.isFunction(this.props.onChange))
            this.props.onChange(this.values);
    };

    componentWillUpdate(nextProps, nextState) {
        if (this.values !== nextProps.values) {
            if (!_.isNull(nextProps.values) && !_.isUndefined(nextProps.values)) {
                if (_.isArray(nextProps.values)) this.values = nextProps.values;
                else this.values = [nextProps.values];
            } else {
                this.values = [];
            }
            nextState.update = this.state.update + 1;
        }
    }

    render() {
        let style = this.props.multiple
            ? { chips: { background: "#5a5c69" } }
            : {
                searchBox: { height: "38px" },
                chips: {
                    background: "white",
                    borderRadius: 0,
                    color: "#5a5c69"}
            };
        if(this.props.readOnly){
            style.multiselectContainer= { // To change css for multiselect (Width,height,etc..)
                background:'#eaecf4',
                pointerEvents:'none'
            }
        }
        return (
            <Multiselect
                options={this.props.options}
                showCheckbox={this.props.multiple}
                displayValue={this.props.displayValue}
                singleSelect={!this.props.multiple}
                //selectionLimit={!this.props.multiple ? 1 : -1}
                selectedValues={_.cloneDeep(this.values)}
                onSelect={this.onSelect.bind(this)}
                onRemove={this.onRemove.bind(this)}
                style={style}
                closeIcon="cancel"
            />
        );
    }
}
