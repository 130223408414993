import React from "react";
import _ from "lodash";
import $ from "jquery";
import moment from "moment-timezone";
import "react-phone-number-input/style.css";
import PhoneInput, {parsePhoneNumber, getCountryCallingCode} from "react-phone-number-input";
import "../../template/dashboard/css/custom.css";
import RxComponent from "../../redux/RxComponent";
import TipologiaDocumentoService from "../../modules/TipologiaDocumento/TipologiaDocumento.sevice";
import LinguaService from "../../modules/Lingua/Lingua.service";
import { triggerRegister, errorRegister } from "../../redux/Auth/Auth.actions";
import { onError } from "../../redux/Shared/Shared.actions";
import { generateArrFiscalCodes, isValidCF } from "../../services/fiscalCode";
import DatePicker from "../../components/Content/DatePicker";
import { FILTER_TEXT } from "../../components/TableServer/FilterItem";
import LoginService from "../Login/Login.service";

class Component extends React.PureComponent {
  tipologiaDocumentoService = new TipologiaDocumentoService();
  linguaService = new LinguaService();
  loginService = new LoginService();
  validPasswordRegExp = new RegExp(
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/
  );

  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      validDate: null,
      password: "",
      cntrlPassword: "",
      noMatch: false,
      isValidPwd: null,
      number: "",
      country: null,
      submitted: false,
      isForeign: false,
      TypeDocument: "",
      numberDoc: "",
      listTipologiaDocumento: [],
      listLingue: [],
      listComuni: [],
      privacy: false,
      validCF: false,
      birthPlace: { idComuneNascita: "", descrizione: "", codificaFiscale: "" },
    };
  }

  componentDidMount() {
    $(document).click((event) => {
      if (!$(event.target).closest(".form--input-typeahead-child").length) {
        if (this.state.birthPlace.codificaFiscale) {
          this.setState({ listComuni: [] });
        } else {
          this.setState({
            birthPlace: {
              idComuneNascita: "",
              descrizione: "",
              codificaFiscale: "",
            },
            listComuni: [],
          });
        }
      }
    });

    this.tipologiaDocumentoService
      .getAllNewApi() /*getAll()*/
      .then((res) => {
        this.setState(
          {
            listTipologiaDocumento: res.data.result,
          },
          this._selectFiscalCode.bind(this)
        );
      });
    this.linguaService
      .getAllNewApi() /*getAll()*/
      .then((res) => {
        this.setState({
          listLingue: res.data.result,
          Lingua: res.data.result[0].idLingua,
        });
      });
  }

  register(ev) {
    ev.preventDefault();
    this.setState({
      submitted: true,
    });
    if (!this.state.privacy) {
      return;
    }
    if (this.state.noMatch) {
      return this.props.onError("PasswordDontMatch");
    }
    if (!this.state.isValidPwd) {
      return this.props.onError("PASSWORD_NOT_VALID");
    }
    if (
      !this.state.validDate ||
      !moment(this.state.dataNascita, "YYYY-MM-DD").isBetween(
        moment("1900-01-01", "YYYY-MM-DD"),
        moment().subtract(14, "years"),
        undefined,
        "[]"
      )
    ) {
      return this.props.onError("DATE_INVALID");
    }
    /* next implementation
        @IDLINGUA						INT,
        @IDCOMUNE						INT = NULL,
        @USERNAME						VARCHAR(100),
        @PASSWORD						VARCHAR(100),
        @IDTIPOLOGIADOCUMENTO			INT,
        @NUMERODOCUMENTO				VARCHAR(20),
        @DATARILASCIO					CHAR(8) = NULL,
        @NOME							VARCHAR(100) = NULL,
        @COGNOME						VARCHAR(100) = NULL,
        @EMAIL							VARCHAR(100) = NULL,
        @CELLULARE						VARCHAR(20) = NULL,
        @DATADINASCITA					CHAR(8),
        @IDCOMUNEDINASCITA				INT,
        @SESSO							CHAR(1)
        */
    const data = {
      IDLINGUA: parseInt(this.state.Lingua),
      USERNAME:
        //   document
        // .querySelector(".PhoneInputInput")
        // .value.split(" ")
        // .join(""),
      this.state.userName,
      PASSWORD: this.state.password,
      IDTIPOLOGIADOCUMENTO: parseInt(this.state.TypeDocument),
      NUMERODOCUMENTO: this.state.numberDoc,
      CELLULARE: this.state.number,
      DATADINASCITA: this.state.dataNascita.split("-").join(""),
      NOME: this.state.nome,
      COGNOME: this.state.cognome,
      IDCOMUNEDINASCITA: this.state.birthPlace.idComuneNascita
        ? this.state.birthPlace.idComuneNascita
        : null,
      SESSO: this.state.gender,
      EMAIL: this.state.email,
    };
    if (this.state.validCF || (this.state.isForeign && this.state.numberDoc)) {
      this.props.triggerRegister(data);
    } else {
      this.props.errorRegister(data);
    }
  }

  _selectFiscalCode() {
    const cf = this.state.listTipologiaDocumento.find(
      (d) => d.codiceTipologiaDocumento === "CF"
    ).idTipologiaDocumento;
    this.setState({
      TypeDocument: cf,
    });
  }

  getComune() {
    if (this.state.birthPlace.descrizione) {
      this.loginService
        .getComuneNascita(this.state.birthPlace.descrizione)
        .then((res) => {
          const listComuni = _.orderBy(
            res.data.result,
            (e) =>
              e.descrizione === this.state.birthPlace.descrizione.toUpperCase(),
            ["desc"]
          );
          this.setState({ listComuni: listComuni });
        });
    }
  }

  validateFiscalCode() {
    if (!this.state.isForeign && this.state.numberDoc.length === 16) {
      if (
        this.state.gender &&
        this.state.dataNascita &&
        this.state.nome &&
        this.state.cognome &&
        this.state.birthPlace.codificaFiscale
      ) {
        let res = isValidCF(
          generateArrFiscalCodes({
            sesso: this.state.gender,
            date: moment(this.state.dataNascita, "YYYY-MM-DD"),
            nome: this.state.nome.trim(),
            cognome: this.state.cognome.trim(),
            luogo: this.state.birthPlace.codificaFiscale.trim(),
          }),
          this.state.numberDoc
        );

        if (res) {
          this.setState({ validCF: true });
          return;
        }
      }
      this.setState({ validCF: false });
    }
  }

  getToken = (ev) => {
    ev.preventDefault();
    //this.recaptcha.execute();
    //npm install react-recaptcha-v3 --save
  };

  handleComuneChange(ev) {
    this.setState(
      {
        listComuni: [],
        birthPlace: {
          idComuneNascita: "",
          codificaFiscale: "",
          descrizione: ev.target.value,
        },
      },
      _.debounce(this.getComune.bind(this), 1000)
    );
  }

  /* onFocusOut(ev){
    if((moment(ev, "DD/MM/YYYY").isAfter(moment().subtract(14,"year"))) || (
      !inputVal.val() ||
      !inputVal.val().length ||
      !moment(inputVal.val(), "DD/MM/YYYY").isValid()
    ) ||
    (moment(ev, "DD/MM/YYYY").isBefore(moment("1900","YYYYY"))) ){

    }
  }*/

  templateHTML() {
    return (
      <div className="bg-gradient">
        {/*<ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey="6LcsYawZAAAAALPkaGjqQLnqGQHba8gBafGBz1tD"
            action='submit'
            verifyCallback={this.register.bind(this)}

        />*/}
        <div className="Login-wrapper fadeInDown">
          <div className="Login-form-Content">
            <div className="fadeIn first">
              <h1 className="Login-form--title">
                {this.props.t("Registration")}
              </h1>
            </div>
            <form onSubmit={this.register.bind(this)}>
              <div className="Login-form-cont">
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("NumberPhone")}*</label>
                </div>
                <div required className="form--input fadeIn first">
                  <PhoneInput
                    defaultCountry="IT"
                    required="true"
                    value={this.state.number}
                    onChange={(ev) => {
                      return this.setState({
                        number: ev,
                        userName: ev && parsePhoneNumber(ev)? parsePhoneNumber(ev).nationalNumber : null
                      });
                    }}
                    onCountryChange={(ev) => {
                     // return this.setState({country: ev}
                    }}
                  />
                </div>
                {/*<input*/}
                {/*  required*/}
                {/*  type="number"*/}
                {/*  id="number"*/}
                {/*  className="form--input fadeIn first"*/}
                {/*  onChange={(ev) => this.setState({ number: ev.target.value })}*/}
                {/*  name="number"*/}
                {/*/>*/}
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Email")}*</label>
                </div>
                <input
                  type="email"
                  id="email"
                  required
                  className="form--input fadeIn"
                  onChange={(ev) => this.setState({ email: ev.target.value })}
                  name="email"
                />
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Password")}*</label>
                </div>
                <input
                  required
                  maxLength={20}
                  minLength={8}
                  type="password"
                  id="password"
                  className="form--input fadeIn"
                  onChange={(ev) => {
                    return this.setState({
                      isValidPwd: this.validPasswordRegExp.test(
                        ev.target.value
                      ),
                      password: ev.target.value,
                    });
                  }}
                  onBlur={() => {
                    if (
                      this.state.password !== this.state.cntrlPassword &&
                      this.state.cntrlPassword !== "" &&
                      this.state.password !== ""
                    ) {
                      return this.setState({ noMatch: true });
                    } else {
                      return this.setState({ noMatch: false });
                    }
                  }}
                  name="password"
                />
                <div
                  className={`form-input-label-cont py-0 ${
                    !this.state.isValidPwd && this.state.isValidPwd !== null
                      ? "error-text"
                      : ""
                  }`}
                >
                  <p className="small">{this.props.t("PASSWORD_PATTERN")}</p>
                </div>
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("ControllPassword")}*</label>
                </div>
                <input
                  type="password"
                  id="cntrlPassword"
                  required
                  onChange={(ev) =>
                    this.setState({ cntrlPassword: ev.target.value })
                  }
                  onBlur={() => {
                    if (
                      this.state.password !== this.state.cntrlPassword &&
                      this.state.cntrlPassword !== "" &&
                      this.state.password !== ""
                    ) {
                      return this.setState({ noMatch: true });
                    } else {
                      return this.setState({ noMatch: false });
                    }
                  }}
                  className="form--input fadeIn"
                  name="psw"
                />
                <div
                  className="form-input-label-cont py-0 error-text"
                  hidden={!this.state.noMatch}
                >
                  <label>{this.props.t("PasswordDontMatch")}</label>
                </div>
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Name")}*</label>
                </div>
                <input
                  required
                  type="text"
                  id="nome"
                  className="form--input fadeIn"
                  onChange={(ev) =>
                    this.setState(
                      { nome: ev.target.value },
                      this.validateFiscalCode.bind(this)
                    )
                  }
                  name="nome"
                />
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Surname")}*</label>
                </div>
                <input
                  required
                  type="text"
                  id="cognome"
                  className="form--input fadeIn"
                  onChange={(ev) =>
                    this.setState(
                      { cognome: ev.target.value },
                      this.validateFiscalCode.bind(this)
                    )
                  }
                  name="cognome"
                />
                <div className="form-input-label-cont">
                  <input
                    style={{ width: "0", border: "0" }}
                    id="gender"
                    name="gender"
                    value={this.state.gender}
                    required
                  />
                  <label>{this.props.t("Gender")}*:</label>
                  <div className="radio-cont-child">
                    <div className="radio-cont-child">
                      <div
                        className="radio-input"
                        onClick={() =>
                          this.setState(
                            { gender: "M" },
                            this.validateFiscalCode.bind(this)
                          )
                        }
                      >
                        {this.state.gender === "M" && (
                          <div className="radio-input-active"></div>
                        )}
                      </div>
                      <label>M</label>
                    </div>
                    <div className="radio-cont-child">
                      <div
                        className="radio-input"
                        onClick={() =>
                          this.setState(
                            { gender: "F" },
                            this.validateFiscalCode.bind(this)
                          )
                        }
                      >
                        {this.state.gender === "F" && (
                          <div className="radio-input-active"></div>
                        )}
                      </div>
                      <label>F</label>
                    </div>
                  </div>
                </div>
                <div className="form-input-label-cont py-0 datepicker-form datepicker-form-required">
                  <DatePicker
                    //minDateEmpty={moment("1900", "YYYY")}
                    /* maxDateEmpty={moment()
                      .subtract(14, "years")
                      .format("DD/MM/YYYY")}*/
                    placeholder={this.props.t("PLACEHOLDER_DATE")}
                    required={true}
                    label={this.props.t("BirthDate")}
                    value={this.state.dataNascita}
                    endDate={moment()
                      .subtract(14, "years")
                      .format("DD/MM/YYYY")}
                    onChange={(ev) => {
                      if (ev.date) {
                        return this.setState(
                          {
                            validDate: true,
                            dataNascita: moment([
                              ev.date.getFullYear(),
                              ev.date.getMonth(),
                              ev.date.getDate(),
                            ]).format("YYYY-MM-DD"),
                          },
                          this.validateFiscalCode.bind(this)
                        );
                      } else {
                        return this.setState({
                          validDate: false,
                        });
                      }
                    }}
                    readOnly={this.state.readOnly}
                    format="DD/MM/YYYY"
                  />
                </div>
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("BirthPlace")}*</label>
                </div>
                <div className="relative fadeIn" style={{ zIndex: "1" }}>
                  <div className="relative">
                    <input
                      required={!this.state.isForeign}
                      disabled={this.state.isForeign}
                      value={this.state.birthPlace.descrizione}
                      className="form--input"
                      placeholder={this.props.t("TypeMunicipality")}
                      onChange={(ev) => this.handleComuneChange(ev)}
                    />
                    {this.state.listComuni.length > 0 && (
                      <div className="form--input-typeahead-cont">
                        {this.state.listComuni.map((l) => {
                          return (
                            <div
                              className="form--input-typeahead-child"
                              key={l.idComuneNascita}
                              value={l.descrizione}
                              onClick={() =>
                                this.setState(
                                  { birthPlace: l, listComuni: [] },
                                  this.validateFiscalCode.bind(this)
                                )
                              }
                            >
                              {l.descrizione}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <i
                    className="fa fa-chevron-down form-input-icon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Language")}*</label>
                </div>
                <div className="form-input-cont-select fadeIn">
                  <select
                    required
                    value={this.state.Lingua}
                    className="form-input-select"
                    onChange={(ev) =>
                      this.setState({ Lingua: ev.target.value })
                    }
                  >
                    {this.state.listLingue.map((l) => {
                      return (
                        <option key={l.idLingua} value={l.idLingua}>
                          {this.props.t(l.descLocalizzata)}
                        </option>
                      );
                    })}
                  </select>
                  <i
                    className="fa fa-chevron-down form-input-icon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="form-input-label-cont">
                  <label>{this.props.t("isForeign")}</label>
                  <div className="radio-cont-child">
                    <div className="radio-cont-child">
                      <div
                        className="radio-input"
                        onClick={() =>
                          this.setState({
                            isForeign: true,
                            isValidCF: false,
                            birthPlace: {
                              idComuneNascita: "",
                              descrizione: "",
                              codificaFiscale: "",
                            },
                          })
                        }
                      >
                        {this.state.isForeign && (
                          <div className="radio-input-active"></div>
                        )}
                      </div>
                      <label>{this.props.t("Yes")}</label>
                    </div>
                    <div className="radio-cont-child">
                      <div
                        className="radio-input"
                        onClick={() =>
                          this.setState(
                            { isForeign: false },
                            this._selectFiscalCode.bind(this)
                          )
                        }
                      >
                        {!this.state.isForeign && (
                          <div className="radio-input-active"></div>
                        )}
                      </div>
                      <label>{this.props.t("No")}</label>
                    </div>
                  </div>
                </div>

                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("TypeDocument")}*</label>
                </div>
                <div className="form-input-cont-select fadeIn">
                  <select
                    required
                    disabled={!this.state.isForeign}
                    value={this.state.TypeDocument}
                    className="form-input-select"
                    onChange={(ev) =>
                      this.setState(
                        { TypeDocument: ev.target.value, numberDoc: "" },
                        () => {
                          document.getElementById("numberDoc").value = "";
                          this.validateFiscalCode.bind(this);
                        }
                      )
                    }
                  >
                    {this.state.listTipologiaDocumento.map((l) => {
                      return (
                        <option
                          key={l.idTipologiaDocumento}
                          value={l.idTipologiaDocumento}
                        >
                          {this.props.t(l.descLocalizzata)}
                        </option>
                      );
                    })}
                  </select>
                  <i
                    className="fa fa-chevron-down form-input-icon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="form-input-label-cont py-0">
                  <label>
                    {!this.state.isForeign
                      ? this.props.t("CODICE_FISCALE")
                      : this.props.t("NumberDocument")}*
                  </label>
                </div>
                <input
                  required
                  type="text"
                  id="numberDoc"
                  className="form--input fadeIn uppercase"
                  value={this.state.numberDoc}
                  onChange={(ev) => {
                    let value = ev.target.value;

                    value = value.replace(/[^A-Za-z0-9]/gi, "");
                    return this.setState(
                      { numberDoc: value },
                      _.debounce(this.validateFiscalCode.bind(this), 1000)
                    );
                  }}
                  name="numberDoc"
                />
                <div
                  className="form-input-label-cont py-0 error-text"
                  hidden={
                    this.state.isForeign ||
                    (!(this.state.submitted && !this.state.validCF) &&
                      !(
                        !this.state.submitted &&
                        this.state.numberDoc.length > 16
                      ))
                  }
                >
                  <label hidden={!this.state.birthPlace.codificaFiscale}>
                    {this.props.t("WrongDocNumber")}
                  </label>
                </div>
                <div
                  className="form-input-label-cont py-0 error-text"
                  hidden={
                    this.state.numberDoc.length == 0 || this.state.isForeign
                  }
                >
                  <label
                    hidden={
                      this.state.nome &&
                      this.state.cognome &&
                      this.state.gender &&
                      this.state.dataNascita &&
                      this.state.birthPlace.codificaFiscale
                    }
                  >
                    {this.props.t("RequireInsertFields")}
                    <ul style={{ textAlign: "left" }}>
                      <li hidden={this.state.nome}>{this.props.t("Name")} </li>
                      <li hidden={this.state.cognome}>
                        {this.props.t("Surname")}
                      </li>
                      <li hidden={this.state.gender}>
                        {this.props.t("Gender")}
                      </li>
                      <li hidden={this.state.dataNascita}>
                        {this.props.t("BirthDate")}
                      </li>
                      <li hidden={this.state.birthPlace.codificaFiscale}>
                        {this.props.t("BirthPlace")}
                      </li>
                    </ul>
                  </label>
                </div>
                <div className="form-input-label-cont py-0">
                  <div
                    className="form-input-checkbox"
                    onClick={() =>
                      this.setState({ privacy: !this.state.privacy })
                    }
                  >
                    <i
                      className="fas fa-check"
                      hidden={!this.state.privacy}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <label
                    className={
                      !(this.state.submitted && !this.state.privacy)
                        ? null
                        : "error-text"
                    }
                  >
                    {this.props.t("AuthorizePrivacy")}
                  </label>
                </div>
                <div
                  className="form-input-label-cont py-0 error-text"
                  hidden={!(this.state.submitted && !this.state.privacy)}
                >
                  <label>{this.props.t("FieldRequired")}</label>
                </div>
              </div>
              <input
                type="submit"
                className="fadeIn fourth"
                value={this.props.t("REGISTER")}
              />
            </form>
            <span>
                {this.props.t("THESE_FIELDS_ARE_REQUIRED")}
              </span>
            <div className="Login-form-Footer d-flex justify-content-between mt-3">
              <a className="Login-form--underlineHover" href="/login">
                {this.props.t("Login")}
              </a>
              <a
                className="Login-form--underlineHover"
                href="/registerHelpdesk"
              >
                {this.props.t("RegistrationProblem")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.templateHTML();
  }
}

const RegisterPage = RxComponent({ triggerRegister, errorRegister, onError })(
  Component
);
export default RegisterPage;
