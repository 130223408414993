import StubRepo from "../../repos/StubRepo";
import VarcoMockRepo from "./repositories/Varco.mock";
import VarcoHttpRepo from "./repositories/Varco.http";

export default class Varco extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Varco repo";
  }

  static getLocal() {
    if (Varco._localInstance == null) {
      Varco._localInstance = new VarcoMockRepo();
    }
    return Varco._localInstance;
  }

  static getService() {
    if (Varco._serviceInstance == null) {
      Varco._serviceInstance = new VarcoHttpRepo();
    }
    return Varco._serviceInstance;
  }
}
