import {
  SUCCESS_SEARCH,
  ERROR_SEARCH,
  ERROR_VALIDATE,
  SUCCESS_TIPOLOGIA_AREA_SEARCH,
  SELECT_AREA,
  ON_SUCCESS_INSERT_PRENOTAZIONE,
  ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE,
  ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE,
  SUCCESS_SEARCH_USER_BY_DOC,
  ON_CHANGE_FASCIA_ORARIA,
  ON_FILTER_TIPOLOGIA_AREA,
  NOT_FILTER_TIPOLOGIA_AREA,
  ON_SUCCESS_CHANGE_AREA_STATUS,
  ON_SUCCESS_PARCHEGGI_SEARCH,
    PRENOTAZIONE_NON_TROVATA
} from "./Prenotazione.types";

const initialState = {
  list: null,
  type: null,
  data: null,
  error: null,
  message: null,
};

export const PrenotazioneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_SEARCH:
      return {
        ...state,
        type: SUCCESS_SEARCH,
        list: action.payload.results,
        selectedFields: action.payload.selectedFields,
        error: null,
        message: "Search completed",
      };
    case SUCCESS_SEARCH_USER_BY_DOC:
      return {
        ...state,
        type: SUCCESS_SEARCH_USER_BY_DOC,
        userAssegnato: action.payload,
        error: null,
        message: "Search user by doc completed",
      };
    case SUCCESS_TIPOLOGIA_AREA_SEARCH:
      return {
        ...state,
        type: SUCCESS_TIPOLOGIA_AREA_SEARCH,
        listTipologie: action.payload,
        error: null,
        message: "Search completed",
      };
    case ON_SUCCESS_PARCHEGGI_SEARCH:
      return {
        ...state,
        type: ON_SUCCESS_PARCHEGGI_SEARCH,
        listParcheggi: action.payload,
        error: null,
        message: "Search completed",
      };

    case ERROR_SEARCH:
      return {
        ...state,
        type: ERROR_SEARCH,
        data: null,
        error: true,
        message: action.payload.message,
      };
    case ERROR_VALIDATE:
      return {
        ...state,
        type: ERROR_VALIDATE,
        data: null,
        error: true,
        message: action.payload.message,
      };
    case SELECT_AREA:
      return {
        ...state,
        type: SELECT_AREA,
        selectedArea: action.payload,
      };
    case ON_SUCCESS_INSERT_PRENOTAZIONE:
      return {
        ...state,
        type: ON_SUCCESS_INSERT_PRENOTAZIONE,
      };

    case ON_SUCCESS_CHANGE_AREA_STATUS:
      return {
        ...state,
        type: ON_SUCCESS_CHANGE_AREA_STATUS,
      };
    case ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE:
      return {
        ...state,
        type: ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE,
        pnr: action.payload,
      };
    case ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE:
      return {
        ...state,
        type: ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE,
        arrayPnr: action.payload,
      };
    case PRENOTAZIONE_NON_TROVATA:
      return {
        ...state,
        type:PRENOTAZIONE_NON_TROVATA
      }
    case ON_CHANGE_FASCIA_ORARIA:
      return {
        ...state,
        type: ON_CHANGE_FASCIA_ORARIA,
        selectedFasciaOraria: action.payload,
      };

    case ON_FILTER_TIPOLOGIA_AREA:
      return {
        ...state,
        type: action.payload.isToUpdate
          ? ON_FILTER_TIPOLOGIA_AREA
          : NOT_FILTER_TIPOLOGIA_AREA,
        isToUpdateFilterList: action.payload.isToUpdate,
        fileteredAreaList: action.payload.listArea,
      };
    default:
      return state;
  }
};
