import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_TIPO_PRENOTAZIONE =
  "generic/search3/prenotazione/tipologiaprenotazione";
export default class TipologiaPrenotazioneHttpRepo extends HttprepoTAAL {
  getTable() {
    return "TipologiaPrenotazione";
  }

  getTipologiaPrenotazioneNewApi(idList) {
    return this.API.post(GET_TIPO_PRENOTAZIONE, {
      idList: idList,
    });
  }
}
