import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => {
	let ret = {};
	if (state) {
		Object.keys(state).forEach(element => {
			ret[element] = state[element];
			//Object.keys(state[element]).forEach(el => {
			//ret[el] = state[element][el];
			// });
		});
	}
	return ret;
};

const RxComponent = arrayOfActions => {
	
	/*let mapDispatchToProps = dispatch => {
		let ret = {};
		arrayOfActions.forEach(element => {
			ret[element.name] = (...args) => {
				dispatch(element(...args));
			};
		});
		return ret;
	};*/

	/*function mapDispatchToProps(dispatch){
		return bindActionCreators({...arrayOfActions},dispatch)
	}*/

	return (comp)=>connect(
		mapStateToProps,
		{...arrayOfActions}
	)(withTranslation()(comp));
};

export default RxComponent;