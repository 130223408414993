import React from 'react';
import Userinfo from './Userinfo';
import RxComponent from '../../redux/RxComponent';

class Component extends React.PureComponent {
  render(){
    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <i className="fa fa-bars"></i>
        </button>
        {
          this.props.Auth.user.Comune && (
            <div hidden={this.props.Auth.user.Comune.descrizione}>
              <b style={{color:"#000"}}>{this.props.t("MUNICIPALITY")}: {this.props.Auth.user.Comune.descrizione}</b>
            </div>
          )
        }
        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block"></div>
          <Userinfo />
        </ul>
      </nav>
    );
  }
}

const Navbar = RxComponent({})(Component)
export default Navbar;