import React from 'react';
import RxComponent from '../../redux/RxComponent';
import { onSuccess } from '../../redux/Shared/Shared.actions';
import ComuneSettings from './components/ComuneSettings';

function ComuneConfigPageC(props) {
    
    return (
        <>
            <h3 className="Login-form--title">{props.t("GENERAL_SETTINGS")}</h3>
            <ComuneSettings />
        </>
    );
  }

 const ComuneConfigPage= RxComponent({onSuccess})(ComuneConfigPageC);
 export default ComuneConfigPage;