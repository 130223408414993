import React from "react";
import _ from "lodash";
import AreaService from "../Area/Area.service";
import VarcoService from "./Varco.service";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import { SelectPicker } from "../../components/Content/SelectPicker";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import CrudNetForm from "../../components/CrudNet/CrudNetForm";
import RxComponent from "../../redux/RxComponent";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";

class Comp extends React.Component {
  areaService = new AreaService();
  varcoService = new VarcoService();

  state = {
    areaList: [],
    activeTab: "1",
    areaValues: [],
  };

  componentDidMount() {
    let filter = {
      [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.fkIdComune],
    };
    let assegnazioneFilter = {
      [`${FILTER_NUMBER}&&&fkIdVarco`]: [this.props.idVarco],
    };
    this.areaService.paginate(0, -1, filter).then((res) => {
      this.setState({ areaList: res.data.result });
      return this.varcoService
        .getAssignedArea(assegnazioneFilter)
        .then((res) => {
          let areaValues = [];
          res.data.result.forEach((elm) => {
            areaValues.push({
              ...elm.Area,
              idRelAreaParcheggio: elm.idRelAreaParcheggio,
            });
          });
          return this.setState({ areaValues: areaValues });
        });
    });
  }

  changeTab(tab) {
    this.setState({ activeTab: tab });
  }

  handleChange(ev, action) {
    this.setState({
      areaValues: ev,
    });
    if (action.action === "select-option") {
      const data = {
        fkIdVarco: this.props.idVarco,
        fkIdArea: action.option.value.idArea,
      };
      return this.areaService.relVarcoArea(data).then((res) => {
        let index = null;
        let value = this.state.areaValues.find((elm, i) => {
          index = i;
          return elm.idArea === res.data.result.fkIdArea;
        });
        value = { ...value, idRelVarcoArea: res.data.result.idRelVarcoArea };
        const newValues = this.state.areaValues;
        newValues[index] = value;
        this.setState({ areaValues: newValues });
      });
    } else if (action.action === "clear") {
      return this.areaService.clearRealAreaParcheggio({
        IDVARCO: this.props.idVarco,
      });
    } else {
      const value = this.state.areaValues.find(
        (elm) => elm.idArea === action.removedValue.value.idArea
      );
      const data = {
        idRelVarcoArea: value.idRelVarcoArea,
      };
      return this.areaService.deleteRelAreaParcheggio(data);
    }
  }

  renderRelVarcoAreaForm() {
    return (
      <form className="row">
        <div className="col-sm-4">
          <label className="w-100 text-left" htmlFor={"Aree"}>
            {this.props.t("ChooseAree")}
          </label>
          <SelectPicker
            values={this.state.areaValues}
            options={_.differenceBy(
              this.state.areaList,
              this.state.areaValues,
              "idVarco"
            )}
            displayValue={"descrizione"}
            multiple
            onChange={(ev, action) => this.handleChange(ev, action)}
          />
        </div>
      </form>
    );
  }


  submitVarco(formData,pp,close){
    formData.fkIdComune = this.props.Auth.user.fkIdComune
    ? this.props.Auth.user.fkIdComune
    : this.props.fkIdComune;
      let promise = null;
      if (this.props.viewMode === "insert") {
        promise = this.props.service.create(formData)
      } else if (this.props.viewMode === "edit") {
        promise = this.props.service.edit(formData)
      } else {
        promise = this.props.service.remove(formData);
      }
      promise
        .then((resp) => {
          close();
          this.props.onSuccess("SuccessedOperation");
        })
        .catch((ex) => {
          this.props.onError(ex);
        });
  }

  render() {
    return this.props.viewMode !== "delete" ? (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={{ active: this.state.activeTab === "1" }}
              onClick={() => {
                this.changeTab("1");
              }}
            >
              {this.props.t("DATI_VARCO")}
            </NavLink>
          </NavItem>
          {this.props.viewMode === "edit" && (
            <>
              <NavItem>
                <NavLink
                  className={{ active: this.state.activeTab === "2" }}
                  onClick={() => {
                    this.changeTab("2");
                  }}
                >
                  {this.props.t("AreaAssignment")}
                </NavLink>
              </NavItem>
            </>
          )}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <CrudNetForm
              {...this.props}
              onSubmit={this.submitVarco.bind(this)}
              service={this.props.service}
              viewMode={this.props.viewMode}
            />
          </TabPane>
          <TabPane tabId="2">
            {this.state.activeTab === "2" && this.renderRelVarcoAreaForm()}
          </TabPane>
        </TabContent>
      </React.Fragment>
    ) : (
      <CrudNetForm
        {...this.props}
        onSubmit={this.submitVarco.bind(this)}
        service={this.props.service}
        viewMode={this.props.viewMode}
      />
    );
  }
}

const VarcoCRUD = RxComponent({ onSuccess, onError })(Comp);
export default VarcoCRUD;
