import React from 'react';
import logo from '../../template/dashboard/img/logo-3.png';

export class Brand extends React.Component{


    render(){
        return (<a className="sidebar-brand d-flex align-items-center justify-content-center bg-white" href="/" 
            style={{
                backgroundImage: `url(${logo})`,
                backgroundSize: 60,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                color:'transparent'
            }}
        >
            /
        </a>);
        /*return (<a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
            </div>
            <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
        </a>);*/
    }

}