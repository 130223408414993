import StubRepo from "../../repos/StubRepo";
import PrenotazioneMockRepo from "./repositories/Prenotazione.mock";
import PrenotazioneHttpRepo from "./repositories/Prenotazione.http";

export default class Prenotazione extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Prenotazione repo";
  }

  static getLocal() {
    if (Prenotazione._localInstance == null) {
      Prenotazione._localInstance = new PrenotazioneMockRepo();
    }
    return Prenotazione._localInstance;
  }

  static getService() {
    if (Prenotazione._serviceInstance == null) {
      Prenotazione._serviceInstance = new PrenotazioneHttpRepo();
    }
    return Prenotazione._serviceInstance;
  }
}
