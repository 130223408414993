import React from "react";
import RxComponent from "../../../redux/RxComponent";
import HorizontalFilterList from "../../../components/Content/HorizontalFilterList";
import AreaService from "./../Area.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../..//template/site/css/vendor.css";
import _ from "lodash"
import ListItem from "../../../components/Content/ListItem";
import {
  triggerSelectArea,
  onFilterTipologiaArea,
} from "../../../redux/Prenotazione/Prenotazione.actions";
import {
  Row,
  CardImg,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Alert,
} from "reactstrap";
import placehlder from "../../../template/site/img/placeholder.png";
import { BASE_URL_TAAL } from "../../../constants/Settings";
import { AREASTATUSES } from "../../../constants/AreaStatus";
import SearchPrenotazioneBanner from "./SearchPrenotazioneBanner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {ROLES} from "../../../constants/Roles";
import {AREE} from "../../../constants/Aree";

class Component extends React.Component {
  state = {
    service: new AreaService(),
    listAree: this.props.Prenotazione.list,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!_.isEqual(this.props.Prenotazione.list, prevProps.Prenotazione.list)) {
      this.setState({
        listAree: this.props.Prenotazione.list
      })
    }
  }

  onTipologiaAreaSelect = (tipologiaAreaId) => {
    let newList = this.props.Prenotazione.list.filter(
      (res) => res.IDTIPOLOGIAAREA === tipologiaAreaId
    );
    this.props.onFilterTipologiaArea(newList, true);
    this.setState({
      listAree: newList,
    });
  };

  onClearFilter() {
    this.props.onFilterTipologiaArea(null, true);
    this.setState({
      listAree: this.props.Prenotazione.list,
    });
  }

  onAreaSelect(area) {
    if (window.location.href.indexOf("/dashboard") >= 0) {
      area.fromDashboard = true;
    }
    this.props.triggerSelectArea(area);
  }

  _getCategoryById(id) {
    const tipo = this.props.Prenotazione.listTipologie.find(
      (p) => p.IDTIPOLOGIAAREA === id
    );
    if (tipo) return tipo.DESCLOCALIZZATA;
    return null;
  }

  render() {
    return (
      <>
        <Accordion
            allowZeroExpanded={true}
            preExpanded={[
              "AREA_RESULT",
            ]}
        >
          <AccordionItem
              uuid="AREA_RESULT"
              section="AreaResult"
          >
            <AccordionItemHeading>
              <AccordionItemButton>
                {this.props.t("MODIFICA_DATI_PRENOTAZIONE")}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
                className="accordion_custom_panel"
            >
              <SearchPrenotazioneBanner/>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <div className="alert alert-info" role="alert">
          <p className="mb-1">{this.props.t("DISPLAY_TEXT_INFO_F")}</p>
          <p className="m-0">{this.props.t("DISPLAY_TEXT_INFO_L")}</p>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <Row className="w-100">
            <div className="col-md-4 float-left">
              <h2>{this.props.t("SEARCH_TITLE")}</h2>
            </div>
            <div className="col-md-8 float-right">
              <HorizontalFilterList
                data={this.props.Prenotazione.listTipologie}
                onSelectFilter={this.onTipologiaAreaSelect.bind(this)}
                onClearFilter={this.onClearFilter.bind(this)}
              />
            </div>
          </Row>
          <Row></Row>
        </div>
        <div className="d-flex align-content-stretch flex-wrap">
          {
            //<ShowResultComp data={this.state.listAree} />
            this.state.listAree.map((el, index) => {
              let imgp = placehlder;

              if (el.FORMATO && el.FORMATO.length) {
                let formatoP = el.FORMATO.split("/")[1];
                imgp = `${BASE_URL_TAAL}/Generic/image/v_Area/foto/${
                  el.IDAREA
                }/${formatoP}?date=${new Date().getTime()}`;
              }
              let PER_OCCUPAZIONE;
              let DISPONIBILITA;

              if (el.CODICETIPOLOGIAAREA === AREE.EVENTO) {
                PER_OCCUPAZIONE = (
                    ((el.NUMEROTOTALIPERSONE / el.POSTI_TOTALI ) * 100)
                ).toFixed(0); //((( === 0 ? 1 : el.OCCUPANTI_PRESENTI)* 100) /el.POSTI_TOTALI).toFixed(0)
                DISPONIBILITA =(el.POSTI_TOTALI - el.NUMEROTOTALIPERSONE) > 0;
              } else {
                PER_OCCUPAZIONE = (
                    ((el.POSTI_TOTALI - el.POSTI_LIBERI) * 100) /
                    el.POSTI_TOTALI
                ).toFixed(0); //((( === 0 ? 1 : el.OCCUPANTI_PRESENTI)* 100) /el.POSTI_TOTALI).toFixed(0)
                DISPONIBILITA = el.POSTI_LIBERI >= 0;
              }
              const ISOPEN = el.CODICESTATO !== AREASTATUSES.CLOSED;
              //this.props.Prenotazione.selectedFields.numeroOccupanti;
              /*el.FOTO
                ? "data:" + (el.FORMATO || "image/jpeg") + ";base64," + el.FOTO
                : placehlder;*/
              return (
                /*<React.Fragment key={el.idArea + "-" + index}>
                <ListItem
                  foto={el.FOTO}
                  descrizione={el.DESCRIZIONE}
                  id={el.IDAREA}
                  codiceArea={el.CODICEAREA}
                  idTipologiaArea={el.IDTIPOLOGIAAREA}
                  accessoDisabili={el.ACCESSODISABILI}
                  accessoAnimali={el.ACCESSOANIMALI}
                  onClick={this.onAreaSelect.bind(this)}
                ></ListItem>
                <hr  className="col-lg-8"></hr>
              </React.Fragment>*/
                <div className="col-md-4 mb-2" key={"result" + index}>
                  <Card style={
                    {
                      height: "100%",
                      boxShadow: "0 0 15px rgba(0,0,0,.1)"
                    }
                  }>
                    {el.CODICETIPOLOGIAAREA === AREE.EVENTO && (
                        <div className="ribbon ribbon-top-left">
                          <span>Evento</span>
                        </div>
                    )}
                    <CardImg top width="100%" src={imgp} height={200} />
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-start">
                        <CardTitle className="text-weight-500">
                          {this.props.t(el.DESCRIZIONE)}
                        </CardTitle>
                        <div>
                          <span
                            style={{ padding: 10 }}
                            hidden={!el.ACCESSOANIMALI}
                          >
                            <i className="fas fa-dog fa-2x"></i>
                          </span>
                          <span
                            style={{ padding: 10 }}
                            hidden={!el.ACCESSODISABILI}
                          >
                            <i className="fas fa-wheelchair fa-2x"></i>
                          </span>
                        </div>
                      </div>
                      <CardSubtitle className="mb-2">
                        {this.props.t(
                          this._getCategoryById(el.IDTIPOLOGIAAREA)
                        )}
                      </CardSubtitle>
                      <CardText>
                        <div className="mt-1">
                          <div className="progress">
                            <div
                              className={
                                "progress-bar progress-bar-striped progress-bar-animated " +
                                (PER_OCCUPAZIONE < 35 && DISPONIBILITA && ISOPEN
                                  ? "bg-success"
                                  : PER_OCCUPAZIONE < 70 &&
                                    DISPONIBILITA &&
                                    ISOPEN
                                  ? "bg-warning"
                                  : "bg-danger")
                              }
                              role="progressbar"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width:
                                  DISPONIBILITA && ISOPEN
                                    ? PER_OCCUPAZIONE + "%"
                                    : "100%",
                              }}
                            >
                              {DISPONIBILITA && ISOPEN
                                ? PER_OCCUPAZIONE + "%"
                                : "100%"}
                            </div>
                          </div>
                        </div>
                      </CardText>

                      {PER_OCCUPAZIONE === "100" ||
                      !DISPONIBILITA ||
                      !ISOPEN ? (
                        <Alert color="danger">{this.props.t("FULL")}</Alert>
                      ) : (
                        <Button
                          className="float-right"
                          onClick={() => this.onAreaSelect(el)}
                        >
                          {this.props.t("BOOK_NOW")}
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }
}
const AreaResultList = RxComponent({
  triggerSelectArea,
  onFilterTipologiaArea,
})(Component);
export default AreaResultList;
