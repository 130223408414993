import React from "react";
import _ from "lodash";
import DatePicker from "../Content/DatePicker";
import { SelectPicker } from "../Content/SelectPicker";
import { Trans, Translation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import MapComponent from "../Content/MapsComp";
import { SelectPickerOld } from "../Content/SelectPickerOld";

export const FIELD_TEXT = "FIELD_TEXT";
export const FIELD_DATE = "FIELD_DATE";
export const FIELD_INTEGER = "FIELD_INTEGER";
export const FIELD_DECIMAL = "FIELD_DECIMAL";
export const FIELD_LONGTEXT = "FIELD_LONGTEXT";
export const FIELD_SELECT = "FIELD_SELECT";
export const FIELD_BOOLEAN = "FIELD_BOOLEAN";
export const FIELD_SELECT_MULTIPLE = "FIELD_SELECT_MULTIPLE";
export const FIELD_FILE = "FIELD_FILE";
export const FIELD_MAPS = "FIELD_MAPS";
export const FIELD_PASSWORD = "FIELD_PASSWORD";

class DynamicFormField extends React.PureComponent {
  state = {
    type: null,
    id: null,
    label: null,
    required: false,
    precision: 999,
    value: null,
    readOnly: null,
    options: [],
    multiple: false,
    displayValue: null,
  };

  componentDidMount() {
    this._initState();
  }

  componentDidUpdate() {
    this._initState();
  }

  _initState() {
    this.setState({
      type: this.props.type,
      id: this.props.id,
      label: this.props.label,
      required: this.props.required,
      precision: this.props.precision,
      value: this.props.value,
      readOnly: this.props.readOnly,
      options: this.props.options,
      multiple: this.props.multiple,
      displayValue: this.props.displayValue,
        customName: this.props.customName
    });
  }

  _onSubmit = (e) => {
    e.preventDefault();
  };

  _handleChange(val) {
    if (this.props.onChange && _.isFunction(this.props.onChange)) {
      this.props.onChange(val);
    }
  }

  _inputText = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label}  />:{this.state.required && "*"}
      </label>
      <input
        value={this.state.value}
        readOnly={this.state.readOnly}
        size={this.state.precision}
        onChange={(event) => this._handleChange(event.target.value)}
        type="text"
        className="form-control"
        name={this.state.id}
        id={this.state.id}
      />
    </div>
  );

  _inputPassword = () => {
    return (
      <div className="col-sm-4">
        <label className="w-100 text-left" htmlFor={this.state.id}>
          <Trans i18nKey={this.state.customName? this.state.customName : this.state.label}  />:{this.state.required && "*"}
        </label>
        <input
          value={this.state.value}
          readOnly={this.state.readOnly}
          size={this.state.precision}
          onChange={(event) => this._handleChange(event.target.value)}
          type="password"
          className="form-control"
          name={this.state.id}
          id={this.state.id}
        />
      </div>
    );
  };

  _inputInteger = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <input
        value={this.state.value}
        readOnly={this.state.readOnly}
        onChange={(event) => this._handleChange(event.target.value)}
        size={this.state.precision}
        type="number"
        step={1}
        className="form-control"
        name={this.state.id}
        id={this.state.id}
      />
    </div>
  );

  _inputDecimal = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <input
        value={this.state.value}
        readOnly={this.state.readOnly}
        onChange={(event) => this._handleChange(event.target.value)}
        size={this.state.precision}
        type="number"
        step={0.01}
        className="form-control"
        name={this.state.id}
        id={this.state.id}
      />
    </div>
  );

  _inputLongText = () => (
    <div className="col-sm-9">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <textarea
        value={this.state.value}
        readOnly={this.state.readOnly}
        onChange={(event) => this._handleChange(event.target.value)}
        className="form-control"
        name={this.state.id}
        id={this.state.id}
      />
    </div>
  );

  _inputFile = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <input
        //value={this.state.value}
        readOnly={this.state.readOnly}
        size={this.state.precision}
        onChange={(e) => {
          if (e.target.files && e.target.files.item(0)) {
              this._handleChange(e.target.files[0])
            // const reader = new FileReader();
            // reader.onloadend = () => {
            //   // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
            //   var b64 = reader.result; //.replace(/^data:.+;base64,/, '');
            //   this._handleChange(b64);
            // };
            // reader.readAsDataURL(e.target.files.item(0));
          }
        }}
        type="file"
        className="form-control"
        name={this.state.id}
        id={this.state.id}
      />
    </div>
  );

  _inputMap = () => (
    <>
      <div className="col-sm-4">
        <label className="w-100 text-left" htmlFor={this.state.id}>
          <Trans i18nKey="Latitude" />:{this.state.required && "*"}
        </label>
        <input
          value={this.state.value ? this.state.value.latitudine : null}
          readOnly={this.state.readOnly}
          onChange={(event) =>
              this.setState(
              {
                value: {
                  latitudine: event.target.value,
                  longitudine: this.state.value?.longitudine,
                },
              },
              this._handleChange({
                latitudine: event.target.value,
                longitudine: this.state.value?.longitudine,
              })
            )
          }
          size={this.state.precision}
          type="number"
          step={0.0001}
          className="form-control"
          name={"latitudine"}
          id={this.state.id}
        />
      </div>
      <div className="col-sm-4">
        <label className="w-100 text-left" htmlFor={this.state.id}>
          <Trans i18nKey="Longitude" />:{this.state.required && "*"}
        </label>
        <input
          value={this.state.value ? this.state.value.longitudine : null}
          readOnly={this.state.readOnly}
          step={0.0001}
          onChange={(event) =>
            this.setState(
              {
                value: {
                  latitudine: this.state.value?.latitudine,
                  longitudine: event.target.value,
                },
              },
              this._handleChange({
                latitudine: this.state.value?.latitudine,
                longitudine: event.target.value,
              })
            )
          }
          size={this.state.precision}
          type="number"
          className="form-control"
          name={"longitudine"}
          id={this.state.id}
        />
      </div>
      <div className="col-sm-2">
        <label className="w-100 text-left" htmlFor={this.state.id}>
          &nbsp;
        </label>
        <button
          type="button"
          className="btn btn-sm btn-info"
          onClick={() => this.setState({ showMapModal: true })}
        >
          <Trans i18nKey="SELECT_FROM_MAPS" />
          &nbsp;
          <i className="fas fa-map-marker"></i>
        </button>

        <Modal
          isOpen={this.state.showMapModal}
          toggle={() =>
            this.setState({ showMapModal: false, lat: null, lng: null })
          }
          size="md"
        >
          <ModalHeader
            toggle={() => this.setState({ showMapModal: false })}
          ></ModalHeader>
          <ModalBody>
            <div
              style={{
                height: 500,
                position: "relative",
              }}
            >
              {this.state.showMapModal && (
                <MapComponent
                  onSelectPin={(marker) => {
                    this.setState({
                      latTemp: parseFloat(marker.lat),
                      lngTemp: parseFloat(marker.lng),
                    });
                  }}
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="dark"
              onClick={() =>
                this.setState(
                  {
                    showMapModal: false,
                    value: {
                      latitudine: this.state.latTemp,
                      longitudine: this.state.lngTemp,
                    },
                    latTemp: "",
                    lngTemp: "",
                  },
                  () =>
                    this._handleChange({
                      latitudine: parseFloat(this.state.value.latitudine),
                      longitudine: parseFloat(this.state.value.longitudine),
                    })
                )
              }
            >
              <Trans i18nKey="Ok" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );

  _inputDate = () => (
    <div className="col-sm-4">
      <DatePicker
        id={this.state.id}
        required={this.state.required}
        label={this.state.customName? this.state.customName : this.state.label}
        value={this.state.value}
        onChange={(ev) => this._handleChange(ev.date)}
        readOnly={this.state.readOnly}
        format="DD/MM/YYYY"
      />
    </div>
  );

  _inputSelect = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <SelectPicker
        values={this.state.value}
        options={this.state.options}
        displayValue={this.state.displayValue || "descrizione"}
        multiple={this.props.multiple}
        readOnly={this.state.readOnly}
        onChange={(ev) => this._handleChange(ev)}
      />
    </div>
  );

  _inputSelectMultiple = () => (
    <div className="col-sm-4">
      <label className="w-100 text-left" htmlFor={this.state.id}>
        <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
      </label>
      <SelectPicker
        values={this.state.value}
        options={this.state.options}
        displayValue={this.state.displayValue || "descrizione"}
        multiple={this.props.multiple || true}
        readOnly={this.state.readOnly}
        onChange={(ev) => this._handleChange(ev)}
      />
    </div>
  );

  _inputBoolean = () => {
    return (
      <div className="col-sm-4">
        <label className="w-100 text-left" htmlFor={this.state.id}>
          <Trans i18nKey={this.state.customName? this.state.customName : this.state.label} />:{this.state.required && "*"}
        </label>
        <Translation>
          {(t) => (
            <SelectPicker
              values={
                this.state.value === true
                  ? [{ id: 1, descrizione: t("Yes") }]
                  : this.state.value === false
                  ? [{ id: 0, descrizione: t("No") }]
                  : null
              }
              options={[
                { id: 1, descrizione: t("Yes") },
                { id: 0, descrizione: t("No") },
              ]}
              displayValue={"descrizione"}
              readOnly={this.state.readOnly}
              onChange={(ev) => {
                if (ev && ev.length) this._handleChange(!!ev[0].id);
              }}
            />
          )}
        </Translation>
      </div>
    );
  };

  _inputFromType() {
      switch (this.state.type) {
      case FIELD_TEXT:
        return this._inputText();
      case FIELD_INTEGER:
        return this._inputInteger();
      case FIELD_DECIMAL:
        return this._inputDecimal();
      case FIELD_LONGTEXT:
        return this._inputLongText();
      case FIELD_DATE:
        return this._inputDate();
      case FIELD_SELECT:
        return this._inputSelect();
      case FIELD_BOOLEAN:
        return this._inputBoolean();
      case FIELD_SELECT_MULTIPLE:
        return this._inputSelectMultiple();
      case FIELD_FILE:
        return this._inputFile();
      case FIELD_MAPS:
        return this._inputMap();
      case FIELD_PASSWORD:
        return this._inputPassword();
      default:
        return (
          <p>
            <Trans i18nKey="Loading" />
            ...
          </p>
        );
    }
  }

  render = () => this._inputFromType();
}

export default DynamicFormField;
