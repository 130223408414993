import StubRepo from "../../repos/StubRepo";
import StatoPrenotazioneMockRepo from "./repositories/StatoPrenotazione.mock";
import StatoPrenotazioneHttpRepo from "./repositories/StatoPrenotazione.http";

export default class StatoPrenotazione extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "StatoPrenotazione repo";
  }

  static getLocal() {
    if (StatoPrenotazione._localInstance == null) {
      StatoPrenotazione._localInstance = new StatoPrenotazioneMockRepo();
    }
    return StatoPrenotazione._localInstance;
  }

  static getService() {
    if (StatoPrenotazione._serviceInstance == null) {
      StatoPrenotazione._serviceInstance = new StatoPrenotazioneHttpRepo();
    }
    return StatoPrenotazione._serviceInstance;
  }
}
