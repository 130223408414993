import React from "react";
import _ from "lodash";
import LookupsService from "../Lookups.service";
import RxComponent from "../../../redux/RxComponent";

class LookupsPickerC extends React.PureComponent {
  service = new LookupsService();

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      list: [],
      selectedOne: this.props.t("ChooseLookup"),
    };
  }

  componentDidMount() {
    this.service.getAll().then((resp) => {
      this.setState({ list: resp });
    });
  }

  onChangeInput(ev) {
    if (this.props.onChange && _.isFunction(this.props.onChange))
      this.setState({ selectedOne: ev.name });
    this.props.onChange(ev.target.value);
  }

  render() {
    return (
      <React.Fragment>
        <label htmlFor="lookups" className="mr-2">
          {this.props.t("Lookup")}:{this.props.required && "*"}
        </label>
        <select
          className="form-control"
          id="lookup"
          value={this.state.selectedOne}
          onChange={this.onChangeInput.bind(this)}
        >
          {
            //!values || !values.length?
          <option value="">{this.props.t("ChooseLookup")}</option>
            //<option>&nbsp;</option>
          }
          {this.state.list &&
            this.state.list.length &&
            this.state.list.map((o) => (
              <option key={`${o.value}-${o.descrizione}`} value={o.descrizione}>
                {this.props.t(o.descrizione)}
              </option>
            ))}
        </select>
      </React.Fragment>
    );
  }
}

const LookupsPicker = RxComponent()(LookupsPickerC);
export default LookupsPicker;