import React from "react";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import PrenotazioneService from "./Prenotazione.service";
import RxComponent from "../../redux/RxComponent";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import moment from "moment";
import QrCodeViewer from "../../components/Content/QrCodeViewer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PrenotazioneDetailForm from "../Prenotazione/components/Prenotazione.insertdetail";
import { triggerGetPrenotazione } from "../../redux/Prenotazione/Prenotazione.actions";
import { SUCCESS_SEARCH } from "../../redux/Prenotazione/Prenotazione.types";
import { STATUSES } from "../../constants/PrenotazioneStatuses";
import ComuneService from "../Comune/Comune.service";

class Comp extends React.Component {
  state = {
    pnr: null,
    openPnr: false,
    showModal: false,
    showModalDelete: false,
    selectedPnr: null,
    readOnly: null,
  };

  comuneService = new ComuneService();

  columns = [
    {
      label: this.props.t("State"),
      data: "DESCLOCSTATOPRENOTAZIONE",
    },
    {
      label: this.props.t("Comune"),
      data: "DESCCOMUNE",
    },
    {
      label: this.props.t("Area"),
      data: "DESCAREA",
    },
    {
      label: this.props.t("Tipology"),
      data: "DESCLOCTIPOLOGIAPRENOTAZIONE",
    },
    {
      label: this.props.t("Date"),
      data: "DATAINIZIO",
      type: "custom",
      render: (celldata) => moment(celldata).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      label: this.props.t("TimeSlot"),
      data: "DESCLOCFASCIAORARIA",
    },
    {
      label: "PNR",
      type: "custom",
      data: "PNR",
      render: (celldata) => (
        <Button
          onClick={() => this.openPnr(celldata)}
          style={{
            padding: "1px 5px",
          }}
          size="sm"
        >
          {celldata}
          <i className="ml-2 fas fa-qrcode"></i>
        </Button>
      ),
    },
    {
      label: this.props.t("Actions"),
      type: "custom",
      render: (celldata, row) => (
        <React.Fragment>
          {row.PNR && row.CODICESTATOPRENOTAZIONE === STATUSES.CREATED && (
            <>
              <Button
                outline
                color="warning"
                className="border-0"
                size="sm"
                onClick={() => {
                  this.handleSearch = true;
                  this.props.triggerGetPrenotazione(row.PNR);
                  this.setState({
                    selectedPnr: row.PNR,
                    selectedPrenotazioneId: row.IDPRENOTAZIONE,
                    selectedVeicoloId: row.IDVEICOLO,
                    numMaxOccupanti: row.MAXOCCUPANTI,
                    selectedDataInizio: row.DATAINIZIO,
                    selectedPrenotazioneIdComune: row.IDCOMUNE,
                    readOnly: row.CODICESTATOPRENOTAZIONE !== STATUSES.CREATED,
                  });
                }}
              >
                <i className="fas fa-pencil-alt"></i>
              </Button>
              <Button
                outline
                color="danger"
                className="border-0"
                size="sm"
                onClick={() => {
                  this.setState({
                    showModalDelete: true,
                    selectedPrenotazioneId: row.IDPRENOTAZIONE,
                    selectedPrenotazioneIdComune: row.IDCOMUNE,
                  });
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            </>
          )}
        </React.Fragment>
      ),
    },
  ];
  filters = {
    [`${FILTER_NUMBER}&&&fkIdUtente`]: [this.props.Auth.user.idUtente],
  };

  service = new PrenotazioneService();

  componentDidUpdate() {
    if (this.handleSearch && this.props.Prenotazione.type === SUCCESS_SEARCH) {
      this.handleSearch = false;
      this.setState({
        showModal: true,
      });
    }
  }

  openPnr(pnr) {
    this.setState({
      pnr: pnr,
      openPnr: true,
    });
  }

  getData(pageNum, itemsPerPage, ff, sort) {
    return this.service
      .getPrenotazioniUtente(
        this.props.Auth.user.idUtente,
        pageNum,
        itemsPerPage,
        ff,
        sort
      )
      .then((res) => {
        res.data.rowCount = res.data.rowCount || res.data.result.length;
        return res;
      });
  }

  closeModal() {
    this.setState(
      {
        showModal: false,
        readOnly: null,
      },
      () => {
        this.refs.crudNetPrenotazione.refreshTable();
      }
    );
  }

  closeModalDelete() {
    this.setState(
      {
        showModalDelete: false,
      },
      () => {
        this.refs.crudNetPrenotazione.refreshTable();
      }
    );
  }

  handleDelete() {
    let flagSms = false;

    this.comuneService
      .getById(this.state.selectedPrenotazioneIdComune)
      .then((res) => {
        if (res.data.result.settings) {
          const settings = JSON.parse(res.data.result.settings);
          if (settings.sendNotification) {
            flagSms = true;
          }
        }
      })
      .then(() => {
        this.service.setFlgPrenotazione(
          this.state.selectedPrenotazioneId,
          STATUSES.DELETED,
          flagSms
        );
      })
      .finally(() => {
        this.closeModalDelete();
      });
  }

  componentDidMount() {
    window.onpopstate = () => {
      window.location.reload();
    };
  }

  componentWillUnmount() {
    //window.onpopstate=()=>{};
  }

  render() {
    return (
      <>
        <CrudNetTable
          getData={this.getData.bind(this)}
          customHeader={() => <h2>{this.props.t("PRENOTAZIONI")}</h2>}
          title={this.props.t("PRENOTAZIONI")}
          actions={[]}
          columns={this.columns}
          filters={this.filters}
          defaultFilters={this.filters}
          service={this.service}
          disablePaginate={false}
          ref="crudNetPrenotazione"
        />
        <QrCodeViewer
          style={{ marginTop: 100 }}
          show={this.state.openPnr}
          code={this.state.pnr}
          onClose={() => {
            this.setState({
              pnr: null,
              openPnr: false,
            });
          }}
        />
        <Modal
          isOpen={this.state.showModal}
          toggle={this.closeModal.bind(this)}
          size="xl"
        >
          <ModalHeader toggle={this.closeModal.bind(this)}>
            {this.props.t("RESERVATION")}
          </ModalHeader>
          <ModalBody>
            {this.state.showModal && (
              <PrenotazioneDetailForm
                readOnly={this.state.readOnly}
                isEdit={true}
                prenotazioneId={this.state.selectedPrenotazioneId}
                veicoloId={this.state.selectedVeicoloId}
                onClose={this.closeModal.bind(this)}
                maxOccupanti={this.state.numMaxOccupanti}
                dataInizio={this.state.selectedDataInizio}
              />
            )}
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalDelete}
          toggle={this.closeModalDelete.bind(this)}
          size="md"
        >
          <ModalHeader toggle={this.closeModalDelete.bind(this)}>
            {this.props.t("RESERVATION")}
          </ModalHeader>
          <ModalBody>{this.props.t("DELETE_MESSAGE")}</ModalBody>
          <ModalFooter>
            <Button onClick={this.handleDelete.bind(this)}>
              {this.props.t("Ok")}
            </Button>
            <Button onClick={this.closeModalDelete.bind(this)}>
              {this.props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const PrenotazionePage = RxComponent({ triggerGetPrenotazione })(Comp);
export default PrenotazionePage;
