import HttprepoTAAL from "../../../repos/HttpRepoTaal";
const GET_GESTORE = "/Generic/search2/GestoreArea";

export default class GestoreAreaHttpRepo extends HttprepoTAAL {
  getTable() {
    return "GestoreArea";
  }
  getRecordInLogin(idUser, token) {
    return this.API.post(
      GET_GESTORE,
      {
        pageNum: 0,
        pageSize: -1,
        Includes: ["Area.Comune"],
        filter: {
          Expression: "fkIdUtente == @0",
          ExpressionValues: [{ value: idUser }],
        },
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }
}
