import React from 'react';
import {onError, onSuccess} from "../../redux/Shared/Shared.actions";
import CrudNetTable from '../../components/CrudNet/CrudNetTable';
import ParcheggioService from './Parcheggio.service';
import Switch from "react-switch";
import RxComponent from "../../redux/RxComponent";
import {FILTER_NUMBER} from "../../components/TableServer/FilterItem";

class Page extends React.Component{

    parkService = new ParcheggioService()

    columns=[
        {
            label: this.props.t("Description"),
            data:'descrizione'
        },
        {
            label: this.props.t("Municipality"),
            data:'Comune.descrizione'
        },
        {
            label: this.props.t("TotalSlot"),
            data:'slotTotali'
        },
        {
            label:'',
            data:'flgPrivato',
            type:'custom',
            render:(cellData)=>{
                return <>
                    {cellData? <>{this.props.t('LOCKED')}<i className="fas fa-lock"></i></>:<>{this.props.t('UNLOCKED')}<i className="fas fa-lock-open"></i></>}
                </>
            }
        },
        {
            label: this.props.t("State"),
            type: "custom",
            render: (celldata, row) => {
                return (
                    <React.Fragment>
                        <Switch
                            onChange={() => this.handleSwitch(row)}
                            checked={row.flgAttivo}
                        />
                    </React.Fragment>
                );
            },
        },
    ];

    filters = {
        [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.Auth.user.fkIdComune],
    };

    handleSwitch = (row) => {
        let objToPass = {
            idParcheggio: row.idParcheggio,
            isActive: !row.flgAttivo,
        };

        this.parkService.setFlgAbilitazioneParcheggio(objToPass.idParcheggio, objToPass.isActive)
            .then((resp) => {
                this.props.onSuccess(this.props.t("SuccessedOperation"));
                this.refs.crudNetPark.refreshTable();
            })
            .catch((ex) => {
                this.props.onError(ex);
            });
    };


    submitCrud(formData,viewMode,close){
        debugger;
        formData.fkIdComune = this.props.Auth.user.fkIdComune
        ? this.props.Auth.user.fkIdComune
        : this.props.fkIdComune;
          let promise = null;
          if (viewMode === "insert") {
            promise = this.parkService.create(formData)
          } else if (viewMode === "edit") {
            promise = this.parkService.edit(formData)
          } else {
            promise = this.parkService.remove(formData);
          }
          promise
            .then((resp) => {
              close();
              this.props.onSuccess("SuccessedOperation");
            })
            .catch((ex) => {
              this.props.onError(ex);
            });
      }

    render(){
        return(
            <CrudNetTable
                title={this.props.t("ParkingArea")}
                refFieldMap={{ fkIdTipologiaArea: "descLocalizzata" }}
                columns={this.columns}
                filters={this.filters}
                defaultFilters={this.filters}
                service={this.parkService}
                actions={["edit"]}
                onSubmit={this.submitCrud.bind(this)}
                ref="crudNetPark"
            />
        );
    }

}

const ParcheggioPage = RxComponent({onSuccess, onError})(Page);
export default ParcheggioPage;
