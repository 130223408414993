import StubRepo from "../../repos/StubRepo";
import TipologiaVarcoMockRepo from "./repositories/TipologiaVarco.mock";
import TipologiaVarcoHttpRepo from "./repositories/TipologiaVarco.http";

export default class TipologiaVarco extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaVarco repo";
  }

  static getLocal() {
    if (TipologiaVarco._localInstance == null) {
      TipologiaVarco._localInstance = new TipologiaVarcoMockRepo();
    }
    return TipologiaVarco._localInstance;
  }

  static getService() {
    if (TipologiaVarco._serviceInstance == null) {
      TipologiaVarco._serviceInstance = new TipologiaVarcoHttpRepo();
    }
    return TipologiaVarco._serviceInstance;
  }
}
