import React from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';

export default class SortIndicator extends React.Component{

    DIRECTION_ASC = 'ASC';
    DIRECTION_DESC = 'DESC';

    constructor(props){
        super(props);
        this.state={
            direction:null
        }   
    }

    onSort(){
        let nextDirection=this.DIRECTION_ASC;
        if(this.state.direction===this.DIRECTION_ASC)
            nextDirection=this.DIRECTION_DESC;
        if(this.state.direction===this.DIRECTION_DESC)
            nextDirection=null;

        this.setState({
            direction:nextDirection
        },()=>{
            if(this.props.onSortChange && _.isFunction(this.props.onSortChange)){
                this.props.onSortChange(this.state.direction);
            }
        });

    }

    render(){
        if(!this.state.direction){
            return (
                <Button color="link" style={{color:'#858796'}} onClick={this.onSort.bind(this)}>
                    <i className="fas fa-sort"></i>
                </Button>
            );
        }
        return(
            <Button color="link" style={{color:'#858796'}} onClick={this.onSort.bind(this)}>
                {
                    this.state.direction===this.DIRECTION_ASC?
                        <i className="fas fa-sort-up"></i>
                    :null
                }
                {
                    this.state.direction===this.DIRECTION_DESC?
                        <i className="fas fa-sort-down"></i>
                    :null
                }
            </Button>
        );
    }


}