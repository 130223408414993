import { Home } from "../pages/Home";
import { Users } from "../pages/Users";
import LookupsPage from "../modules/Lookups/Lookups.page.js";
import PrenotazioneForm from "../modules/Prenotazione/components/Prenotazione.form2";
import Login from "../pages/Login/Login.page";
import PasswordRecovery from "../pages/Login/ForgetPassword";
import RegisterPage from "../pages/Register/Register.page";
import RegisterHelpdesk from "../pages/Register/components/Register.helpdesk";
import AreaPage from "../modules/Area/Area.page";
import CentraleOperativaPage from "../modules/CentraleOperativa/CentraleOperativa.page";
import NotificationCOPage from "../modules/CentraleOperativa/NotificationCentraleOperativa.page";
import AreaResultList from "../modules/Area/components/Area.showresult";
import ControlloVarco from "../modules/ControlloVarco/ControlloVarco.page";
import ParcheggioPage from "../modules/Parcheggio/Parcheggio.page";
import PrenotazioneDetailForm from "../modules/Prenotazione/components/Prenotazione.insertdetail";
import AreaDetails from "../modules/Area/components/Area.details";
import UtentePage from "../modules/Utente/Utente.page";
import PrenotazionePage from "../modules/Prenotazione/Prenotazione.page";
import VarcoPage from "../modules/Varco/Varco.page";
import ControlloTarga from "../modules/ControlloTarga/ControlloTarga.page";
import RegisterFromVarco from "../pages/Register/components/RegisterFromVarco";
import SerchUserFromVarco from "../modules/Prenotazione/components/Prenotazione.searchUserFromVarco";
import { ROLES } from "./Roles";
import ComuneConfigPage from "../modules/Comune/ComuneConfig.page";
import ChiusuraAreaVarco from "../modules/ControlloVarco/components/ChiusuraAreaVarco";
import RegistroSanitario from "../modules/RegistroSanitario/RegistroSanitario.page";
import GestoreAreaPage from "../modules/AssegnaGestoreArea/AssegnaGestoreArea.page";
import PrenotazioneFromGestore from "../modules/Prenotazione/components/PrenotazioneFromGestore";
import TurnazioneVarcoPage from "../modules/TurnazioneVarco/TurnazioneVarco.page";
import EventoPage from "../modules/Evento/Evento.page";

export const menu = [
  {
    path: "/login",
    name: "Login",
    icon: "tachometer-alt",
    component: Login,
    layout: "",
  },
  /*{
    path: "/passwordrecovery",
    name: "PasswordRecovery",
    icon: "tachometer-alt",
    component: PasswordRecovery,
    layout: "",
  },*/
  {
    path: "/register",
    name: "Register",
    icon: "tachometer-alt",
    component: RegisterPage,
    layout: "",
  },
  /*{
    path: "/registerHelpdesk",
    name: "register Helpdesk",
    icon: "tachometer-alt",
    component: RegisterHelpdesk,
    layout: "",
  },*/
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home",
    component: Home,
    layout: "/dashboard",
  },
  {
    path: "/registervarco",
    name: "Register From Varco",
    icon: "tachometer-alt",
    component: RegisterFromVarco,
    layout: "/dashboard",
    roles: [
      ROLES.ADDETTO_SICUREZZA,
      ROLES.RESPONSABILE_VARCHI_STRADALI,
      ROLES.RESPONSABILI_VARCHI_AREE,
      ROLES.ADMIN_COMUNE,
    ],
  },
  {
    path: "/closaareavarco",
    name: "Close Area From Varco",
    icon: "far fa-times-circle",
    component: ChiusuraAreaVarco,
    layout: "/dashboard",
    roles: [
      ROLES.ADDETTO_SICUREZZA,
      ROLES.RESPONSABILE_VARCHI_STRADALI,
      ROLES.RESPONSABILI_VARCHI_AREE,
      ROLES.ADMIN_COMUNE,
    ],
  },
  {
    path: "/prenotazionevarco",
    name: "Book From Varco",
    icon: "fas fa-ticket-alt",
    component: SerchUserFromVarco,
    layout: "/dashboard",
    roles: [
      ROLES.ADDETTO_SICUREZZA,
      ROLES.RESPONSABILE_VARCHI_STRADALI,
      ROLES.RESPONSABILI_VARCHI_AREE,
      ROLES.ADMIN_COMUNE,
    ],
  },
  {
    path: "/home",
    name: "Nuova prenotazione",
    icon: "fas fa-ticket-alt",
    component: PrenotazioneForm,
    layout: "/site",
  },
  {
    path: "/prenotazioni",
    name: "Prenotazioni",
    icon: "tachometer-alt",
    component: PrenotazionePage,
    layout: "/site",
  },
  {
    path: "/area",
    name: "Area",
    icon: "fas fa-map-marked-alt",
    layout: "/dashboard",
    roles: [ROLES.CENSORE_AREA, ROLES.ADMIN_COMUNE /*ROLES.GESTORE_AREA*/],
    component: AreaPage,
  },
  {
    path: "/evento",
    name: "Evento",
    icon: "fas fa-calendar-day",
    layout: "/dashboard",
    roles: [ROLES.CENSORE_AREA, ROLES.ADMIN_COMUNE /*ROLES.GESTORE_AREA*/],
    component: EventoPage,
  },
  {
    path: "/areadetails",
    name: "Area Details",
    icon: "tachometer-alt",
    component: AreaDetails,
    layout: "/site",
    skipMenu: true,
  },
  {
    path: "/serchuserfromgestore",
    name: "Nuova prenotazione",
    icon: "tachometer-alt",
    component: SerchUserFromVarco,
    layout: "/dashboard",
    roles: [ROLES.GESTORE_AREA],
  },
  {
    path: "/prenotazioneFromGestore",
    name: "Nuova prenotazione",
    icon: "tachometer-alt",
    component: PrenotazioneFromGestore,
    layout: "/dashboard",
    skipMenu: true,
  },
  /*{
    path: "/gestorearea",
    name: "Gestore Area",
    icon: "tachometer-alt",
    component: GestoreAreaPage,
    layout: "/dashboard",
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN_COMUNE],
    //skipMenu: true,
  },*/
  {
    path: "/varco",
    name: "Varco",
    icon: "fas fa-door-open",
    layout: "/dashboard",
    roles: [
      ROLES.RESPONSABILE_VARCHI_STRADALI,
      ROLES.RESPONSABILI_VARCHI_AREE,
      ROLES.ADMIN_COMUNE,
    ],
    component: VarcoPage,
  },
  {
    path: "/insertdetail",
    name: "Area",
    icon: "",
    layout: "/site",
    component: PrenotazioneDetailForm,
    skipMenu: true,
  },
  {
    path: "/controlloVarco",
    name: "Controllo Varco",
    icon: "fas fa-clipboard-check",
    layout: "/dashboard",
    roles: [
      ROLES.ADDETTO_SICUREZZA,
      ROLES.RESPONSABILE_VARCHI_STRADALI,
      ROLES.RESPONSABILI_VARCHI_AREE,
      ROLES.ADMIN_COMUNE,
    ],
    component: ControlloVarco,
  },
  {
    path: "/controlloTarga",
    name: "Controllo Targa",
    icon: "fas fa-map-marked-alt",
    layout: "/dashboard",
    roles: [ROLES.AUSILIARE_TRAFFICO],
    component: ControlloTarga,
  },
  {
    path: "/parcheggio",
    name: "Parcheggio",
    icon: "fas fa-car",
    layout: "/dashboard",
    roles: [ROLES.CENSORE_PARCHEGGI, ROLES.ADMIN_COMUNE],
    component: ParcheggioPage,
  },
  {
    path: "/utenti",
    name: "Utenti",
    icon: "fas fa-user",
    layout: "/dashboard",
    roles: [ROLES.ADMIN_COMUNE],
    component: UtentePage,
  },
  {
    path: "/turnazionevarco",
    name: "TurnazioneVarco",
    icon: "fas fa-calendar-day",
    layout: "/dashboard",
    roles: [ROLES.ADMIN_COMUNE],
    component: TurnazioneVarcoPage,
  },
  {
    path: "/config",
    name: "Configurazione",
    icon: "fas fa-tools",
    layout: "/dashboard",
    roles: [ROLES.ADMIN_COMUNE],
    component: ComuneConfigPage,
  },
  {
    path: "/resultSearch",
    name: "ResultSearch",
    icon: "",
    component: AreaResultList,
    layout: "/site",
    skipMenu: true,
  },
  {
    path: "/centrale",
    name: "CentraleOperativa",
    icon: "fas fa-project-diagram",
    roles: [ROLES.CENTRALE_OPERATIVA_SICUREZZA, ROLES.ADMIN_COMUNE],
    layout: "/dashboard",
    children: [
      {
        path: "/realtime",
        name: "Check RT",
        component: CentraleOperativaPage,
        roles: [ROLES.CENTRALE_OPERATIVA_SICUREZZA, ROLES.ADMIN_COMUNE],
      },
      {
        path: "/notifications",
        name: "Notifiche",
        component: NotificationCOPage,
        roles: [ROLES.CENTRALE_OPERATIVA_SICUREZZA, ROLES.ADMIN_COMUNE],
      },
    ],
  },
  {
    path: "/lookups",
    name: "Lookups",
    component: LookupsPage,
    layout: "/dashboard",
    roles: [ROLES.SUPER_ADMIN],
  },
  /*{
    path: "/admin",
    name: "Administration",
    icon: "users-cog",
    layout: "/dashboard",
    children: [
      {
        path: "/users",
        name: "Users",
        component: Users,
      },
      {
        path: "/centrale",
        name: "Centrale Operativa",
        component: CentraleOperativaPage,
      },
      {
        path: "/lookups",
        name: "Lookups",
        component: LookupsPage,
        roles: ["ADM"],
      },
    ],
  },*/
  {
    path: "/prenotazioneFromVarco",
    name: "Nuova prenotazione",
    icon: "tachometer-alt",
    component: PrenotazioneForm,
    layout: "/dashboard",
    skipMenu: true,
  },
  {
    path: "/resultSearch",
    name: "ResultSearch",
    icon: "",
    component: AreaResultList,
    layout: "/dashboard",
    skipMenu: true,
  },

  {
    path: "/areadetails",
    name: "Area Details",
    icon: "tachometer-alt",
    component: AreaDetails,
    layout: "/dashboard",
    skipMenu: true,
  },
  {
    path: "/insertDetailFromDashboard",
    name: "Area",
    icon: "",
    layout: "/dashboard",
    component: PrenotazioneDetailForm,
    skipMenu: true,
  },
  {
    path: "/registroSanitario",
    name: "Registro Presenze",
    icon: "fas fa-ticket-alt",
    layout: "/dashboard",
    component: RegistroSanitario,
    roles: [ROLES.GESTORE_AREA, ROLES.SUPER_ADMIN],
  },
];
