export const AREE = {
  SPIAGGIA_ATTREZZATA: "SPP",
  SPIAGGIA_LIBERA: "SPL",
  BATTIGIA: "BAT",
  STRADA_LIT: "LIT",
  RISTORANTE: "RIST",
  HOTEL: "HOT",
  ABITAZIONE: "ABP",
  EVENTO: "EVE",
  ATTIVITA_LAVORATIVA: "ATL",
  AREA_SHOPPING: "SHOP",
};
