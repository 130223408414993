import React from 'react';
import Quagga from 'quagga';
import { Modal, ModalHeader, ModalBody, Button, InputGroupAddon, InputGroup, Input } from 'reactstrap';
import _ from 'lodash';
import { Translation } from "react-i18next";

export default class BarcodeScanner extends React.PureComponent{

    state={
        openModal:false,
        code:null
    }

    openFilePicker=()=>{
        document.querySelector('#file-barcode').click();
    }

    readBarcodeFromFile = (src) => {
        Quagga.decodeSingle({
            decoder: {
                readers: ["code_39_reader"] // List of active readers
            },
            locate: true, // try to locate the barcode in the image
            locator:{
                patchSize: 'medium'
            },
            numOfWorkers: navigator.hardwareConcurrency,
            size:1280,
            src: src // or 'data:image/jpg;base64,' + data
        }, (result)=> {
            if(result && result.codeResult) {
                this.setCode(result.codeResult.code);
            } else {
                alert('not detected')
            }
        });
    }

    openModal(){
        this.setState({
            openModal:true
        },()=>{
            setTimeout(()=>this.initScan(),2000);
        })
    }

    render() {
        return (
            <>
            <InputGroup>
                <Translation>
                {
                    t =>
                        <Input
                            required={!!this.props.required}
                            value={this.state.code}
                            placeholder={t("BARCODE_DOC")}
                                //onChange={(ev)=>this.setState({code:ev.target.value})}
                            onChange={(ev) => {
                                let value = ev.target.value;
                                value = value.replace(/[^A-Za-z0-9]/gi, "");
                                return this.setState(
                                    { code: value },
                                    ()=>{
                                        if(_.isFunction(this.props.onDetect) && this.props.triggerOnChange){
                                            this.props.onDetect(value);
                                        }
                                    }
                                    );
                            }}
                        />
                }
                </Translation>
                <InputGroupAddon addonType="append">
                    <Translation>
                    {
                        t =>
                        <Button type="button" color="primary" onClick={this.openModal.bind(this)} title={t("SCAN")}>
                            <i className="fas fa-barcode"></i>
                        </Button>
                    }
                    </Translation>

                    <Button type="button" color="primary" onClick={this.openFilePicker.bind(this)}>
                        <i className="far fa-folder-open"></i>
                    </Button>
                    {!this.props.hideSearchButton && (
                        <Button type="button" color="primary" onClick={()=>this.setCode(this.state.code)}>
                            <i className="fas fa-search"></i>
                        </Button>
                    )}
                </InputGroupAddon>
                <input onChange={(e)=>{
                    if (e.target.files && e.target.files.length) {
                        this.readBarcodeFromFile(URL.createObjectURL(e.target.files[0]));
                    }
                }} type="file" accept="image/*" id="file-barcode"  className="d-none"/>
            </InputGroup>

            {

                <Modal
                    style={this.props.style}
                    isOpen={this.state.openModal}
                    toggle={() => this.closePicker()}
                    size="lg"
                >
                    <ModalHeader toggle={() => this.closePicker()}>
                    <Translation>
                    {
                        t => <h5>{this.state.code || t("READING")}</h5>
                    }
                    </Translation>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{

                        }} id="barcodeScanner" className="viewport"/>
                    </ModalBody>
                </Modal>

            }

            </>
        );
    }

    initScan() {
        Quagga.init({
            inputStream: {
                type : "LiveStream",
                constraints: {
                    width: 1280,
                    height: 600,
                    facingMode: "environment",
                    aspectRatio: {min: 1, max: 100}
                  },
                target: document.querySelector('#barcodeScanner')
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: navigator.hardwareConcurrency,
            decoder: {
                readers : ["code_39_reader"]
            },
            locate: true,
            halfSample: true,
            patchSize: "medium", // x-small, small, medium, large, x-large

        }, function(err) {
            if (err) {
                return console.log(err);
            }
            Quagga.start();
            let vid=document.querySelector('#barcodeScanner video');
            vid.setAttribute('style',"height:100%;width:100%;");

        });
        Quagga.onDetected(this._onDetected.bind(this));
    }

    _onDetected(result) {
        if(result && result.codeResult) {
            this.setCode(result.codeResult.code);
            Quagga.offDetected(this._onDetected)
            Quagga.stop();
            this.closePicker();
        }
    }

    closePicker(){
        this.setState({
            openModal:false
        })
    }

    setCode(code){
        this.setState({
            code:code
        },()=>{
            if(_.isFunction(this.props.onDetect)){
                this.props.onDetect(code);
            }
        })
    }
}

