import React from "react";
import _ from "lodash";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./TimeRcPicker.css";
import $ from "jquery";
import { Trans } from "react-i18next";

export default class TimeRcPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      id: this._generateId(),
      selectedHour: null,
      maxTime: this.props.maxTime,
      minTime: this.props.minTime,
    };
  }

  componentDidMount() {
    this._mergePropsInState();
    this._setRequiredInput();
    this.setState({ selectedHour: this.props.value.format("HH") });
  }

  componentDidUpdate(prevProps) {
    //this._mergePropsInState();
    if (this.props.value.format("HH") !== this.state.selectedHour) {
      this.setState({ selectedHour: this.props.value.format("HH") });
    }
    if (
      this.props.maxTime !== this.state.maxTime ||
      this.props.minTime !== this.state.minTime
    ) {
      this.setState({
        maxTime: this.props.maxTime,
        minTime: this.props.minTime,
      });
    }
  }

  _generateId() {
    return "trcp-" + Date.now().toString(36);
  }

  _setRequiredInput() {
    if (this.props.required) {
      $("#" + this.state.id + " input.rc-time-picker-input").attr(
        "required",
        "required"
      );
    }
  }

  _mergePropsInState = () => {
    this.setState({ ...this.state, ...this.props });
  };

  _onChange = (ev) => {
    if (_.isFunction(this.props.onChange) && ev) {
      this.props.onChange(ev);
      this.setState({
        selectedHour: ev.format("HH"),
        selectedMinutes: ev.format("mm"),
      });
    }
  };

  calculateDisabledHours() {
    let disH = [];
    if (this.state.minTime && this.state.maxTime) {
      let hsMin = parseInt(this.state.minTime.substring(0, 2));
      let msMin = parseInt(this.state.minTime.substring(2, 4));
      let hsMax = parseInt(this.state.maxTime.substring(0, 2));
      let msMax = parseInt(this.state.maxTime.substring(2, 4));
      if (
        parseInt(this.state.minTime.substring(0, 2)) <
        parseInt(this.state.maxTime.substring(0, 2))
      ) {
        for (let i = 0; i < 24; i++) {
          if (i < hsMin) disH.push(i);
        }
        if (parseInt(this.state.selectedMinutes) < msMin) {
          disH.push(parseInt(hsMin));
        }

        for (let i = 0; i < 24; i++) {
          if (i > hsMax) disH.push(i);
        }
        if (parseInt(this.state.selectedMinutes) > msMax) {
          disH.push(parseInt(hsMax));
        }
      } else if (
        parseInt(this.state.minTime.substring(0, 2)) >
        parseInt(this.state.maxTime.substring(0, 2))
      ) {
        for (let i = 0; i < 24; i++) {
          if (i < hsMin && i > hsMax) disH.push(i);
        }
        if (parseInt(this.state.selectedMinutes) < msMin) {
          disH.push(parseInt(hsMin));
        }

        for (let i = 0; i < 24; i++) {
          if (i > hsMax && i < hsMin) disH.push(i);
        }
        if (parseInt(this.state.selectedMinutes) > msMax) {
          disH.push(parseInt(hsMax));
        }
      }

      if (parseInt(this.state.minTime) === parseInt(this.state.maxTime)) {
        disH = [];
        for (let i = 0; i < 24; i++) {
          disH.push(i);
        }
      }
    }
    return disH;
  }

  calculateDisabledMinutes() {
    let disM = [];
    if (this.state.minTime) {
      let hsMin = parseInt(this.state.minTime.substring(0, 2));
      let msMin = parseInt(this.state.minTime.substring(2, 4));

      if (parseInt(this.state.selectedHour) === hsMin) {
        for (let i = 0; i < 60; i++) {
          if (i < msMin) disM.push(i);
        }
      }
    }
    if (this.state.maxTime) {
      let hsMax = parseInt(this.state.maxTime.substring(0, 2));
      let msMax = parseInt(this.state.maxTime.substring(2, 4));

      if (parseInt(this.state.selectedHour) === hsMax) {
        for (let i = 0; i < 60; i++) {
          if (i > msMax) disM.push(i);
        }
      }
    }
    if (this.state.minTime && this.state.maxTime) {
      if (parseInt(this.state.minTime) === parseInt(this.state.maxTime)) {
        disM = [];
        for (let i = 0; i < 60; i++) {
          disM.push(i);
        }
      }
    }
    return disM;
  }

  render() {
    return (
      <div id={this.state.id}>
        <label htmlFor={`${this.state.id}`}>
          <Trans i18nKey={this.state.label} />
          {this.props.required && "*"}
        </label>
        <div>
          <TimePicker
            inputReadOnly={this.props.readOnly}
            className="form-group dateRcPicker"
            value={this.props.value}
            showSecond={false}
            label={this.state.label}
            onChange={this._onChange.bind(this)}
            disabledHours={this.calculateDisabledHours.bind(this)}
            disabledMinutes={this.calculateDisabledMinutes.bind(this)}
            disabled={this.props.readOnly}
          />
        </div>
      </div>
    );
  }
}
