import StubRepo from "../../repos/StubRepo";
import ControlloTargaMockRepo from "./repositories/ControlloTarga.mock";
import ControlloTargaHttpRepo from "./repositories/ControlloTarga.http";

export default class ControlloTarga extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "ControlloTarga repo";
  }

  static getLocal() {
    if (ControlloTarga._localInstance == null) {
      ControlloTarga._localInstance = new ControlloTargaMockRepo();
    }
    return ControlloTarga._localInstance;
  }

  static getService() {
    if (ControlloTarga._serviceInstance == null) {
      ControlloTarga._serviceInstance = new ControlloTargaHttpRepo();
    }
    return ControlloTarga._serviceInstance;
  }
}
