import StubRepo from "../../repos/StubRepo";
import FasciaOrariaMockRepo from "./repositories/FasciaOraria.mock";
import FasciaOrariaHttpRepo from "./repositories/FasciaOraria.http";

export default class FasciaOraria extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "FasciaOraria repo";
  }

  static getLocal() {
    if (FasciaOraria._localInstance == null) {
      FasciaOraria._localInstance = new FasciaOrariaMockRepo();
    }
    return FasciaOraria._localInstance;
  }

  static getService() {
    if (FasciaOraria._serviceInstance == null) {
      FasciaOraria._serviceInstance = new FasciaOrariaHttpRepo();
    }
    return FasciaOraria._serviceInstance;
  }
}
