import {
  ON_ERROR,
  ON_SUCCESS,
  ON_WARNING,
  ON_INFO,
  LOADING_START,
  LOADING_END,
  CLEAR_NOTIFICATION,
} from "./Shared.types";

const initialState = {
  loading: null,
  messageType: null,
  messageText: null,
};

export const SharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_SUCCESS:
      return {
        ...state,
        messageType: action.type,
        showModal: true,
        messageText: action.payload,
      };
    case ON_ERROR:
      return {
        ...state,
        messageType: action.type,
        messageText: action.payload,
        loading: false,
      };
    case ON_WARNING:
      return {
        ...state,
        messageType: action.type,
        messageText: action.payload,
      };
    case ON_INFO:
      return {
        ...state,
        messageType: action.type,
        messageText: action.payload,
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
        messageText: action.payload,
      };
    case LOADING_END:
      return {
        ...state,
        loading: false,
        messageText: action.payload,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        messageType: null,
        messageText: null,
        loading: null,
      };
    default:
      return state;
  }
};
