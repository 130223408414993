import StubRepo from "../../repos/StubRepo";
import ParcheggioMockRepo from "./repositories/Parcheggio.mock";
import ParcheggioHttpRepo from "./repositories/Parcheggio.http";

export default class Parcheggio extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Parcheggio repo";
  }

  static getLocal() {
    if (Parcheggio._localInstance == null) {
      Parcheggio._localInstance = new ParcheggioMockRepo();
    }
    return Parcheggio._localInstance;
  }

  static getService() {
    if (Parcheggio._serviceInstance == null) {
      Parcheggio._serviceInstance = new ParcheggioHttpRepo();
    }
    return Parcheggio._serviceInstance;
  }
}
