import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Utente from "./Utente.repo";
import { FILTER_TEXT } from "../../components/TableServer/FilterItem";

export default class UtenteService extends BaseServiceTaal {
  getRepo = () => Utente.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "Comune",
          "TipologiaProfilo",
          "Lingua",
          "AnagraficaUtente_List.Documento.TipologiaDocumento",
        ]
      )
    );


  searchByCF = (par) =>
    this.getRepo().then((repo) => repo.exec("fn_GetIdUtenteFromDoc", par));

  tableDefinition = (fieldToHide) =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          res.data.result.columns = res.data.result.columns.filter(
            (c) =>
              c.name !== "dataCreazione" &&
              c.name !== "dataUltimaModifica" &&
              c.name !== "fkIdComune" &&
              c.name !== "fkIdComune" &&
              c.name !== "flgAttivo" &&
              c.name !== "flgEliminato" &&
              c.name !== fieldToHide
          );
          /* res.data.result.columns.map(
            (el) => (el.type = el.name === "password" ? "Password" : el.type)
          );*/
        }
        return res;
      });

  create = (par) =>
    this.getRepo().then((repo) => repo.exec("sp_CreateUtenteBackOffice", par));

  edit = (par) =>
    this.getRepo().then((repo) => repo.exec("sp_UpdateUtenteBackOffice", par));

  setFlgAbilitazioneUtente = (utente) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_UpdateUtenteBackOffice", utente);
    });
  };

  changePassword = (idUtente, password) => {
    return this.getRepo().then((repo) => {
      let par = { idUtente: idUtente, password: password };
      return repo.exec("sp_UpdatePasswordUtente", par);
    });
  };
}
