import React from 'react';
import RxComponent from '../../redux/RxComponent';

class Comp extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    _gooogleProfile() {
        const profilePhotoContainer = {
            width: "150px",
            height: "150px",
            border: "2px solid",
            background: "linear-gradient(60deg, #ffa726, #fb8c00)",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "22px",
            overflow: "hidden"
        };

        return (
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div
                                className="mx-auto d-flex align-items-center justify-content-center rounded-circle" style={profilePhotoContainer}>
                                <img width="100%" height="100%" src={this.props.Auth.googleUser.QK} alt={this.props.Auth.googleUser.Ad} />
                            </div>
                        </div>
                        <div className="col-12 text-center my-3">
                            <h4>{this.props.Auth.googleUser.Ad}</h4>
                        </div>
                        <div className="col-12 text-center my-3">
                            <h4>{this.props.Auth.googleUser.yu}</h4>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            this.props.Auth.loggedIn &&
            this._gooogleProfile()
        )
    }


}

const GoogleProfile = RxComponent()(Comp)
export default GoogleProfile;