import HttprepoTAAL from "./HttpRepoTaal";

export default class StubRepo {

    static getName() {
        return "REPO";
    }

    static getService() {
    }

    static getLocal() {
    }

    static getInstanceTaal() {
        return new Promise((resolve, reject) => {
            //return http service if is production
            return resolve(this.getService());
            if(process.env.NODE_ENV === 'production')  return resolve(this.getService());

            console.log(`choosing repo for: ${this.getName()}`);
            HttprepoTAAL.testConnection().then((res)=>{
                resolve(this.getService());
                console.log(` ${this.getName()} is online`);
            }).catch(error => {
                if (error.message.toLowerCase() === "network error" || error.message.toLowerCase().indexOf('timeout of') >= 0) {
                    resolve(this.getLocal());
                    console.log(` ${this.getName()} is mocked`);
                } else {
                    resolve(this.getService());
                    console.log(` ${this.getName()} is online`);
                }
            });
        });

    }


}