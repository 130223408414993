import React from "react";
import { Chart } from "react-charts";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Table,
} from "reactstrap";
import moment from "moment";
import DatePicker from "../../components/Content/DatePicker";
import CentraleOperativaService from "./CentraleOperativa.service";
import RxComponent from "../../redux/RxComponent";
import { ROLES } from "../../constants/Roles";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Comp extends React.Component {
  service = new CentraleOperativaService();

  state = {
    activeTab: null,
    selectedData: moment().format("DD/MM/YYYY"),
    tabledata: [],
    onStamp: false,
  };

  axesH = [
    { primary: true, type: "ordinal", position: "left" },
    { position: "bottom", type: "linear", stacked: true },
  ];

  axesV = [
    {
      primary: true,
      type: "ordinal",
      position: "bottom",
    },
    {
      type: "linear",
      position: "left",
      stacked: true,
    },
  ];

  toogleTab(tab) {
    this.setState({ activeTab: tab });
  }

  componentDidMount() {
    this._reload(moment().format("DD/MM/YYYY"));
  }

  stampPDF(e) {
    e.preventDefault();
    const but = e.target;
    but.style.display = "none";
    window.document.getElementById("header-stamp").style.display = "flex";
    window.document.getElementById("data-stamp").style.display = "flex";
    let input = window.document.getElementsByClassName("card-body")[0];
    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jspdf("l", "pt");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight() - 220;
      pdf.addImage(img, "png", 0, 0, width, height);
      pdf.save("chart.pdf");
      but.style.display = "block";
    });
    window.document.getElementById("header-stamp").style.display = "none";
    window.document.getElementById("data-stamp").style.display = "none";
  }

  _reload(date) {
    if (date && this.props.Auth.user.fkIdComune) {
      this.setState({ selectedData: date }, () => {
        this.service
          .getStatisticByDateAndComune(
            moment(this.state.selectedData, "DD/MM/YYYY").format("YYYYMMDD"),
            this.props.Auth.user.fkIdComune
          )
          .then((res) => {
            this.setState(
              {
                tabledata: res.data.result,
              },
              () => {
                this.setState({
                  activeTab: !this.state.activeTab ? "1" : this.state.activeTab,
                });
              }
            );
          });
      });
    }
  }

  _mapToGraph(axes) {
    let totalOccpati = [];
    let totalPosti = [];
    let totalPrenotati = [];
    this.state.tabledata.forEach((p) => {
      /*totalOccpati.push([p.IDAREA + " - " + p.DESCRIZIONE,p.TOTALEOCCUPATI]);
        totalPosti.push([p.IDAREA + " - " + p.DESCRIZIONE, (p.TOTALEPOSTI-p.TOTALEOCCUPATI-p.TOTALEPRENOTATI)]);
        totalPrenotati.push([p.IDAREA + " - " + p.DESCRIZIONE, p.TOTALEPRENOTATI])*/
      totalOccpati.push([p.DESCRIZIONE, p.TOTALEOCCUPATI]);
      totalPosti.push([
        p.DESCRIZIONE,
        p.TOTALEPOSTI - p.TOTALEOCCUPATI - p.TOTALEPRENOTATI,
      ]);
      totalPrenotati.push([p.DESCRIZIONE, p.TOTALEPRENOTATI]);
    });
    return (
      <div
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <Chart
          data={[
            {
              label: this.props.t("TOTALE_OCCUPATI"),
              data: totalOccpati,
            },
            {
              label: this.props.t("TOTALE_DISPONIBILI"),
              data: totalPosti,
            },
            {
              label: this.props.t("TOTALE_PRENOTATI"),
              data: totalPrenotati,
            },
          ]}
          axes={axes}
          series={{ type: "bar" }}
          tooltip
          getSeriesStyle={(p) => {
            if (p.label === this.props.t("TOTALE_OCCUPATI")) {
              return { color: "#a92525" };
            }
            if (p.label === this.props.t("TOTALE_PRENOTATI")) {
              return { color: "#e3ea1c" };
            }
            return { color: "#22d230" };
          }}
        />
      </div>
    );
  }

  render() {
    if (
      !this.props.Auth.user.fkIdComune &&
      this.props.Auth.user.TipologiaProfilo.codiceProfilo !== ROLES.SUPER_ADMIN
    ) {
      return <p>{this.props.t("NotAuthorized")}</p>;
    }
    return (
      <>
        <div
          id="header-stamp"
          className="justify-content-start"
          style={{ display: "none", marginBottom: "-60px" }}
        >
          <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="logo" />
        </div>
        <div
          className="d-flex justify-content-end"
          data-html2canvas-ignore="true"
        >
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.props.t("EXPORT_DATA")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" onClick={(ev) => this.stampPDF(ev)}>
                <i className="far fa-file-pdf fa-2x mr-1"></i>PDF
              </a>
              <ExcelFile
                element={
                  <a className="dropdown-item">
                    <i className="far fa-file-excel fa-2x mr-1"></i> EXCEL
                  </a>
                }
              >
                <ExcelSheet data={this.state.tabledata} name="Data">
                  <ExcelColumn
                    label={this.props.t("Description")}
                    value="DESCRIZIONE"
                  />
                  <ExcelColumn
                    label={this.props.t("TOTALE_OCCUPATI")}
                    value="TOTALEOCCUPATI"
                  />
                  <ExcelColumn
                    label={this.props.t("TOTALE_POSTI")}
                    value="TOTALEPOSTI"
                  />
                  <ExcelColumn
                    label={this.props.t("TOTALE_PRENOTATI")}
                    value="TOTALEPRENOTATI"
                  />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
        </div>
        <div
          id="data-stamp"
          className="mt-5 mb-2"
          style={{ display: "none", color: "#000" }}
        >
          <p>
            <b>{this.props.t("Date") + ":"}</b>{" "}
            {this.state.selectedData
              ? moment(this.state.selectedData, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )
              : null}
          </p>
        </div>
        <div data-html2canvas-ignore="true">
          <DatePicker
            label={this.props.t("Date") + ":"}
            value={this.state.selectedData || null}
            displayValue="description"
            onChange={(ev) => this._reload(ev.date)}
          />
        </div>
        <Nav tabs data-html2canvas-ignore="true">
          <NavItem>
            <NavLink
              className={{ active: this.state.activeTab === "1" }}
              onClick={() => {
                this.toogleTab("1");
              }}
            >
              <i className="far fa-chart-bar fa-rotate-90"></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: this.state.activeTab === "2" }}
              onClick={() => {
                this.toogleTab("2");
              }}
            >
              <i className="far fa-chart-bar"></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: this.state.activeTab === "3" }}
              onClick={() => {
                this.toogleTab("3");
              }}
            >
              <i className="fas fa-list"></i>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">{this._mapToGraph(this.axesH)}</Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">{this._mapToGraph(this.axesV)}</TabPane>
          <TabPane tabId="3">
            <Table
              size="sm"
              hover
              className="table-header responsivetable"
              responsive
              id={"table-reddc"}
            >
              <thead>
                <tr>
                  <th className="th-custom-hide text-nowrap">
                    <span>{this.props.t("Description")}</span>
                  </th>
                  <th className="th-custom-hide text-nowrap">
                    <span>{this.props.t("TOTALE_OCCUPATI")}</span>
                  </th>
                  <th className="th-custom-hide text-nowrap">
                    <span>{this.props.t("TOTALE_POSTI")}</span>
                  </th>
                  <th className="th-custom-hide text-nowrap">
                    <span>{this.props.t("TOTALE_PRENOTATI")}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.tabledata.map((r, index) => {
                  return (
                    <tr key={index + r.DESCRIZIONE}>
                      <td
                        className="text-nowrap"
                        data-label={this.props.t("Description")}
                      >
                        <span>{r.DESCRIZIONE}</span>
                      </td>
                      <td
                        className="text-nowrap"
                        data-label={this.props.t("TOTALE_OCCUPATI")}
                      >
                        <span>{r.TOTALEOCCUPATI}</span>
                      </td>
                      <td
                        className="text-nowrap"
                        data-label={this.props.t("TOTALE_POSTI")}
                      >
                        <span>{r.TOTALEPOSTI}</span>
                      </td>
                      <td
                        className="text-nowrap"
                        data-label={this.props.t("TOTALE_PRENOTATI")}
                      >
                        <span>{r.TOTALEPRENOTATI}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TabPane>
        </TabContent>
        {this.state.activeTab !== "3" && (
          <div
            id="leggend-stamp"
            className="d-flex flex-column my-2"
            style={{ color: "#000" }}
          >
            <div className="d-flex align-items-center mx-3">
              <b className="mb-0">{this.props.t("LEGEND")}:</b>
            </div>
            <div className="d-flex align-items-center mx-3">
              <div
                style={{
                  backgroundColor: "#22d230",
                  border: "1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <p className="mb-0 mx-1">{this.props.t("TOTALE_DISPONIBILI")}</p>
            </div>
            <div className="d-flex align-items-center mx-3">
              <div
                style={{
                  backgroundColor: "#FFEB3B",
                  border: "1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <p className="mb-0 mx-1">{this.props.t("TOTALE_PRENOTATI")}</p>
            </div>
            <div className="d-flex align-items-center mx-3">
              <div
                style={{
                  backgroundColor: "#FF0000",
                  border: "1px solid #000",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <p className="mb-0 mx-1">{this.props.t("TOTALE_OCCUPATI")}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const CentraleOperativaPage = RxComponent()(Comp);
export default CentraleOperativaPage;
