import BaseServiceTaal from "../../Service/BaseServiceTaal";
import GestoreArea from "./AssegnaGestoreArea.repo";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";

export default class GestoreAreaService extends BaseServiceTaal {
  getRepo = () => GestoreArea.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "Area.Comune",
          "Area.TipologiaArea",
          "Utente.TipologiaProfilo",
          "Utente.AnagraficaUtente_List.Documento",
        ]
      )
    );

  getRecordInLogin = (idUser, token) => {
    return GestoreArea.getService().getRecordInLogin(idUser, token);
  };

  setGestoreArea = (data) =>
    this.getRepo().then((repo) => repo.exec("sp_SetGestoreArea", data));

  removeGestoreArea = (data) =>
    this.getRepo().then((repo) => repo.exec("sp_RemoveGestoreArea", data));

  getByIdUser = (idUser) =>
    this.getRepo().then((repo) => {
      let filter = {
        [`${FILTER_NUMBER}&&&fkIdUtente`]: [idUser],
      };
      let page = 1;
      let size = 1;
      return repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filter),
        null
      );
    });

  GetUserByNameSurnameTipoDocNumDoc = (objToPass) =>
    this.getRepo().then((repo) =>
      repo.exec("fn_GetUserByNameSurnameTipoDocNumDoc", objToPass)
    );

  deleteGestore = (par) =>
    this.getRepo().then((repo) => repo.exec("sp_RemoveGestoreArea", par));
}
