export const enLabels = {
  locIT: "Italian",
  locEN: "English",
  locSA: "Super Admin",
  locAC: "Council Admin",
  locUser: "User",
  locGestoreArea: "Area Manager",
  locCensoreArea: "Area Censor",
  locCensorePark: "Parking Censor",
  locAddetto: "Security Officer",
  locCentrale: "Security Headquarter",
  locRVS: "Road Gates Manager",
  locRVA: "Area Gates Manager",
  locDA: "Area Employee",
  locAddettoInfo: "Information Point Employee",
  locVS: "Road Gates",
  locVA: "Area Gates",
  locCF: "Fiscal Code",
  locCI: "Identity Document",
  locPassport: "Passaport",
  locAutoPrivata: "Private Car",
  locAutoServizio: "Service Car",
  locNavetta: "Public Van",
  locPullman: "Public Bus",
  locVan: "Private Van",
  locMoto: "Motorbike",
  locAmbulanza: "Ambulance",
  locVigiliFuoco: "Firefighters",
  locPolizia: "Police",
  locTaxi: "Taxi",
  locCarabinieri: "Carabinieri",
  locEsercito: "Esercito Italiano",
  locFinanza: "Guardia di Finanza",
  locPrenSpiaggiaMattina: "Morning (8-13)",
  locPrenSpiaggiaPomeriggio: "Afternoon (14-19)",
  locPrenSpiaggiaDay: "Whole day (8-19)",
  locPrenRistorante: "Restaurant Access",
  locPrenHotel: "Hotel Access",
  locPrenAbbonamento: "Monthly Subscription",
  locPrenLavorativa: "Working Access",
  locPrenEvento: "Event Access",
  locPrenAbitazione: "Private Household Access",
  locPrenPassaggio: "Transit Access (No stop allowed)",
  locPrenShopping: "Shopping Area Access",
  locDur5H: "5 hours",
  locDur11H: "11 hours",
  locDurPranzo: "Lunch",
  locDurVariabileD: "Variable (days)",
  locDurMensile: "Monthly (30 days)",
  locSpiaggiaAttrezzata: "Managed Beach",
  locSpiaggiaLibera: "Free Beach",
  locBattigia: "Shoreline",
  locStradaLit: "Shore Road",
  locRistorante: "Restaurant",
  locHotel: "Hotel",
  locAbitazione: "Private Household",
  locEvento: "Event",
  locAttivitaLavorativa: "Commercial Activity",
  locAreaShopping: "Shopping Area",
  locStatoPrenCreata: "Created",
  locStatoPrenConfermata: "Confirmed",
  locStatoPrenEliminata: "Deleted",
  locStatoPrenScaduta: "Expired",
  locStatoPrenUtilizzata: "Used",
  locStatoAssegnata: "Assigned",
  locStatoLiberata: "Freed",
  locStatoPrenotata: "Booked",
  locStatoEliminata: "Deleted",
  locDurCena: "Dinner",
  locDur1H: "1 Hour",
  locFasciaMattina: "Morning",
  locFasciaMensile: "Month",
  locFasciaPranzo: "Lunch",
  locFasciaPomeriggio: "Afternoon",
  locFasciaCena: "Dinner",
  locFasciaSera: "Evening",
  locFasciaDay: "Whole Day",
  locNotCreata: "Created",
  locNotRead: "Read",
  locNotDeleted: "Deleted",
  locNotSent: "Sent",
  locNotEvasa: "Despatched",
  locPrenMezzaGiornata: "Half Day",
  Login: "Login",
  User: "User",
  Password: "Password",
  ClearFilter: "Clear Filters",
  SomethingWentWrong: "Warning! Something happened, please retry.",
  SelectLeastFile: "Choose at least one file",
  SelectFile: "Choose a file",
  Ok: "OK",
  Province: "District",
  City: "City",
  ForgotPassword: "Forgot Password",
  OTPDescription: "Insert the OTP in the field below",
  RequestNewCode: "Resend OTP",
  Send: "Send",
  Registration: "Register",
  NumberPhone: "Phone Number",
  isForeign: "Are you a foreign?",
  Yes: "Yes",
  No: "No",
  TypeDocument: "Document Type",
  NumberDocument: "Document Number",
  RegistrationProblem: "Registration issues?",
  BookNow: "Book now",
  Loading: "Loading",
  Select: "Select",
  Month: "Month",
  NotAuthorizedLookPage: "Unauthorized, please contact an Admin",
  BackHome: "Go to Home Page",
  Year: "Year",
  AreYouSureRemoveElement: "Are you sure you want to delete this?",
  Confirm: "Confirm",
  Save: "Save",
  Logout: "Logout",
  Week: "Week",
  Total: "Totale",
  SeeMoreDetails: "More Details",
  EditData: "Edit data",
  DeleteData: "Delete data",
  FormatDateGG: "format (gg/mm/aaaa)",
  From: "Totale",
  To: "Totale",
  Filters: "Totale",
  ApplyFilters: "Apply Filters",
  ExportExcel: "Excel export",
  ExportPDF: "PDF export",
  TotalElements: "No. of elements",
  Search: "Search",
  SearchFor: "Search by...",
  ActivityLog: "Activity Log",
  Profile: "Profile",
  Settings: "Settings",
  REGISTER: "Register",
  descrizione: "Description",
  locPatente: "Driving License",
  ENTER_YOUR_BOOKING_DETAILS: "Enter your booking details",
  NUMERO_OCCUPANTI: "No. of People",
  PRENOTAZIONE_WIN_PARKING: "Congratulations! You won a parking spot",
  PRENOTAZIONE_NOTWIN_PARKING:
    "We're sorry but you didn't book in the first 100 spots! You didn't won a parking spot",
  SUBMIT: "Search",
  SEARCH_TITLE: "Available Areas",
  BOOK_NOW: "BOOK NOW",
  NUMERO_SPAZI_TOTALI: "Total Capacity",
  NUMERO_SPAZI_LIBERI: "Available spots",
  NUMERO_PERSONE_PRESENTI_IN_AREA: "Area occupants",
  "ENTER_DETAILS_FOR_PASSENGER_N°": "Guest No. ",
  WHO_COMES_WITH_YOU: "Who comes with you ?",
  BOOK_ON_TIME: "Book in time",
  CHOISE_MUNICIPALITIES_SECURE_RESERVATION:
    "the Councils choice for safe booking",
  "404": "404 Page Not Found",
  Welcome: "Welcome",
  Email: "Email",
  Name: "Name",
  Surname: "Surname",
  Gender: "Gender",
  BirthDate: "Birth Date",
  BirthPlace: "Birth Place",
  TypeMunicipality: "Enter your District",
  Language: "Language",
  Italiano: "Italian",
  Inglese: "English",
  "Codice Fiscale": "Fiscal code",
  "Carta d'Identità": "Identity Document",
  Passaporto: "Passport",
  Patente: "Driving License",
  WrongDocNumber: "Your ID number is wrong",
  RequireInsertFields: "Please insert the following fields:",
  AuthorizePrivacy:
    "Autorizzo il trattamento dei miei dati personali ai sensi del Decreto Legislativo 30 giugno 2003, n. 196 “Codice in materia di protezione dei dati personali” e del GDPR (Regolamento UE 2016/679).\n" +
    "Dichiaro, altresì che per i dati personali inseriti dal sottoscritto relativi ad altri soggetti, ho ricevuto apposita e specifica autorizzazione sollevando l’Amministrazione Comunale e la ditta erogatrice del servizio telematico da qualsivoglia responsabilità",
  FieldRequired: "Questo campo è obbligatorio",
  EnterCodeMobile: "Insert the code sent to your mobile in the fields below!",
  SentedCodeEmail:
    "we've sent you an email with the code (please check in the spam folder)",
  ControllPassword: "Password confirmation",
  PasswordDontMatch: "Password not identical",
  ChooseAree: "Choose areas",
  AreaAssignment: "Assign area",
  Description: "Description",
  DoorType: "Gate type",
  Municipality: "Town",
  Latitude: "Latitude",
  Longitude: "Longitude",
  State: "State",
  Door: "Gate",
  Cancel: "Cancel",
  Actions: "Actions",
  TimeSlot: "Time slot",
  Area: "Area",
  Date: "Date",
  Tipology: "Type",
  EnterBookingDetails: "Insert the booking details",
  BookTipology: "Booking type",
  NumberOccupants: "Occupants no.",
  ORARIO_ARRIVO: "Arrival time",
  LicensePlateRequired: "License Plate Required",
  Payed: "Payed",
  TotalSlot: "Total Slot",
  SuccessedOperation: "Successful operation",
  ParkingArea: "Parking",
  Lookups: "Lookups",
  Lookup: "Lookup",
  ChooseLookup: "Choose lookup",
  Entrance: "Entrance",
  Exit: "Exit",
  Notes: "Note",
  ControlOk: "Check Ok",
  ControlKo: "Check Ko",
  ControlLicensePlate: "Check License Plate",
  NotificationCentral: "Headquarter notification",
  NotAuthorized: "Unauthorized",
  TOTALE_PRENOTATI: "Total booked spots",
  TOTALE_POSTI: "Total spots",
  TOTALE_OCCUPATI: "Total occupied spots",
  TOTALE_DISPONIBILI: "Total free spots",
  DISPLAY_TEXT_INFO_F:
    "Warning! The Available spots is shown for the searched area",
  DISPLAY_TEXT_INFO_L:
    "The availability for the time span may vary depending during the booking phase.",
  REQUEST_SUPPORT: "Support request",
  SUBJECT: "Object",
  EXPORT_DATA: "Export Data",
  ENTER_DOCUMENT_NUMBER: "Enter your ID number",
  MUNICIPALITY: "Town",
  SEND_NOTIFICATION: "Send notification",
  BANNER_TYPE: "Banner type",
  BANNER_IMAGE: "Banner image",
  PRENOTAZIONE_BLOCK: "Booking blocks",
  DETAIL: "Details",
  OTP_MANDATORY: "OTP mandatory",
  USERNAME_MANDATORY: "USERNAME mandatory",
  USER_NOT_ASSIGNED: "User not assigned",
  BARCODE_REQUIRED: "Barcode Required",
  EXCEEDED_PAX_LIMIT: "Exceed max number of occupants",
  BAD_CREDENTIAL: "Wrong Credentials",
  ERROR_DURING_SEARCH_AVAILABLE_TURN:
    "There was an error while looking for an Available Time Span",
  NUMBER_NOT_FOUND: "ID Number not Found",
  ERROR_DURING_PRENOTAZIONE_INSERT_VALIDATION:
    "There was an error while validating the insertion",
  ERROR_DURING_PRENOTAZIONE_INSERT:
    "There was an error while creating your booking",
  ERROR_DURING_PRENOTAZIONE_UPDATE_VALIDATION:
    "There was an error while trying to validate your booking",
  ERROR_DURING_PRENOTAZIONE_UPDATE:
    " There was an error while trying to update your booking ",
  PRENOTAZIONE_NOT_FOUND: "Booking not found",
  INVALID_BARCODE: "Invalid barcode",
  INVALID_TARGA: "Invalid plate",
  VARCO_CONFIRMED: "Gate transit confirmed",
  VARCO_DENIED: "Anomaly reported",
  REGISTRAZIONE_OK: "Registration complete",
  OTP_SEND: "OTP sent",
  PASSWORD_CHANGED: "Password changed",
  PRENOTAZIONE_INSERT_SUCCESS: "Booking creation complete",
  PRENOTAZIONE_UPDATE_SUCCESS: "Booking update complete",
  FORGETPASSWORD: "Forgot password",
  DETTAGLIO_AREA: "Area detail",
  dettaglioArea: "Area detail",
  DETTAGLIO_PRENOTAZIONE: "Booking detail",
  dettaglioPrenotazione: " Booking detail ",
  DETTAGLIO_VEICOLO: "Vehicle detail",
  dettaglioVeicolo: " Vehicle detail ",
  DETTAGLIO_ANAGRAFICA: "Registry detail",
  dettaglioAnagrafica: "Registry detail",
  TIPOLOGIA_PRENOTAZIONE: "Booking type",
  FASCIA_ORARIA: "Time slot",
  TIPO_VEICOLO: "Vehicle type",
  PARCHEGGIO: "Parking",
  NUMERO_DOCUMENTO: "Document number",
  TIPOLOGIA_DOCUMENTO: "Document type",
  LABEL_WRONG_COMUNE: "Invalid booking",
  LEGEND: "Legend",
  NOTIFICATION_DETAIL: "Notification detail",
  PRENOTAZIONI: "Booking",
  DELETE_MESSAGE: "Are you sure you want to delete your booking?",
  USER_BANNED: "User banned",
  EDIT: "Edit",
  ORA_DI_INIZIO_PRENOTAZIONE: "Booking hour start",
  DURATA_PRENOTAZIONE: "Booking duration",
  TARGA: "Plate number",
  FORGOTPASSWORD: "Forgot password",
  NEWPASSWORD: "New password",
  RESERVATION: "Booking",
  "Fk id tipologia profilo": "Profile type",
  "Fk id lingua": "Language",
  Username: "Username",
  "Data creazione": "Creation date",
  "Data ultima modifica": "Last modified date",
  "Flg attivo": "Enabled",
  "Flg eliminato": "Deleted",
  "Fk id comune": "Town",
  EVADI_NOTIFICA: "Process notification",
  GENERAL_SETTINGS: "General settings",
  image: "Image",
  map: "Map",
  SELEZIONA_PARCHEGGI: "Parking selection",
  SELEZIONA_FASCE_ORARIE: "Time slot selection",
  SELEZIONA_VARCHI: "Gate selection",
  DATI_AREA: "Area details",
  ASSEGNAZIONE_PARCHEGGI: "Parking assignment",
  ASSEGNAZIONE_FASCE_ORARIE: "Time slot assignment",
  ASSEGNAZIONE_VARCO: "Gate assignment",
  DATI_VARCO: "Gate details",
  "Fk id tipologia varco": "Gate Type",
  BARCODE_DOC: "Document barcode",
  SCAN: "Scan",
  READING: "Reading...",
  "Register From Varco": "Gate registration",
  "Book From Varco": "Gate booking",
  "Nuova prenotazione": "New booking",
  Varco: "Gate",
  "Controllo Varco": "Gate check",
  "Controllo Targa": "Plate check",
  Parcheggio: "Parking",
  Utenti: "Users",
  Configurazione: "Settings",
  CentraleOperativa: "Operations center",
  Notifiche: "Notifications",
  ResultSearch: "Search results",
  "Area Details": "Area details",
  "Slot totali": "Total capacity",
  "Flg privato": "Private",
  SELECT_FROM_MAPS: "Select using Maps",
  "Fk id tipologia area": "Area type",
  "Flg accesso disabili": "Disabled access",
  "Flg animali ammessi": "Animals admitted",
  StatoAssegnazione: "Assignment status",
  StatoPrenotazione: "Booking status",
  TipologiaArea: "Area type",
  TipologiaDocumento: "Document type",
  TipologiaPrenotazione: "Booking type",
  TipologiaProfilo: "Profile type",
  TipologiaVarco: "Gate type",
  TipologiaVeicolo: "Vehicle type",
  Comune: "Town",
  Lingua: "Language",
  RisorsaLogica: "Logic resource",
  RelTipoDurataFasciaOraria: "Duration type time slot",
  RelTipoPrenotazioneTipoDurata: "Booking type duration type",
  RelTipoAreaTipoPrenotazione: "Area type booking type",
  "Id stato assegnazione": "Assignment status ID ",
  "Codice stato assegnazione": "Assignment status code",
  "Desc localizzata": "Localized description",
  Azioni: "Actions",
  "Id stato prenotazione": "Booking status ID ",
  "Codice stato prenotazione": "Booking status code",
  "Id tipologia area": "Area type ID",
  "Codice tipologia area": "Area type code",
  "Id tipologia documento": "Document type ID ",
  "Codice tipologia documento": "Document type code",
  "Id tipologia prenotazione": "Booking type ID ",
  "Codice tipologia prenotazione": "Booking type code",
  "Flg abilitata utente": "Enabled for user",
  "Id tipologia profilo": "Profile type ID ",
  "Codice profilo": "Profile code",
  "Flg comunale": "Municipal",
  "Id tipologia varco": "Gate type ID ",
  "Codice tipologia varco": "Gate type code",
  "Id tipologia veicolo": "Vehicle type ID ",
  "Codice tipologia veicolo": "Vehicle type code",
  "Flg veicolo privato": "Private vehicle",
  "Id comune": "Town ID",
  "Fk id provincia": "Province ID",
  Descrizione: "Description",
  "Giorni prenotazione": "Booking days",
  "Giorni blocco": "Ban days",
  "Minuti ritardo massimo": "Minutes of maximum delay ",
  "Flg abilitato": "Enabled",
  "Max occupanti": "Maximum occupants",
  Impostazioni: "Settings",
  "Park giornalieri": "Daily parkings",
  Latitudine: "Latitude",
  Longitudine: "Longitude",
  "Id lingua": "Language ID",
  "Codice lingua": "Language code",
  "Id risorsa logica": "Logic resource ID",
  "Codice risorsa logica": "Logic resource code",
  "Id rel tipo durata fascia oraria": "Duration type time slot ID",
  "Tipologia durata.desc localizzata": "Localized duration type",
  "Fascia oraria.desc localizzata": "Localized time slot",
  "Id rel tipo prenotazione tipo durata": "Booking type time slot ID",
  "Tipologia Durata Localizzata": "Localized duration type",
  "Tipologia prenotazione.desc localizzata": "Localized booking type",
  "Id rel tipo area tipo prenotazione": "Area type booking type ID",
  "Tipologia area.desc localizzata": "Localized area type",
  "Tipologia Prenotazione Localizzata": "Localized booking type",
  locDipendenteArea: "Area employee",
  locAusiliareTraffico: "Traffic auxiliary",
  Add: "Add",
  "notValidPassengerN°": " invalid for passenger n° ",
  field: "the field ",
  isNotValid: " is invalid",
  NO_ID_PRENOTAZIONE:
    "System error. Please try again later, if the error persists contact support.",
  NO_VEHICLE_TYPE: "No vehicle type selected",
  NO_PARCHEGGIO_SELEZIONATO: "No parking selected",
  INSERT_PERSONAL_DATA: "Insert occupants details",
  DATA_NOT_AVAILABLE_FOR_USER:
    "Not available date selected, you may already have another booking in this date",
  VALIDATE_NOTE_ERROR: "Warning! Notes are mandatory to report an anomaly",
  VALIDATE_INGRESSO_ERROR: "Warning! Please select IN or OUT",
  NUMBER_OR_DOC_ALREADY_EXIST:
    " Phone number or document number already registered, please contact support for more info",
  insert_password: "Insert new password",
  confirm_password: "Confirm password",
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  FULL: "FULL",
  SELEZIONA_AREA: "Select an area",
  CODICE_FISCALE: "Fiscal code",
  RICONTROLLA_DATI:
    "Please check again the provided data. If the error persist contact support.",
  "Close Area From Varco": "Close area from gate",
  confirm_operation: "PLEASE CONFIRM SELECTED ACTION",
  Close_Area: "Close area",
  Open_Area: "Open area",
  REGISTRAZIONE: "Registration",
  REGISTRAZIONE_RAPIDA: "Fast registration",
  SELECT_TIPOLOGIA_DOC: "Select document type",
  GESTORE_AREA: "Area manager",
  "Fk id utente": "User",
  "Fk id area": "Area",
  ERROR_LOADIN_AREA_DETAILS: "Error loading area details",
  LoginProblem: "Are you having problems logging in?",
  ERROR_DURING_HELPDESK:
    "There was an error in your report, please try again in a few minutes",
  HelpdeskSucces:
    "Your report has been received, we’ll answer as soon as possible",
  PASSWORD_PATTERN:
    "Password must be between 8 and 20 chars, it must contain at least 1 letter, 1 number, 1 special char (!@#$%^&*()_+) and it can’t start with a special char",
  PASSWORD_NOT_VALID: "Invalid password",
  DATE_INVALID:
    "Invalid birthdate, you must be at least 14 years old to register",
  INVALID_PREN_DATE:
    "Invalid booking date, please check if you already have a booking in this date",
  INVALID_MUNICIPALITY: "Invalid town",
  PLACEHOLDER_DATE: "DD/MM/YYYY",
  REGISTRO_PRESENZE: "Attendance register",
  RESETPASSWORD: "Change password",
  USER_NOT_FOUND: "User not found",
  SELECT_AREA: "Select an area",
  DELETE_MESSAGE_GESTORE: "Are you sure you want to delete this?",
  AREA_NOT_FOUND: "Area not found",
  MODIFICA_DATI_PRENOTAZIONE: "Change booking details",
  LOCKED: "Locked",
  UNLOCKED: "Unlocked",
  THESE_FIELDS_ARE_REQUIRED: "Fields marked with (*) are required",
  INSERT_PNR_OR_DOC: "Insert PNR or document number",
  locFasciaEvento: "Event",
  locFasciaOrariaEvento: "Event",
  numeroPersone: "Max person number",
  MODAL_CONTROLLO_LABEL: "Select booking:"
};
