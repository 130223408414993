import React from "react";
import RxComponent from "../../../redux/RxComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Form, FormGroup } from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import TipologiaVeicoloService from "../../TipologiaVeicolo/TipologiaVeicolo.service";
import { ON_CHANGE_FASCIA_ORARIA } from "../../../redux/Prenotazione/Prenotazione.types";
import ParcheggioService from "../../Parcheggio/Parcheggio.service";

class Component extends React.Component {
  tipoVeicoloservice = new TipologiaVeicoloService();
  parcheggioService = new ParcheggioService();

  constructor(props) {
    super(props);
    this.state = {
      selectedTipoVeicolo: this.props.selectedTipoVeicolo || "",
      targaVeicolo: this.props.selectedTarga || "",
      tipoVeicoloList: [],
      listParcheggi: [],
      selectedFasciaOraria: this.props.Prenotazione.selectedFields.fasciaOraria
        ? this.props.Prenotazione.selectedFields.fasciaOraria[0].idFasciaOraria
        : "",
      selectedParcheggio: this.props.selectedParcheggio || "",
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.tipoVeicoloservice
      .getTipologiaVeicoloListPrenotazione()
      .then((res) => {
        this.setState({
          tipoVeicololist: res.data.result,
        });
      });
    if (
      this.props.Prenotazione.selectedArea.IDAREA &&
      this.props.Prenotazione.selectedFields.dataPrenotazione &&
      this.props.Prenotazione.selectedFields.fasciaOraria &&
      this.props.Prenotazione.selectedFields.fasciaOraria[0].idFasciaOraria
    ) {
      this.getParcheggio(
        this.props.Prenotazione.selectedFields.idParcheggio,
        this.props.Prenotazione.selectedFields.descParcheggio
      );
    }
  }

  componentDidUpdate() {
    if (
      this.props.Prenotazione.type === ON_CHANGE_FASCIA_ORARIA &&
      this.state.selectedFasciaOraria !==
        this.props.Prenotazione.selectedFasciaOraria
    ) {
      this.handleChangeP = true;
      this.setState({
        selectedFasciaOraria: this.props.Prenotazione.selectedFasciaOraria,
      });
    }
    if (this.handleChangeP) {
      this.handleChangeP = false;
      this.getParcheggio();
    }
  }

  getParcheggio(value, desc) {
    this.parcheggioService
      .getParcheggioPrenotazione(
        this.props.Prenotazione.selectedArea.IDAREA,
        this.props.Prenotazione.selectedFields.dataPrenotazione,
        this.state.selectedFasciaOraria
      )
      .then((res) => {
        this.setState({
          listParcheggi: res.data.result,
          selectedParcheggio: [{ IDPARCHEGGIO: value, DESCRIZIONE: desc }],
        });
      });
  }

  getData() {
    return {
      selectedTipoVeicolo: this.state.selectedTipoVeicolo,
      targaVeicolo: this.state.targaVeicolo,
      selectedParcheggio: this.state.selectedParcheggio,
    };
  }

  validate() {
    let errors = [];
    if (
      !this.state.selectedTipoVeicolo ||
      this.state.selectedTipoVeicolo.length === 0
    ) {
      errors.push(this.props.t("NO_VEHICLE_TYPE"));
    }
    if (
      this.state.selectedTipoVeicolo &&
      this.state.selectedTipoVeicolo.length > 0
    ) {
      if (
        this.state.selectedTipoVeicolo[0].flgVeicoloPrivato &&
        this.state.targaVeicolo === ""
      ) {
        errors.push(this.props.t("LicensePlateRequired"));
      }
      if (
        this.state.selectedTipoVeicolo[0].flgVeicoloPrivato &&
        this.state.selectedFasciaOraria &&
        this.state.listParcheggi.length &&
        (!this.state.selectedParcheggio ||
          this.state.selectedParcheggio.length === 0)
      ) {
        errors.push(this.props.t("NO_PARCHEGGIO_SELEZIONATO"));
      }
    }
    return errors;
  }

  render() {
    return (
      <Form className="row m-0">
        <FormGroup className="col-12 col-md-4">
          <label htmlFor="TipoVeicolo">{this.props.t("TIPO_VEICOLO")}:*</label>
          <SelectPicker
            emptyField
            values={this.state.selectedTipoVeicolo || null}
            options={this.state.tipoVeicololist}
            displayValue="descLocalizzata"
            onChange={(ev) => {
              this.setState({ selectedTipoVeicolo: ev });
            }}
            required
            readOnly={this.props.readOnly}
          />
        </FormGroup>
        {this.state.selectedTipoVeicolo &&
          this.state.selectedTipoVeicolo.length > 0 &&
          this.state.selectedTipoVeicolo[0].flgVeicoloPrivato && (
            <FormGroup className="col-12 col-md-4">
              <label htmlFor="Targa">{this.props.t("TARGA")}:*</label>
              <input
                value={this.state.targaVeicolo}
                required
                type="text"
                id="targaVeicolo"
                onChange={(ev) =>
                  this.setState({ targaVeicolo: ev.target.value })
                }
                name="targaVeicolo"
                className="form-control"
                aria-label="Search"
                aria-describedby="basic-addon2"
                readOnly={this.props.readOnly}
              />
            </FormGroup>
          )}
        {this.state.selectedTipoVeicolo &&
          this.state.selectedTipoVeicolo.length > 0 &&
          this.state.selectedTipoVeicolo[0].flgVeicoloPrivato &&
          this.state.selectedFasciaOraria &&
          this.state.listParcheggi.length > 0 && (
            <FormGroup className="col-12 col-md-4">
              <div className="d-flex justify-content-space-between">
                <div className="flex-grow-1">
                  <label htmlFor="Parcheggio">
                    {this.props.t("PARCHEGGIO")}:*
                  </label>
                  <SelectPicker
                    emptyField
                    values={this.state.selectedParcheggio || null}
                    options={this.state.listParcheggi}
                    displayValue="DESCRIZIONE"
                    onChange={(ev) => {
                      this.setState({ selectedParcheggio: ev });
                    }}
                    required
                  />
                </div>
                {this.props.Prenotazione.selectedFields.statoPagamento && (
                  <div>
                    <label>{this.props.t("Payed")}</label>
                    <div className="d-flex justify-content-center">
                      <div class="form-input-checkbox">
                        <i
                          class="fas fa-check"
                          hidden={
                            this.props.Prenotazione.selectedFields
                              .statoPagamento.codiceStatoPagamento === "NP"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FormGroup>
          )}
      </Form>
    );
  }
}

const PrenotazioneVeicoloForm = RxComponent({})(Component);
export default PrenotazioneVeicoloForm;
