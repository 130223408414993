import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const LOGIN = "/login";
const REGISTER = "sp_RegisterUtente";
const FASTREGISTER = "sp_FastRegisterUtente";
const CHECK_OTP = "sp_VerificaOTP";
const SEND_OTP = "/Generic/sendotp/";
const SEND_SMS = "/Generic/sendsms/fn_GetCodiceOtp";
const SEND_SMS_TEST = "/Generic/sendsms/fn_GetSMSParameters";
const SEND_EMAIL_TEST = "/Generic/search/fn_GetEmailParameters";
const READ_ROLES = "/Generic/search2/ProfiloImpiegato";
const READ_USER = "/Generic/search2/Utente";
const CHANGE_PASSWORD = "sp_ChangePasswordOTP";
const READ_USER_FN = "fn_ReadUserData";
const READ_USER_BAN_FN = "fn_ReadUserData_BanList";
const GET_COMUNE_NASCITA_REGISTER = "generic/search3/register/comunenascita";

export default class LoginRepoTaalHttp extends HttprepoTAAL {
  loginTaal(username, password) {
    return this.API.post(
      LOGIN,
      {
        utente: username,
        password: password,
      },
      {
        headers: {
          Authorization: "",
        },
      }
    );
  }

  register(data) {
    return this.exec(REGISTER, data);
  }

  registerFast(data) {
    return this.exec(FASTREGISTER, data);
  }

  checkOtp(idUtente, otp, token) {
    return this.exec(CHECK_OTP, { IDUTENTE: idUtente, OTP: otp }, null, null, {
      headers: { Authorization: "Bearer " + token },
    });
  }

  sendOtp(idUtente, token) {
    /*  return this.API.get(SEND_OTP+idUtente,
      {
        headers: { Authorization: "Bearer " + token },
      }); */
    return this.API.post(
      SEND_SMS,
      {
        par: {
          idutente: idUtente,
          ISRECUPEROPASSWORD: 0,
        },
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }

  sendSms(idUtente, token) {
    return this.API.post(SEND_SMS_TEST, {
      par: {
        idutente: idUtente,
        ISRECUPEROPASSWORD: 0,
      },
    });
  }

  sendEmail(idPrenotazione, token) {
    return this.API.post(SEND_EMAIL_TEST, {
      par: {
        idprenotazione: idPrenotazione,
      },
    });
  }

  readRoles(idUser, token) {
    return this.API.post(
      READ_ROLES,
      {
        pageNum: 0,
        pageSize: -1,
        Includes: ["Profilo.ProfiloRisorsaLogica_List"],
        filter: {
          Expression: "fkidimpiegato == @0 && flagAttivo",
          ExpressionValues: [{ value: idUser }],
        },
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }

  /*readUserData(idUser, token) {
    return this.API.post(
      READ_USER,
      {
        pageNum: 0,
        order: [],
        pageSize: -1,
        Includes: ["Comune", "Lingua", "TipologiaProfilo", "ListaBan_List"],
        filter: {
          Expression: "idUtente == @0",
          ExpressionValues: [{ value: idUser }],
        },
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }*/

  readUserData(idUser, token) {
    return this.exec(READ_USER_FN, null, null, null, {
      headers: { Authorization: "Bearer " + token },
    }).then((res1) => {
      return this.exec(READ_USER_BAN_FN, null, null, null, {
        headers: { Authorization: "Bearer " + token },
      }).then((res2) => {
        let res1temp = { ...res1 };
        res1 = res1.data.result[0];
        res2 = res2.data.result;
        let ret = {
          //idUtente: null,
          fkIdTipologiaProfilo: res1.IDTIPOLOGIAPROFILO,
          fkIdLingua: res1.IDLINGUA,
          fkIdComune: res1.IDCOMUNE,
          username: res1.USERNAME,
          //password: null,
          dataCreazione: res1.DATACREAZIONE,
          dataUltimaModifica: res1.DATAULTIMAMODIFICA,
          flgAttivo: res1.FLGATTIVO,
          flgEliminato: res1.FLGELIMINATO,
          /*AnagraficaUtente_List: null,
          CensoreArea_List: null,
          CensoreParcheggio_List: null,
          GestoreArea_List: null,
          ListaBan_List: [],
          NotificaCentraleOperativa_List: null,
          OtpRegistrazione_List: null,
          PersonaleArea_List: null,
          Prenotazione_List: null,
          RegistroTransitoVarco_List: null,
          RuoloComune_List: null,
          TurnazioneParcheggio_List: null,
          TurnazioneVarco_List: null,
          */
          FLGPASSWORDSAFE: res1.FLGPASSWORDSAFE,
          Comune: {
            descrizione: res1.DESCRIZIONE,
            fkIdProvincia: res1.IDPROVINCIA,
            flgAbilitato: res1.FLGABILITATO,
            giorniBlocco: res1.GIORNIBLOCCO,
            giorniPrenotazione: res1.GIORNIPRENOTAZIONE,
            idComune: res1.IDCOMUNE,
            latitudine: res1.LATITUDINE,
            longitudine: res1.LONGITUDINE,
            maxOccupanti: res1.MAXOCCUPANTI,
            minutiRitardoMassimo: res1.MINUTIRITARDOMASSIMO,
            parkGiornalieri: res1.PARKGIORNALIERI,
            settings: res1.SETTINGS,
          },
          Lingua: {
            idLingua: res1.IDLINGUA,
            codiceLingua: res1.CODICELINGUA,
            descLocalizzata: res1.LINGUA_DESCLOCALIZZATA,
            //Localizzazione_List: null,
            //Utente_List: null,
          },
          TipologiaProfilo: {
            idTipologiaProfilo: res1.IDTIPOLOGIAPROFILO,
            codiceProfilo: res1.CODICEPROFILO,
            descLocalizzata: res1.TIPOPROF_DESCLOCALIZZATA,
            flgComunale: res1.FLGCOMUNALE,
            //RelProfiloRisorsa_List: null,
            //Utente_List: null,
          },
        };

        res2 = res2.map((el) => {
          return {
            fkIdComune: el.FKIDCOMUNE,
            dataInizioBan: el.DATAINIZIOBAN,
            dataFineBan: el.DATAFINEBAN,
            flgBanAttivo: el.FLGBANATTIVO,
            fkIdUtenteBannato: el.FKIDUTENTEBANNATO,
            idListaBan: el.IDLISTABAN,
          };
        });

        ret.ListaBan_List = res2;

        res1temp.data.result[0] = ret;

        return res1temp;
      });
    });
  }

  changePasswordByOtp(username, password, otp) {
    return this.exec(CHANGE_PASSWORD, {
      USERNAME: username,
      PASSWORD: password,
      OTP: otp,
    });
  }

  getComuneNascita(descrizione) {
    return this.API.post(GET_COMUNE_NASCITA_REGISTER, {
      descrizione: descrizione,
    });
  }
}
