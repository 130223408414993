import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GETPARCHEGGIFN = "fn_GetParcheggiArea"

export default class ParcheggioHttpRepo extends HttprepoTAAL {
  
  getTable() {
    return "Parcheggio";
  }
  getParcheggiPrenotazione(idArea, data, fascia){
    return this.exec(GETPARCHEGGIFN, {idArea: idArea, data:data})
  }
}
