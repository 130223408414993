import LoginRepo from "./Login.repo";
import BaseServiceTaal from "../../Service/BaseServiceTaal";
import { FILTER_TEXT } from "../../components/TableServer/FilterItem";

const FORGET_PASSWORD = "sp_SendOTPEmail";

export default class LoginService extends BaseServiceTaal {
  loginTaal(username, password) {
    return LoginRepo.getService().loginTaal(username, password);
  }

  readRoles(idUser, token) {
    return LoginRepo.getService().readRoles(idUser, token);
  }

  readUserData(idUser, token) {
    return LoginRepo.getService().readUserData(idUser, token);
  }

  register(registerData) {
    return LoginRepo.getService().register(registerData);
  }
  registerFast(registerData) {
    return LoginRepo.getService().registerFast(registerData);
  }

  contactUs = (data) =>
    this.getRepo().then((repo) => repo.sendMail("fn_GetEmailContactUs", data));

  getComune = (filters) =>
    this.getRepo().then((repo) =>
      repo.search2(
        1,
        -1,
        this.transformFilterToServer(filters),
        null,
        [],
        "ComuneNascita"
      )
    );

  getComuneNascita = (descrizione) =>
    LoginRepo.getService().getComuneNascita(descrizione);

  checkOtp(idUtente, otp, token) {
    return LoginRepo.getService().checkOtp(idUtente, otp, token);
  }
  sendOtp(idUtente, username, token) {
    return LoginRepo.getService().sendOtp(idUtente, token);
    //return LoginRepo.getService().sendMail(FORGET_PASSWORD, {
    // username: username,
    // ISRECUPEROPASSWORD: 0,
    //});
  }

  forgetPassword(username) {
    return LoginRepo.getService().sendMail(FORGET_PASSWORD, {
      username: username,
      ISRECUPEROPASSWORD: 1,
    });
  }
  changePasswordByOtp(username, password, otp) {
    return LoginRepo.getService().changePasswordByOtp(username, password, otp);
  }
}
