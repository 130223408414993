import React from 'react';
import _ from 'lodash';
import SortIndicator from './SortIndicator';
import { withTranslation } from 'react-i18next';

class ColumnHeader extends React.Component {

    constructor(props){
        super(props);
        this.state={
            icon:null,
            label:'',
            type:'string',
            sortable:false,
            filterable:false
        }
    }

    componentDidUpdate(prevProps,prevState){
        let newState={};

        if(this.state.icon !== this.props.icon){
            newState.icon=this.props.icon;
        }
        if(this.state.label !== this.props.label){
            newState.label=this.props.label;
        }
        if(this.state.type !== this.props.type){
            newState.type=this.props.type;
        }
        if(this.state.sortable !== this.props.sortable){
            newState.sortable=this.props.sortable;
        }
        if(this.state.filterable !== this.props.filterable){
            newState.filterable=this.props.filterable;
        }

        if(!_.isEmpty(newState))
            this.setState(newState);
    }

    contentByType = (type,label) => {
        switch(type){//add case to manage other types
            case 'string':
                return <span>{label}</span>;
            case 'view':
                    return <span>{label}</span>;
            default:
                return <span>{label}</span>;

        }
    }

    onSortChange(direction){
        if(this.props.onSortChange && _.isFunction(this.props.onSortChange)){
            this.props.onSortChange(direction);
        }
    }

    render(){
        let content=this.contentByType(this.state.type,this.props.t(this.state.label));
        return (
            <th className={`${this.state.sortable? 'th-custom-show' : 'th-custom-hide'} text-nowrap`} >
                {
                    this.state.icon && this.icon.lenght &&
                    <i className={`${this.state.icon}`}></i>
                }
                {content}
                {this.state.sortable?<SortIndicator
                        onSortChange={this.onSortChange.bind(this)}
                    />:null}
            </th>
        );
    }

}
export default withTranslation()(ColumnHeader);
