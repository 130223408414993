import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_ALL_REGISTER = "generic/search3/register/tipologiadocumento";
export default class TipologiaDocumentoHttpRepo extends HttprepoTAAL {
  getTable() {
    return "TipologiaDocumento";
  }

  getAllRegister() {
    return this.API.post(GET_ALL_REGISTER);
  }
}
