import BaseServiceTaal from "../../Service/BaseServiceTaal";
import TipologiaPrenotazione from "./TipologiaPrenotazione.repo";

export default class TipologiaPrenotazioneService extends BaseServiceTaal {
  getRepo = () => TipologiaPrenotazione.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "RelTipoPrenotazioneTipoDurata_List.TipologiaDurata.RelTipoDurataFasciaOraria_List.FasciaOraria",
        ]
      )
    );

  searchTipologiaPrenotazione = (formField) =>
    this.getRepo().then((repo) =>
      repo.exec("fn_GetTipoPrenotazioniDurata", formField)
    );

  getTipologiaPrenotazioneNewApi = (idList) =>
    this.getRepo().then((repo) => repo.getTipologiaPrenotazioneNewApi(idList));
}
