import React from "react";
import $ from "jquery";
import "../../template/dashboard/js/bootstrap-datepicker";
import "../../template/dashboard/js/bootstrap-datepicker.it";
import _ from "lodash";
import moment from "moment";

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      format: "dd/mm/yyyy",
      maxViewMode: 2,
      defaultDate: new Date(),
      language: "it",
      orientation: "auto right",
      keyboardNavigation: false,
      keepEmptyValues: true,
      forceParse: true,
      autoclose: true,
      todayHighlight: true,
      showOnFocus: false,
      startDate: props.startDate,
      endDate: props.endDate,
      templates: {
        leftArrow: '<i  class="fas fa-chevron-left"></i>',
        rightArrow: '<i  class="fas fa-chevron-right"></i>',
      },
      id: props.id ? props.id + "-" + new Date().getTime() : this._generateId(),
      label: "",
    };
  }

  componentDidMount() {
    this._mergePropsInState();
    this._initCalendar();
    this._setValue();
  }

  componentDidUpdate(prevProps) {
    //this._mergePropsInState();
    let changes = {};
    if (this.props.endDate != this.state.endDate) {
      changes.endDate = this.props.endDate;
    }
    if (this.props.startDate != this.state.startDate) {
      changes.startDate = this.props.startDate;
    }

    if (Object.keys(changes).length) {
      this.setState(changes, () => {
        this._destroyCalendar();
        this._initCalendar();
      });
    } else {
      if (prevProps.id !== this.props.id) {
        this._destroyCalendar();
        this._initCalendar();
      }
    }

    /*if(prevProps.value != this.props.value){
            this._setValue();
        }*/
  }

  componentWillUnmount() {
    this._destroyCalendar();
  }

  _mergePropsInState = () => {
    this.setState({ ...this.state, ...this.props });
  };

  _generateId() {
    let ts = document.getElementsByClassName("datapicker-custom");
    let num = 0;
    if (ts.length > 0) {
      let max = 0;
      for (let i = 0; i < ts.length; i++) {
        let id = ts[i].getAttribute("id");
        if (id !== null) {
          num = _.toNumber(id.replace("datepicker-", ""));
          if (!_.isNaN(num) && num > max) {
            max = num;
          }
        }
      }
      return "datepicker-" + (max + 1) + "-" + new Date().getTime();
    }
    return "datepicker-1-" + new Date().getTime();
  }

  _initCalendar = () => {
    $("#" + this.state.id)
      .closest(".input-group.date")
      .datepicker(this.state)
      .on("changeDate", (ev) => {
        this._dataChanged(ev);
      });
  };

  _destroyCalendar = () => {
    $("#" + this.state.id)
      .closest(".input-group.date")
      .datepicker("destroy");
    $("#" + this.state.id)
      .closest(".input-group.date")
      .off("changeDate");
  };

  _dataChanged = (ev) => {
    const inputVal = $(`#${this.state.id}`).val();
    if (
      !inputVal ||
      !inputVal.length ||
      !moment(inputVal, "DD/MM/YYYY").isValid()
    ) {
      ev.date = null;
    }
    if (this.props.onChange && _.isFunction(this.props.onChange))
      this.props.onChange(ev);
  };

  _setValue = (valr) => {
    //const df=moment(val).format(this.state.format);
    let val = valr || this.props.value;
    $("#" + this.state.id)
      .closest(".input-group.date")
      .datepicker("setDate", val);
  };

  _onBlur() {
    const inputVal = $(`#${this.state.id}`);
    if (
      !inputVal.val() ||
      !inputVal.val().length ||
      !moment(inputVal.val(), "DD/MM/YYYY").isValid()
    ) {
      inputVal.val(null);
    }
  }

  render() {
    return (
      <div className="form-group">
        <label className="w-100 text-left" htmlFor={`${this.state.id}`}>
          {this.state.label}
          {this.props.required && "*"}
        </label>
        <div className="input-group date">
          <input
            placeholder={this.props.placeholder}
            id={`${this.state.id}`}
            type="text"
            className="form-control datapicker-custom"
            maxLength="10"
            required={this.props.required == "true" ? true : false}
            readOnly={this.props.readOnly}
            mindate={this.props.minDate}
            onBlur={() => this._onBlur()}
          />
          <div className="input-group-append" aria-hidden="true">
            <button
              disabled={this.props.readOnly}
              type="button"
              className="btn btn-dark"
              tabIndex="-1"
            >
              <i className="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
