import React from "react";
import RxComponent from "../../../redux/RxComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
} from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import DatePicker from "../../../components/Content/DatePicker";
import AreaService from "../../Area/Area.service";
import UtenteService from "../../Utente/Utente.service";
import TipologiaPrenotazioneService from "../../TipologiaPrenotazione/TipologiaPrenotazione.service";
import ComuneService from "../../Comune/Comune.service";
import moment, { relativeTimeThreshold } from "moment";
import {
  FILTER_NUMBER,
  FILTER_MULTIPLE,
} from "../../../components/TableServer/FilterItem";
import { ROLES } from "../../../constants/Roles";
import GestoreAreaService from "../../AssegnaGestoreArea/AssegnaGestoreArea.service";
import PrenotazioneDetailForm from "./Prenotazione.insertdetail";
import PrenotazioneForm from "./Prenotazione.form2";

class Component extends React.Component {
  areaService = new AreaService();
  TipologiaPrenotazioneService = new TipologiaPrenotazioneService();
  ComuneService = new ComuneService();
  gestoreAreaService = new GestoreAreaService();
  utenteService = new UtenteService();

  constructor(props) {
    super(props);
    const codiceProfilo = this.props.Auth.user.TipologiaProfilo.codiceProfilo;

    this.state = {
      listAreeGestore: [],
    };
  }

  componentDidMount() {
    let filter = {
      [`${FILTER_NUMBER}&&&fkIdUtente`]: [this.props.Auth.user.idUtente],
    };
    let filterUser = {
      [`${FILTER_NUMBER}&&&IdUtente`]: [this.props.Prenotazione.userAssegnato],
    };
    this.gestoreAreaService.paginate(1, 10, filter, null).then((res) => {
      this.utenteService.paginate(1, 10, filterUser, null).then((us) => {
        this.setState({
          listAreeGestore: res.data.result.map((el) => el.Area),
          utenteAssegnato: us.data.result[0],
        });
      });
    });
  }

  handleSubmit = (pren) => {
    this.setState({
      prenotazione: pren,
      showDetail: true,
    });
  };

  resetForm = () => {
    this.setState({
      prenotazione: null,
      showDetail: false,
    });
  };

  render() {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center h-100">
          <Form className="row m-0 d-flex align-items-center justify-content-center ">
            <FormGroup className="col-md-7">
              <label htmlFor="Area">{this.props.t("Area")}:</label>
              <SelectPicker
                options={this.state.listAreeGestore}
                displayValue="descrizione"
                required
                onChange={(ev) => {
                  this.setState({
                    idArea: ev[0].idArea,
                    area: ev[0],
                  });
                }}
              />
            </FormGroup>

            <PrenotazioneForm
              onSubmit={this.handleSubmit.bind(this)}
              isFromGestore
              onResetForm={this.resetForm.bind(this)}
            />
          </Form>
        </div>
        {this.state.showDetail && (
          <PrenotazioneDetailForm
            dataInizio={this.state.prenotazione.selectedDataInizio}
            numeroOccupanti={this.state.prenotazione.numeroOccupanti}
            idArea={this.state.idArea}
            dataPrenotazione={this.state.prenotazione.dataPrenotazione}
            email={this.state.area.email}
            tipologiaDocumentoUserAssegnato={
              this.state.utenteAssegnato.AnagraficaUtente_List[0].Documento
                .TipologiaDocumento.descLocalizzata
            }
            numeroDocumentoUserAssegnato={
              this.state.utenteAssegnato.AnagraficaUtente_List[0].Documento
                .numeroDocumento
            }
            idUtenteAssegnato={this.state.utenteAssegnato.idUtente}
            ComuneId={1}
          />
        )}
      </>
    );
  }
}
const PrenotazioneFromGestore = RxComponent({})(Component);
export default PrenotazioneFromGestore;
