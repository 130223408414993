
  export function disableIOS() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', e => {
          if(e.scale !== 1) {
            e.preventDefault();
          }
        }, {passive: false});
      }
  }

  export function disableAndroid() {
    if (/Android/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', e => {
    
          if(e.touches.length>1){
            e.preventDefault();
          }
        }, {passive: false});
    
      }
    }

    export function disableAll() {
        disableIOS();
        disableAndroid();
    }