import {
  SUCCESS_SEARCH,
  ERROR_SEARCH,
  ERROR_VALIDATE,
  TRIGGER_SEARCH,
  SUCCESS_TIPOLOGIA_AREA_SEARCH,
  SELECT_AREA,
  TRIGGER_SELECT_AREA,
  TRIGGER_INSERT_PRENOTAZIONE,
  ON_SUCCESS_INSERT_PRENOTAZIONE,
  TRIGGER_GET_PRENOTAZIONE,
  TRIGGER_UPDATE_PRENOTAZIONE,
  ON_SUCCESS_UPDATE_PRENOTAZIONE,
  TRIGGER_SCAN_BARCODE_PRENOTAZIONE,
  ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE,
  ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE,
  TRIGGER_TARGA,
  ON_SUCCESS_TARGA_PRENOTAZIONE,
  SUCCESS_SEARCH_USER_BY_DOC,
  TRIGGER_SEARCH_USER_BY_DOC,
  ON_CHANGE_FASCIA_ORARIA,
  ON_FILTER_TIPOLOGIA_AREA,
  ON_SUCCESS_CHANGE_AREA_STATUS,
  TRIGGER_SEARCH_USER_BY_DOC_GESTORE,
  ON_SUCCESS_PARCHEGGI_SEARCH, PRENOTAZIONE_NON_TROVATA,
} from "./Prenotazione.types";

export const successSearch = (results, filters) => {
  return {
    type: SUCCESS_SEARCH,
    payload: { results: results, selectedFields: filters },
  };
};

export const successUserByDocSearch = (results) => {
  return {
    type: SUCCESS_SEARCH_USER_BY_DOC,
    payload: results,
  };
};

export const searchUtenteByDocFromVarco = (data) => {
  return {
    type: TRIGGER_SEARCH_USER_BY_DOC,
    payload: data,
  };
};

export const searchUtenteByDocFromGestore = (data) => {
  return {
    type: TRIGGER_SEARCH_USER_BY_DOC_GESTORE,
    payload: data,
  };
};

export const successTipologieAreaSearch = (results) => {
  return {
    type: SUCCESS_TIPOLOGIA_AREA_SEARCH,
    payload: results,
  };
};
export const successParcheggiSearch = (results) => {
  return {
    type: ON_SUCCESS_PARCHEGGI_SEARCH,
    payload: results,
  };
};

export const errorSearch = (error) => {
  return {
    type: ERROR_SEARCH,
    payload: error,
  };
};

export const errorValidate = (error) => {
  return {
    type: ERROR_VALIDATE,
    payload: error,
  };
};

export const triggerCercaDisponibilita = (formData) => {
  return {
    type: TRIGGER_SEARCH,
    payload: formData,
  };
};

export const triggerSelectArea = (area) => {
  return {
    type: TRIGGER_SELECT_AREA,
    payload: area,
  };
};

export const selectArea = (area) => {
  return {
    type: SELECT_AREA,
    payload: area,
  };
};

export const triggerInsertPrenotazione = (prenotazione) => {
  return {
    type: TRIGGER_INSERT_PRENOTAZIONE,
    payload: prenotazione,
  };
};
export const onSuccessInsertPrenotazione = (prenotazione) => {
  return {
    type: ON_SUCCESS_INSERT_PRENOTAZIONE,
    payload: prenotazione,
  };
};

export const triggerUpdatePrenotazione = (prenotazione) => {
  return {
    type: TRIGGER_UPDATE_PRENOTAZIONE,
    payload: prenotazione,
  };
};

export const onSuccessUpdatePrenotazione = (prenotazione) => {
  return {
    type: ON_SUCCESS_UPDATE_PRENOTAZIONE,
    payload: prenotazione,
  };
};

export const onSuccessChangeAreaStatus = (area) => {
  return {
    type: ON_SUCCESS_CHANGE_AREA_STATUS,
    payload: area,
  };
};

export const triggerGetPrenotazione = (pnr) => {
  return {
    type: TRIGGER_GET_PRENOTAZIONE,
    payload: pnr,
  };
};
export const triggerScanBarCodePrenotazione = (barcode) => {
  return {
    type: TRIGGER_SCAN_BARCODE_PRENOTAZIONE,
    payload: barcode,
  };
};

export const successScanBarCodePrenotazione = (pnr) => {
  return {
    type: ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE,
    payload: pnr,
  };
};

export const successScanBarCodePrenotazioneMultiple = (pnr) => {
  return {
    type: ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE,
    payload: pnr,
  };
};

export const prenotazioneNonTrovata = () => {
  return {
    type: PRENOTAZIONE_NON_TROVATA
  }
}

export const triggerTarga = (targa) => {
  return {
    type: TRIGGER_TARGA,
    payload: targa,
  };
};

export const successTargaPrenotazione = (pnr) => {
  return {
    type: ON_SUCCESS_TARGA_PRENOTAZIONE,
    payload: pnr,
  };
};
export const onChangeFasciaOraria = (idFasciaOraria) => {
  return {
    type: ON_CHANGE_FASCIA_ORARIA,
    payload: idFasciaOraria,
  };
};

export const onFilterTipologiaArea = (listArea, isToUpdate) => {
  return {
    type: ON_FILTER_TIPOLOGIA_AREA,
    payload: { listArea: listArea, isToUpdate: isToUpdate },
  };
};
