import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { configureStore } from './redux/RxStore';
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ErrorBoundary from './components/Content/ErrorBoundary';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import moment from 'moment-timezone';
import 'moment/locale/it';
import { I18nextProvider } from 'react-i18next';

import Loader from './components/Content/Loader';
import { getTranslate } from './i18n/TranslationConfig';
import AppSwitch from './components/Content/AppSwitch';
import { disableAll } from './Service/MobileDisableZoom';


export const rxStore = configureStore();
const persistor = persistStore(rxStore);

export const hist= createBrowserHistory();

moment.tz.setDefault('Europe/Berlin');
moment.locale('it');



class App extends React.Component{

  state={
    initEnd:false,
    translationConfig:null
  }

  
  componentDidMount(){
    getTranslate.then(i18nCOnfig=>{
      this.setState({
        translationConfig:i18nCOnfig,
        initEnd:true
      })
    });
    disableAll();
  }
  

  render(){
    if(!this.state.initEnd)
      return <div className="loading">Loading&#8230;</div>;


    return (
      <I18nextProvider i18n={this.state.translationConfig}>
        <Provider store={rxStore}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
              <Router history={hist}>  
                <AppSwitch />
              </Router>
             
              <Loader />
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    );
  }
}

export default App;
