import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../template/site/css/vendor.css";
import _ from "lodash";
import { Trans } from "react-i18next";

export default class HorizontalFilterList extends React.Component {
  state = { list: this.props.data, testo: "filtro ", numero: 1 };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.props.data.map((el, index) => {
          return (
            <button
              key={el.idArea + "-" + index}
              onClick={() => {
                this.props.onSelectFilter(el.IDTIPOLOGIAAREA);
              }}
              type="button"
              className="btn btn-sm btn-light m-2"
              style={{ borderRadius: 50 }}
            >
              <Trans i18nKey={el.DESCLOCALIZZATA} />
              <span className="badge badge-light">{el.TOTALEAREE}</span>
            </button>
          );
        })}
        <button
          className="btn btn-sm btn-secondary m-2"
          onClick={() => {
            this.props.onClearFilter();
          }}
          style={{ borderRadius: 50 }}
        >
          <Trans i18nKey="ClearFilter" />
        </button>
      </React.Fragment>
    );
  }
}
