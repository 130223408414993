import StubRepo from "../../repos/StubRepo";
import TipologiaProfiloMockRepo from "./repositories/TipologiaProfilo.mock";
import TipologiaProfiloHttpRepo from "./repositories/TipologiaProfilo.http";

export default class TipologiaProfilo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaProfilo repo";
  }

  static getLocal() {
    if (TipologiaProfilo._localInstance == null) {
      TipologiaProfilo._localInstance = new TipologiaProfiloMockRepo();
    }
    return TipologiaProfilo._localInstance;
  }

  static getService() {
    if (TipologiaProfilo._serviceInstance == null) {
      TipologiaProfilo._serviceInstance = new TipologiaProfiloHttpRepo();
    }
    return TipologiaProfilo._serviceInstance;
  }
}
