import React, { useState, useEffect } from "react";
import {
  FIELD_SELECT_MULTIPLE,
  FIELD_SELECT,
  FIELD_FILE,
  FIELD_BOOLEAN,
} from "../../../components/DynamicForm/DynamicFormField";
import DynamicForm from "../../../components/DynamicForm/DynamicForm";
import dafaultBannerImage from "../../../template/site/img/slide_3.jpg";
import RxComponent from "../../../redux/RxComponent";
import ComuneService from "../Comune.service";
import { onSuccess, onError } from "../../../redux/Shared/Shared.actions";
import Resizer from "react-image-file-resizer";
import ComuneBannerService from "../../ComuneBanner/ComuneBanner.service";
import { FILTER_NUMBER } from "../../../components/TableServer/FilterItem";
import { BASE_URL_TAAL } from "../../../constants/Settings";
import { FormText } from "reactstrap";

export const BLOCKS = {
  AREA_DETAIL: "dettaglioArea",
  RESERVATION_DETAIL: "dettaglioPrenotazione",
  VEHICLE_DETAIL: "dettaglioVeicolo",
  PERSONAL_DATA_DETAIL: "dettaglioAnagrafica",
};

export const BANNER_TYPE = {
  IMAGE: "image",
  MAP: "map",
};

const service = new ComuneService();
const comunebannerservice = new ComuneBannerService();

function ComuneSettingsC(props) {
  const initialFormData = [
    {
      type: FIELD_SELECT_MULTIPLE,
      id: "prenotazioneDetail",
      label: props.t("PRENOTAZIONE_BLOCK"),
      options: [
        { id: BLOCKS.AREA_DETAIL, name: props.t(BLOCKS.AREA_DETAIL) },
        {
          id: BLOCKS.RESERVATION_DETAIL,
          name: props.t(BLOCKS.RESERVATION_DETAIL),
        },
        { id: BLOCKS.VEHICLE_DETAIL, name: props.t(BLOCKS.VEHICLE_DETAIL) },
        {
          id: BLOCKS.PERSONAL_DATA_DETAIL,
          name: props.t(BLOCKS.PERSONAL_DATA_DETAIL),
        },
      ],
      displayValue: "name",
      value: [
        { id: BLOCKS.AREA_DETAIL, name: props.t(BLOCKS.AREA_DETAIL) },
        {
          id: BLOCKS.RESERVATION_DETAIL,
          name: props.t(BLOCKS.RESERVATION_DETAIL),
        },
        { id: BLOCKS.VEHICLE_DETAIL, name: props.t(BLOCKS.VEHICLE_DETAIL) },
        {
          id: BLOCKS.PERSONAL_DATA_DETAIL,
          name: props.t(BLOCKS.PERSONAL_DATA_DETAIL),
        },
      ], //for edit override this to set value
    },
    {
      type: FIELD_SELECT,
      id: "type",
      label: props.t("BANNER_TYPE"),
      options: [
        {
          id: BANNER_TYPE.MAP,
          name: props.t(BANNER_TYPE.MAP),
        },
        { id: BANNER_TYPE.IMAGE, name: props.t(BANNER_TYPE.IMAGE) },
      ],
      displayValue: "name",
      value: [{ id: BANNER_TYPE.IMAGE, name: props.t(BANNER_TYPE.IMAGE) }], //for edit override this to set value
    },
    {
      type: FIELD_FILE,
      id: "typeValue",
      label: props.t("BANNER_IMAGE"),
      value: dafaultBannerImage, //for edit override this to set value
      hideMe: (fields) =>
        !fields[1].value ||
        !fields[1].value[0] ||
        fields[1].value[0].id !== BANNER_TYPE.IMAGE,
    },
    {
      type: FIELD_BOOLEAN,
      id: "sendNotification",
      label: props.t("SEND_NOTIFICATION"),
      value: false, //for edit override this to set value
    }
  ];

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(true);

  const init = () => {
    service.getById(props.Auth.user.Comune.idComune).then((res) => {
      let comune = res.data.result;
      if (comune && comune.settings) {
        const settings = JSON.parse(comune.settings);
        let mapped = [...initialFormData];
        mapped[0].value = settings.blocks.prenotazioneDetail.map((pd) => ({
          id: pd,
          name: props.t(pd),
          key: pd,
        }));
        mapped[1].value = [
          { id: settings.banner.type, name: props.t(settings.banner.type) },
        ];
        //mapped[2].value = settings.banner.image;
        mapped[3].value = !!settings.sendNotification;

        if(mapped[1].value &&
          mapped[1].value[0] &&
          mapped[1].value[0].id === BANNER_TYPE.IMAGE){
            let filter = {
              [`${FILTER_NUMBER}&&&FkIdComune`]: [props.Auth.user.Comune.idComune],
            };
            comunebannerservice.paginate(0, -1, filter).then(async (res) => {
              mapped[2].value=await createFile(res.data.result[0].idComuneBanner,res.data.result[0].formato);
              setFormData(mapped);
              setLoading(false);
            });
          }else{
            setFormData(mapped);
            setLoading(false);
          }
       
      }
    });
  };


  async function createFile(idComuneBanner,formato){
    let response = await fetch(`${BASE_URL_TAAL}/Generic/image/v_ComuneBanner/imgBanner/${idComuneBanner}/jpeg`);
    let data = await response.blob();
    let metadata = {
      type: formato
    };
    return new File([data], "server."+formato.split('/')[1], metadata);
    // ... do something with the file or return it
  }


  const resizeImage = (file, maxWidth, maxHeight) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const _handleSubmit = (formData) => {
    let mapped = {
      blocks: {
        prenotazioneDetail: formData[0].value.map((p) => p.id),
      },
      banner: {
        type: formData[1].value[0].id,
      },
    };
    let toSaveImage=false;
    if (
      formData[1].value &&
      formData[1].value[0] &&
      formData[1].value[0].id === BANNER_TYPE.IMAGE
    ) {
      //mapped.banner.image = await resizeImage(formData[2].value);
      mapped.sendNotification = formData[3].value;   
      toSaveImage=true;
    } else {
      mapped.sendNotification = formData[2].value;
    }
   

    service
      .updateSettings(JSON.stringify(mapped), props.Auth.user.fkIdComune)
      .then(async (result) => {
        
        if(toSaveImage){
          if(formData[2].value){
            let imageWithType = await resizeImage(formData[2].value);
            let splitted = imageWithType.split(";base64,");
            let formato = splitted[0].split("data:")[1];
            let imageToSave = splitted[1];
            let objToSave = { imgBanner: imageToSave, formato: formato };

            comunebannerservice
              .updateSettings(objToSave, props.Auth.user.fkIdComune)
              .then((result) => {
                props.onSuccess("OK");
              });
          }else{
            props.onError('PHOTO_REQUIRED');
          }
          
        }else{
          props.onSuccess("OK");
        }
        
      });
  };

  //per intercettare tutti i refresh come il componentDidUpdate
  //ma in base al secondo parametro posso filtrare gli eventi da intercettare
  useEffect(init, []); //per intercettare solo in componentDidMount

  if (loading) {
    return <p>Loading...</p>;
  }

  return <DynamicForm fields={formData} onSubmit={_handleSubmit} />;
}

const ComuneSettings = RxComponent({ onSuccess, onError })(ComuneSettingsC);
export default ComuneSettings;
