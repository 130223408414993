import {
  SUCCESS_HR_LOGIN,
  SUCCESS_LOGOUT,
  SUCCESS_GOOGLE_LOGIN,
  SUCCESS_LOGIN,
  ERROR_HR_LOGIN,
  SUCCESS_GOOGLE_LOGOUT,
  SUCCESS_TAAL_LOGIN,
  ERROR_TAAL_LOGIN,
  ERROR_LOGIN,
  SUCCESS_ROLES,
  ERROR_ROLES,
  SUCCESS_REGISTER,
  SUCCESS_PASSWORD_RECOVERY,
  ERROR_PASSWORD_RECOVERY,
  SUCCESS_CHANGE_PASSWORD,
  ERROR_CHANGE_PASSWORD,
} from "./Auth.types";

const initialState = {
  user: null,
  roles: null,
  loggedIn: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_GOOGLE_LOGIN:
      return {
        ...state,
        type: SUCCESS_GOOGLE_LOGIN,
        googleUser: action.payload,
      };
    case SUCCESS_GOOGLE_LOGOUT:
      return {
        ...state,
        type: SUCCESS_GOOGLE_LOGOUT,
        googleUser: null,
        loggedIn: false,
        hrUser: null,
        taalUser: null,
      };
    case SUCCESS_TAAL_LOGIN:
      return {
        ...state,
        type: SUCCESS_TAAL_LOGIN,
        user: action.payload,
      };
    case ERROR_TAAL_LOGIN:
      return {
        ...state,
        type: ERROR_TAAL_LOGIN,
        message: action.payload,
      };
    case SUCCESS_LOGIN:
      return {
        ...state,
        type: SUCCESS_LOGIN,
        loggedIn: true,
      };
    case ERROR_LOGIN:
      return {
        ...state,
        type: ERROR_LOGIN,
        message: action.payload,
      };
    case SUCCESS_ROLES:
      return {
        ...state,
        type: SUCCESS_ROLES,
        roles: action.payload,
      };
    case ERROR_ROLES:
      return {
        ...state,
        type: ERROR_ROLES,
        roles: null,
      };
    case SUCCESS_REGISTER:
      return {
        ...state,
        type: SUCCESS_REGISTER,
        register: action.payload,
      };

    case SUCCESS_LOGOUT:
      return initialState;

    case SUCCESS_PASSWORD_RECOVERY:
      return {
        ...state,
        type:SUCCESS_PASSWORD_RECOVERY
      }
    case ERROR_PASSWORD_RECOVERY:
      return {
        ...state,
        type:ERROR_PASSWORD_RECOVERY,
        message:action.payload
      }
    case SUCCESS_CHANGE_PASSWORD:
        return {
          ...state,
          type:SUCCESS_CHANGE_PASSWORD
        }
      case ERROR_CHANGE_PASSWORD:
        return {
          ...state,
          type:ERROR_CHANGE_PASSWORD,
          message:action.payload
        }
    default:
      return state;
  }
};
