export const TRIGGER_SEARCH = "TRIGGER_SEARCH";

export const SUCCESS_VALIDATE = "SUCCESS_VALIDATE";
export const ERROR_VALIDATE = "ERROR_VALIDATE";

export const SUCCESS_SEARCH = "SUCCESS_SEARCH";
export const ERROR_SEARCH = "ERROR_SEARCH";
export const SUCCESS_TIPOLOGIA_AREA_SEARCH = "SUCCESS_TIPOLOGIA_AREA_SEARCH";

export const TRIGGER_SELECT_AREA = "TRIGGER_SELECT_AREA";
export const SELECT_AREA = "SELECT_AREA";
export const TRIGGER_INSERT_PRENOTAZIONE = "TRIGGER_INSERT_PRENOTAZIONE";
export const ON_SUCCESS_INSERT_PRENOTAZIONE = "ON_SUCCESS_INSERT_PRENOTAZIONE";

export const TRIGGER_GET_PRENOTAZIONE = "TRIGGER_GET_PRENOTAZIONE";
export const TRIGGER_UPDATE_PRENOTAZIONE = "TRIGGER_UPDATE_PRENOTAZIONE";
export const ON_SUCCESS_UPDATE_PRENOTAZIONE = "ON_SUCCESS_UPDATE_PRENOTAZIONE";

export const TRIGGER_SCAN_BARCODE_PRENOTAZIONE =
  "TRIGGER_SCAN_BARCODE_PRENOTAZIONE";

export const TRIGGER_TARGA = "TRIGGER_TARGA";
export const ON_SUCCESS_TARGA_PRENOTAZIONE = "ON_SUCCESS_TARGA_PRENOTAZIONE";

export const TRIGGER_SEARCH_USER_BY_DOC = "TRIGGER_SEARCH_USER_BY_DOC";
export const SUCCESS_SEARCH_USER_BY_DOC = "SUCCESS_SEARCH_USER_BY_DOC";

export const ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE =
  "ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE";
export const ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE =
    "ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE"
export const PRENOTAZIONE_NON_TROVATA = "PRENOTAZIONE_NON_TROVATA";

export const ON_CHANGE_FASCIA_ORARIA = "ON_CHANGE_FASCIA_ORARIA";

export const ON_FILTER_TIPOLOGIA_AREA = "ON_FILTER_TIPOLOGIA_AREA";
export const NOT_FILTER_TIPOLOGIA_AREA = "NOT_FILTER_TIPOLOGIA_AREA";
export const ON_SUCCESS_CHANGE_AREA_STATUS = "ON_SUCCESS_CHANGE_AREA_STATUS";
export const TRIGGER_SEARCH_USER_BY_DOC_GESTORE =
  "TRIGGER_SEARCH_USER_BY_DOC_GESTORE";

export const ON_SUCCESS_PARCHEGGI_SEARCH = "SUCCESS_PARCHEGGI_SEARCH";
