import React from 'react';
import _ from 'lodash';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class Paginator extends React.Component {

    fromComponent = false;

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            itemsPerPage: 10,
            totalRecords: 0,
            pages: []
        };
    }

    componentDidMount() {
        let changed = {};
        if (this.state.currentPage !== this.props.currentPage) {
            changed.currentPage = this.props.currentPage;
        }
        if (this.props.itemsPerPage && this.state.itemsPerPage !== this.props.itemsPerPage) {
            changed.itemsPerPage = this.props.itemsPerPage;
        }
        if (this.props.totalRecords && this.state.totalRecords !== this.props.totalRecords) {
            changed.totalRecords = this.props.totalRecords;
        }
        if (!_.isEmpty(changed))
            this.setState(changed, this.getPages.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        let changed = {};
        if (this.state.currentPage !== this.props.currentPage && prevProps.currentPage !== this.props.currentPage) {
            changed.currentPage = this.props.currentPage;
        }
        if (this.props.itemsPerPage && this.state.itemsPerPage !== this.props.itemsPerPage && prevProps.itemsPerPage !== this.props.itemsPerPage) {
            changed.itemsPerPage = this.props.itemsPerPage;
        }
        if (this.props.totalRecords && this.state.totalRecords !== this.props.totalRecords && prevProps.totalRecords !== this.props.totalRecords) {
            changed.totalRecords = this.props.totalRecords;
        }

        if (!_.isEmpty(changed) && !this.fromComponent) {
            this.setState(changed, this.getPages.bind(this));
        } else if (this.fromComponent) {
            this.getPages();
            this.fromComponent = false;
        }

    }

    getPages() {
        if (this.state.currentPage > this.state.totalRecords) {
            this.fromComponent = true;
            this.setState({
                currentPage: this.state.totalRecords
            });
            return;
        }
        let addedPage = (this.state.totalRecords % this.state.itemsPerPage) > 0 ? 1 : 0;
        let totalPages = parseInt((this.state.totalRecords / this.state.itemsPerPage) + addedPage);
        let ret = [
            {
                skipPage: (this.state.currentPage - 1) * -1,
                label: '<<'
            },
            {
                skipPage: -1,
                label: '<'
            }
        ];

        let startIndex = 1;
        let endIndex = 5;
        let toAdd = null;
        if (this.state.currentPage >= 5) {
            ret.push({
                skipPage: -4,
                label: '...'
            });
            startIndex = this.state.currentPage - 2;
        }
        if ((totalPages - this.state.currentPage) >= 5) {
            toAdd = [{
                skipPage: 4,
                label: '...'
            }];
            endIndex = this.state.currentPage >= 4 ? this.state.currentPage + 2 : 5;
        } else {
            let diff = totalPages - this.state.currentPage;
            endIndex = this.state.currentPage + diff;
            if(totalPages - 4 !== 0){
                startIndex = totalPages - 4;
            }
        }
        if (this.state.currentPage <= 3) {
            startIndex = 1;
        }

        for (let i = startIndex; i <= endIndex; i++) {
            //let abs = i>this.state.currentPage?1:-1;
            let step = i - this.state.currentPage;
            ret.push({
                skipPage: step,
                label: `${i}`
            });
        }

        if (toAdd) {
            ret = ret.concat(toAdd);
        }
        ret = ret.concat([
            {
                skipPage: 1,
                label: '>'
            },
            {
                skipPage: (this.state.currentPage - totalPages) * -1,
                label: '>>'
            }
        ]);
        this.setState({
            totalPages: totalPages,
            pages: ret
        });
    }

    jumpPage(skipPage) {
        if(this.state.currentPage + skipPage > this.state.totalPages || this.state.currentPage + skipPage < 1){
            return
        }
        this.fromComponent = true;
        this.setState({
            currentPage: this.state.currentPage + skipPage
        }, () => {
            if (this.props.onPageChanged && _.isFunction(this.props.onPageChanged)) {
                this.props.onPageChanged(this.state.currentPage);
            }
        });
    }

    render() {
        return (
            <Pagination size="sm" aria-label="Navigazione per tabella">
                {
                    this.state.pages.map((page, index) => {
                        return (<PaginationItem
                            key={'pager-' + index}
                            onClick={() => { this.jumpPage(page.skipPage) }}
                            active={page.label === this.state.currentPage.toString()}
                        >
                            <PaginationLink>
                                {page.label}
                            </PaginationLink>
                        </PaginationItem>);
                    })
                }
            </Pagination>
        );
    }

}