import React from "react";
import RxComponent from "../../../redux/RxComponent";
import {
  searchUtenteByDocFromVarco,
  searchUtenteByDocFromGestore,
} from "../../../redux/Prenotazione/Prenotazione.actions";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
} from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import DatePicker from "../../../components/Content/DatePicker";
import { Trans } from "react-i18next";
import AreaService from "../../Area/Area.service";
import TipologiaPrenotazioneService from "../../TipologiaPrenotazione/TipologiaPrenotazione.service";
import ComuneService from "../../Comune/Comune.service";
import moment from "moment";
import {
  FILTER_NUMBER,
  FILTER_MULTIPLE,
} from "../../../components/TableServer/FilterItem";
import UtenteService from "../../Utente/Utente.service";
import { ROLES } from "../../../constants/Roles";
import BarcodeScanner from "../../../components/Content/BarcodeReader";

class Component extends React.Component {
  areaService = new AreaService();
  TipologiaPrenotazioneService = new TipologiaPrenotazioneService();
  ComuneService = new ComuneService();
  UtenteService = new UtenteService();

  constructor(props) {
    super(props);
    this.state = {
      comuneList: [],
      occupantiList: [],
      numeroOccupanti: null,
      selectedComune: null,
      dataPrenotazione: null,
      prenotazioneToBePassed: [],
      giorniPrenotazioneComune: null,
      form_key: 0,
      numberDoc: "",
    };
  }

  componentDidMount() {}

  handleSubmit = (ev) => {
    let par = {
      numeroDocumento: ev,
      idTipoDocumento: null,
    };
    this.props.Auth.user.TipologiaProfilo.codiceProfilo === ROLES.GESTORE_AREA
      ? this.props.searchUtenteByDocFromGestore(par)
      : this.props.searchUtenteByDocFromVarco(par);
  };

  validate() {}

  resetForm() {
    let newKey = this.state.form_key;
    newKey += 1;
    this.setState({ form_key: newKey, numeroOccupanti: null });
  }

  handleChange(e) {
    let value = e.target.value;

    value = value.replace(/[^A-Za-z0-9]/gi, "");

    this.setState({ numberDoc: value });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row w-100 d-flex justify-content-center">
          <div className="form-group col-md-7 mb-3">
            <h3 className="Login-form--title">
              {this.props.t("ENTER_DOCUMENT_NUMBER")} /{" "}
              {this.props.t("CODICE_FISCALE")}
            </h3>
          </div>
        </div>
        <form
          className="row w-100 d-flex justify-content-center"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="col-md-3 input-group mb-3">
            {/*<input*/}
            {/*  value={this.state.numberDoc}*/}
            {/*  required*/}
            {/*  type="text"*/}
            {/*  id="numberDoc"*/}
            {/*  className="form-control uppercase"*/}
            {/*  onChange={(ev) => this.handleChange(ev)}*/}
            {/*  name="numberDoc"*/}
            {/*  placeholder=""*/}
            {/*  aria-label="Search"*/}
            {/*  aria-describedby="basic-addon2"*/}
            {/*/>*/}
            {/*<div className="input-group-append">*/}
            {/*  <button className="btn btn-primary" type="submit">*/}
            {/*    <i className="fas fa-search fa-sm"></i>*/}
            {/*  </button>*/}
            {/*</div>*/}
            <BarcodeScanner
                hideSearchButton={false}
                triggerOnChange={false}
                required={true}
                onDetect={(barcode) => {
                  this.handleSubmit(barcode);
                }}
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}
const SerchUserFromVarco = RxComponent({
  searchUtenteByDocFromVarco,
  searchUtenteByDocFromGestore,
})(Component);
export default SerchUserFromVarco;
