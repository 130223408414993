import React from "react";
import moment, { relativeTimeThreshold } from "moment";
import Select from "react-select";
import { STATUSES } from "../../constants/PrenotazioneStatuses";

import RxComponent from "../../redux/RxComponent";
import {
  FILTER_NUMBER,
  FILTER_TEXT,
} from "../../components/TableServer/FilterItem";

import "bootstrap/dist/css/bootstrap.min.css";
import ControlloTargaService from "./ControlloTarga.service";
import PrenotazioneService from "../Prenotazione/Prenotazione.service";
import PrenotazioneDetailForm from "../Prenotazione/components/Prenotazione.insertdetail";
import {
  triggerGetPrenotazione,
  triggerScanBarCodePrenotazione,
  triggerTarga,
} from "../../redux/Prenotazione/Prenotazione.actions";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";
import {
  SUCCESS_SEARCH,
  ON_SUCCESS_TARGA_PRENOTAZIONE,
} from "../../redux/Prenotazione/Prenotazione.types";
import ComuneService from "../Comune/Comune.service";

class Component extends React.Component {
  //TODO mock utente
  state = {
    flgEntrataUscita: "",
    pnr: "",
    service: new ControlloTargaService(),
    assignedVarco: null,
    typeAssignedVarco: null,
    prenotazioneService: new PrenotazioneService(),
    prenotazione: null,
    note: "",
    idUtenteAddetto: this.props.Auth.user.idUtente,
    loadingAccordion: false,
    idComune: null,
    comune: null,
    isCorrectComune: false,
    selectedDataInizio: null,
  };

  handleTarga = false;
  comuneService = new ComuneService();

  componentDidMount() {
    this.comuneService
      .getById(this.props.Auth.user.Comune.idComune)
      .then((res) => {
        this.setState({
          idComune: res.data.result.idComune,
        });
      });
  }

  componentDidUpdate() {
    if (this.handleTarga && this.props.Prenotazione.type === SUCCESS_SEARCH) {
      this.handleTarga = false;
      let dataInizio = this.props.Prenotazione.selectedFields
        ? this.props.Prenotazione.selectedFields.dataInizio
        : null;
      let correctComune = true;
      //wrongComune =this.props.Auth.user.Comune.idComune !== this.props.Prenotazione.selectedArea.IDCOMUNE;
      this.state.prenotazioneService
        .getMatchBetweenComunePnr(
          this.state.idComune,
          this.props.Prenotazione.selectedFields.pnr
        )
        .then((res) => {
          correctComune = res.data.result[0].FLGVALIDATE > 0;
          this.setState({
            loadingAccordion: true,
            selectedDataInizio: dataInizio,
            isCorrectComune: correctComune,
          });
        });
    }

    /*if (
      this.handleTarga &&
      this.props.Prenotazione.type === ON_SUCCESS_TARGA_PRENOTAZIONE
    ) {
      this.handleTarga = false;
      this.setState({ pnr: this.props.Prenotazione.pnr }, () => {
        this.handlepnrChange();
      });
    }*/
  }

  setStatePrenotazione() {}

  validateForm(param) {
    return new Promise((resolve, reject) => {
      let err = [];
      if (this.state.flgEntrataUscita === "") {
        err.push(this.props.t("VALIDATE_INGRESSO_ERROR"));
      }
      if (param && this.state.note === "") {
        err.push(this.props.t("VALIDATE_NOTE_ERROR"));
      }
      if (err.length) {
        reject(err);
      }
      resolve();
    });
  }

  handleTargaChange(event) {
    if (event) event.preventDefault();
    this.setState(
      {
        loadingAccordion: false,
      },
      () => {
        this.handleTarga = true;
        this.props.triggerTarga(this.state.targa);
      }
    );
  }

  handlepnrChange(event) {
    if (event) event.preventDefault();

    this.setState(
      {
        loadingAccordion: false,
      },
      () => {
        this.handleTarga = true;
        this.props.triggerGetPrenotazione(this.state.pnr);
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-group">
          <h1 className="Login-form--title">
            {this.props.t("ControlLicensePlate")}
          </h1>
        </div>
        <form
          className="row w-100 d-flex justify-content-center"
          onSubmit={(event) => this.handleTargaChange(event)}
        >
          <div className=" col-md-3 input-group mb-3">
            <input
              required
              type="text"
              id="targa"
              value={this.state.targa}
              onChange={(ev) => this.setState({ targa: ev.target.value })}
              name="targa"
              className="form-control"
              placeholder="Targa"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>

        {this.state.loadingAccordion && (
          <>
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.isCorrectComune}
            >
              {this.props.t("LABEL_WRONG_COMUNE")}
            </div>
            <PrenotazioneDetailForm
              dataInizio={this.state.selectedDataInizio}
              readOnly={true}
              isAusiliareTraffico={true}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}
//maxOccupanti={this.state.comune.maxOccupanti}
const ControlloTarga = RxComponent({
  triggerGetPrenotazione,
  onError,
  onSuccess,
  triggerScanBarCodePrenotazione,
  triggerTarga,
})(Component);
export default ControlloTarga;
