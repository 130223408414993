import BaseServiceTaal from "../../Service/BaseServiceTaal";
import RelTipoPrenotazioneTipoDurata from "./RelTipoPrenotazioneTipoDurata.repo";

export default class RelTipoPrenotazioneTipoDurataService extends BaseServiceTaal {
  getRepo = () => RelTipoPrenotazioneTipoDurata.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["TipologiaPrenotazione", "TipologiaDurata"]
      )
    );


  getRefFieldMap = ()=>{
    return {
      fkIdTipologiaDurata:'descLocalizzata',
      fkIdTipologiaPrenotazione:'descLocalizzata'
    }
  }
  

  tableDefinition = (isTable) =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          if(isTable){
            res.data.result.columns=res.data.result.columns.filter(c=>c.name.indexOf('fk')<0);
            res.data.result.columns.push(
              { 
                name:'TipologiaDurata.descLocalizzata',
                type:'String'
              }
            );
            res.data.result.columns.push(
              { 
                name:'TipologiaPrenotazione.descLocalizzata',
                type:'String'
              }
            );
          }
        }
        return res;
      });
}
