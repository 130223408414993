import React from "react";
import Tableserver from "../TableServer/Tableserver";
import CrudNetForm from "./CrudNetForm";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import BaseServiceTaal from "../../Service/BaseServiceTaal";
import { Trans } from "react-i18next";

export default class CrudNetTable extends React.PureComponent {
  state = {
    loading: true,
    columns: [],
    fiters: [],
    service: new BaseServiceTaal(),
    showModal: false,
  };

  componentDidMount() {
    let col = [];
    if (this.props.columns && _.isArray(this.props.columns)) {
      col = [...this.props.columns];
      if (!this.props.actions || this.props.actions.length)
        col.push({
          label: "Azioni",
          type: "actions",
          actions: this.props.actions || ["edit", "delete"],
          modalHeader: (row, viewModal) => {
            let title = `Modifica ${this.props.title}`;
            if (viewModal === "delete") {
              title = `Rimuovi ${this.props.title}`;
            }
            return <React.Fragment>{title}</React.Fragment>;
          },
          modalComponent: (params) => this.getCrud(params.viewMode, params),
        });
    }
    this.setState({
      columns: col,
      filters: this.props.filters,
      service: this.props.service,
      loading: false,
      prevColProps: this.props.columns,
    });
  }

  componentDidUpdate() {
    if (this.props.columns && this.props.columns !== this.state.prevColProps) {
      let col = [];
      if (this.props.columns && _.isArray(this.props.columns)) {
        col = [
          ...this.props.columns,
          {
            label: "Azioni",
            type: "actions",
            actions: this.props.actions || ["edit", "delete"],
            modalHeader: (row, viewModal) => {
              let title = `Modifica ${this.props.title}`;
              if (viewModal === "delete") {
                title = `Rimuovi ${this.props.title}`;
              }
              return <React.Fragment>{title}</React.Fragment>;
            },
            modalComponent: (params) => this.getCrud(params.viewMode, params),
          },
        ];
      }
      this.setState({
        columns: col,
        fiters: this.props.fiters,
        service: this.props.service,
        prevColProps: this.props.columns,
      });
    }
  }

  getData = (pageNum, filter, sort, itemsPerPage) => {
    const df = !this.props.defaultFilters ? {} : this.props.defaultFilters;
    const ff = { ...df, ...filter };

    if (_.isFunction(this.props.getData)) {
      return this.props
        .getData(pageNum, itemsPerPage, ff, sort)
        .then((records) => {
          return {
            totalItems: records.data.rowCount,
            rows: records.data.result,
          };
        });
    }
    return this.state.service
      .paginate(pageNum, itemsPerPage, ff, sort)
      .then((records) => {
        return {
          totalItems: records.data.rowCount,
          rows: records.data.result,
        };
      });
  };

  getCrud(viewMode, params) {
    if (this.props.customComponent) {
      return React.cloneElement(this.props.customComponent, {
        ...params,
        viewMode: viewMode,
        service: this.state.service,
        refFieldMap: this.props.refFieldMap,
        onSubmit: this.props.onSubmit,
      });
      //const CUSTOMCOMP=this.props.customComponent();
      //return <CUSTOMCOMP {...params} viewMode={viewMode} service={this.state.service} refFieldMap={this.props.refFieldMap} />
    }
    if (this.props.children) {
      return React.cloneElement(this.props.children, {
        ...params,
        viewMode: viewMode,
        service: this.state.service,
        refFieldMap: this.props.refFieldMap,
        onSubmit: this.props.onSubmit,
      });
    }
    return (
      <CrudNetForm
        {...params}
        hideField={this.props.hideField}
        lookupFilters={this.props.lookupFilters}
        service={this.state.service}
        viewMode={viewMode}
        onSubmit={this.props.onSubmit}
        refFieldMap={this.props.refFieldMap}
      />
    );
  }

  closeModal() {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.refs.table.updateTable();
      }
    );
  }

  refreshTable() {
    this.refs.table.updateTable();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.customHeader ? (
          this.props.customHeader(() => this.setState({ showModal: true }))
        ) : (
          <h1 className="h3 mb-4 text-gray-800 text-left page-header">
            {this.props.title}
            <Button
              color="light"
              outline
              className="ml-2 border-0"
              size="sm"
              onClick={() => this.setState({ showModal: true })}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </h1>
        )}

        <div className="bg-white ">
          <Tableserver
            ref="table"
            updateData={this.getData.bind(this)}
            columns={this.state.columns}
            filters={this.state.filters}
            disablePaginate={this.props.disablePaginate}
          />
        </div>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.closeModal.bind(this)}
          size="xl"
        >
          <ModalHeader toggle={this.closeModal.bind(this)}>
            <Trans i18nKey="Add"></Trans> {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.getCrud("insert", { closeModal: this.closeModal.bind(this) })}
          </ModalBody>
          {/*
            <ModalFooter>
              <button onClick={this.closeModal.bind(this)} type="button" className="btn btn-dark" aria-label="Chiudi ...">Chiudi</button>
            </ModalFooter>
          */}
        </Modal>
      </React.Fragment>
    );
  }
}
