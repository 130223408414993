import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_COMUNI_PRENOTAZIONE = "generic/search3/prenotazione/comune";
export default class ComuneHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Comune";
  }

  searchComuniPrenotazione(flgAbilitato) {
    return this.API.post(GET_COMUNI_PRENOTAZIONE, {
      flgAbilitato: flgAbilitato,
    });
  }
}
