import {
  ON_GOOGLE_LOGIN,
  ON_HR_LOGIN,
  SUCCESS_HR_LOGIN,
  ERROR_HR_LOGIN,
  SUCCESS_GOOGLE_LOGIN,
  SUCCESS_LOGIN,
  ON_GOOGLE_LOGOUT,
  ON_TAAL_LOGIN,
  SUCCESS_TAAL_LOGIN,
  ERROR_TAAL_LOGIN,
  ERROR_LOGIN,
  SUCCESS_ROLES,
  ERROR_ROLES,
  TRIGGER_REGISTER,
  SUCCESS_REGISTER,
  ERROR_REGISTER,
  TRIGGER_OTP,
  SUCCESS_OTP,
  ERROR_OTP,
  TRIGGER_SEND_OTP,
  TRIGGER_LOGOUT,
  TRIGGER_PASSWORD_RECOVERY,
  TRIGGER_CHANGE_PASSWORD,
  TRIGGER_REGISTER_VARCO,
  SUCCESS_PASSWORD_RECOVERY,
  ERROR_PASSWORD_RECOVERY,
  SUCCESS_CHANGE_PASSWORD,
  ERROR_CHANGE_PASSWORD,
  TRIGGER_CHANGE_PASSWORD_SAFE,
  TRIGGER_LOGOUT_NO_LOGIN,
} from "./Auth.types";

export const onGoogleLogin = (googleData) => {
  return {
    type: ON_GOOGLE_LOGIN,
    payload: googleData,
  };
};

export const onGoogleLogout = () => {
  return {
    type: ON_GOOGLE_LOGOUT,
  };
};

export const successGoogleLogin = (googleData) => {
  return {
    type: SUCCESS_GOOGLE_LOGIN,
    payload: googleData,
  };
};

export const onLoginTaal = (username, password) => {
  return {
    type: ON_TAAL_LOGIN,
    payload: { username: username, password: password },
  };
};

export const successLoginTaal = (userData) => {
  return {
    type: SUCCESS_TAAL_LOGIN,
    payload: userData,
  };
};

export const errorLoginTaal = (errorData) => {
  return {
    type: ERROR_TAAL_LOGIN,
    payload: errorData,
  };
};

export const successLogin = () => {
  return {
    type: SUCCESS_LOGIN,
  };
};

export const errorLogin = (errorData) => {
  return {
    type: ERROR_LOGIN,
    payload: errorData,
  };
};

export const successRoles = (successData) => {
  return {
    type: SUCCESS_ROLES,
    payload: successData,
  };
};

export const errorRoles = (errorData) => {
  return {
    type: ERROR_ROLES,
    payload: errorData,
  };
};

export const triggerRegister = (registerData) => {
  return {
    type: TRIGGER_REGISTER,
    payload: registerData,
  };
};

export const successRegister = (registerData) => {
  return {
    type: SUCCESS_REGISTER,
    payload: registerData,
  };
};

export const errorRegister = (registerData) => {
  return {
    type: ERROR_REGISTER,
    payload: registerData,
  };
};

export const triggerOtp = (idUtente, otp, token) => {
  return {
    type: TRIGGER_OTP,
    payload: { idUtente: idUtente, otp: otp, token: token },
  };
};

export const successOtp = (otpData) => {
  return {
    type: SUCCESS_OTP,
    payload: otpData,
  };
};

export const errorOtp = (otpError) => {
  return {
    type: ERROR_OTP,
    payload: otpError,
  };
};

export const triggerSendOtp = (idUtente, username, token) => {
  return {
    type: TRIGGER_SEND_OTP,
    payload: { idUtente: idUtente, username: username, token: token },
  };
};

export const triggerLogout = () => {
  return {
    type: TRIGGER_LOGOUT,
  };
};

export const triggerLogoutNoLogin = () => {
  return {
    type: TRIGGER_LOGOUT_NO_LOGIN,
  };
};

export const triggerPasswordRecovery = (username) => {
  return {
    type: TRIGGER_PASSWORD_RECOVERY,
    payload: username,
  };
};

export const successPasswordRecovery = () => {
  return {
    type: SUCCESS_PASSWORD_RECOVERY,
  };
};

export const errorPasswordRecovery = (otpError) => {
  return {
    type: ERROR_PASSWORD_RECOVERY,
    payload: otpError,
  };
};

export const triggerChangePassword = (username, password, otp) => {
  return {
    type: TRIGGER_CHANGE_PASSWORD,
    payload: { username: username, password: password, otp: otp },
  };
};

export const triggerChangePasswordSafe = (username, password, otp) => {
  return {
    type: TRIGGER_CHANGE_PASSWORD_SAFE,
    payload: { username: username, password: password, otp: otp },
  };
};

export const successChangePassword = () => {
  return {
    type: SUCCESS_CHANGE_PASSWORD,
  };
};

export const errorChangePassword = (otpError) => {
  return {
    type: ERROR_CHANGE_PASSWORD,
    payload: otpError,
  };
};

export const triggerRegisterVarco = (registerData) => {
  return {
    type: TRIGGER_REGISTER_VARCO,
    payload: registerData,
  };
};
