import StubRepo from "../../repos/StubRepo";
import ControlloVarcoMockRepo from "./repositories/ControlloVarco.mock";
import ControlloVarcoHttpRepo from "./repositories/ControlloVarco.http";

export default class ControlloVarco extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "ControlloVarco repo";
  }

  static getLocal() {
    if (ControlloVarco._localInstance == null) {
      ControlloVarco._localInstance = new ControlloVarcoMockRepo();
    }
    return ControlloVarco._localInstance;
  }

  static getService() {
    if (ControlloVarco._serviceInstance == null) {
      ControlloVarco._serviceInstance = new ControlloVarcoHttpRepo();
    }
    return ControlloVarco._serviceInstance;
  }
}
