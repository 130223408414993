import React from "react";
import RxComponent from "../../redux/RxComponent";
import "../../template/dashboard/css/custom.css";
import { onLoginTaal } from "../../redux/Auth/Auth.actions";
import { triggerLogoutNoLogin } from "../../redux/Auth/Auth.actions";

class Component extends React.PureComponent {
  state = {
    username: null,
    password: null,
  };

  login(ev) {
    ev.preventDefault();
    this.props.onLoginTaal(this.state.username.split(" ")
        .join(""), this.state.password);
  }

  templateHTML() {
    return (
      <React.Fragment>
        <div className="bg-gradient">
          <div className="Login-wrapper fadeInDown">
            <div className="Login-form-Content">
              <div className="fadeIn first">
                <h1 className="Login-form--title">{this.props.t("Login")}</h1>
              </div>
              <form onSubmit={this.login.bind(this)}>
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("NumberPhone")}</label>
                </div>
                <input
                  type="text"
                  id="username"
                  onChange={(ev) =>
                    this.setState({ username: ev.target.value })
                  }
                  className="form--input fadeIn second"
                  name="username"
                />
                <div className="form-input-label-cont py-0">
                  <label>{this.props.t("Password")}</label>
                </div>
                <input
                  type="password"
                  id="password"
                  onChange={(ev) =>
                    this.setState({ password: ev.target.value })
                  }
                  className="form--input fadeIn third"
                  name="psw"
                />
                <input
                  type="submit"
                  className="form--button-cyan fadeIn fourth"
                  value={this.props.t("Send")}
                />
              </form>
              <div className="Login-form-Footer d-flex justify-content-between">
                <a className="Login-form--underlineHover" href="/register">
                  {this.props.t("REGISTER")}
                </a>
                <a
                  className="Login-form--underlineHover"
                  href="/passwordrecovery"
                >
                  {this.props.t("ForgotPassword")}
                </a>
              </div>
              <div className="m-3">
                <a
                  className="Login-form--underlineHover"
                  href="/registerHelpdesk"
                >
                  {this.props.t("LoginProblem")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.templateHTML();
  }
}

const Login = RxComponent({ onLoginTaal, triggerLogoutNoLogin })(Component);
export default Login;
