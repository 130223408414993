import React from "react";
import Tableserver from "../components/TableServer/Tableserver";

export class Users extends React.Component {
  columns = [
    {
      label: "Nome",
      data: "nome",
      type: "string"
    },
    {
      label: "Cognome",
      data: "cognome",
      type: "string"
    }
  ];

  getData = (pageNum, filter, sort) => {
    return Promise.resolve({
      totalItems: 2,
      rows: [
        {
          nome: "Calogero",
          cognome: "Miraglia"
        },
        {
          nome: "Tonino",
          cognome: "Belli"
        }
      ]
    });
  };

  render() {
    return (
      <React.Fragment>
        <h1 className="h3 mb-4 text-gray-800">Users Page</h1>
        <Tableserver
          updateData={this.getData.bind(this)}
          columns={this.columns}
        />
      </React.Fragment>
    );
  }
}
