import {
  ON_ERROR,
  ON_SUCCESS,
  ON_WARNING,
  ON_INFO,
  LOADING_START,
  LOADING_END,
  CLEAR_NOTIFICATION,
} from "./Shared.types";

export const clearNotification = () => {
  return {
    type: CLEAR_NOTIFICATION,
  };
};

export const onError = (error) => {
  return {
    type: ON_ERROR,
    payload: error,
  };
};

export const onSuccess = (param) => {
  return {
    type: ON_SUCCESS,
    payload: param,
  };
};

export const onWarning = (param) => {
  return {
    type: ON_WARNING,
    payload: param,
  };
};

export const onInfo = (param) => {
  return {
    type: ON_INFO,
    payload: param,
  };
};

export const startLoading = (param) => {
  return {
    type: LOADING_START,
    payload: param,
  };
};

export const endLoading = (param) => {
  return {
    type: LOADING_END,
    payload: param,
  };
};
