import React from "react";
import { Brand } from "./Brand";
import { menu } from "../../constants/Menu";
import { NavLink } from "react-router-dom";
import HasRole from "../Content/HasRole";
import { Translation } from "react-i18next";

export class Sidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Brand />

        {menu
          .filter((i) => i.layout === "/dashboard" && !i.skipMenu)
          .map((item, index) => {
            return (
              <React.Fragment key={item.name + "-" + index}>
                {!item.children || !item.children.length ? (
                  <HasRole
                    roles={item.roles}
                    key={`${item.layout}${item.path}`}
                  >
                    <hr className="sidebar-divider my-0" />
                    <li className="nav-item">
                      <NavLink
                        to={`${item.layout}${item.path}`}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={"fas fa-fw fa-" + item.icon}></i>
                        <Translation>
                        {
                            t => <span>{t(item.name)}</span>
                        }
                        </Translation>
                      </NavLink>
                    </li>
                  </HasRole>
                ) : (
                  <HasRole roles={item.roles}>
                    <li className="nav-item">
                      <a
                        className="nav-link collapsed"
                        data-toggle="collapse"
                        data-target={`#collapse${item.name}`}
                        aria-expanded="true"
                        aria-controls={`collapse${item.name}`}
                      >
                        <i className={"fas fa-fw fa-" + item.icon}></i>
                        <span>{item.name}</span>
                      </a>
                      <div
                        className="collapse"
                        id={`collapse${item.name}`}
                        aria-labelledby={`heading${item.name}`}
                        data-parent="#accordionSidebar"
                      >
                        <div className="bg-white py-2 collapse-inner rounded">
                          {
                            //<h6 className="collapse-header">Custom Utilities:</h6>
                          }
                          {item.children.map((child) => {
                            return (
                              <HasRole
                                roles={child.roles}
                                key={`${item.name}-${child.name}`}
                              >
                                <NavLink
                                  to={`${item.layout}${item.path}${child.path}`}
                                  className="collapse-item"
                                  activeClassName="active"
                                >
                                  {child.name}
                                </NavLink>
                              </HasRole>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                  </HasRole>
                )}
              </React.Fragment>
            );
          })}

        <hr className="sidebar-divider d-none d-md-block" />

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div>
      </ul>
    );
  }
}
