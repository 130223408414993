import StubRepo from "../../repos/StubRepo";
import UtenteMockRepo from "./repositories/Utente.mock";
import UtenteHttpRepo from "./repositories/Utente.http";

export default class Utente extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Utente repo";
  }

  static getLocal() {
    if (Utente._localInstance == null) {
      Utente._localInstance = new UtenteMockRepo();
    }
    return Utente._localInstance;
  }

  static getService() {
    if (Utente._serviceInstance == null) {
      Utente._serviceInstance = new UtenteHttpRepo();
    }
    return Utente._serviceInstance;
  }
}
