import StubRepo from "../../repos/StubRepo";
import TipologiaDocumentoMockRepo from "./repositories/TipologiaDocumento.mock";
import TipologiaDocumentoHttpRepo from "./repositories/TipologiaDocumento.http";

export default class TipologiaDocumento extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaDocumento repo";
  }

  static getLocal() {
    if (TipologiaDocumento._localInstance == null) {
      TipologiaDocumento._localInstance = new TipologiaDocumentoMockRepo();
    }
    return TipologiaDocumento._localInstance;
  }

  static getService() {
    if (TipologiaDocumento._serviceInstance == null) {
      TipologiaDocumento._serviceInstance = new TipologiaDocumentoHttpRepo();
    }
    return TipologiaDocumento._serviceInstance;
  }
}
