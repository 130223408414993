import StubRepo from "../../repos/StubRepo";
import TipologiaAreaMockRepo from "./repositories/TipologiaArea.mock";
import TipologiaAreaHttpRepo from "./repositories/TipologiaArea.http";

export default class TipologiaArea extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaArea repo";
  }

  static getLocal() {
    if (TipologiaArea._localInstance == null) {
      TipologiaArea._localInstance = new TipologiaAreaMockRepo();
    }
    return TipologiaArea._localInstance;
  }

  static getService() {
    if (TipologiaArea._serviceInstance == null) {
      TipologiaArea._serviceInstance = new TipologiaAreaHttpRepo();
    }
    return TipologiaArea._serviceInstance;
  }
}
