import BaseServiceTaal from "../../Service/BaseServiceTaal";
import RegistroSanitario from "./RegistroSanitario.repo";

export default class RegistroSanitarioService extends BaseServiceTaal {
  getRepo = () => RegistroSanitario.getInstanceTaal();

  create = (par) =>
    this.getRepo().then((repo) => repo.exec("sp_RecordRegistroSanitario", par));

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) => {
      return repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["Documento"]
      );
    });
}
