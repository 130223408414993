import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { SharedReducer } from "./Shared/Shared.reducer";
import { AuthReducer } from "./Auth/Auth.reducers";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./RxSaga";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PrenotazioneReducer } from "./Prenotazione/Prenotazione.reducer";
import createEncryptor from "redux-persist-transform-encrypt";

const RootReducer = combineReducers({
  Shared: SharedReducer,
  Auth: AuthReducer,
  Prenotazione: PrenotazioneReducer,
});

const encryptor = createEncryptor({
  secretKey: 'taalLambda'
})

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
  whitelist: ["Auth", "Prenotazione"], // only reducer to persist
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  let store = null;
  if (process.env.NODE_ENV === "production")
    store = createStore(
      persistedReducer,
      applyMiddleware(thunk, sagaMiddleware)
    );
  else
    store = createStore(
      persistedReducer,
      applyMiddleware(thunk, logger, sagaMiddleware)
    );
  sagaMiddleware.run(rootSaga);
  return store;
};
