import StubRepo from '../../repos/StubRepo';
import LoginRepoMock from './repositories/Login.mock';
import LoginRepoTaalHttp from './repositories/Login.httptaal';

export default class LoginRepo extends StubRepo{
    
    static _localInstance = null;
    static _serviceInstance = null;
    static _serviceTaalInstance = null;

    static getName() {
        return "Login repo";
    }

    static getLocal() {
        if (LoginRepo._localInstance == null) {
            LoginRepo._localInstance = new LoginRepoMock();
        }
        return LoginRepo._localInstance;
    }

    static getService() {
        if (LoginRepo._serviceTaalInstance == null) {
            LoginRepo._serviceTaalInstance = new LoginRepoTaalHttp();
        }
        return LoginRepo._serviceTaalInstance;
    }
}