import React from "react";
import _ from "lodash";
import { Trans, Translation, withTranslation } from "react-i18next";

class ColumnData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      type: "string",
      row: {},
      modalComponent: () => <p>no component</p>,
      modalHeader: () => <p>no title</p>,
      actions: ["view"],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /*let newState={};

        if(this.state.data != this.props.data){
            newState.data=this.props.data;
        }
        if(this.state.type != this.props.type){
            newState.type=this.props.type;
        }
        if(this.state.row != this.props.row){
            newState.row=this.props.row;
        }

        if(!_.isEmpty(newState))
            this.setState(newState);*/
  }

  componentDidMount() {
    this.setState(this.props);
  }

  contentByType = (type, row, data) => {
    switch (
      type //add case to manage other types
    ) {
      case "expand":
        return (
          <button
            type="button"
            className="btn btn-outilne-dark btn-sm border-0"
            onClick={() => this.onExpand(row)}
            title={"Espandi ulteriori dettagli ..."}
          >
            {row.expanded ? (
              <i className="far fa-minus-square"></i>
            ) : (
              <i className="far fa-plus-square"></i>
            )}
          </button>
        );
      case "custom":
        return this.state.render(_.get(row, data), row, this.state.rowIndex);
      case "actions":
        return (
          <React.Fragment>
            {!this.state.actions || this.state.actions.indexOf("view") >= 0 ? (
              <button
                type="button"
                className="btn btn-outilne-info btn-sm border-0"
                onClick={() => this.sendShowModal(row, "view")}
                title={"Consulta ulteriori dettagli ..."}
              >
                <i className="fas fa-search"></i>
                <span className="sr-only">
                  <Trans i18nKey="SeeMoreDetails" /> ...
                </span>
              </button>
            ) : null}
            {this.state.actions && this.state.actions.indexOf("edit") >= 0 ? (
              <button
                type="button"
                className="btn btn-outline-dark btn-sm border-0 ml-1"
                onClick={() => this.sendShowModal(row, "edit")}
                title={"Modifica i dati ..."}
              >
                <i className="fas fa-pencil-alt"></i>
                <span className="sr-only">
                  <Trans i18nKey="EditData" /> ...
                </span>
              </button>
            ) : null}
            {this.state.actions && this.state.actions.indexOf("delete") >= 0 ? (
              <button
                type="button"
                className="btn btn-outline-danger btn-sm border-0 ml-1"
                onClick={() => this.sendShowModal(row, "delete")}
                title={"Elimina i dati ..."}
              >
                <i className="fas fa-trash-alt"></i>
                <span className="sr-only">
                  <Trans i18nKey="DeleteData" /> ...
                </span>
              </button>
            ) : null}
          </React.Fragment>
        );
      default:
        return <span>{this.props.t(_.get(row, data))}</span>;
    }
  };

  sendShowModal(row, viewModal) {
    if (this.props.OnModal && _.isFunction(this.props.OnModal)) {
      let header = this.state.modalHeader(row, viewModal);
      let paramas = _.cloneDeep(row);
      paramas.viewMode = viewModal;
      paramas.closeModal = this.state.closeModal;
      let component = this.state.modalComponent(paramas);
      this.props.OnModal(row, header, component);
    }
  }

  onExpand(row) {
    if (this.props.onExpand && _.isFunction(this.props.onExpand)) {
      this.props.onExpand(row);
    }
  }

  render() {
    let content = this.contentByType(
      this.state.type,
      this.state.row,
      this.state.data
    );
    return (
      <React.Fragment>
        {this.state.type === "actions" ? (
          <td
            data-label={this.props.t(this.state.label)}
            className="text-center"
          >
            {content}
          </td>
        ) : (
          <td
            data-label={this.props.t(this.state.label)}
            className="text-xl-nowrap text-lg-nowrap text-md-nowrap text-sm-center"
          >
            <br className={`d-xl-none d-lg-none d-md-none d-sm-block d-sm-block`}/>
            {content}
          </td>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ColumnData);
