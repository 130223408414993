import MockRepo from "../../../repos/MockRepo";

export default class LookupsMockRepo extends MockRepo {
  getList(...p) {}

  getAll() {
    return new Promise((resolve, reject) => {
      let lookupsMockList = [
        {
          descrizione: "StatoAssegnazione",
          value: 1,
        },
      ];
      resolve(lookupsMockList);
    });
  }
}
