import BaseServiceTaal from "../../Service/BaseServiceTaal";
import EventoRepo from "./Evento.repo";
import AreaService from "../Area/Area.service";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";


const CREATE = "sp_CreateEvento";
const UPDATE = "sp_UpdateEvento";
const DELETE = "sp_DeleteEvento";

export default class EventoService extends BaseServiceTaal {

    getRepo = () => EventoRepo.getInstanceTaal();


    // override del paginate per mandare da tabella a vista
    paginate = (page, size, filters, orders) =>  this.getRepo()
        .then((repo) =>  repo.search2(
            page - 1,
            size,
            this.transformFilterToServer(filters),
            orders,
            ["Area.Comune", "Area.TipologiaArea"]
        )
    );

    getById = (id) =>
    this.paginate(0, -1, { [`${FILTER_NUMBER}&&&idEvento`]: [id] }).then(
      (res) => {
        res.data.result = res.data.result.map((p) => {
            let mappedArea={};
            if(p.Area){
                Object.keys(p.Area).forEach((k)=>{
                    mappedArea[k]=p.Area[k];
                });
                mappedArea.maps = {
                    latitudine: p.Area.latitudine,
                    longitudine: p.Area.longitudine,
                  };
            }
          return {
              ...p,
              ...mappedArea
          };
        })[0];
        return res;
      }
    );


    tableDefinition = () =>
        this.getRepo().then((repo) => {
        return new Promise((resolve, reject) => {
            let toRet = [];
            repo
            .tabledef()
            .then((res) => {
                if (!res.data.error) {
                toRet = res.data.result.columns.filter(
                    (c) => c.name !== "fkIdArea" && c.name !== "fkIdAreaAssociata"
                );
                }
                return new AreaService().tableDefinition();
            })
            .then((res) => {
                if (!res.data.error) {
                toRet = [
                    ...toRet,
                    ...res.data.result.columns.filter(
                        (c) => c.name !== "idArea" && c.name !== "fkIdTipologiaArea"
                    )
                        .map(col => {
                        if (col.name === "slotTotali" ) {
                            col={...col, customName: "numeroPersone"}
                            return col
                        } else return col
                    })

                ];
                res.data.result.columns = toRet;
                }
                resolve(res);
            });
        });
    });

    create = (obj) => {
        return this.getRepo().then(repo => {
            return repo.exec(CREATE, obj);
        })
    }

    edit = (obj) => {
        return this.getRepo().then(repo => {
            return repo.exec(UPDATE, obj);
        })
    }

    remove = (obj) => {
        return this.getRepo().then(repo => {
            return repo.exec(DELETE, obj);
        });
    }

}
