import BaseServiceTaal from "../../Service/BaseServiceTaal";
import TipologiaVeicolo from "./TipologiaVeicolo.repo";

export default class TipologiaVeicoloService extends BaseServiceTaal {
  getRepo = () => TipologiaVeicolo.getInstanceTaal();

  getTipologiaVeicoloList = () =>
    this.getRepo().then((repo) => repo.search(0, -1));

  getTipologiaVeicoloListPrenotazione = () =>
    this.getRepo().then((repo) => repo.getTipologiaVeicoloListPrenotazione());
}
