import axios from "axios";
import { rxStore } from "../App";
import { startLoading, endLoading } from "../redux/Shared/Shared.actions";
import { triggerLogout } from "../redux/Auth/Auth.actions";
import { BASE_URL_TAAL, REDIRECT_ON_UNAUTHORIZED } from "../constants/Settings";
import FileSaver from "file-saver";
import _ from "lodash";

export default class HttprepoTAAL {
  API = axios.create({
    baseURL: BASE_URL_TAAL,
  });

  constructor() {
    this.API.interceptors.request.use(
      (config) => {
        //to gesture all request
        if (!config.headers) config.headers = {};

        config.headers["Pragma"] = "no-cache";
        config.headers["Cache-Control"] = "no-cache";
        config.headers["Content-Type"] = "application/json";
        if (
          rxStore.getState().Auth.loggedIn &&
          !config.headers["Authorization"]
        ) {
          config.headers["Authorization"] =
            "Bearer " + rxStore.getState().Auth.user.token;
        }
        rxStore.dispatch(startLoading("test"));
        return config;
      },
      (error) => {
        console.log("error in request", error);
        rxStore.dispatch(endLoading());
      }
    );
    this.API.interceptors.response.use(
      (response) => {
        //to gesture all response
        rxStore.dispatch(endLoading());
        return response;
      },
      (error) => {
        const status = error.response.status;
        if (
          REDIRECT_ON_UNAUTHORIZED === "true" &&
          (status === 401 || status === 403)
        ) {
          rxStore.dispatch(triggerLogout());
        }
        rxStore.dispatch(endLoading());
      }
    );
  }

  getTable() {}

  add(newObject, customTable) {
    let params = {};
    if (_.isArray(newObject)) {
      params.entities = [...newObject];
    } else {
      params.entity = { ...newObject };
    }
    return this.API.post(
      `/Generic/add/${customTable || this.getTable()}`,
      params
    );
  }

  del(newObject, customTable) {
    let params = {};
    if (_.isArray(newObject)) {
      params.entities = [...newObject];
    } else {
      params.entity = { ...newObject };
    }
    return this.API.post(
      `/Generic/del/${customTable || this.getTable()}`,
      params
    );
  }

  update(newObject, customTable) {
    let params = {};
    if (_.isArray(newObject)) {
      params.entities = [...newObject];
    } else {
      params.entity = { ...newObject };
    }
    return this.API.post(
      `/Generic/update/${customTable || this.getTable()}`,
      params
    );
  }

  updateflds(newObject, filter) {
    let params = {};
    params.filter = filter;
    if (_.isArray(newObject)) {
      params.entities = [...newObject];
    } else {
      params.entity = { ...newObject };
    }
    return this.API.post(`/Generic/updateflds/${this.getTable()}`, params);
  }

  exec(procName, parameters, pageNum, pageSize, conf, orders) {
    let params = {
      par: parameters,
    };
    if (pageNum) {
      params.pageNum = pageNum;
    } else {
      params.pageNum = 0
    }
    if (pageSize) {
      params.pageSize = pageSize;
    }
    if (orders) {
      params.order = [...orders]
    } else {
      params.order = []
    }
    return this.API.post(`/Generic/exec/${procName}`, params, conf);
  }

  sendMail(procName, parameters, conf) {
    let params = {
      par: parameters,
    };
    return this.API.post(`/Generic/sendmail/${procName}`, params, conf);
  }

  sendSms(procName, parameters, conf) {
    let params = {
      par: parameters,
    };
    return this.API.post(`/Generic/sendsms/${procName}`, params, conf);
  }

  find(id) {
    return this.API.get(`/Generic/find/${this.getTable()}/${id}`);
  }

  tabledef(customTable) {
    return this.API.get(`/Generic/tabledef/${customTable || this.getTable()}`);
  }

  search(pageNum, pageSize, filter, orders, customTable) {
    let params = {
      filter: filter,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    if (orders) params.orders = [...orders];
    return this.API.post(
      `/Generic/search/${customTable || this.getTable()}`,
      params
    );
  }

  search2(pageNum, pageSize, filter, orders, includes, customTable) {
    let params = {
      filter: filter,
      pageNum: pageNum,
      pageSize: pageSize,
      Includes: includes,
    };
    if (orders) params.orders = [...orders];
    return this.API.post(
      `/Generic/search2/${customTable || this.getTable()}`,
      params
    );
  }

  download(url, params, headers, filename) {
    return this.API({
      url: url,
      method: "GET",
      responseType: "blob",
      params: params,
      headers: headers, // important
    }).then((response) => {
      FileSaver.saveAs(new Blob([response.data]), filename);
    });
  }

  downloadBlob(url, params, headers, filename) {
    return this.API({
      url: url,
      method: "GET",
      responseType: "blob",
      params: params,
      headers: headers, // important
    }).then((response) => {
      return response.data;
    });
  }

  upload(url, params, headers, file, fileKey) {
    var formData = new FormData();
    if (params && _.isObject(params)) {
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
    }
    formData.append(fileKey, file);
    return this.API.post(url, formData, {
      headers: _.merge(headers, {
        "Content-Type": "multipart/form-data",
      }),
    });
  }

  static testConnection() {
    rxStore.dispatch(startLoading("test"));
    return axios
      .get(BASE_URL_TAAL + "/Generic/TestAuth", { timeout: 2000 })
      .finally(() => rxStore.dispatch(endLoading()));
  }
}
