import BaseServiceTaal from "../../Service/BaseServiceTaal";
import TurnazioneVarcoRepo from "./TurnazioneVarco.repo";


export default class TurnazioneVarcoService extends BaseServiceTaal {

  getRepo = () => TurnazioneVarcoRepo.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "Utente.TipologiaProfilo",
            "Varco"
        ]
      )
    );

    tableDefinition = () =>
    this.getRepo().then((repo) => {
      return new Promise((resolve, reject) => {
        repo.tabledef().then((res) => {
          if (!res.data.error) {
            res.data.result.columns = res.data.result.columns
            .map((c) => {
              if (c.name === "fkIdUtenteAddetto") c.realName = "fkIdUtente";
             
              return c;
            });
          }
          resolve(res);
        });
      });
    });



}
