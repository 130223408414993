import StubRepo from "../../repos/StubRepo";
import RegistroSanitarioMockRepo from "./repositories/RegistroSanitario.mock";
import RegistroSanitarioHttpRepo from "./repositories/RegistroSanitario.http";

export default class RegistroSanitario extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "RegistroSanitario repo";
  }

  static getLocal() {
    if (RegistroSanitario._localInstance == null) {
      RegistroSanitario._localInstance = new RegistroSanitarioMockRepo();
    }
    return RegistroSanitario._localInstance;
  }

  static getService() {
    if (RegistroSanitario._serviceInstance == null) {
      RegistroSanitario._serviceInstance = new RegistroSanitarioHttpRepo();
    }
    return RegistroSanitario._serviceInstance;
  }
}
