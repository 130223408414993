import BaseServiceTaal from "../../Service/BaseServiceTaal";
import RelTipoAreaTipoPrenotazione from "./RelTipoAreaTipoPrenotazione.repo";

export default class RelTipoAreaTipoPrenotazioneService extends BaseServiceTaal {
  getRepo = () => RelTipoAreaTipoPrenotazione.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["TipologiaPrenotazione", "TipologiaArea"]
      )
    );


    
  getRefFieldMap = ()=>{
    return {
      fkIdTipologiaPrenotazione:'descLocalizzata',
      fkIdTipologiaArea:'descLocalizzata'
    }
  }

  tableDefinition = (isTable) =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          if(isTable){
            res.data.result.columns=res.data.result.columns.filter(c=>c.name.indexOf('fk')<0);
            res.data.result.columns.push(
              { 
                name:'TipologiaArea.descLocalizzata',
                type:'String'
              }
            );
            res.data.result.columns.push(
              { 
                name:'TipologiaPrenotazione.descLocalizzata',
                type:'String'
              }
            );
          }
        }
        return res;
      });
}
