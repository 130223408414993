import BaseServiceTaal from "../../Service/BaseServiceTaal";
import ControlloVarco from "./ControlloVarco.repo";
import {
  FILTER_NUMBER,
  FILTER_TEXT,
} from "../../components/TableServer/FilterItem";

export default class ControlloVarcoService extends BaseServiceTaal {
  getRepo = () => ControlloVarco.getInstanceTaal();

  getVarcoFromId = (id) =>
    this.getRepo().then((repo) => {
      let varco = "";
      let filter = {
        [`${FILTER_NUMBER}&&&fkIdUtenteAddetto`]: [id],
      };
      return repo
        .search2(
          0,
          -1,
          this.transformFilterToServer(filter),
          null,
          ["Varco", "Varco.TipologiaVarco"],
          "turnazioneVarco"
        )
        .then((res) => {
          varco = res.data.result;
          if (varco && varco.length > 0) {
            return {
              type: varco[0].Varco.TipologiaVarco.codiceTipologiaVarco,
              varco,
            };
          } else {
            filter = {
              [`${FILTER_NUMBER}&&&fkIdUtente`]: [id],
            };
            return repo
              .search2(
                0,
                -1,
                this.transformFilterToServer(filter),
                null,
                [],
                "turnazioneParcheggio"
              )
              .then((res) => {
                varco = res.data.result;
                return {
                  type: "P",
                  varco,
                };
              });
          }
        });
    });

  registerTransito = (data, type) => {
    if (type === "VS" || type === "VA") {
      return this.getRepo().then((repo) => {
        return repo.add(data, "RegistroTransitoVarco");
      });
    } else {
      return this.getRepo().then((repo) => {
        return repo.add(data, "RegistroTransitoParcheggio");
      });
    }
  };

  notifyCentraleOperativa = (data) => {
    return this.getRepo().then((repo) => {
      return repo.add(data, "NotificaCentraleOperativa");
    });
  };

  setFlgPrenotazione = (id, flag) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_UpdateStatoPrenotazione", {
        IDPRENOTAZIONE: id,
        CODICESTATOPRENOTAZIONE: flag,
      });
    });
  };
}
