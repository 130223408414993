import StubRepo from "../../repos/StubRepo";
import GestoreAreaMockRepo from "./repositories/AssegnaGestoreArea.mock";
import GestoreAreaHttpRepo from "./repositories/AssegnaGestoreArea.http";

export default class GestoreArea extends StubRepo {
    static _localInstance = null;
    static _serviceInstance = null;

    static getName() {
        return "Comune repo";
    }

    static getLocal() {
        if (GestoreArea._localInstance == null) {
            GestoreArea._localInstance = new GestoreAreaMockRepo();
        }
        return GestoreArea._localInstance;
    }

    static getService() {
        if (GestoreArea._serviceInstance == null) {
            GestoreArea._serviceInstance = new GestoreAreaHttpRepo();
        }
        return GestoreArea._serviceInstance;
    }
}
