import React from "react";
import RxComponent from "../../redux/RxComponent";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "./css/sb-admin-2.css";
import initTheme from "./js/script";
import $ from "jquery";
import { menu } from "../../constants/Menu";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "../../components/Topbar/Navbar";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import Card from "../../components/Content/Card";
import { ROLES } from "../../constants/Roles";
import { rxStore } from "../../App";

class layoutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  menuToRoutes = () => {
    let routes = [];
    if (menu && menu.length) {
      menu.forEach((m) => {
        if (m.layout === "/dashboard") {
          let parent = {
            path: `${m.layout}${m.path}`,
            name: m.name,
            component: m.component,
            roles: m.roles,
            skipMenu: m.skipMenu,
          };
          if (m.children && m.children.length) {
            m.children.forEach((child) => {
              routes.push({
                path: `${m.layout}${m.path}${child.path}`,
                name: child.name,
                component: child.component,
                roles: child.roles,
              });
            });
          } else {
            routes.push(parent);
          }
        }
      });
    }
    return routes;
  };

  componentDidMount() {
    initTheme($);
  }

  render() {
    let routes = this.menuToRoutes();
    return (
      <div id="wrapper" className="App">
        <Sidebar />

        <div
          id="content-wrapper"
          className="content-wrapper d-flex flex-column"
        >
          <div id="content">
            <Navbar />
            <div className="container-fluid" style={{ paddingBottom: 100 }}>
              <Card>
                {routes && routes.length ? (
                  <Switch>
                    {routes
                      .filter((p) => {
                        let ok = true;
                        if (p.roles && p.roles.length) {
                          ok = false;
                          if (
                            rxStore.getState().Auth.user.TipologiaProfilo
                              .codiceProfilo === ROLES.SUPER_ADMIN ||
                            p.roles.indexOf(
                              rxStore.getState().Auth.user.TipologiaProfilo
                                .codiceProfilo
                            ) >= 0
                          ) {
                            ok = true;
                          }
                        }
                        return ok;
                      })
                      .map((prop, key) => {
                        return (
                          <Route
                            path={prop.path}
                            component={prop.component}
                            key={"dashboard-" + key}
                          />
                        );
                      })}
                    <Redirect
                      to={{
                        pathname: "/dashboard/home",
                      }}
                    />
                  </Switch>
                ) : (
                  <p>{this.props.t("Loading")}...</p>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Layout = RxComponent({})(layoutComponent);
export default Layout;
