import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Varco from "./Varco.repo";

export default class VarcoService extends BaseServiceTaal {
  getRepo = () => Varco.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["Comune", "TipologiaVarco"]
      )
    );

  tableDefinition = () =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          res.data.result.columns = res.data.result.columns.filter(
            (c) => c.name !== "formato" && c.name!=='fkIdComune'
          );
        }
        return res;
      });

  setFlgAbilitazioneVarco = (id, flag) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_AbilitazioneVarco", {
        IDVARCO: id,
        FLGABILITATO: flag,
      });
    });
  };

  remove = (id) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_AbilitazioneVarco", {
        IDVARCO: id,
        FLGABILITATO: 0,
      });
    });
  };

  getAssignedArea = filter =>
      this.getRepo()
          .then((repo =>
              repo.search2(0,-1,
                  this.transformFilterToServer(filter),
                  null,
                  ['Area'],
                  'RelVarcoArea'))
          )
}
