import StubRepo from "../../repos/StubRepo";
import RisorsaLogicaMockRepo from "./repositories/RisorsaLogica.mock";
import RisorsaLogicaHttpRepo from "./repositories/RisorsaLogica.http";

export default class RisorsaLogica extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "RisorsaLogica repo";
  }

  static getLocal() {
    if (RisorsaLogica._localInstance == null) {
      RisorsaLogica._localInstance = new RisorsaLogicaMockRepo();
    }
    return RisorsaLogica._localInstance;
  }

  static getService() {
    if (RisorsaLogica._serviceInstance == null) {
      RisorsaLogica._serviceInstance = new RisorsaLogicaHttpRepo();
    }
    return RisorsaLogica._serviceInstance;
  }
}
