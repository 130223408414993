import StubRepo from "../../repos/StubRepo";
import CentraleOperativaMockRepo from "./repositories/CentraleOperativa.mock";
import CentraleOperativaHttpRepo from "./repositories/CentraleOperativa.http";

export default class CentraleOperativaRepo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "CentraleOperativa repo";
  }

  static getLocal() {
    if (CentraleOperativaRepo._localInstance == null) {
      CentraleOperativaRepo._localInstance = new CentraleOperativaMockRepo();
    }
    return CentraleOperativaRepo._localInstance;
  }

  static getService() {
    if (CentraleOperativaRepo._serviceInstance == null) {
      CentraleOperativaRepo._serviceInstance = new CentraleOperativaHttpRepo();
    }
    return CentraleOperativaRepo._serviceInstance;
  }
}
