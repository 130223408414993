import React from 'react';
import QrCode from 'qrcode.react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';

export default class QrCodeViewer extends React.PureComponent{

    state={
        openModal:false,
        code:''
    }

    componentDidUpdate(){
        this.setState({
            openModal:this.props.show,
            code:this.props.code
        })
    }

    closePicker(){
        this.setState({
            code:null
        },()=>{
            if(_.isFunction(this.props.onClose)){
                this.props.onClose();
            }
        })
        
    }

    render(){
        return (
            <Modal
                style={this.props.style}
                isOpen={this.state.openModal}
                toggle={() => this.closePicker()}
                size="md"
            >
                <ModalHeader toggle={() => this.closePicker()}>
                    <h5>{this.state.code}</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        this.state.code && <QrCode renderAs="svg" size={300} value={this.state.code} />
                    }
                    
                </ModalBody>
        </Modal>
        )
    }

}