import React from "react";
import RxComponent from "../../redux/RxComponent";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/bootstrap-datepicker3.standalone.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "./css/responsive-menu.css";
import "./css/custom.css";
import logo from "../../template/dashboard/img/logo-3.png";

import { menu } from "../../constants/Menu";
import { Switch, Route, Redirect } from "react-router-dom";
import { hist } from "../../App";
import NavBarOverlay from "../../components/Topbar/NavBarOverlay";
import LoginRepo from "../../pages/Login/Login.repo.js";

import SettingBannerImage from "../../components/Settings/SettingsBannerImage";

class layoutComponent extends React.Component {
  menuToRoutes = () => {
    let routes = [];
    if (menu && menu.length) {
      menu.forEach((m) => {
        if (m.layout === "/site") {
          let parent = {
            path: `${m.layout}${m.path}`,
            name: m.name,
            component: m.component,
            skipMenu: m.skipMenu,
          };
          if (m.children && m.children.length) {
            m.children.forEach((child) => {
              routes.push({
                path: `${m.layout}${m.path}${child.path}`,
                name: child.name,
                component: child.component,
                skipMenu: child.skipMenu,
              });
            });
          } else {
            routes.push(parent);
          }
        }
      });
    }
    return routes;
  };

  sendMessage(ev) {
    ev.preventDefault();
    //LoginRepo.getService().sendSms(1)
    LoginRepo.getService().sendEmail(1);
  }

  render() {
    let routes = this.menuToRoutes();

    let idComune = null;
    if (hist.location.pathname === "/site/resultSearch") {
      idComune =
        this.props.Prenotazione && this.props.Prenotazione.selectedFields
          ? this.props.Prenotazione.selectedFields.idComune
          : null;
    }

    const classC =
      hist.location.pathname === "/site/home"
        ? "d-flex flex-column flex-md-row align-items-stretch container-site"
        : "d-flex flex-column align-items-stretch container-site mt-90";
    return (
      <>
        <NavBarOverlay logo={logo} menu={routes.filter((m) => !m.skipMenu)} />

        <div className={classC}>
          <SettingBannerImage idComune={idComune}>
            <div className="container-custom d-flex flex-column align-items-center justify-content-center h-100 bg-black-30 p-relative">
              <h4 className="container-title">
                {this.props.t("BOOK_ON_TIME")}
              </h4>
              <h5 className="container-description ml-2 mr-2">
                {this.props.t("CHOISE_MUNICIPALITIES_SECURE_RESERVATION")}
              </h5>
            </div>
          </SettingBannerImage>
          {/*
            <div className="container-store rounded text-white pl-2">
            <button className="container-store-button ios">
              <div>
                <i className="fab fa-apple text-white " style={{ fontSize: "2em" }}></i>
              </div>
              <div>
                <span>AppStore</span>
              </div>
            </button>
            <button className="container-store-button android">
              <div>
                <svg x="0px" y="0px" viewBox="30 336.7 120.9 129.2">
                  <path id="path16" fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7 c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                  <path id="path23" fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3 c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                  <path id="path30" fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1 c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                  <path id="path37" fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6 c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                </svg>
              </div>
              <div>
                <span>Google Play</span>
              </div>
            </button>
          </div>*/}
          <div className="w-100 w-md-50 p-3 bg-grey pl-lg-150 pr-lg-150">
            {routes && routes.length ? (
              <Switch>
                {routes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
              </Switch>
            ) : (
              <p>{this.props.t("Loading")}...</p>
            )}
          </div>
        </div>
      </>
    );
  }
}

const Layout = RxComponent({})(layoutComponent);
export default Layout;
