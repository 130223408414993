import React from "react";
import TipologiaPrenotazioneService from "../../TipologiaPrenotazione/TipologiaPrenotazione.service";
import { Row, FormGroup } from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import TimeRcPicker from "../../../components/Content/TimeRcPicker/TimeRcPicker";
import moment from "moment";
import FasciaOrariaService from "../../FasciaOraria/FasciaOraria.service";
import TimePicker from "rc-time-picker";
import {
  FILTER_MULTIPLE,
  FILTER_NUMBER,
} from "../../../components/TableServer/FilterItem";
import { onChangeFasciaOraria } from "../../../redux/Prenotazione/Prenotazione.actions";
import RxComponent from "../../../redux/RxComponent";

class PrenotazioneTipoPrenotazionep extends React.Component {
  tipoPrenotazioneService = new TipologiaPrenotazioneService();
  fasciaOrariaService = new FasciaOrariaService();
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      listaTipologie: [],
      listaTipologieTot: [],
      listaFasceOrarie: [],
      dataInizio: moment(),
      dataFine: moment(),
      oraInizio: moment(),
      idArea: this.props.idArea,
      data: this.props.data,
      numeroOccupanti: this.props.numeroOccupanti,
      selectedTipologiaPrenotazione: this.props.tipologiaPrenotazione,
      selectedFasciaOraria: this.props.fasciaOraria,
      dataInizioCompleta: this.props.dataInizio
        ? moment(this.props.dataInizio).format("YYYYMMDDHHmm")
        : null,
      dataFineCompleta: null,
      oraInizioTimePckr:
        this.props.fasciaOraria && this.props.fasciaOraria.length
          ? this.props.fasciaOraria[0].orarioInizio
          : null,
      oraFineTimePckr:
        this.props.fasciaOraria && this.props.fasciaOraria.length
          ? this.props.fasciaOraria[0].orarioFine
          : null,
      valueTme: this.props.dataInizio
        ? moment(this.props.dataInizio)
        : moment(),
      form_key: 0,
    };
  }

  /*componentDidMount2() {
    this.props.onRef(this);
    if (!this.state.idArea || !this.state.data || !this.state.numeroOccupanti)
      return;
    let filts = {
      idArea: this.state.idArea,
      data: this.state.data,
      numeroOccupanti: this.state.numeroOccupanti,
    };
    let idList = [];
    let listDef;
    this.tipoPrenotazioneService
      .searchTipologiaPrenotazione(filts)
      .then((res) => {
        if (res.data.result) {
          idList = res.data.result.map((el) => el.IDTIPOLOGIAPRENOTAZIONE);
          idList = idList.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });

          listDef = res.data.result.map((rec) => {});

          return res.data.result;
        }
      })
      .then((listaTipologieTot) => {
        let filtro = {
          [`${FILTER_MULTIPLE}&&&idTipologiaPrenotazione`]: idList,
        };
        this.tipoPrenotazioneService.paginate(1, -1, filtro).then((elem) => {
          this.setState({
            listaTipologie: elem.data.result,
            listaTipologieTot: listaTipologieTot,
          });
        });
        return true;
      })
      .then(() => {
        if (this.state.selectedTipologiaPrenotazione) {
          this.populateFasceOrarie(this.state.selectedTipologiaPrenotazione);
        }
      });
  }*/

  componentDidUpdate() {}

  componentDidMount() {
    this.props.onRef(this);
    if (!this.state.idArea || !this.state.data || !this.state.numeroOccupanti)
      return;
    let filts = {
      idArea: this.state.idArea,
      data: this.state.data,
      numeroOccupanti: this.state.numeroOccupanti,
    };
    let idList = [];
    let listDef = [];
    this.tipoPrenotazioneService
      .searchTipologiaPrenotazione(filts)
      .then((res) => {
        if (res.data.result) {
          let resFromsearch = res.data.result;
          idList = res.data.result.map((el) => el.IDTIPOLOGIAPRENOTAZIONE);
          idList = idList.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });

          idList.forEach((el) => {
            let elToPush = {
              idTipologiaPrenotazione: el,
              descLocalizzata: null,
              codiceTipologiaPrenotazione: null,
              listaFasceOrarie: [],
            };
            let temp = resFromsearch.filter(
              (obj) => obj.IDTIPOLOGIAPRENOTAZIONE === el
            );
            temp.forEach((x) => {
              elToPush.listaFasceOrarie.push({
                idFasciaOraria: x.IDFASCIAORARIA,
                descLocalizzata: x.DESCLOCFASCIA,
              });
            });
            listDef.push(elToPush);
          });

          return res.data.result;
        }
      })
      .then((listaTipologieTot) => {
        this.tipoPrenotazioneService
          .getTipologiaPrenotazioneNewApi(idList)
          .then((elem) => {
            listDef.forEach((x) => {
              elem.data.result.forEach((a) => {
                if (a.idTipologiaPrenotazione === x.idTipologiaPrenotazione) {
                  x.descLocalizzata = a.descLocalizzata;
                  x.codiceTipologiaPrenotazione = a.codiceTipologiaPrenotazione;
                }
              });
            });
          })
          .then(() => {
            this.setState({
              listaTipologie: listDef,
            });
          })
          .then(() => {
            if (this.state.selectedTipologiaPrenotazione) {
              this.populateFasceOrarie(
                this.state.selectedTipologiaPrenotazione
              );
            }
          });
      });
  }

  _formVar(unit) {
    return (
      <>
        <FormGroup className="col-12 col-md-4">
          <TimeRcPicker
            value={this.state.oraInizio}
            label={this.props.t("ORA_DI_INIZIO_PRENOTAZIONE")}
            onChange={(oo) => this.setDatesVar(oo, this.state.elapse, unit)}
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-4">
          <label>
            {this.props.t("DURATA_PRENOTAZIONE")} ({unit})
          </label>
          <input
            value={this.state.elapse}
            className="form-control"
            min="0"
            type="number"
            step="1"
            onChange={(ev) => {
              const val = ev.target.valueAsNumber;
              this.setDatesVar(this.state.oraInizio, val, unit);
            }}
          />
        </FormGroup>
      </>
    );
  }

  setDatesVar(orainizio, elapse, unit) {
    //const val=ev.target.valueAsNumber;
    const fdatei = this.state.dataInizio.format("DD/MM/YYYY");
    const forai = orainizio.format("HH:mm");
    const df = moment(`${fdatei} ${forai}`, "DD/MM/YYYY HH:mm").add(
      elapse,
      unit
    );
    this.setState({ oraInizio: orainizio, dataFine: df, elapse: elapse });
  }

  populateFasceOrarie(ev) {
    let listaFasc = ev[0].listaFasceOrarie ? ev[0].listaFasceOrarie : [];
    if (!listaFasc) {
      listaFasc = [this.props.fasciaOraria];
    }
    this.setState({
      selectedTipologiaPrenotazione: ev[0],
      listaFasceOrarie: listaFasc,
    });
    if (!listaFasc.length) {
      this.setState({
        showFasciaOraria: this.props.fasciaOraria[0].descLocalizzata,
      });
    }
    if (ev[0].listaFasceOrarie) {
      this.resetForm();
    }
  }

  resetForm() {
    let newKey = this.state.form_key;
    newKey += 1;
    this.setState({ form_key: newKey, selectedFasciaOraria: null });
  }

  manageFasciaOraria = (ev) => {
    let idFascia = ev[0].idFasciaOraria;
    this.fasciaOrariaService.getById(idFascia).then((res) => {
      let dataInizio = moment(this.state.data).format("YYYYMMDD");
      let OraInizio = moment(res.data.result.orarioInizio, "HHmm").format(
        "HHmm"
      );
      let OraFine = moment(res.data.result.orarioFine, "HHmm").format("HHmm");
      let dataInizioCompleta = dataInizio + OraInizio;
      let dataFineCompleta = dataInizio + OraFine;
      let dat = moment(dataInizioCompleta, "YYYYMMDDHHmm");
      this.setState({
        dataInizioCompleta: dataInizioCompleta,
        dataFineCompleta: dataFineCompleta,
        selectedFasciaOraria: ev[0],
        valueTme: dat,
        oraInizioTimePckr: OraInizio,
        oraFineTimePckr: OraFine,
      });
      this.props.onChangeFasciaOraria(idFascia);
    });
  };

  validate() {
    let errors = [];
    if (!this.state.selectedTipologiaPrenotazione) {
      errors.push(
        this.props.t("field") +
          this.props.t("TIPOLOGIA_PRENOTAZIONE") +
          this.props.t("isNotValid")
      );
    }
    if (!this.state.selectedFasciaOraria) {
      errors.push(
        this.props.t("field") +
          this.props.t("FASCIA_ORARIA") +
          this.props.t("isNotValid")
      );
    }
    if (!this.state.dataInizioCompleta) {
      errors.push(
        this.props.t("field") +
          this.props.t("dataInizioCompleta") +
          this.props.t("isNotValid")
      );
    }
    if (!this.state.dataFineCompleta) {
      errors.push(
        this.props.t("field") +
          this.props.t("dataFineCompleta") +
          this.props.t("isNotValid")
      );
    }
    return errors;
  }
  validateUpd() {
    let errors = [];
    if (!this.state.dataInizioCompleta) {
      errors.push(
        this.props.t("field") +
          this.props.t("ORARIO_ARRIVO") +
          this.props.t("isNotValid")
      );
    }
    return errors;
  }

  getData() {
    let obToReturn = {
      selectedTipologiaPrenotazione: this.state.selectedTipologiaPrenotazione,
      selectedFasciaOraria: this.state.selectedFasciaOraria,
      dataInizioCompleta: this.state.dataInizioCompleta,
      dataFineCompleta: this.state.dataFineCompleta,
    };
    return obToReturn;
  }

  handleTime(ev) {
    if (ev) {
      let OraInizio = moment(ev).format("HHmm");
      let or = moment(this.state.dataInizioCompleta, "YYYYMMDDHHmm")
        .hour(moment(ev).format("HH"))
        .minutes(moment(ev).format("mm"));

      this.setState({
        valueTme: ev,
        dataInizioCompleta: or.format("YYYYMMDDHHmm"),
      });
    }
  }

  render() {
    return (
      <Row>
        <FormGroup className="col-12 col-md-4">
          <label htmlFor="tipologiaPrenotazione">
            {this.props.t("TIPOLOGIA_PRENOTAZIONE")}*
          </label>
          <SelectPicker
            values={this.state.selectedTipologiaPrenotazione}
            options={this.state.listaTipologie}
            displayValue={"descLocalizzata"}
            onChange={(ev) => {
              this.populateFasceOrarie(ev);
            }}
            readOnly={this.props.isEdit || this.props.readOnly}
          />
        </FormGroup>
        {this.state.listaFasceOrarie.length > 0 && (
          <FormGroup key={this.state.form_key} className="col-12 col-md-4">
            <label htmlFor="fasciaOraria">
              {this.props.t("FASCIA_ORARIA")}*
            </label>
            <SelectPicker
              values={this.state.selectedFasciaOraria}
              options={this.state.listaFasceOrarie}
              displayValue={"descLocalizzata"}
              onChange={(ev) => this.manageFasciaOraria(ev)}
              readOnly={this.props.isEdit || this.props.readOnly}
            />
          </FormGroup>
        )}
        {this.state.showFasciaOraria && (
          <FormGroup key={this.state.form_key} className="col-12 col-md-4">
            <label htmlFor="fasciaOraria">
              {this.props.t("FASCIA_ORARIA")}*
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "hsl(0,0%,95%)",
                borderColor: "hsl(0,0%,90%)",
                borderRadius: "4px",
                borderStyle: "solid",
                borderWidth: "1px",
                minHeight: "38px",
                outline: "0 !important",
                position: "relative",
                transition: "all 100ms",
                boxSizing: "border-box",
                padding: "2px 8px",
              }}
            >
              <input
                style={{ border: "none", background: "none", color: "#999999", overflow:"hidden" }}
                value={this.props.t(this.state.showFasciaOraria)}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "hsl(0,0%,90%)",
                    width: "1px",
                    boxSizing: "border-box",
                    marginRight: "10px",
                    minHeight: "20px",
                  }}
                ></div>
                <i
                  style={{
                    color: "#cccccc",
                    fontSize: "14px",
                    padding: "0 3px",
                  }}
                  className="fas fa-chevron-down"
                ></i>
              </div>
            </div>
          </FormGroup>
        )}
        {this.state.dataInizioCompleta && (
          <FormGroup
            className="col-12 col-md-4"
            hidden={!this.state.selectedFasciaOraria}
          >
            <TimeRcPicker
              label={this.props.t("ORARIO_ARRIVO")}
              required
              value={this.state.valueTme}
              onChange={this.handleTime.bind(this)}
              minTime={this.state.oraInizioTimePckr}
              maxTime={this.state.oraFineTimePckr}
              readOnly={this.props.isAusiliareTraffico || this.props.readOnly}
              allowEmpty={false}
            />
          </FormGroup>
        )}
      </Row>
    );
  }
}

const PrenotazioneTipoPrenotazione = RxComponent({ onChangeFasciaOraria })(
  PrenotazioneTipoPrenotazionep
);
export default PrenotazioneTipoPrenotazione;
