import BaseServiceTaal from "../../Service/BaseServiceTaal";
import CentraleOperativaRepo from "./CentraleOperativa.repo";

export default class CentraleOperativaService extends BaseServiceTaal {
  getRepo = () => CentraleOperativaRepo.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) => {
      return repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "Prenotazione",
          "Varco.Comune",
          "StatoNotifica",
          "Prenotazione.Utente.AnagraficaUtente_List",
          "Prenotazione.Utente.AnagraficaUtente_List.Documento",
        ]
      );
    });

  getStatisticByDateAndComune = (data, comune) =>
    CentraleOperativaRepo.getInstanceTaal().then((repo) =>
      repo.getStatisticByDateAndComune(data, comune)
    );

  changeStatusNotifica = (idNotifica, codiceStato) => {
    this.getRepo().then((repo) => {
      return repo.exec("sp_UpdateStatoNotifica", {
        IDNOTIFICACENTRALEOPERATIVA: idNotifica,
        CODICESTATONOTIFICA: codiceStato,
      });
    });
  };
}
