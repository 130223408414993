import React from 'react';
import { Card as BCard,CardHeader,CardBody,CardFooter } from 'reactstrap';

export default class Card extends React.Component{


    componentDidMount(){
    }

    render(){
        return (
            <BCard style={this.props.style}>
                {this.props.header &&
                    <CardHeader>
                        {this.props.header}
                    </CardHeader>
                }
                <CardBody>
                    {this.props.children}
                </CardBody>
                {this.props.footer &&
                    <CardFooter>
                        {this.props.footer}
                    </CardFooter>
                }
            </BCard>
        );
    }

}
