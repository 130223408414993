import React from "react";
import Switch from "react-switch";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";

import TurnazioneVarcoService from "./TurnazioneVarco.service";
import RxComponent from "../../redux/RxComponent";
import { onSuccess, onError } from "../../redux/Shared/Shared.actions";
import moment from 'moment-timezone';
import { FILTER_NUMBER, FILTER_TEXT_EQUALS } from "../../components/TableServer/FilterItem";
import { ROLES } from "../../constants/Roles";

class Page extends React.Component {
 
  serviceV = new TurnazioneVarcoService();
  columns = [
    {
      label: "Varco",
      data: "Varco.descrizione",
    },
    {
        label: "Utente",
        data: "Utente.username",
    },
    {
        label: "DataInizio",
        data: "dataInizio",
        type:'custom',
        render: (celldata) => moment(celldata).format('DD/MM/YYYY HH:mm')
    },
    {
        label: "DataFine",
        data: "dataFine",
        type:'custom',
        render: (celldata) => moment(celldata).format('DD/MM/YYYY HH:mm')
    }
  ];

  filters = {
   
  };

  
  render() {
    return (
      <CrudNetTable
        title={this.props.t("TurnazioneVarco")}
        refFieldMap={{
          fkIdUtenteAddetto: "username",
        }}
        defaultFilters={{
            [`${FILTER_NUMBER}&&&Varco.fkIdComune`]: [this.props.Auth.user.fkIdComune]
        }}
        lookupFilters={{
            Varco: {
              [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.Auth.user.fkIdComune],
            },
            Utente: {
                [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.Auth.user.fkIdComune],
                [`${FILTER_TEXT_EQUALS}&&&TipologiaProfilo.codiceProfilo`]: [ROLES.ADDETTO_SICUREZZA],
            },
          }}
        columns={this.columns}
        filters={this.filters}
        service={this.serviceV}
        ref="crudNetVarco"
      >
      </CrudNetTable>
    );
  }
}

const TurnazioneVarcoPage = RxComponent({ onSuccess, onError })(Page);
export default TurnazioneVarcoPage;
