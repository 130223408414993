import React from "react";
import RxComponent from "../../../redux/RxComponent";
import { triggerOtp, triggerSendOtp } from "../../../redux/Auth/Auth.actions";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
    };
  }

  submitOTP(ev) {
    ev.preventDefault();
    this.props.triggerOtp(
      this.props.Auth.user.idUtente,
      this.state.otp,
      this.props.Auth.user.token
    );
  }

  resendOTP(ev) {
    ev.preventDefault();
    this.props.triggerSendOtp(
      this.props.Auth.user.idUtente,
      this.props.Auth.user.username,
      this.props.Auth.user.token
    );
  }

  templateHTML() {
    return (
      <React.Fragment>
        <div className="bg-gradient">
          <div className="Login-wrapper fadeInDown">
            <div className="Login-form-Content">
              <div className="fadeIn first">
                <h1 className="Login-form--title">OTP</h1>
              </div>
              <div className="fadeIn second">
                <i className="Login-form--title scalex100 fa fa-mobile"></i>
                <h5 className="mx-3">{this.props.t("EnterCodeMobile")}</h5>
              </div>

              <form
                onSubmit={this.submitOTP.bind(this)}
                className="fadeIn third"
              >
                {/*<div className="digit-group">
                                    <input type="text" id="digit-1" name="digit-1"/>
                                    <input type="text" id="digit-2" name="digit-2"/>
                                    <input type="text" id="digit-3" name="digit-3"/>
                                    <span className="splitter">&ndash;</span>
                                    <input type="text" id="digit-4" name="digit-4"/>
                                    <input type="text" id="digit-5" name="digit-5"/>
                                    <input type="text" id="digit-6" name="digit-6"/>
                                </div>*/}
                <input
                  type="number"
                  size="7"
                  id="otp"
                  className="form--input fadeIn"
                  onChange={(ev) => this.setState({ otp: ev.target.value })}
                  name="otp"
                />
                <input
                  type="submit"
                  className="fadeIn fourth"
                  value={this.props.t("Send")}
                />
              </form>
              <div className="Login-form-Footer">
                <a
                  className="Login-form--underlineHover"
                  href="#"
                  onClick={this.resendOTP.bind(this)}
                >
                  {this.props.t("RequestNewCode")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.templateHTML();
  }
}

const RegisterOTP = RxComponent({ triggerOtp, triggerSendOtp })(Component);
export default RegisterOTP;
