import React from "react";
import RxComponent from "../../redux/RxComponent";
import { Trans } from "react-i18next";
import placeholder from "../../template/dashboard/img/userph.jpg";
import { triggerLogout } from "../../redux/Auth/Auth.actions";

export class Comp extends React.Component {
  getInitials = (name) => {
    var canvas = document.createElement("canvas");
    canvas.style.display = "none";
    canvas.width = "32";
    canvas.height = "32";
    document.body.appendChild(canvas);
    var context = canvas.getContext("2d");
    context.fillStyle = "#01b8e9";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "16px Arial";
    context.fillStyle = "#fff";
    var first, last;
    if (name && name.first && name.first != "") {
      first = name.first[0];
      last = name.last && name.last != "" ? name.last[0] : null;
      if (last) {
        var initials = first + last;
        context.fillText(initials.toUpperCase(), 3, 23);
      } else {
        var initials = first;
        context.fillText(initials.toUpperCase(), 10, 23);
      }
      var data = canvas.toDataURL();
      document.body.removeChild(canvas);
      return data;
    } else {
      return false;
    }
  };

  logout() {
    this.props.triggerLogout();
  }

  render() {
    return (
      <li className="nav-item dropdown no-arrow">
        <a
          className="nav-link dropdown-toggle"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="mr-2 d-none d-lg-inline text-gray-600 small">
            {this.props.Auth.user && this.props.Auth.user.username}
          </span>
          <img className="img-profile rounded-circle" src={placeholder} />
        </a>

        <div
          className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          {/*
                    <a className="dropdown-item" >
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      <Trans i18nKey="Profile" />
                    </a>
                    <a className="dropdown-item" >
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      <Trans i18nKey="Settings" />
                    </a>
                    <a className="dropdown-item" >
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      <Trans i18nKey="Activity Log" />
                    </a>
                    <div className="dropdown-divider"></div>
                  */}

          <a
            className="dropdown-item"
            data-toggle="modal"
            data-target="#logoutModal"
            onClick={this.logout.bind(this)}
          >
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            <Trans i18nKey="Logout" />
          </a>
        </div>
      </li>
    );
  }
}
const Userinfo = RxComponent({ triggerLogout })(Comp);
export default Userinfo;
