import React from "react";
import RxComponent from "../../redux/RxComponent";
import "../../template/dashboard/css/custom.css";
import {
  triggerChangePassword,
  triggerPasswordRecovery,
  triggerChangePasswordSafe,
} from "../../redux/Auth/Auth.actions";
import { SUCCESS_PASSWORD_RECOVERY } from "../../redux/Auth/Auth.types";
import { onError } from "../../redux/Shared/Shared.actions";
//import { onLoginTaal } from "../../redux/Auth/Auth.actions";

class Component extends React.PureComponent {
  validPasswordRegExp = new RegExp(
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/
  );

  state = {
    submitted: false,
    username: null,
    password: "",
    isValidPwd: null,
    cntrlPassword: "",
    otp: null,
    smsSented: false,
    noMatch: false,
  };

  handlePasswordRecovery = false;
  handlePasswordChange = false;

  componentDidMount() {
    const safe =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.safe
        ? this.props.history.location.state.safe
        : null;
    this.setState({
      isSafe: safe,
    });
  }

  submitForm(ev) {
    ev.preventDefault();

    if (this.state.smsSented) {
      if (!this.state.isValidPwd) {
        return this.props.onError("PASSWORD_NOT_VALID");
      }
      if (
        this.state.password &&
        this.state.password.length &&
        this.state.password === this.state.cntrlPassword
      ) {
        if (this.state.otp && this.state.otp.length) {
          //exec
          this.handlePasswordChange = true;
          if (
            this.props.Auth.user &&
            this.props.Auth.user.idUtente &&
            this.props.Auth.user.token &&
            !this.props.Auth.user.FLGPASSWORDSAFE
          ) {
            this.props.triggerChangePasswordSafe(
              this.state.username,
              this.state.password,
              this.state.otp
            );
          } else {
            this.props.triggerChangePassword(
              this.state.username,
              this.state.password,
              this.state.otp
            );
          }
        } else {
          this.props.onError("OTP_MANDATORY");
        }
      } else {
        this.props.onError("PasswordDontMatch");
      }
    } else {
      if (this.state.username) {
        //this.props.sendSms(this.state.username);
        this.handlePasswordRecovery = true;
        this.props.triggerPasswordRecovery(this.state.username);
      } else {
        this.props.onError("USERNAME_MANDATORY");
      }
    }
  }

  componentDidUpdate() {
    if (
      this.props.Auth.type === SUCCESS_PASSWORD_RECOVERY &&
      this.handlePasswordRecovery
    ) {
      this.handlePasswordRecovery = false;
      this.setState({
        smsSented: true,
      });
    }
  }

  templateHTML() {
    return (
      <React.Fragment>
        <div className="bg-gradient">
          <div className="Login-wrapper fadeInDown">
            <div className="Login-form-Content">
              <div className="fadeIn first">
                <h1 className="Login-form--title">
                  {this.props.t("RESETPASSWORD")}
                </h1>
                {this.state.isSafe && (
                  <p>
                    Attenzione! Abbiamo introdotto un nuovo algoritmo di
                    cifratura della password ed è dunque necessario che
                    modifichiate la vostra secondo i nuovi parametri di
                    sicurezza.
                  </p>
                )}
              </div>
              <div className="fadeIn" hidden={!this.state.smsSented}>
                <i className="Login-form--title scalex100 fas fa-envelope"></i>
                <h5 className="mx-3">{this.props.t("SentedCodeEmail")}</h5>
              </div>
              <form onSubmit={this.submitForm.bind(this)}>
                {!this.state.smsSented ? (
                  <>
                    <div className="form-input-label-cont py-0">
                      <label>{this.props.t("NumberPhone")}</label>
                    </div>
                    <input
                      type="number"
                      id="username"
                      required
                      onChange={(ev) =>
                        this.setState({ username: ev.target.value })
                      }
                      className="form--input fadeIn second inputPhonNumber"
                      name="username"
                    />
                    <div
                      className="form-input-label-cont py-0 error-text"
                      hidden={!(this.state.submitted && !this.state.username)}
                    >
                      <label>{this.props.t("FieldRequired")}</label>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-input-label-cont py-0">
                      <label>{this.props.t("NEWPASSWORD")}</label>
                    </div>
                    <input
                      type="password"
                      id="password"
                      required
                      value={this.state.password}
                      onChange={(ev) => {
                        return this.setState({
                          isValidPwd: this.validPasswordRegExp.test(
                            ev.target.value
                          ),
                          password: ev.target.value,
                        });
                      }}
                      onBlur={() =>
                        this.state.password === this.state.cntrlPassword
                          ? this.setState({ noMatch: false })
                          : this.state.cntrlPassword !== ""
                          ? this.setState({ noMatch: false })
                          : this.setState({ noMatch: true })
                      }
                      className="form--input fadeIn"
                      name="psw"
                    />
                    <div
                      className={`form-input-label-cont py-0 ${
                        !this.state.isValidPwd && this.state.isValidPwd !== null
                          ? "error-text"
                          : ""
                      }`}
                    >
                      <p className="small">
                        {this.props.t("PASSWORD_PATTERN")}
                      </p>
                    </div>{" "}
                    <div
                      className="form-input-label-cont py-0 error-text"
                      hidden={!(this.state.submitted && !this.state.password)}
                    >
                      <label>{this.props.t("FieldRequired")}</label>
                    </div>
                    <div className="form-input-label-cont py-0">
                      <label>{this.props.t("ControllPassword")}</label>
                    </div>
                    <input
                      type="password"
                      id="cntrlPassword"
                      required
                      onChange={(ev) =>
                        this.setState({ cntrlPassword: ev.target.value })
                      }
                      onBlur={() =>
                        this.state.password === this.state.cntrlPassword
                          ? this.setState({ noMatch: false })
                          : this.state.password !== ""
                          ? this.setState({ noMatch: false })
                          : this.setState({ noMatch: true })
                      }
                      className="form--input fadeIn"
                      name="psw"
                    />
                    <div
                      className="form-input-label-cont py-0 error-text"
                      hidden={
                        !(this.state.submitted && !this.state.cntrlPassword)
                      }
                    >
                      <label>{this.props.t("FieldRequired")}</label>
                    </div>
                    <div
                      className="form-input-label-cont py-0 error-text"
                      hidden={!this.state.noMatch}
                    >
                      <label>{this.props.t("PasswordDontMatch")}</label>
                    </div>
                    <div className="form-input-label-cont py-0">
                      <label>OTP</label>
                    </div>
                    <input
                      type="text"
                      id="otp"
                      required
                      onChange={(ev) => this.setState({ otp: ev.target.value })}
                      className="form--input fadeIn"
                      name="otp"
                    />
                    <div
                      className="form-input-label-cont py-0 error-text"
                      hidden={!(this.state.submitted && !this.state.otp)}
                    >
                      <label>{this.props.t("FieldRequired")}</label>
                    </div>
                  </>
                )}
                <input
                  type="submit"
                  className="form--button-cyan fadeIn fourth"
                  value={this.props.t("Send")}
                />
              </form>

              <div className="Login-form-Footer">
                {this.state.smsSented ? (
                  <a className="Login-form--underlineHover" href="/">
                    {this.props.t("RequestNewCode")}
                  </a>
                ) : this.state.isSafe ? null : (
                  <a className="Login-form--underlineHover" href="/login">
                    {this.props.t("Login")}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return this.templateHTML();
  }
}

const PasswordRecover = RxComponent({
  triggerChangePassword,
  triggerPasswordRecovery,
  onError,
  triggerChangePasswordSafe,
})(Component);
export default PasswordRecover;
