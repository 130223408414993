import React from "react";
import Card from "../components/Content/Card";
import {
  FIELD_TEXT,
  FIELD_DATE,
  FIELD_SELECT,
} from "../components/DynamicForm/DynamicFormField";
import MonthlyScheduler from "../components/Schedulers/MonthlyScheduler";
import GoogleProfile from "../components/Login/GoogleProfile";
import RxComponent from "../redux/RxComponent";

class Comp extends React.Component {
  fields = [
    {
      id: "nome",
      label: "Nome",
      required: true,
      type: FIELD_TEXT,
      precision: 20,
    },
    {
      id: "data",
      label: "Data",
      required: true,
      type: FIELD_DATE,
    },
    {
      id: "list",
      label: "List",
      required: true,
      type: FIELD_SELECT,
      multiple: true,
      value: [
        {
          id: 1,
          code: "pippo",
          description: "Pippo",
        },
      ],
      options: [
        {
          id: 1,
          code: "pippo",
          description: "Pippo",
        },
        {
          id: 2,
          code: "pluto",
          description: "Pluto",
        },
      ],
    },
  ];

  render() {
    return (
      <React.Fragment>
        <h1 className="h3 mb-4 text-gray-800">{this.props.t("Welcome")}:<br />{this.props.Auth.user && this.props.Auth.user.username}</h1>
      </React.Fragment>
    );
  }
}

export const Home = RxComponent()(Comp);