import { rxStore } from '../App';
import { startLoading,endLoading } from '../redux/Shared/Shared.actions';

export default class LocalStorageRepo {

    

    getList(...p) { };


    get(...key) {
        rxStore.dispatch(startLoading('test'));
        return new Promise((resolve, reject) => {
            try {
                setTimeout(()=>{
                    rxStore.dispatch(endLoading());
                    resolve(this.getList(...key));
                },1000);
            } catch (error) {
                reject(error.toString());
            }
        });

    }

    insert(key, value) {
        return new Promise((resolve, reject) => {
            try {
                resolve(true);
            } catch (error) {
                reject(error.toString());
            }
        });
    }

    update(key, value) {
        return new Promise((resolve, reject) => {
            try {
                resolve(true);
            } catch (error) {
                reject(error.toString());
            }
        });

    }

    delete(key) {
        return new Promise((resolve, reject) => {
            try {
                resolve(true);
            } catch (error) {
                reject(error.toString());
            }
        });
    }


}
