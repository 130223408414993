import React from "react";
import RxComponent from "../../redux/RxComponent";
import { ENABLE_SECURITY } from "../../constants/Settings";

class Comp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let match = true;
    if(!this.props.Auth.loggedIn || !this.props.Auth.user) return null

    if (this.props.roles && this.props.roles.length) {
      match = false;
      for (let i in this.props.roles) {
        if (
          this.props.Auth.user.TipologiaProfilo.codiceProfilo === "SA" ||
          this.props.roles[i] ===
            this.props.Auth.user.TipologiaProfilo.codiceProfilo
        ) {
          match = true;
        }
      }
    }

    //this.props.Auth.loggedIn && match da inserire dopo l'inserimento dei ruoli
    if (ENABLE_SECURITY && match) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

const HasRole = RxComponent()(Comp);
export default HasRole;
