import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Area from "./Area.repo";

export default class AreaService extends BaseServiceTaal {
  getRepo = () => Area.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["Comune", "TipologiaArea"]
      )
    );

  getAreaDetailPrenotazione = (idArea) =>
    this.getRepo().then((repo) => repo.getAreaDetailPrenotazione(idArea));

  tableDefinition = () =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          res.data.result.columns = res.data.result.columns.filter(
            (c) =>
              c.name !== "formato" &&
              c.name !== "longitudine" &&
              c.name !== "latitudine" &&
              c.name !== "fkIdComune" &&
              c.name !== "fotoOriginale"
          );
        }
        res.data.result.columns.push({ name: "maps", type: "Maps" });
        return res;
      });

  setFlgAbilitazioneArea = (id, flag) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_AbilitazioneArea", {
        IDAREA: id,
        FLGABILITATO: flag,
      });
    });
  };

  remove = (id) => {
    return this.getRepo().then((repo) => {
      return repo.exec("sp_AbilitazioneArea", {
        IDAREA: id,
        FLGABILITATO: 0,
      });
    });
  };

  getById = (id) =>
    this.getRepo().then((repo) =>
      repo.find(id).then((res) => {
        res.data.result.maps = {
          latitudine: res.data.result.latitudine,
          longitudine: res.data.result.longitudine,
        };
        return res;
      })
    );

  relAreaParcheggio = (data) =>
    this.getRepo().then((repo) => repo.add(data, "RELAREAPARCHEGGIO"));

  clearRealAreaParcheggio = (data) =>
    this.getRepo().then((repo) => repo.exec("sp_ClearParcheggiArea", data));

  deleteRelAreaParcheggio = (data) =>
    this.getRepo().then((repo) => repo.del(data, "RELAREAPARCHEGGIO"));

  relAreaFasciaOraria = (data) =>
    this.getRepo().then((repo) => repo.add(data, "relAreaFasciaOraria"));

  clearRealAreaFasciaOraria = (data) =>
    this.getRepo().then((repo) => repo.exec("sp_ClearFasceArea", data));

  deleteRelAreaFasciaOraria = (data) =>
    this.getRepo().then((repo) => repo.del(data, "relAreaFasciaOraria"));

  relVarcoArea = (data) =>
    this.getRepo().then((repo) => repo.add(data, "relVarcoArea"));

  clearRealVarcoArea = (data) =>
    this.getRepo().then((repo) => repo.exec("sp_ClearVarcoArea", data));

  deleteRelVarcoArea = (data) =>
    this.getRepo().then((repo) => repo.del(data, "relVarcoArea"));

  getAssignedParcheggi = (filter) =>
    this.getRepo().then((repo) =>
      repo.search2(
        0,
        -1,
        this.transformFilterToServer(filter),
        null,
        ["Parcheggio"],
        "RELAREAPARCHEGGIO"
      )
    );

  getAssignedFasciaOraria = (filter) =>
    this.getRepo().then((repo) =>
      repo.search2(
        0,
        -1,
        this.transformFilterToServer(filter),
        null,
        ["FasciaOraria"],
        "relAreaFasciaOraria"
      )
    );

  getAssignedVarco = (filter) =>
    this.getRepo().then((repo) =>
      repo.search2(
        0,
        -1,
        this.transformFilterToServer(filter),
        null,
        ["Varco"],
        "RelVarcoArea"
      )
    );

  searchAreeComune = (formField) =>
    this.getRepo().then((repo) => repo.exec("fn_GetAreeComune", formField));

  closeAreaFromVarco = (par) => {
    return this.getRepo().then((repo) =>
      repo.exec("sp_UpdateStatoChiusura", par)
    );
  };
}
