import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Parcheggio from "./Parcheggio.repo";

export default class ParcheggioService extends BaseServiceTaal {

    getRepo = () => Parcheggio.getInstanceTaal();

    getParcheggioPrenotazione = (idArea, data, fascia) => this.getRepo().then(
        (repo) => repo.getParcheggiPrenotazione(idArea, data, fascia)
    )

    paginate = (page, size, filters, orders) =>  this.getRepo()
    .then((repo) =>  repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["Comune"]
      )
    );

    tableDefinition = () =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          res.data.result.columns = res.data.result.columns.filter(
            (c) =>  c.name!=='fkIdComune'
          );
        }
        return res;
      });

    setFlgAbilitazioneParcheggio = (id, flag) => {
        return this.getRepo().then((repo) => {
            return repo.exec("sp_AbilitazioneParcheggio", {
                IDPARCHEGGIO: id,
                FLGABILITATO: flag,
            });
        });
    };

    remove = (id) => {
        return this.getRepo().then((repo) => {
            return repo.exec("sp_AbilitazioneParcheggio", {
                IDPARCHEGGIO: id,
                FLGABILITATO: 0,
            });
        });
    };

}
