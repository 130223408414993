import StubRepo from "../../repos/StubRepo";
import ComuneMockRepo from "./repositories/Comune.mock";
import ComuneHttpRepo from "./repositories/Comune.http";

export default class Comune extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Comune repo";
  }

  static getLocal() {
    if (Comune._localInstance == null) {
      Comune._localInstance = new ComuneMockRepo();
    }
    return Comune._localInstance;
  }

  static getService() {
    if (Comune._serviceInstance == null) {
      Comune._serviceInstance = new ComuneHttpRepo();
    }
    return Comune._serviceInstance;
  }
}
