import { AREE } from "./Aree";
import pinRistoranteGiallo from "../template/site/pngs/mapsPins/pinRistoranteGiallo.png";
import pinRistoranteRosso from "../template/site/pngs/mapsPins/pinRistoranteRosso.png";
import pinRistoranteVerde from "../template/site/pngs/mapsPins/pinRistoranteVerde.png";
import pinSpiaggiaLiberaRosso from "../template/site/pngs/mapsPins/pinSpiaggiaLiberaRosso.png";
import pinSpiaggiaLiberaGiallo from "../template/site/pngs/mapsPins/pinSpiaggiaLiberaGiallo.png";
import pinSpiaggiaLiberaVerde from "../template/site/pngs/mapsPins/pinSpiaggiaLiberaVerde.png";
import pinSpiaggiaPrivataRosso from "../template/site/pngs/mapsPins/pinSpiaggiaPrivataRosso.png";
import pinSpiaggiaPrivataGiallo from "../template/site/pngs/mapsPins/pinSpiaggiaPrivataGiallo.png";
import pinSpiaggiaPrivataVerde from "../template/site/pngs/mapsPins/pinSpiaggiaPrivataVerde.png";
import pinEventoRosso from "../template/site/pngs/mapsPins/pinEventoRosso.png";
import pinEventGiallo from "../template/site/pngs/mapsPins/pinEventoGiallo.png";
import pinEventoVerde from "../template/site/pngs/mapsPins/pinEventoVerde.png";

export const PINS = {
  SPIAGGIA_LIBERA_ROSSO: {
    pin: pinSpiaggiaLiberaRosso,
    areaCod: AREE.SPIAGGIA_LIBERA,
    stato: -1,
  },
  SPIAGGIA_LIBERA_GIALLO: {
    pin: pinSpiaggiaLiberaGiallo,
    areaCod: AREE.SPIAGGIA_LIBERA,
    stato: 0,
  },
  SPIAGGIA_LIBERA_VERDE: {
    pin: pinSpiaggiaLiberaVerde,
    areaCod: AREE.SPIAGGIA_LIBERA,
    stato: 1,
  },
  SPIAGGIA_ATTREZZATA_ROSSO: {
    pin: pinSpiaggiaPrivataRosso,
    areaCod: AREE.SPIAGGIA_ATTREZZATA,
    stato: -1,
  },
  SPIAGGIA_ATTREZZATA_GIALLO: {
    pin: pinSpiaggiaPrivataGiallo,
    areaCod: AREE.SPIAGGIA_ATTREZZATA,
    stato: 0,
  },
  SPIAGGIA_ATTREZZATA_VERDE: {
    pin: pinSpiaggiaPrivataVerde,
    areaCod: AREE.SPIAGGIA_ATTREZZATA,
    stato: 1,
  },
  RISTORANTE_ROSSO: {
    pin: pinRistoranteRosso,
    areaCod: AREE.RISTORANTE,
    stato: -1,
  },
  RISTORANTE_GIALLO: {
    pin: pinRistoranteGiallo,
    areaCod: AREE.RISTORANTE,
    stato: 0,
  },
  RISTORANTE_VERDE: {
    pin: pinRistoranteVerde,
    areaCod: AREE.RISTORANTE,
    stato: 1,
  },
  EVENTO_ROSSO: {
    pin: pinEventoRosso,
    areaCod: AREE.EVENTO,
    stato: -1,
  },
  EVENTO_GIALLO: {
    pin: pinEventGiallo,
    areaCod: AREE.EVENTO,
    stato: 0,
  },
  EVENTO_VERDE: {
    pin: pinEventoVerde,
    areaCod: AREE.EVENTO,
    stato: 1,
  },
};
