import React from "react";
import Comune from "../../modules/Comune/Comune.repo";
import _ from "lodash";

export default class SettingsBlock extends React.PureComponent {
  state = {
    permittedSections: [],
  };

  componentDidMount() {
    Comune.getService()
      .find(this.props.idComune)
      .then((res) => {
        if (res.data.result.settings) {
          const settings = JSON.parse(res.data.result.settings);
          if (_.isObject(settings) && _.isObject(settings.blocks)) {
            this.setState({
              permittedSections: settings.blocks[this.props.block],
            });
          }
        }
      });
  }

  render() {
    return React.Children.map(this.props.children, (child) => {
      if (child && child.props.section) {
        if (this.state.permittedSections.indexOf(child.props.section) >= 0)
          return child;
        return null;
      }
      return child;
    });
  }
}
