import React from "react";
import DynamicFormField, {
  FIELD_DATE,
  FIELD_BOOLEAN,
  FIELD_MAPS,
} from "./DynamicFormField";
import _ from "lodash";
import moment from "moment";
import { Translation } from "react-i18next";

export default class DynamicForm extends React.PureComponent {
  state = {
    fields: [],
  };

  componentDidMount() {
    this.setState({
      fields: this.props.fields,
    });
  }

  /*componentDidUpdate(){
        this.setState({
            fields:this.props.fields
        });
    }*/

  _onSubmit = (e) => {
    e.preventDefault();
    if (this.props.onSubmit && _.isFunction(this.props.onSubmit)) {
      this.props.onSubmit(this._validate());
    }
  };

  _validate = () => {
    return this.state.fields
      .filter((f) => !f.hideMe || !f.hideMe(this.state.fields))
      .map((f) => {
        let ret = {
          ...f,
          error: false,
          validationsMessage: [],
        };

        if (f.required) {
          if (f.type === FIELD_DATE && !moment(f.value).isValid()) {
            ret.error = true;
            ret.validationsMessage.push(
              "Il campo " + f.label + " è obbigatorio"
            );
          } else if (f.type === FIELD_BOOLEAN) {
            if (_.isNull(f.value) || _.isUndefined(f.value)) {
              ret.error = true;
              ret.validationsMessage.push(
                "Il campo " + f.label + " è obbigatorio"
              );
            }
          } else if (f.type === FIELD_MAPS) {
            if (
              _.isNull(f.value) ||
              _.isUndefined(f.value) ||
              _.isNull(f.value.latitudine) ||
              _.isUndefined(f.value.latitudine) ||
              f.value.latitudine < 0 ||
              _.isNull(f.value.longitudine) ||
              _.isUndefined(f.value.longitudine) ||
              f.value.longitudine < 0
            ) {
              ret.error = true;
              ret.validationsMessage.push(
                "Latitudine e Longitudine sono campi obbligatori"
              );
            }
          } else if (!f.value || !f.value.toString().length) {
            ret.error = true;
            ret.validationsMessage.push(
              "Il campo " + f.label + " è obbigatorio"
            );
          }
        }
        if (
          f.precision &&
          f.precision > 0 &&
          f.value &&
          f.value.length > f.precision
        ) {
          ret.error = true;
          ret.validationsMessage.push(
            "Il campo " +
              f.label +
              " deve essere lungo massimo " +
              f.precision +
              " caratteri"
          );
        }

        return ret;
      });
  };

  _setValToState = (field, newVal) => {
    let newState = this.state.fields.map((f) => {
      if (f.id === field.id) f.value = newVal;

      return f;
    });
    this.setState({
      fields: newState,
    });
  };

  render() {
    return (
      <form className="row" onSubmit={this._onSubmit}>
        {this.state.fields &&
          this.state.fields
            .filter((f) => !f.hideMe || !f.hideMe(this.state.fields))
            .map((f) => (
              <DynamicFormField
                key={f.id}
                {...f}
                onChange={(newVal) => this._setValToState(f, newVal)}
              />
            ))}
        {!this.props.readOnly && (
          <div className="col-12 d-flex justify-content-end mt-2">
            <Translation>
              {(t) => (
                <button
                  type="submit"
                  className="btn btn-dark ml-1"
                  aria-label={t("Save")}
                >
                  <i className="far fa-save"></i>
                  {t("Save")}
                </button>
              )}
            </Translation>
          </div>
        )}
      </form>
    );
  }
}
