import React from "react";
import BaseServiceTaal from "../../Service/BaseServiceTaal";
import {
  FIELD_TEXT,
  FIELD_INTEGER,
  FIELD_DECIMAL,
  FIELD_DATE,
  FIELD_SELECT,
  FIELD_BOOLEAN,
  FIELD_SELECT_MULTIPLE,
  FIELD_FILE,
  FIELD_MAPS,
  FIELD_PASSWORD,
} from "../DynamicForm/DynamicFormField";
import DynamicForm from "../DynamicForm/DynamicForm";
import _ from "lodash";
import moment from "moment-timezone";
import { onSuccess, onError } from "../../redux/Shared/Shared.actions";
import { rxStore } from "../../App";
import { Trans } from "react-i18next";

export default class CrudNetForm extends React.PureComponent {
  state = {
    loading: true,
    fields: null,
    externalFields: null,
    service: new BaseServiceTaal(),
  };

  tableName = "";

  componentDidMount() {
    this._init();
  }

  componentDidUpdate() {
    if (
      this.props.viewMode !== this.state.viewMode ||
      JSON.stringify(this.props.defaultValues) !==
        JSON.stringify(this.state.defaultValues)
    )
      this._init();
  }

  _init() {
    this.setState(
      {
        service: this.props.service,
        loading: true,
        viewMode: this.props.viewMode,
        externalFields: this.props.externalFields,
        defaultValues: this.props.defaultValues,
      },
      () => {
        this.getCurrentTableName()
          .then((tableName) => {
            this.tableName = tableName;
            console.log(
              "initializing crud form of " +
                tableName +
                " in " +
                this.state.viewMode
            );
            return this._getFormFields();
          })
          .then((formFields) => this._loadOptions(formFields))
          .then((formFields) => this._selectValueDefault(formFields))
          .then((formFields) => this._selectValueEdit(formFields))
          .then((formFields) => {
            this.setState({
              fields: formFields,
              loading: false,
            });
          });
      }
    );
  }

  _getFormFields() {
    let fieldToHide = null;
    if (this.props.hideField) {
      fieldToHide = this.props.hideField[this.props.viewMode];
    }
    return this.state.service.tableDefinition(fieldToHide).then((res) => {
      let formField = [];
      if (!res.data.error) {
        formField = res.data.result.columns
          .filter(
            (col) =>
              col.type.indexOf("Boolean") >= 0 ||
              col.type.indexOf("String") >= 0 ||
              col.type.indexOf("Int") >= 0 ||
              col.type.indexOf("Decimal") >= 0 ||
              col.type.indexOf("Date") >= 0 ||
              col.type.indexOf("Byte") >= 0 ||
              col.type.indexOf("Maps") >= 0 ||
              col.name.indexOf("fk") >= 0 ||
              col.type.indexOf("Password") >= 0
          )
          .map((col) => {
            let type = FIELD_TEXT;
            if (col.name.indexOf("fk") >= 0) {
              type = FIELD_SELECT;
            } else if (col.type.indexOf("Int") >= 0) {
              type = FIELD_INTEGER;
            } else if (col.type.indexOf("Boolean") >= 0) {
              type = FIELD_BOOLEAN;
            } else if (col.type.indexOf("Decimal") >= 0) {
              type = FIELD_DECIMAL;
            } else if (col.type.indexOf("Date") >= 0) {
              type = FIELD_DATE;
            } else if (col.type.indexOf("Byte") >= 0) {
              type = FIELD_FILE;
            } else if (col.type === "ICollection") {
              type = FIELD_SELECT_MULTIPLE;
            } else if (col.type === "Maps") {
              type = FIELD_MAPS;
            } else if (col.type === "Password") {
              type = FIELD_PASSWORD;
            }

            return {
              type: type,
              id: col.name,
              realId: col.realName,
              customName: col.customName,
              label: this.camelCaseToString(col.name),
              required: col.required,
              precision: col.precision,
              readOnly: this.props.viewMode === "view",
              multiple: col.type.indexOf("ICollection") >= 0,
              hideMe: col.hideMe
                ? (fd) =>
                    col.hideMe(
                      this.stateToObject(fd),
                      fd,
                      this.state.externalFields
                    )
                : null,
            };
          })
          .filter((c) => this.tableName !== this.getTableNameFromField(c.id));
      }
      return _.orderBy(formField, "required", "desc");
    });
  }

  _loadLookup = (tableName) => {
    let filter = null;

    if (this.props.lookupFilters && this.props.lookupFilters[tableName]) {
      filter = this.state.service.transformFilterToServer(
        this.props.lookupFilters[tableName]
      );
    }
    return this.state.service
      .getRepo()
      .then((repo) => repo.search(0, -1, filter, null, tableName))
      .then((res) => res.data.result);
  };

  _loadOptions = async (formFields) => {
    for (let i = 0; i < formFields.length; i++) {
      if (
        formFields[i].type === FIELD_SELECT ||
        formFields[i].type === FIELD_SELECT_MULTIPLE
      ) {
        formFields[i].options = await this._loadLookup(
          this.getTableNameFromField(formFields[i].realId || formFields[i].id)
        );
        formFields[i].displayValue = null;
        if (this.props.refFieldMap) {
          formFields[i].displayValue = this.props.refFieldMap[formFields[i].id];
        }
        if (_.isFunction(this.state.service.getRefFieldMap)) {
          formFields[i].displayValue = this.state.service.getRefFieldMap()[
            formFields[i].id
          ];
        }
      }
    }
    return formFields;
  };

  _selectValueEdit = (formFields) => {
    if (this.props.viewMode === "edit" || this.props.viewMode === "view") {
      return this.state.service
        .getById(this.props["id" + this.tableName])
        .then((res) => {
          if (!res.data.error) {
            const selected = res.data.result;
            const formFieldsWithVal = formFields.map((field) => {
              if (field.type === FIELD_SELECT) {
                field.value = field.options.filter((op) => {
                  return (
                    op[
                      "id" +
                        this.getTableNameFromField(field.realId || field.id)
                    ] === selected[field.id]
                  );
                });
              } else if (field.type === FIELD_DATE) {
                const mD = moment(selected[field.id]);
                if (mD.isValid()) field.value = mD.toDate();
              } else {
                field.value = selected[field.realId || field.id];
              }

              return field;
            });

            return formFieldsWithVal;
          }
        });
    }
    return Promise.resolve(formFields);
  };

  _selectValueDefault = (formFields) => {
    if (
      this.props.viewMode === "insert" &&
      _.isObject(this.state.defaultValues)
    ) {
      let formFieldsWithVal = [...formFields];
      Object.keys(this.state.defaultValues).forEach((key) => {
        formFieldsWithVal = formFieldsWithVal.map((field) => {
          if (field.id === key) {
            if (field.type === FIELD_SELECT) {
              field.value = field.options.filter((op) => {
                return (
                  op[
                    "id" + this.getTableNameFromField(field.realId || field.id)
                  ] === this.state.defaultValues[key]
                );
              });
            } else {
              field.value = this.state.defaultValues[key];
            }
          }
          return field;
        });
      });
      return formFieldsWithVal;
    }
    return Promise.resolve(formFields);
  };

  camelCaseToString(s) {
    return _.capitalize(s.split(/(?=[A-Z])/).join(" "));
  }

  getTableNameFromField(field) {
    let f = field.replace("fk", "");
    if (f.indexOf("id") === 0 || f.indexOf("Id") === 0) {
      f = f.replace("id", "").replace("Id", "");
    }
    return f[0].toUpperCase() + f.slice(1);
  }

  getCurrentTableName = () =>
    this.state.service.getRepo().then((repo) => repo.getTable());

  onSubmit = (formData) => {
    let promise = null;
    if (this.props.onSubmit && _.isFunction(this.props.onSubmit)) {
      this.validateLookups(formData)
        .then(() => {
          //this.closeMe();
          return this.props.onSubmit(
            this.stateToObject(formData),
            this.props.viewMode,
            this.closeMe.bind(this)
          );
        })
        .catch((ex) => {
          rxStore.dispatch(onError(ex));
        });
      return;
    } else if (this.props.viewMode === "insert") {
      promise = this.validateLookups(formData).then(() =>
        this.state.service.create(this.stateToObject(formData))
      );
    } else if (this.props.viewMode === "edit") {
      promise = this.validateLookups(formData).then(() =>
        this.state.service.edit(this.stateToObject(formData))
      );
    } else {
      promise = this.state.service.remove(this.stateToObject(formData));
    }
    promise
      .then((resp) => {
        rxStore.dispatch(onSuccess("SuccessedOperation"));
        this.closeMe();
      })
      .catch((ex) => {
        rxStore.dispatch(onError(ex));
      });
  };

  validateLookups(formData) {
    return new Promise((resolve, reject) => {
      if (this.props.viewMode === "delete") {
        resolve();
      } else {
        let errors = formData
          .filter((fd) => fd.error)
          .map((fd) => [...fd.validationsMessage]);
        if (errors.length) {
          reject(
            <React.Fragment>
              <h6>Validation errors</h6>
              <ul>
                {errors.map((error, index) => (
                  <li key={`${error[0].split(" ").join("_")}_${index}}`}>
                    {error[0]}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          );
        }
        resolve();
      }
    });
  }

  stateToObject(formData) {
    let ret = {};

    if (this.props.viewMode === "delete") {
      ret["id" + this.tableName] = this.props["id" + this.tableName];
      return ret;
    }

    if (this.props.viewMode === "edit")
      ret["id" + this.tableName] = this.props["id" + this.tableName];

    formData.forEach((fd) => {
      if (fd.type === FIELD_DATE) {
        if (moment(fd.value).isValid())
          ret[fd.id] = moment(fd.value).format("YYYY-MM-DDTHH:mm:ssZ");
      } else if (fd.type === FIELD_SELECT) {
        const fieldName = "id" + this.getTableNameFromField(fd.realId || fd.id);
        if (fd.value && fd.value.length) {
          ret[fd.id] = fd.value.map((op) => op[fieldName])[0];
        }
      } else if (fd.type === FIELD_SELECT_MULTIPLE) {
        const fieldName = "id" + this.getTableNameFromField(fd.realId || fd.id);
        if (fd.value && fd.value.length) {
          let list = "";
          fd.value.forEach((el) => {
            let str =
              el === fd.value[fd.value.length - 1]
                ? el[fieldName]
                : el[fieldName] + ",";
            list = list + str;
          });
          ret[fd.id] = list;
        }
      } else if (fd.type === FIELD_INTEGER) {
        if (fd.value) ret[fd.id] = parseInt(fd.value);
      } else if (fd.type === FIELD_DECIMAL) {
        if (fd.value) ret[fd.id] = parseFloat(fd.value);
      } else if (fd.type === FIELD_FILE) {
        ret[fd.id] = fd.value;
      } else if (fd.type === FIELD_MAPS) {
        if(fd.value) {
          fd.value.latitudine = parseFloat(fd.value.latitudine);
          fd.value.longitudine = parseFloat(fd.value.longitudine);
          ret[fd.id] = fd.value;
        }
      } else {
        ret[fd.id] = fd.value;
      }
    });
    return ret;
  }

  closeMe() {
    if (this.props.closeModal && _.isFunction(this.props.closeModal)) {
      this.props.closeModal();
    }
  }

  deleteContent() {
    return (
      <React.Fragment>
        <Trans i18nKey="AreYouSureRemoveElement" />
        <div className="col-12 d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-dark ml-1"
            aria-label="Salva"
            onClick={() => this.onSubmit(this.state.fields)}
          >
            <i className="fas fa-trash-alt"></i>
            <Trans i18nKey="Confirm" />
          </button>
          {
            //<button onClick={this.closeMe.bind(this)} type="button" className="btn btn-dark" aria-label="Chiudi ...">Chiudi</button>
          }
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.loading)
      return (
        <p>
          <Trans i18nKey="Loading" />
          ...
        </p>
      );

    if (this.props.viewMode === "delete") return this.deleteContent();
    return (
      this.state.fields && (
          <>
            <DynamicForm
              readOnly={this.props.viewMode === "view"}
              fields={this.state.fields}
              onSubmit={this.onSubmit.bind(this)}
            />
            <span>
              <Trans i18nKey="THESE_FIELDS_ARE_REQUIRED"/>
            </span>
        </>
      )
    );
  }
}
