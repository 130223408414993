import pinParcheggioRosso from "../template/site/pngs/mapsPins/pinParcheggioRosso.png";
import pinParcheggioGiallo from "../template/site/pngs/mapsPins/pinParcheggioGiallo.png";
import pinParcheggioVerde from "../template/site/pngs/mapsPins/pinParcheggioVerde.png";
//const PARCHEGGIO =

export const PINSPARK = {
  PARCHEGGIO_ROSSO: {
    pin: pinParcheggioRosso,
    stato: -1,
  },
  PARCHEGGIO_GIALLO: {
    pin: pinParcheggioGiallo,
    stato: 0,
  },
  PARCHEGGIO_VERDE: {
    pin: pinParcheggioVerde,
    stato: 1,
  },
};
