import React from "react";
import RxComponent from "../../../redux/RxComponent";
import {FILTER_NUMBER} from "../../../components/TableServer/FilterItem";
import "bootstrap/dist/css/bootstrap.min.css";
import AreaService from "../Area.service";
import placehlder from "../../../template/site/img/placeholder.png";
import {BASE_URL_TAAL} from "../../../constants/Settings";

class AreaDetailsC extends React.Component {
    state = {
        areaService: new AreaService(),
        areaToShow: null,
        idAreaPassed: this.props.idArea || 0,
        loading: true,
        imgUrl: null,
    };

    componentDidMount() {
        this.state.areaService
            .getAreaDetailPrenotazione(this.state.idAreaPassed)
            .then((res) => {
                let area = res.data.result[0];
                let imgUrlToSet = null;
                if (area.formato) {
                    const splitted = area.formato.split("/");
                    imgUrlToSet =
                        BASE_URL_TAAL +
                        "/Generic/image/v_Area/fotoOriginale/" +
                        area.idArea +
                        "/" +
                        splitted[1] +
                        "?date=" +
                        new Date().getTime();
                }
                this.setState({
                    areaToShow: area,
                    imgUrl: imgUrlToSet,
                    loading: !this.state.loading,
                });
            });
    }

    render() {
        if (this.state.loading) {
            return <p>{this.props.t("Loading")}...</p>;
        }
        return (
            <React.Fragment>
                <div className="row d-flex justify content-center">
                    <div className="col-12">
                        <h3 className="text-left">
                            {this.props.t(this.state.areaToShow.descrizione)}{" "}
                            <div className="float-right">
                <span className="badge badge-secondary">
                {this.props.t(
                    this.state.areaToShow.TipologiaArea.descLocalizzata
                )}
              </span>
              {
                  this.state.areaToShow.latitudine && this.state.areaToShow.longitudine
                  ?<a className="btn btn-warning btn-sm ml-2" target="_blank"
                  href={`https://maps.google.com/?q=${this.state.areaToShow.latitudine},${this.state.areaToShow.longitudine}&z=11`}>
                   <i className="fas fa-map-marked-alt mr-2"></i>
                   Google Maps
               </a>:<a disabled className="btn btn-warning btn-sm ml-2" >
                   <i className="fas fa-map-marked-alt mr-2"></i>
                   Google Maps not Avaiable
               </a>
              }
                                {this.state.areaToShow.flgAccessoDisabili && (
                                    <i className="fas fa-wheelchair m-2"></i>
                                )}
                                {this.state.areaToShow.flgAnimaliAmmessi && (
                                    <i className="fas fa-dog m-2"></i>
                                )}
                            </div>
                        </h3>
                    </div>
                    <div className="col-12">
                        <img
                            src={this.state.imgUrl ? this.state.imgUrl : placehlder}
                            style={{width: "100%", display: "block", margin: "auto"}}
                            className="img-fluid"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const AreaDetails = RxComponent()(AreaDetailsC);
export default AreaDetails;

/*

<img
              src={
                this.state.areaToShow.foto
                  ? "data:" +
                    (this.state.areaToShow.formato || "image/jpeg") +
                    ";base64," +
                    this.state.areaToShow.foto
                  : placehlder
              }
              style={{ width: "100%", display: "block", margin: "auto" }}
              className="img-fluid"
            /> */
