import React from "react";
import Switch from "react-switch";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";

import VarcoService from "./Varco.service";
import RxComponent from "../../redux/RxComponent";
import { onSuccess, onError } from "../../redux/Shared/Shared.actions";
import VarcoCRUD from "./Varco.crud";
import {FILTER_NUMBER} from "../../components/TableServer/FilterItem";

class Page extends React.Component {
  state = {
    checked: false,
  };
  serviceV = new VarcoService();
  columns = [
    {
      label: this.props.t("Description"),
      data: "descrizione",
    },
    {
      label: this.props.t("DoorType"),
      data: "TipologiaVarco.descLocalizzata",
    },
    {
      label: this.props.t("Municipality"),
      data: "Comune.descrizione",
    },
    {
      label: this.props.t("Latitude"),
      data: "latitudine",
    },
    {
      label: this.props.t("Longitude"),
      data: "longitudine",
    },
    {
      label: this.props.t("State"),
      type: "custom",
      render: (celldata, row) => {
        return (
          <React.Fragment>
            <Switch
              onChange={() => this.handleSwitch(row)}
              checked={row.flgAttivo}
            />
          </React.Fragment>
        );
      },
    },
  ];

  filters = {
    [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.Auth.user.fkIdComune],
  };

  handleSwitch = (row) => {
    let objToPass = {
      idVarco: row.idVarco,
      isActive: !row.flgAttivo,
    };

    this.onSubmit(objToPass, "setFlgAttivo");
  };

  onSubmit(data, viewMode) {
    if (data.foto && data.foto.length) {
      const splitted = data.foto.split(";base64,");
      data.formato = splitted[0].split("data:")[1];
      data.foto = splitted[1];
    }
    let promise = null;
    if (viewMode === "insert") {
      promise = this.serviceV.create(data);
    } else if (viewMode === "edit") {
      promise = this.serviceV.edit(data);
    } else if (viewMode === "setFlgAttivo") {
      promise = this.serviceV.setFlgAbilitazioneVarco(
        data.idVarco,
        data.isActive
      );
    } else {
      data.flgAttivo = false;
      //promise = this.serviceV.edit(data); // chiamare edit e dire data.isDeleted true
      promise = this.serviceV.setFlgAbilitazioneVarco(data.idVarco, false);
    }
    promise
      .then((resp) => {
        this.props.onSuccess("SuccessedOperation");
        this.refs.crudNetVarco.refreshTable();
      })
      .catch((ex) => {
        this.props.onError(ex);
      });
  }

  render() {
    return (
      <CrudNetTable
        title={this.props.t("Door")}
        refFieldMap={{
          fkIdTipologiaVarco: "descLocalizzata",
        }}
        columns={this.columns}
        filters={this.filters}
        defaultFilters={this.filters}
        service={this.serviceV}
        onSubmit={this.onSubmit.bind(this)}
        actions={["edit"]}
        ref="crudNetVarco"
      >
        <VarcoCRUD/>
      </CrudNetTable>
    );
  }
}

const VarcoPage = RxComponent({ onSuccess, onError })(Page);
export default VarcoPage;
