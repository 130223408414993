import React from "react";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";
import RxComponent from "../../redux/RxComponent";
import AreaService from "./Area.service";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import AreaCRUD from "./Area.crud";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import Switch from "react-switch";
import GestoreAreaService from "../AssegnaGestoreArea/AssegnaGestoreArea.service";
import { ROLES } from "../../constants/Roles";

class Page extends React.Component {
  areaService = new AreaService();

  columns = [
    {
      label: this.props.t("Description"),
      data: "descrizione",
    },
    {
      label: this.props.t("Municipality"),
      data: "Comune.descrizione",
    },
    {
      label: this.props.t("Tipology"),
      data: "TipologiaArea.descLocalizzata",
    },
    {
      label: this.props.t("Latitude"),
      data: "latitudine",
    },
    {
      label: this.props.t("Longitude"),
      data: "longitudine",
    },
    {
      label: "Servizi",
      data: "idArea",
      type: "custom",
      render: (cellData, row) => {
        return (
          <>
            {row.flgAccessoDisabili && <i className="fas fa-wheelchair"></i>}
            {row.flgAnimaliAmmessi && <i className="fas fa-dog"></i>}
          </>
        );
      },
    },
    {
      label: this.props.t("State"),
      type: "custom",
      render: (celldata, row) => {
        return (
          <React.Fragment>
            <Switch
              onChange={() => this.handleSwitch(row)}
              checked={row.flgAttivo}
            />
          </React.Fragment>
        );
      },
    },
  ];

  filters = [];

  handleSwitch = (row) => {
    let objToPass = {
      idArea: row.idArea,
      isActive: !row.flgAttivo,
    };

    this.areaService
      .setFlgAbilitazioneArea(objToPass.idArea, objToPass.isActive)
      .then((resp) => {
        this.props.onSuccess("SuccessedOperation");
        this.refs.crudNetArea.refreshTable();
      })
      .catch((ex) => {
        this.props.onError(ex);
      });
  };

  getDataIfGestore(pageNum, itemsPerPage, ff, sort) {
    //this.props.Auth.user.TipologiaProfilo.codiceProfilo
    //this.props.Auth.user.idUtente
    const filter = {
      [`${FILTER_NUMBER}&&&fkIdUtente`]: [this.props.Auth.user.idUtente],
    };
    const gestoreService = new GestoreAreaService();
    return gestoreService
      .paginate(pageNum, itemsPerPage, filter, sort)
      .then((res) => {
        res.data.rowCount = res.data.rowCount || res.data.result.length;
        res.data.result = res.data.result.map((res) => res.Area);
        return res;
      });
  }

  render() {
    return (
      <CrudNetTable
        customHeader={
          this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
          ROLES.GESTORE_AREA
            ? () => <h2>{this.props.t("Area")}</h2>
            : null
        }
        getData={
          this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
          ROLES.GESTORE_AREA
            ? this.getDataIfGestore.bind(this)
            : null
        }
        title={"Area"}
        refFieldMap={{ fkIdTipologiaArea: "descLocalizzata" }}
        columns={this.columns}
        filters={this.filters}
        defaultFilters={
            this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
            ROLES.GESTORE_AREA
                ? {
                  [`notIn&&&fkIdTipologiaArea`]: [9],
                }
                : {
                  [`${FILTER_NUMBER}&&&fkIdComune`]: [
                    this.props.Auth.user.fkIdComune,
                  ],
                  [`notIn&&&fkIdTipologiaArea`]: [9]
                }
        }
        service={new AreaService()}
        actions={["edit"]}
        ref="crudNetArea"
      >
        <AreaCRUD />
      </CrudNetTable>
    );
  }
}

const AreaPage = RxComponent({ onSuccess, onError })(Page);
export default AreaPage;
