import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_PRENOTAZIONE_BY_PNR = "generic/search3/prenotazione/pnr";
export default class PrenotazioneHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Prenotazione";
  }

  getPrenotazionePnrNewApi(pnr) {
    return this.API.post(GET_PRENOTAZIONE_BY_PNR, { pnr: pnr });
  }
}
