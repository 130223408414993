import React from "react";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import UtenteService from "./Utente.service";
import RxComponent from "../../redux/RxComponent";
import { onSuccess, onError } from "../../redux/Shared/Shared.actions";
import moment from "moment";
import Switch from "react-switch";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import { Trans } from "react-i18next";
import {
  FILTER_NUMBER,
  FILTER_TEXT,
} from "../../components/TableServer/FilterItem";

class Page extends React.Component {
  validPasswordRegExp = new RegExp(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/
  );
  state = {
    showModalChangePassword: false,
    selectedIdUtente: null,
    isValidPwd: null,
    password: "",
    cntrlPassword: "",
  };

  service = new UtenteService();

  columns = [
    {
      label: this.props.t("Username"),
      data: "username",
    },
    {
      label: this.props.t("Profile"),
      data: "TipologiaProfilo.descLocalizzata",
    },
    {
      label: this.props.t("Municipality"),
      data: "Comune.descrizione",
    },
    {
      label: this.props.t("Language"),
      data: "Lingua.descLocalizzata",
    },
    {
      label: this.props.t("State"),
      type: "custom",
      render: (celldata, row) => {
        return (
          <React.Fragment>
            <Switch
              onChange={() => this.handleSwitch(row)}
              checked={row.flgAttivo}
            />
          </React.Fragment>
        );
      },
    },
    {
      label: this.props.t("CHANGE_PASSWORD"),
      type: "custom",
      render: (celldata, row) => {
        return (
          <React.Fragment>
            <Button
              outline
              color="danger"
              className="border-0"
              size="sm"
              onClick={() => {
                this.setState({
                  showModalChangePassword: true,
                  selectedIdUtente: row.idUtente,
                });
              }}
            >
              <i className="fas fa-lock"></i>
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  filters = {
    [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.Auth.user.fkIdComune],
  };

  handleChangePassword() {
    if (
      this.state.password &&
      this.state.cntrlPassword &&
      this.state.selectedIdUtente &&
      this.state.password === this.state.cntrlPassword
    ) {
      if (!this.state.isValidPwd) {
        return this.props.onError("PASSWORD_NOT_VALID");
      }
      this.service.changePassword(this.state.selectedIdUtente, this.state.cntrlPassword).then(res => {
        if(res.data.message === "Ok"){
          this.props.onSucces("SuccessedOperation")
        }
      });
      this.closeModalChangePassword();
    } else {
      this.setState({ notMatchingPassword: true });
    }
  }

  lookupFilter = {
    [`${FILTER_TEXT}&&&codiceProfilo`]: ["SA"],
  };

  onSubmit(formData, viewMode) {
    let promise = null;
    formData.idComune = this.props.Auth.user.Comune.idComune;

    if (viewMode === "insert") {
      let objToPass = {
        USERNAME: formData.username,
        PASSWORD: formData.password,
        IDTIPOLOGIAPROFILO: formData.fkIdTipologiaProfilo,
        IDLINGUA: formData.fkIdLingua,
        IDCOMUNE: formData.idComune,
      };

      promise = this.service.create(objToPass);
    } else if (viewMode === "edit") {
      let objToPass = {
        IDUTENTE: formData.idUtente,
        USERNAME: formData.username,
        PASSWORD: formData.password,
        IDTIPOLOGIAPROFILO: formData.fkIdTipologiaProfilo,
        IDLINGUA: formData.fkIdLingua,
        FLAGATTIVO: formData.flgAttivo,
        FLAGELIMINATO: formData.flgEliminato,
      };
      promise = this.service.edit(objToPass);
    } else {
      promise = this.service.remove(formData);
    }
    promise
      .then((resp) => {
        this.props.onSuccess("SuccessedOperation");
      })
      .catch((ex) => {
        this.props.onError(ex);
      });
  }

  handleSwitch = (row) => {
    let utente = {
      IDUTENTE: row.idUtente,
      USERNAME: row.username,
      PASSWORD: row.password,
      IDCOMUNE: row.fkIdComune,
      IDTIPOLOGIAPROFILO: row.fkIdTipologiaProfilo,
      IDLINGUA: row.fkIdLingua,
      FLAGATTIVO: !row.flgAttivo,
      FLAGELIMINATO: !row.flgEliminato,
    };

    this.service
      .setFlgAbilitazioneUtente(utente)
      .then((resp) => {
        this.props.onSuccess("SuccessedOperation");
        this.refs.crudNetUser.refreshTable();
      })
      .catch((ex) => {
        this.props.onError(ex);
      });
  };

  closeModalChangePassword() {
    this.setState(
      {
        showModalChangePassword: false,
        selectedIdUtente: null,
        notMatchingPassword: false,
      },
      () => {
        this.refs.crudNetUser.refreshTable();
      }
    );
  }

  render() {
    return (
      <>
        <CrudNetTable
          lookupFilters={{
            TipologiaProfilo: {
              [`${FILTER_NUMBER}&&&flgComunale`]: ["true"],
            },
          }}
          hideField={{
            edit:'password'
          }}
          title={this.props.t("locUser")}
          refFieldMap={{
            fkIdTipologiaProfilo: "descLocalizzata",
            fkIdLingua: "descLocalizzata",
          }}
          columns={this.columns}
          filters={this.filters}
          defaultFilters={this.filters}
          service={this.service}
          onSubmit={this.onSubmit.bind(this)}
          actions={["edit"]}
          ref="crudNetUser"
        />
        <Modal
          isOpen={this.state.showModalChangePassword}
          toggle={this.closeModalChangePassword.bind(this)}
          size="md"
        >
          <ModalHeader toggle={this.closeModalChangePassword.bind(this)}>
            {this.props.t("CHANGE_PASSWORD")}
          </ModalHeader>
          <ModalBody>
            <div className="col-sm-12">
              {this.state.notMatchingPassword && (
                <Alert color="danger">
                  {this.props.t("NOT_MATCHING_PASSWORD")}
                </Alert>
              )}
              <label className="w-100 text-left" htmlFor={this.state.id}>
                <Trans i18nKey={"insert_password"} />
                :*
              </label>
              <input
                required
                maxLength={20}
                minLength={8}
                onChange={(ev) =>{
                  return this.setState({
                    isValidPwd: this.validPasswordRegExp.test(ev.target.value),
                    password: ev.target.value
                  })
                }}
                onBlur={() =>{
                  if (this.state.password !== this.state.cntrlPassword &&
                      this.state.cntrlPassword !== "" &&
                      this.state.password !=="")
                  {
                    return this.setState({ noMatch: true })
                  } else {
                    return this.setState({ noMatch: false })
                  }
                }}
                type="password"
                className="form-control"
              />
              <label className="w-100 text-left" htmlFor={this.state.id}>
                <Trans i18nKey={"confirm_password"} />
                :*
              </label>
              <input
                required
                onChange={(ev) =>
                    this.setState({ cntrlPassword: ev.target.value })
                }
                onBlur={() => {
                  if (this.state.password !== this.state.cntrlPassword &&
                      this.state.cntrlPassword !== "" &&
                      this.state.password !== "")
                  {
                    return this.setState({ noMatch: true })
                  } else {
                    return this.setState({ noMatch: false })
                  }
                }}
                type="password"
                className="form-control"
              />
            </div>
            <div className="row">
              <div
                  className={`form-input-label-cont py-0 m-0 ${(!this.state.isValidPwd && this.state.isValidPwd !== null)? 'error-text' : ''}`}
              >
                <p className="small">
                  {this.props.t("PASSWORD_PATTERN")}
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleChangePassword.bind(this)}>
              {this.props.t("Ok")}
            </Button>
            <Button onClick={this.closeModalChangePassword.bind(this)}>
              {this.props.t("Cancel")}
            </Button>
          </ModalFooter>
          <span className="m-3">
            <Trans i18nKey="THESE_FIELDS_ARE_REQUIRED"/>
          </span>
        </Modal>
      </>
    );
  }
}

const UtentePage = RxComponent({ onSuccess, onError })(Page);
export default UtentePage;
