import HttprepoTAAL from "../../../repos/HttpRepoTaal";

export default class UtenteHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Utente";
  }

  // search(pageNum, pageSize, filter, orders, customTable) {
  //   let params = {
  //     filter: filter,
  //     pageNum: pageNum,
  //     pageSize: pageSize,
  //   };
  //   if (orders) params.orders = [...orders];
  //   return this.API.post(
  //       `/Generic/search/${customTable || this.getTable()}`,
  //       params
  //   ).then(res => {
  //     if (customTable === 'TipologiaProfilo') {
  //       res.data.result = res.data.result.filter(tipoP => tipoP.flgComunale === true)
  //       return res
  //     } else {
  //       return res
  //     }
  //   });
  // }
}
