import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LayoutDashboard from "../../template/dashboard/Layout";
import LayoutSite from "../../template/site/Layout";
import LoginPage from "../../pages/Login/Login.page";
import RegisterPage from "../../pages/Register/Register.page";
import RegisterOTP from "../../pages/Register/components/Register.opt";
import RegisterHelpdesk from "../../pages/Register/components/Register.helpdesk";
import { ROLES } from "../../constants/Roles";
import { rxStore } from "../../App";
import PasswordRecovery from "../../pages/Login/ForgetPassword";

export default class AppSwitch extends React.Component {
  userLogged = () => rxStore.getState().Auth.loggedIn;

  render() {
    if (this.userLogged())
      return (
        <Switch>
            {rxStore.getState().Auth.user.TipologiaProfilo.codiceProfilo ===
          ROLES.UTENTE_GENERICO ? (
            <>
              {/*

                             <LayoutSite />
                            */}
              <Route path="/site" component={LayoutSite} />
              <Redirect
                to={{
                  pathname: "/site/home",
                }}
              />
            </>
          ) : (
            <>
              {/*
                                <Route path="/dashboard" component={LayoutDashboard} />
                                <Route exact path="/" component={LayoutDashboard} />

                                */}

              <LayoutDashboard />
            </>
          )}
        </Switch>
      );

    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/passwordrecovery" component={PasswordRecovery} />
        <Route path="/registerHelpdesk" component={RegisterHelpdesk} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/otp" component={RegisterOTP} />
        <Redirect to={{ pathname: "/login" }} />
      </Switch>
    );
  }
}
/*
const AppSwitch = RxComponent()(AppSwitchC);
export default AppSwitch;*/
