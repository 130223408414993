import React from "react";
import {
  ON_ERROR,
  ON_SUCCESS,
  ON_INFO,
  ON_WARNING,
} from "../../redux/Shared/Shared.types";
import RxComponent from "../../redux/RxComponent";
import { clearNotification } from "../../redux/Shared/Shared.actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { Trans } from "react-i18next";
import { ERROR_BOUNDARY } from "../../constants/Settings";

class Comp extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.Shared.messageType) {
      let textM = this.props.Shared.messageText;
      if (_.isObject(textM) && React.isValidElement(textM)) {
        console.log("++++++++++++++++++++++++++++++", textM);
      } else {
        if (_.isError(textM)) textM = textM.message;
        if (_.isObject(textM) && !React.isValidElement(textM))
          textM = JSON.stringify(textM);
        textM = this.props.t(textM);
      }

      if (this.props.Shared.messageType === ON_ERROR) {
        toast(textM, { type: "error" });
        //NotificationManager.error(this.props.Shared.messageText,"Errore");
      } else if (this.props.Shared.messageType === ON_WARNING) {
        toast(textM, { type: "warning" });
        //NotificationManager.warning(this.props.Shared.messageText,"Attenzione");
      } else if (this.props.Shared.messageType === ON_SUCCESS) {
        toast(textM, { type: "success" });
        //NotificationManager.success(this.props.Shared.messageText,"Ok");
      } else if (this.props.Shared.messageType === ON_INFO) {
        toast.info(textM, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        //toast(,{type:"info"});
        //NotificationManager.info(this.props.Shared.messageText,"Informazione");
      }
      this.props.clearNotification();
    }
  }

  /*logError(){
        console.log('error boundary ->', this.state.errorInfo.componentStack);
    }*/

  render() {
    if (this.state.errorInfo && ERROR_BOUNDARY == "true") {
      return (
        <div>
          <h2>
            <Trans i18nKey="SomethingWentWrong" />.
          </h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.props.children}
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const ErrorBoundary = RxComponent({ clearNotification })(Comp);
export default ErrorBoundary;
