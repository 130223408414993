import StubRepo from "../../repos/StubRepo";
import LinguaMockRepo from "./repositories/Lingua.mock";
import LinguaHttpRepo from "./repositories/Lingua.http";

export default class Lingua extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Lingua repo";
  }

  static getLocal() {
    if (Lingua._localInstance == null) {
      Lingua._localInstance = new LinguaMockRepo();
    }
    return Lingua._localInstance;
  }

  static getService() {
    if (Lingua._serviceInstance == null) {
      Lingua._serviceInstance = new LinguaHttpRepo();
    }
    return Lingua._serviceInstance;
  }
}
