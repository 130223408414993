import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { enLabels } from './languages/en';
import { itLabels } from './languages/it';
import HttprepoTAAL from '../repos/HttpRepoTaal';
import _ from 'lodash';


export const getTranslate= new Promise((ok,ko) =>{
  
  i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        // we init with resources
        resources: {
          en: {
            translations: enLabels
          },
          it: {
            translations: itLabels
          }
        },
        fallbackLng: 'it',
        debug: false,
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false, // we use content as keys
        interpolation: {
          escapeValue: false, // not needed for react!!
          formatSeparator: ','
        },
        react: {
          wait: true
        }
      });

      //for local translation
      ok(i18n)

      //for remote translation
      /*const remoteHTTPClient= new HttprepoTAAL();
      const feResource={
        it:itLabels,
        en:enLabels
      }


      remoteHTTPClient.search2(0, -1, {}, null, ["Lingua"], "Localizzazione").then((res)=>{
      const results=res.data.result.filter(p=>p.Lingua).map(p=>({
        codiceLingua:p.Lingua.codiceLingua,
        key:p.descLocalizzata,
        val:p.descrizione
      }));
      const grouped=_.groupBy(results,"codiceLingua");
      let resource={};
      Object.keys(grouped).forEach(lang=>{
        resource={};
        grouped[lang].forEach(el=>{
          resource[el.key]=el.val;
        });
        //resource={...resource,...feResource[lang]};
        i18n.addResourceBundle(lang,'translations',resource);
      });
      ok(i18n)
    });*/
})


