import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import _ from "lodash";
import Resizer from "react-image-file-resizer";

import RxComponent from "../../redux/RxComponent";
import { SelectPicker } from "../../components/Content/SelectPicker";
import CrudNetForm from "../../components/CrudNet/CrudNetForm";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import ParcheggioService from "../Parcheggio/Parcheggio.service";
import { onSuccess, onError } from "../../redux/Shared/Shared.actions";
import AreaService from "./Area.service";
import FasciaOrariaService from "../FasciaOraria/FasciaOraria.service";
import VarcoService from "../Varco/Varco.service";
import { ROLES } from "../../constants/Roles";

const MAX_WIDTH_LARGE_IMAGE = 1920;
const MAX_WIDTH_SMALL_IMAGE = 300;
const MAX_HEIGHT_LARGE_IMAGE = 1080;
const MAX_HEIGHT_SMALL_IMAGE = 200;

class Comp extends React.Component {
  parcheggioService = new ParcheggioService();
  areaService = new AreaService();
  fasciaOrariaService = new FasciaOrariaService();
  varcoService = new VarcoService();

  state = {
    fasciaOrariaList: [],
    parcheggiList: [],
    varchiList: [],
    activeTab: "1",
    parkValues: [],
    fasciaOrariaValues: [],
    varchiValues: [],
  };

  componentDidMount() {
    let filter = {
      [`${FILTER_NUMBER}&&&fkIdComune`]: [this.props.fkIdComune],
    };
    let assegnazioneFilter = {
      [`${FILTER_NUMBER}&&&fkIdArea`]: [this.props.idArea],
    };
    this.parcheggioService.paginate(0, -1, filter).then((res) => {
      this.setState({ parcheggiList: res.data.result });
      return this.areaService
        .getAssignedParcheggi(assegnazioneFilter)
        .then((res) => {
          let parkValues = [];
          res.data.result.forEach((elm) => {
            parkValues.push({
              ...elm.Parcheggio,
              idRelAreaParcheggio: elm.idRelAreaParcheggio,
            });
          });
          return this.setState({ parkValues: parkValues });
        });
    });
    this.fasciaOrariaService.paginate(0, -1).then((res) => {
      this.setState({ fasciaOrariaList: res.data.result });
      return this.areaService
        .getAssignedFasciaOraria(assegnazioneFilter)
        .then((res) => {
          let fasciaOrariaValues = [];
          res.data.result.forEach((elm) => {
            fasciaOrariaValues.push({
              ...elm.FasciaOraria,
              idRelAreaFasciaOraria: elm.idRelAreaFasciaOraria,
            });
          });
          return this.setState({ fasciaOrariaValues: fasciaOrariaValues });
        });
    });
    this.varcoService.paginate(0, -1, filter).then((res) => {
      this.setState({ varchiList: res.data.result });
      return this.areaService
        .getAssignedVarco(assegnazioneFilter)
        .then((res) => {
          let varchiValues = [];
          res.data.result.forEach((elm) => {
            varchiValues.push({
              ...elm.Varco,
              idRelVarcoArea: elm.idRelVarcoArea,
            });
          });
          return this.setState({ varchiValues: varchiValues });
        });
    });
  }

  changeTab(tab) {
    this.setState({ activeTab: tab });
  }

  async handleSubmit(data) {
    data.fkIdComune = this.props.Auth.user.fkIdComune
      ? this.props.Auth.user.fkIdComune
      : this.props.fkIdComune;
    if (data.foto) {
      data.fotoOriginale = await this.resizeImage(
        data.foto,
        MAX_WIDTH_LARGE_IMAGE,
        MAX_HEIGHT_LARGE_IMAGE
      );
      data.foto = await this.resizeImage(
        data.foto,
        MAX_WIDTH_SMALL_IMAGE,
        MAX_HEIGHT_SMALL_IMAGE
      );
      let splitted = data.fotoOriginale.split(";base64,");
      data.formato = splitted[0].split("data:")[1];
      data.fotoOriginale = splitted[1];

      splitted = data.foto.split(";base64,");
      data.foto = splitted[1];
    }
    if (this.props.formato) {
      data.formato = this.props.formato;
    }
    if (data.maps) {
      data.latitudine = data.maps.latitudine;
      data.longitudine = data.maps.longitudine;
      delete data.maps;
    }
    let promise = null;
    if (this.props.viewMode === "insert") {
      promise = this.props.service.create(data);
    } else if (this.props.viewMode === "edit") {
      promise = this.props.service.edit(data);
    } else {
      data.flgAttivo = false;
      //promise = this.serviceV.edit(data); // chiamare edit e dire data.isDeleted true
      promise = this.serviceV.setFlgAbilitazioneArea(data.idArea, false);
    }
    promise
      .then((resp) => {
        this.props.onSuccess("SuccessedOperation");
      })
      .catch((ex) => {
        this.props.onError(ex);
      });
  }

  resizeImage = (file, maxWidth, maxHeight) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  handleChange(ev, action, type) {
    if (type === "park") {
      this.setState({
        parkValues: ev,
      });
      if (action.action === "select-option") {
        const data = {
          fkIdArea: this.props.idArea,
          fkIdParcheggio: action.option.value.idParcheggio,
        };
        return this.areaService.relAreaParcheggio(data).then((res) => {
          let index = null;
          let value = this.state.parkValues.find((elm, i) => {
            index = i;
            return elm.idParcheggio === res.data.result.fkIdParcheggio;
          });
          value = {
            ...value,
            idRelAreaParcheggio: res.data.result.idRelAreaParcheggio,
          };
          const newValues = this.state.parkValues;
          newValues[index] = value;
          this.setState({ parkValues: newValues });
        });
      } else if (action.action === "clear") {
        return this.areaService.clearRealAreaParcheggio({
          IDAREA: this.props.idArea,
        });
      } else {
        const value = this.state.parkValues.find(
          (elm) => elm.idParcheggio === action.removedValue.value.idParcheggio
        );
        const data = {
          idRelAreaParcheggio: value.idRelAreaParcheggio,
        };
        return this.areaService.deleteRelAreaParcheggio(data);
      }
    } else if (type === "fascia") {
      this.setState({
        fasciaOrariaValues: ev,
      });
      if (action.action === "select-option") {
        const data = {
          fkIdArea: this.props.idArea,
          fkIdFasciaOraria: action.option.value.idFasciaOraria,
        };
        return this.areaService.relAreaFasciaOraria(data).then((res) => {
          let index = null;
          let value = this.state.fasciaOrariaValues.find((elm, i) => {
            index = i;
            return elm.idFasciaOraria === res.data.result.fkIdFasciaOraria;
          });
          value = {
            ...value,
            idRelAreaFasciaOraria: res.data.result.idRelAreaFasciaOraria,
          };
          const newValues = this.state.fasciaOrariaValues;
          newValues[index] = value;
          this.setState({ fasciaOrariaValues: newValues });
        });
      } else if (action.action === "clear") {
        return this.areaService.clearRealAreaFasciaOraria({
          IDAREA: this.props.idArea,
        });
      } else {
        const value = this.state.fasciaOrariaValues.find(
          (elm) =>
            elm.idFasciaOraria === action.removedValue.value.idFasciaOraria
        );
        const data = {
          idRelAreaFasciaOraria: value.idRelAreaFasciaOraria,
        };
        return this.areaService.deleteRelAreaFasciaOraria(data);
      }
    } else {
      this.setState({
        varchiValues: ev,
      });
      if (action.action === "select-option") {
        const data = {
          fkIdArea: this.props.idArea,
          fkIdVarco: action.option.value.idVarco,
        };
        return this.areaService.relVarcoArea(data).then((res) => {
          let index = null;
          let value = this.state.varchiValues.find((elm, i) => {
            index = i;
            return elm.idVarco === res.data.result.fkIdVarco;
          });
          value = { ...value, idRelVarcoArea: res.data.result.idRelVarcoArea };
          const newValues = this.state.varchiValues;
          newValues[index] = value;
          this.setState({ varchiValues: newValues });
        });
      } else if (action.action === "clear") {
        return this.areaService.clearRealVarcoArea({
          IDAREA: this.props.idArea,
        });
      } else {
        const value = this.state.varchiValues.find(
          (elm) => elm.idVarco === action.removedValue.value.idVarco
        );
        const data = {
          idRelVarcoArea: value.idRelVarcoArea,
        };
        return this.areaService.deleteRelVarcoArea(data);
      }
    }
  }

  renderRelAreaParcheggioForm() {
    return (
      <form className="row" onSubmit={() => this.handleSubmit()}>
        <div className="col-sm-4">
          <label className="w-100 text-left" htmlFor={"Parcheggi"}>
            {this.props.t("SELEZIONA_PARCHEGGI")}
          </label>
          <SelectPicker
            values={this.state.parkValues}
            options={_.differenceBy(
              this.state.parcheggiList,
              this.state.parkValues,
              "idParcheggio"
            )}
            displayValue={"descrizione"}
            multiple
            onChange={(ev, action) => this.handleChange(ev, action, "park")}
          />
        </div>
      </form>
    );
  }

  renderRelAreaFasciaOrariaForm() {
    return (
      <form className="row" onSubmit={() => this.handleSubmit()}>
        <div className="col-sm-4">
          <label className="w-100 text-left" htmlFor={"FasciaOraria"}>
            {this.props.t("SELEZIONA_FASCE_ORARIE")}
          </label>
          <SelectPicker
            values={this.state.fasciaOrariaValues}
            options={_.differenceBy(
              this.state.fasciaOrariaList,
              this.state.fasciaOrariaValues,
              "idFasciaOraria"
            )}
            displayValue={"descLocalizzata"}
            multiple
            onChange={(ev, action) => this.handleChange(ev, action, "fascia")}
          />
        </div>
      </form>
    );
  }

  renderRelVarcoAreaForm() {
    return (
      <form className="row" onSubmit={() => this.handleSubmit()}>
        <div className="col-sm-4">
          <label className="w-100 text-left" htmlFor={"Varchi"}>
            {this.props.t("SELEZIONA_VARCHI")}
          </label>
          <SelectPicker
            values={this.state.varchiValues}
            options={_.differenceBy(
              this.state.varchiList,
              this.state.varchiValues,
              "idVarco"
            )}
            displayValue={"descrizione"}
            multiple
            onChange={(ev, action) => this.handleChange(ev, action, "varco")}
          />
        </div>
      </form>
    );
  }

  render() {
    return this.props.viewMode !== "delete" ? (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={{ active: this.state.activeTab === "1" }}
              onClick={() => {
                this.changeTab("1");
              }}
            >
              {this.props.t("DATI_AREA")}
            </NavLink>
          </NavItem>
          {this.props.viewMode === "edit" && (
            <>
              {this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
                ROLES.GESTORE_AREA && (
                <NavItem>
                  <NavLink
                    className={{ active: this.state.activeTab === "2" }}
                    onClick={() => {
                      this.changeTab("2");
                    }}
                  >
                    {this.props.t("ASSEGNAZIONE_PARCHEGGI")}
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={{ active: this.state.activeTab === "3" }}
                  onClick={() => {
                    this.changeTab("3");
                  }}
                >
                  {this.props.t("ASSEGNAZIONE_FASCE_ORARIE")}
                </NavLink>
              </NavItem>
              {this.props.Auth.user.TipologiaProfilo.codiceProfilo !==
                ROLES.GESTORE_AREA && (
                <NavItem>
                  <NavLink
                    className={{ active: this.state.activeTab === "4" }}
                    onClick={() => {
                      this.changeTab("4");
                    }}
                  >
                    {this.props.t("ASSEGNAZIONE_VARCO")}
                  </NavLink>
                </NavItem>
              )}
            </>
          )}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <CrudNetForm
              {...this.props}
              service={this.props.service}
              viewMode={this.props.viewMode}
              onSubmit={this.handleSubmit.bind(this)}
            />
          </TabPane>
          <TabPane tabId="2">
            {this.state.activeTab === "2" && this.renderRelAreaParcheggioForm()}
          </TabPane>
          <TabPane tabId="3">
            {this.state.activeTab === "3" &&
              this.renderRelAreaFasciaOrariaForm()}
          </TabPane>
          <TabPane tabId="4">
            {this.state.activeTab === "4" && this.renderRelVarcoAreaForm()}
          </TabPane>
        </TabContent>
      </React.Fragment>
    ) : (
      <CrudNetForm
        {...this.props}
        onSubmit={this.handleSubmit.bind(this)}
        service={this.props.service}
        viewMode={this.props.viewMode}
      />
    );
  }
}

const AreaCRUD = RxComponent({ onSuccess, onError })(Comp);
export default AreaCRUD;
