import StubRepo from "../../repos/StubRepo";
import TurnazioneVarcoMockRepo from "./repositories/TurnazioneVarco.mock";
import TurnazioneVarcoHttpRepo from "./repositories/TurnazioneVarco.http";

export default class TurnazioneVarcoRepo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TurnazioneVarco Repo";
  }

  static getLocal() {
    if (TurnazioneVarcoRepo._localInstance == null) {
        TurnazioneVarcoRepo._localInstance = new TurnazioneVarcoMockRepo();
    }
    return TurnazioneVarcoRepo._localInstance;
  }

  static getService() {
    if (TurnazioneVarcoRepo._serviceInstance == null) {
        TurnazioneVarcoRepo._serviceInstance = new TurnazioneVarcoHttpRepo();
    }
    return TurnazioneVarcoRepo._serviceInstance;
  }
}
