import React from "react";
import Select, { components } from "react-select";
import _ from "lodash";
import { withTranslation } from "react-i18next";

const MyValueContainer = ({ children, ...props }) => {
  if (_.isArray(children) && children[0] && children[0].length) {
    const newChilds = children[0].map((child) =>
      React.cloneElement(child, {
        key:
          child.props.data.value && child.props.data.value.id
            ? child.props.data.value.id
            : child.props.children,
      })
    );
    newChilds.forEach((newChild, index) => {
      children[0][index] = newChild;
    });
    // children[0] = newChilds;  wont work. children[0] is readonly
  }
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

class SelectPickerC extends React.Component {
  constructor(props) {
    super(props);
    this.values = _.cloneDeep(props.values) || [];
    this.state = {
      update: 0,
    };
    this._setOptions(props);
    this.defaultValues =
      props.values && props.values.length > 0
        ? props.values.map((elm) => {
            return {
              value: elm,
              label: props.t(_.get(elm, props.displayValue)),
            };
          })
        : [];
  }

  onChange(values, action) {
    if (this.props.multiple) {
      if (values && values.length > 0) {
        this.values = values.map((elm) => elm.value);
      } else {
        this.values = [];
      }
    } else {
      this.values = !values.value ? [] : [values.value];
    }
    this.notifyChange(values, action);
  }

  filterOption = (option, inputValue) =>
    (
      (option.label &&
        option.label
          .toString()
          .toLowerCase()
          .match(inputValue.toLowerCase())) ||
      []
    ).length > 0;

  notifyChange = (values, action) => {
    if (this.props.onChange && _.isFunction(this.props.onChange))
      this.props.onChange(this.values, action);
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.values !== nextProps.values) {
      if (!_.isNull(nextProps.values) && !_.isUndefined(nextProps.values)) {
        if (_.isArray(nextProps.values)) this.values = nextProps.values;
        else this.values = [nextProps.values];
      } else {
        this.values = [];
      }
      nextState.update = this.state.update + 1;
    }
    if (this.options !== nextProps.options) {
      this._setOptions(nextProps);
      nextState.update = this.state.update + 1;
    }
  }

  _setOptions(nextProps) {
    const options =
      nextProps.options && nextProps.options.length > 0
        ? nextProps.options.map((elm) => {
            return {
              value: elm,
              label: this.props.t(_.get(elm, this.props.displayValue)),
            };
          })
        : [];
    if (!this.props.multiple && this.props.emptyField) {
      this.options = [
        {
          value: {},
          label: "---",
        },
        ...options,
      ];
    } else {
      this.options = options;
    }
  }

  render() {
    return (
      <Select
        components={{
          ValueContainer: MyValueContainer,
        }}
        options={this.options}
        onChange={(value, action) => this.onChange(value, action)}
        filterOption={this.filterOption}
        isMulti={this.props.multiple}
        hideSelectedOptions={true}
        isDisabled={this.props.readOnly}
        //value={this.values}
        defaultValue={this.defaultValues}
      />
    );
  }
}

export const SelectPicker = withTranslation()(SelectPickerC);
