import StubRepo from "../../repos/StubRepo";
import AreaMockRepo from "./repositories/Area.mock";
import AreaHttpRepo from "./repositories/Area.http";

export default class Area extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Area repo";
  }

  static getLocal() {
    if (Area._localInstance == null) {
      Area._localInstance = new AreaMockRepo();
    }
    return Area._localInstance;
  }

  static getService() {
    if (Area._serviceInstance == null) {
      Area._serviceInstance = new AreaHttpRepo();
    }
    return Area._serviceInstance;
  }
}
