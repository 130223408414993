import StubRepo from "../../repos/StubRepo";
import RelTipoAreaTipoPrenotazioneMockRepo from "./repositories/RelTipoAreaTipoPrenotazione.mock";
import RelTipoAreaTipoPrenotazioneHttpRepo from "./repositories/RelTipoAreaTipoPrenotazione.http";

export default class RelTipoAreaTipoPrenotazione extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "RelTipoAreaTipoPrenotazione repo";
  }

  static getLocal() {
    if (RelTipoAreaTipoPrenotazione._localInstance == null) {
      RelTipoAreaTipoPrenotazione._localInstance = new RelTipoAreaTipoPrenotazioneMockRepo();
    }
    return RelTipoAreaTipoPrenotazione._localInstance;
  }

  static getService() {
    if (RelTipoAreaTipoPrenotazione._serviceInstance == null) {
      RelTipoAreaTipoPrenotazione._serviceInstance = new RelTipoAreaTipoPrenotazioneHttpRepo();
    }
    return RelTipoAreaTipoPrenotazione._serviceInstance;
  }
}
