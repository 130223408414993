import React from 'react';
import '../../template/dashboard/css/spinner.css';
import RxComponent from '../../redux/RxComponent';
import _ from 'lodash';
import { clearNotification } from '../../redux/Shared/Shared.actions';
import { Trans } from "react-i18next";

class Comp extends React.Component{

    constructor(props){
        super(props);
        this.state={
            loader:false
        };
    }

    componentDidUpdate(prevProps,prevState){
        if(_.isBoolean(this.props.Shared.loading) && this.state.loader!==this.props.Shared.loading){
            this.setState({
                loader:this.props.Shared.loading
            },()=>{
                if(!this.state.loader)
                    this.props.clearNotification();
            });
        }
      }

    render(){
        if(!this.props.Shared.loading)
            return null;
        return (
            <div className="loading"><Trans i18nKey="Loading" />&#8230;</div>
        );
    }

}

const Loader = RxComponent({clearNotification})(Comp);
export default Loader;