import React from "react";
import RxComponent from "../../../redux/RxComponent";
import { triggerCercaDisponibilita } from "../../../redux/Prenotazione/Prenotazione.actions";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
} from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import DatePicker from "../../../components/Content/DatePicker";
import AreaService from "../../Area/Area.service";
import TipologiaPrenotazioneService from "../../TipologiaPrenotazione/TipologiaPrenotazione.service";
import ComuneService from "../../Comune/Comune.service";
import moment, { relativeTimeThreshold } from "moment";
import {
  FILTER_NUMBER,
  FILTER_MULTIPLE,
} from "../../../components/TableServer/FilterItem";
import { ROLES } from "../../../constants/Roles";
import GestoreAreaService from "../../AssegnaGestoreArea/AssegnaGestoreArea.service";
import PrenotazioneDetailForm from "./Prenotazione.insertdetail";

class Component extends React.Component {
  areaService = new AreaService();
  TipologiaPrenotazioneService = new TipologiaPrenotazioneService();
  ComuneService = new ComuneService();
  gestoreAreaService = new GestoreAreaService();

  constructor(props) {
    super(props);
    const codiceProfilo = this.props.Auth.user.TipologiaProfilo.codiceProfilo;

    this.state = {
      comuneList: [],
      occupantiList: [],
      numeroOccupanti: null,
      selectedComune:
        codiceProfilo === ROLES.RESPONSABILE_VARCHI_STRADALI ||
        codiceProfilo === ROLES.ADDETTO_SICUREZZA ||
        codiceProfilo === ROLES.RESPONSABILI_VARCHI_AREE ||
        codiceProfilo === ROLES.ADMIN_COMUNE
          ? [this.props.Auth.user.Comune]
          : null,
      dataPrenotazione: null,
      prenotazioneToBePassed: [],
      giorniPrenotazioneComune: null,
      form_key: 0,
    };
  }

  componentDidMount() {
    this.ComuneService.searchComuniPrenotazione(true).then((res) => {
      let comuneList = res.data.result;
      this.setState({
        comuneList,
      });
    });
    const codiceProfilo = this.props.Auth.user.TipologiaProfilo.codiceProfilo;
    if (window.location.href.indexOf("/dashboard") >= 0) {
      this.setValidDatesPrenotazione(this.props.Auth.user.fkIdComune);
    }

    if (
      codiceProfilo === ROLES.RESPONSABILE_VARCHI_STRADALI ||
      codiceProfilo === ROLES.ADDETTO_SICUREZZA ||
      codiceProfilo === ROLES.RESPONSABILI_VARCHI_AREE ||
      codiceProfilo === ROLES.ADMIN_COMUNE
    ) {
      this.handleChangePrenotazioneToBePassed(
        this.props.Auth.user.Comune,
        "comune"
      );
      this.loadNumeroOccupanti(this.props.Auth.user.Comune);
      this.setState({
        giorniPrenotazioneComune: this.props.Auth.user.Comune
          .giorniPrenotazione,
      });
    } else if (codiceProfilo === ROLES.GESTORE_AREA) {
      this.ComuneService.getById(this.props.Auth.user.fkIdComune).then(
        (res) => {
          let comune = res.data.result;
          this.handleChangePrenotazioneToBePassed(comune, "comune");
          this.loadNumeroOccupanti(comune);
          this.setState({
            giorniPrenotazioneComune: comune.giorniPrenotazione,
            selectedComune: comune,
          });
        }
      );
    }
  }

  loadNumeroOccupanti(comune) {
    let list = [];
    if (comune && comune.maxOccupanti) {
      for (let i = 1; i <= comune.maxOccupanti; i++) {
        list.push({
          value: i,
          descrizione: i,
        });
      }
    }
    this.setState({
      occupantiList: list,
    });
  }

  handleChangePrenotazioneToBePassed(ev, key) {
    let prenotazioneToBePassed = this.state.prenotazioneToBePassed;
    if (
      key === "comune" &&
      this.state.prenotazioneToBePassed[key] !== ev //&&
      //this.state.prenotazioneToBePassed[key]
    ) {
      prenotazioneToBePassed = [];
      prenotazioneToBePassed[key] = ev;
      this.setValidDatesPrenotazione(ev.idComune);
      this.resetForm();
      if (this.props.isFromGestore) {
        this.props.onResetForm();
      }
    } else if (
      key === "dataPrenotazione" &&
      this.state.prenotazioneToBePassed[key] !== ev &&
      this.state.dateDisp
    ) {
      let dat = moment(ev);
      dat = dat.format("YYYYMMDD");

      if (
        this.state.dateDisp.indexOf(moment(ev).format("YYYYMMDD")) >= 0 ||
        this.state.dateDisp.indexOf(dat) >= 0
      ) {
        prenotazioneToBePassed[key] = ev;
        this.setState({
          dataPren: moment(ev).format("YYYYMMDD"),
          dataNotAvailableForUser: false,
        });
      } else {
        this.setState({
          dataNotAvailableForUser: true,
        });
      }
      if (this.props.isFromGestore) {
        this.props.onResetForm();
      }
    } else if (
      key === "numeroOccupanti" &&
      this.state.prenotazioneToBePassed[key] !== ev
    ) {
      prenotazioneToBePassed[key] = ev;
      this.setState({
        numeroOccupanti: ev,
      });
      if (this.props.isFromGestore) {
        this.props.onResetForm();
      }
    }

    this.setState({
      prenotazioneToBePassed: prenotazioneToBePassed,
    });
  }

  handleSubmit = () => {
    if (this.validate()) {
      let prenotazione = {
        idComune: this.state.prenotazioneToBePassed.comune.idComune,
        dataPrenotazione: moment(
          this.state.prenotazioneToBePassed.dataPrenotazione
        ).format("YYYYMMDD"),
        numeroOccupanti: this.state.prenotazioneToBePassed.numeroOccupanti
          .descrizione,
      };
      if (window.location.href.indexOf("/dashboard") >= 0) {
        prenotazione.fromDashboard = true;
        prenotazione.IDUTENTE = this.props.Prenotazione.userAssegnato
      }
      if (this.props.isFromGestore) {
        this.props.onSubmit(prenotazione);
      } else {
        this.props.triggerCercaDisponibilita(prenotazione);
      }
    } else {
      //TODO manageError
    }
  };

  _checkBan(date) {
    let banned = false;
    if (
      this.props.Auth.user.ListaBan_List &&
      this.props.Auth.user.ListaBan_List.length
    ) {
      banned =
        this.props.Auth.user.ListaBan_List.filter((itemBan) => {
          const DI = moment(itemBan.dataInizioBan)
            .set("h", 0)
            .set("m", 0)
            .set("s", 0);
          const D = moment(date).set("h", 0).set("m", 0).set("s", 0);
          const DE = moment(itemBan.dataFineBan)
            .set("h", 23)
            .set("m", 59)
            .set("s", 59);
          return (
            itemBan.flgBanAttivo &&
            date &&
            D.isSameOrAfter(DI) &&
            D.isSameOrBefore(DE) &&
            itemBan.fkIdComune ===
              this.state.prenotazioneToBePassed.comune.idComune
          );
        }).length > 0;
    }

    this.setState({
      userBanned: banned,
    });
  }

  validate() {
    if (
      this.state.selectedComune &&
      this.state.numeroOccupanti &&
      this.state.dataPren &&
      moment(this.state.dataPren).isValid()
    ) {
      return true;
    }
    return false;
  }

  resetForm() {
    let newKey = this.state.form_key;
    newKey += 1;
    if (this.props.isFromGestore) {
      this.props.onResetForm();
    }
    this.setState({
      form_key: newKey,
      numeroOccupanti: null,
      showDetail: false,
    });
  }

  setValidDatesPrenotazione(idComune) {
    let dateDisp = [];
    let idUser =
      window.location.href.indexOf("/dashboard") >= 0
        ? this.props.Prenotazione.userAssegnato
        : this.props.Auth.user.idUtente;
    if (window.location.href.indexOf("/dashboard") >= 0) {
      this.ComuneService.getDatesForUserBO(
        idComune,
        idUser,
        moment().format("YYYYMMDD")
      )
        .then((res) => {
          dateDisp = res.data.result.map((el) => el.DATAABILITATA);
        })
        .then(() => {
          this.setState({
            dateDisp: dateDisp,
          });
        });
    } else {
      this.ComuneService.getDatesForUser(
        idComune,
        idUser,
        moment().format("YYYYMMDD")
      )
        .then((res) => {
          dateDisp = res.data.result.map((el) => el.DATAABILITATA);
        })
        .then(() => {
          this.setState({
            dateDisp: dateDisp,
          });
        });
    }
  }

  render() {
    const codiceProfilo = this.props.Auth.user.TipologiaProfilo.codiceProfilo;

    return (
      <>
        <div className="d-flex align-items-center justify-content-center h-100">
          <Form className="row m-0 d-flex align-items-center justify-content-center ">
            <div className="col-md-8 mb-3">
              {codiceProfilo !== ROLES.GESTORE_AREA && (
                <h5 className="text-black text-weight-500">
                  {this.props.t("ENTER_YOUR_BOOKING_DETAILS")}
                </h5>
              )}
            </div>
            {codiceProfilo !== ROLES.GESTORE_AREA && (
              <FormGroup className="col-md-8" style={{zIndex:3}}>
                <label htmlFor="Comune">{this.props.t("Municipality")}*:</label>
                <SelectPicker
                  readOnly={
                    codiceProfilo === ROLES.RESPONSABILE_VARCHI_STRADALI ||
                    codiceProfilo === ROLES.ADDETTO_SICUREZZA ||
                    codiceProfilo === ROLES.RESPONSABILI_VARCHI_AREE ||
                    codiceProfilo === ROLES.ADMIN_COMUNE
                  }
                  values={this.state.selectedComune || null}
                  value={this.state.selectedComune || null}
                  options={this.state.comuneList}
                  displayValue="descrizione"
                  onChange={(ev) => {
                    if (ev.length && ev[0]) {
                      this.handleChangePrenotazioneToBePassed(ev[0], "comune");
                      this.loadNumeroOccupanti(ev[0]);
                      this.setState({
                        selectedComune: ev[0],
                        giorniPrenotazioneComune: ev[0].giorniPrenotazione,
                      });
                    } else {
                      this.setState({
                        occupantiList: [],
                        numeroOccupanti: null,
                        selectedComune: null,
                        dataPrenotazione: null,
                        prenotazioneToBePassed: [],
                        giorniPrenotazioneComune: null,
                      });
                    }
                  }}
                  required
                />
              </FormGroup>
            )}

            <div
              className="col-md-8"
              hidden={!this.state.giorniPrenotazioneComune}
            >
              <DatePicker
                placeholder={this.props.t("PLACEHOLDER_DATE")}
                key={this.state.form_key}
                label={this.props.t("Date") + ":"}
                required
                value={null}
                displayValue="description"
                startDate={moment().format("DD/MM/YYYY")}
                endDate={moment()
                  .add("d", this.state.giorniPrenotazioneComune)
                  .format("DD/MM/YYYY")}
                onChange={(ev) => {
                  this.handleChangePrenotazioneToBePassed(
                    ev.date,
                    "dataPrenotazione"
                  );
                  this._checkBan(ev.date);
                }}
              />
            </div>
            <FormGroup
              key={this.state.form_key}
              className="col-md-8"
              hidden={!this.state.giorniPrenotazioneComune}
            >
              <label htmlFor="NumeroOccupanti">
                {this.props.t("NUMERO_OCCUPANTI") + ":*"}
              </label>
              <SelectPicker
                values={null}
                options={this.state.occupantiList}
                displayValue="descrizione"
                onChange={(ev) => {
                  this.handleChangePrenotazioneToBePassed(
                    ev[0],
                    "numeroOccupanti"
                  );
                  this.setState({
                    numeroOccupanti: ev[0].descrizione,
                  });
                }}
                required
              />
            </FormGroup>
            <FormGroup className="col-md-8">
              {this.state.userBanned ? (
                <Alert color="danger">{this.props.t("USER_BANNED")}</Alert>
              ) : this.state.dataNotAvailableForUser ? (
                <Alert color="danger">
                  {this.props.t("DATA_NOT_AVAILABLE_FOR_USER")}
                </Alert>
              ) : (
                <Button
                  hidden={!this.state.giorniPrenotazioneComune}
                  className="float-md-right float-lg-right float-sm-none"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {this.props.t("SUBMIT")}
                </Button>
              )}
            </FormGroup>
            <div className="col-12 text-center">
              <span>
                {this.props.t("THESE_FIELDS_ARE_REQUIRED")}
              </span>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
const PrenotazioneForm = RxComponent({ triggerCercaDisponibilita })(Component);
export default PrenotazioneForm;
