import React from "react";
import RxComponent from "../../../redux/RxComponent";
import CentraleOperativaService from "../CentraleOperativa.service";
import { NOTIFICASTATUSES } from "../../../constants/NotificaStatuses";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

class Comp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pnr: props.pnr,
      loading: true,
    };
  }
  service = new CentraleOperativaService();

  componentDidMount() {
    this.setState({
      row: this.props.row,
      anagraficaUtente: this.props.row.Prenotazione.Utente
        .AnagraficaUtente_List[0],
    });
  }

  componentDidUpdate() {
    this.setState({
      pnr: this.props.pnr,
      loading: true,
    });
  }

  render() {
    return (
      <>
        <div>
          {this.state.row && (
            <>
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("Notes")}:
              </label>
              <textarea
                value={this.state.row.note}
                readOnly
                className="form-control"
                name="Note"
                id="Note"
              />
            </>
          )}
          {this.state.anagraficaUtente && (
            <>
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("Name")}:
              </label>
              <textarea
                value={this.state.anagraficaUtente.nome}
                readOnly
                className="form-control"
                name="Note"
                id="Note"
              />
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("Surname")}:
              </label>
              <textarea
                value={this.state.anagraficaUtente.cognome}
                readOnly
                className="form-control"
                name="Note"
                id="Note"
              />
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("NumberPhone")}:
              </label>
              <textarea
                value={this.state.anagraficaUtente.cellulare}
                readOnly
                className="form-control"
                name="Note"
                id="Note"
              />
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("NumberDocument")}:
              </label>
              <textarea
                value={this.state.anagraficaUtente.Documento.numeroDocumento}
                readOnly
                className="form-control"
                name="Note"
                id="Note"
              />
              <button
                onClick={() => {
                  if (
                    this.state.row.StatoNotifica.codiceStatoNotifica &&
                    (this.state.row.StatoNotifica.codiceStatoNotifica ===
                      NOTIFICASTATUSES.READ ||
                      this.state.row.StatoNotifica.codiceStatoNotifica ===
                        NOTIFICASTATUSES.CREATED)
                  )
                    this.service.changeStatusNotifica(
                      this.state.row.idNotificaCentraleOperativa,
                      NOTIFICASTATUSES.PROCESSEDED
                    );
                  this.props.onClose();
                }}
              >
                {this.props.t("EVADI_NOTIFICA")}
              </button>
            </>
          )}
        </div>
      </>
    );
  }
}
const NotificationDetail = RxComponent({})(Comp);
export default NotificationDetail;
