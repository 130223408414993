import React from "react";
import moment, { relativeTimeThreshold } from "moment";
import Select from "react-select";
import { STATUSES } from "../../constants/PrenotazioneStatuses";

import RxComponent from "../../redux/RxComponent";
import {
  FILTER_NUMBER,
  FILTER_TEXT,
} from "../../components/TableServer/FilterItem";

import "bootstrap/dist/css/bootstrap.min.css";
import ControlloVarcoService from "./ControlloVarco.service";
import PrenotazioneService from "../Prenotazione/Prenotazione.service";
import PrenotazioneDetailForm from "../Prenotazione/components/Prenotazione.insertdetail";
import QrCodeReader from "../../components/Content/QrCodeReader";
import {
  triggerGetPrenotazione,
  triggerScanBarCodePrenotazione,
} from "../../redux/Prenotazione/Prenotazione.actions";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";
import {
  SUCCESS_SEARCH,
  ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE, ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE, PRENOTAZIONE_NON_TROVATA,
} from "../../redux/Prenotazione/Prenotazione.types";
import BarcodeScanner from "../../components/Content/BarcodeReader";
import ComuneService from "../Comune/Comune.service";
import {SelectPicker} from "../../components/Content/SelectPicker";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ON_ERROR} from "../../redux/Shared/Shared.types";

class Component extends React.Component {
  //TODO mock utente
  state = {
    showModal: false,
    dropdownOptions: [
      { value: "I", label: this.props.t("Entrance") },
      { value: "U", label: this.props.t("Exit") },
    ],
    flgEntrataUscita: "",
    pnr: "",
    arrayPnr:[],
    selectedPnr:[],
    service: new ControlloVarcoService(),
    assignedVarco: null,
    typeAssignedVarco: null,
    prenotazioneService: new PrenotazioneService(),
    prenotazione: null,
    note: "",
    idUtenteAddetto: this.props.Auth.user.idUtente,
    loadingAccordion: false,
    idComune: this.props.Auth.user.Comune.idComune,
    comune: null,
    isCorrectComune: false,
    selectedDataInizio: null,
  };

  handleSearch = false;
  comuneService = new ComuneService();

  componentDidMount() {
    this.state.service
      .getVarcoFromId(this.state.idUtenteAddetto)
      .then((res) => {
        const assignedVarco = res.varco.filter((varco) =>
          moment().isBetween(varco.dataInizio, varco.dataFine)
        );
        this.setState(
          {
            assignedVarco: assignedVarco,
            typeAssignedVarco: res.type,
            //idComune: assignedVarco[0].Varco.fkIdComune,
          },
          () => {
            if (this.state.assignedVarco.length === 0) {
              this.props.onError("USER_NOT_ASSIGNED");
            }
          }
        );
      })
      .then(() => {
        this.comuneService.getById(this.state.idComune).then((res) => {
          this.setState({
            comune: res.data.result,
          });
        });
      });
  }

  componentDidUpdate() {

    if (this.handleSearch && this.props.Prenotazione.type === SUCCESS_SEARCH) {
      this.handleSearch = false;
      let dataInizio = this.props.Prenotazione.selectedFields
        ? this.props.Prenotazione.selectedFields.dataInizio
        : null;
      this.setState({
        loadingAccordion: true,
        selectedDataInizio: dataInizio,
      });
    }
    if (
      this.props.Prenotazione.type === ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE
    ) {
      return this.handlePrenotazioneSingleType()
    }
    if (
        this.props.Prenotazione.type === ON_SUCCESS_SCAN_BARCODE_PRENOTAZIONE_MULTIPLE
    ) {
      this.handlePrenotazioneMultipleType()
    }

    if (this.props.Prenotazione.type === PRENOTAZIONE_NON_TROVATA) {
      if(this.state.loadingAccordion) {
        this.setState({
          loadingAccordion:false,
          pnr: ''
        })
      }
    }
  }
  handlePrenotazioneMultipleType() {
      if(this.handleBarCode) {
        this.handleBarCode = false
        return this.setState({
          showModal:true,
          arrayPnr: this.props.Prenotazione.arrayPnr.map(obj => {
            return {...obj, label: obj.PNR + ' - ' + obj.NOMEAREA}
          }),
        });
      }
  }
  handlePrenotazioneSingleType() {
    if (this.handleBarCode) {
      this.handleBarCode = false
      return this.setState({ pnr: this.props.Prenotazione.pnr }, () => {
        this.handlepnrChange();
      });
    }
  }

  handleRegister(param) {
    if (param === "OK") {
      return this.validateForm()
        .then(() =>
          this.registerTransito().then(() => this.setStatePrenotazione())
        )
        .then(() => this.props.onSuccess("VARCO_CONFIRMED"))
        .catch((errFromValidate) => this.props.onError(errFromValidate));
    } else {
      return this.validateForm("problem")
        .then(() =>
          this.registerTransito().then(() =>
            this.setStatePrenotazione().then(() =>
              this.notifyCentraleOperativa()
            )
          )
        )
        .then(() => this.props.onSuccess("VARCO_DENIED"))
        .catch((errFromValidate) => this.props.onError(errFromValidate));
    }
  }

  registerTransito() {
    const data = {
      fkIdPrenotazione: this.props.Prenotazione.selectedFields.idPrenotazione,
      fkIdUtenteAddetto: this.state.idUtenteAddetto, //TODO prendere da redux
      fkIdVarco:
        this.state.typeAssignedVarco === "VS" ||
        this.state.typeAssignedVarco === "VA"
          ? this.state.assignedVarco[0].fkIdVarco
          : null,
      fkIdParcheggio:
        this.state.typeAssignedVarco === "P"
          ? this.state.assignedVarco[0].fkIdParcheggio
          : null,
      flgIngressoUscita: this.state.flgEntrataUscita.value,
      noteAddetto: this.state.note,
      timestamp: moment().toISOString(),
    };
    return this.state.service.registerTransito(
      data,
      this.state.typeAssignedVarco
    );
  }

  notifyCentraleOperativa() {
    const data = {
      fkIdPrenotazione: this.props.Prenotazione.selectedFields.idPrenotazione,
      fkIdUtente: this.state.idUtenteAddetto, //TODO prendere da redux
      fkIdVarco:
        this.state.typeAssignedVarco === "VS" ||
        this.state.typeAssignedVarco === "VA"
          ? this.state.assignedVarco[0].fkIdVarco
          : null,
      fkIdParcheggio:
        this.state.typeAssignedVarco === "P"
          ? this.state.assignedVarco[0].fkIdParcheggio
          : null,
      fkIdStatoNotifica: 1,
      note: this.state.note,
    };
    return this.state.service.notifyCentraleOperativa(data);
  }

  setStatePrenotazione() {
    let state = null;
    if (
      (this.state.typeAssignedVarco === "VS" ||
        this.state.typeAssignedVarco === "P") &&
      this.props.Prenotazione.selectedFields.statoPrenotazione
        .codiceStatoPrenotazione === "CR"
    ) {
      //state = 2;
      state = STATUSES.CONFIRMED;
    } else if (
      this.state.typeAssignedVarco === "VA" &&
      (this.props.Prenotazione.selectedFields.statoPrenotazione
        .codiceStatoPrenotazione === "CONF" ||
        this.props.Prenotazione.selectedFields.statoPrenotazione
          .codiceStatoPrenotazione === "CR")
    ) {
      // state = 5;
      state = STATUSES.USED;
    }
    if (state === null) {
      return Promise.resolve(true);
    }
    return this.state.service.setFlgPrenotazione(
      this.props.Prenotazione.selectedFields.idPrenotazione,
      state
    );
  }

  validateForm(param) {
    return new Promise((resolve, reject) => {
      let err = [];
      if (this.state.flgEntrataUscita === "") {
        err.push(this.props.t("VALIDATE_INGRESSO_ERROR"));
      }
      if (param && this.state.note === "") {
        err.push(this.props.t("VALIDATE_NOTE_ERROR"));
      }
      if (err.length) {
        reject(err);
      }
      resolve();
    });
  }

  handlepnrChange(event) {
    if (event) event.preventDefault();

    let result = false;
    this.state.prenotazioneService
      .getMatchBetweenComunePnr(this.state.idComune, this.state.pnr)
      .then((res) => {
        result = res.data.result[0].FLGVALIDATE > 0;
        this.setState(
          {
            loadingAccordion: false,
            isCorrectComune: result,
          },
          () => {
            this.handleSearch = true;
            this.props.triggerGetPrenotazione(this.state.pnr);
          }
        );
      });
  }

  scan() {
    this.setState({
      openScanner: true,
    });
  }

  handleBarCodeScan(barcode) {
    if (!barcode || !barcode.length) {
      this.props.onError("BARCODE_REQUIRED");
    } else {
      this.handleBarCode = true
      this.props.triggerScanBarCodePrenotazione(barcode);
      this.handleBarCode = true
    }
  }

  handleSelectChange(ev) {
    this.setState({selectedPnr: ev} )
  }

  closeModal() {
    this.setState({showModal: false});
  }

  handleModalConfirm() {
    this.setState({pnr:this.state.selectedPnr[0].PNR});
    this.closeModal()
  }

  render() {
    return (
      <React.Fragment>
        <form
          className="row w-100 d-flex justify-content-center"
          onSubmit={(event) => this.handlepnrChange(event)}
        >
          <div className=" col-md-3 input-group mb-3">
            <input
              required
              type="text"
              id="pnr"
              value={this.state.pnr}
              onChange={(ev) => this.setState({ pnr: ev.target.value })}
              name="pnr"
              className="form-control"
              placeholder="Pnr"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={
                  this.state.assignedVarco
                    ? this.state.assignedVarco.length === 0
                    : false
                }
              >
                <i className="fas fa-search fa-sm"></i>
              </button>
              <button
                disabled={
                  this.state.assignedVarco
                    ? this.state.assignedVarco.length === 0
                    : false
                }
                className="btn btn-primary"
                type="button"
                onClick={this.scan.bind(this)}
              >
                <i className="fas fa-qrcode fa-sm"></i>
              </button>
            </div>
          </div>
          <div className="col-md-5 mb-3">
            <BarcodeScanner
              onDetect={(barcode) => {
                this.handleBarCodeScan(barcode);
              }}
            />
          </div>
        </form>
        <span>
          {this.props.t("INSERT_PNR_OR_DOC")}
        </span>
        {this.state.loadingAccordion && (
          <>
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.isCorrectComune}
            >
              {this.props.t("LABEL_WRONG_COMUNE")}
            </div>
            <PrenotazioneDetailForm
              isWrongComune={!this.state.isCorrectComune}
              isEdit={true}
              fromVarco={true}
              maxOccupanti={this.state.comune.maxOccupanti}
              dataInizio={this.state.selectedDataInizio}
            />
            <div hidden={!this.state.isCorrectComune}>
              <label className="w-100 text-center" htmlFor={"note"}>
                {this.props.t("Notes")}:
              </label>
              <textarea
                value={this.state.note}
                onChange={(event) =>
                  this.setState({ note: event.target.value })
                }
                className="form-control"
                name="Note"
                id="Note"
              />
            </div>
            <div className="col-12 d-flex justify-content-center mt-2">
              <div
                hidden={!this.state.isCorrectComune}
                className="col-sm-12 col-md-4 col-lg-4"
              >
                <label
                  className="w-100 text-center"
                  htmlFor={"flgEntrataUscita"}
                >
                  {this.props.t("Entrance")}/{this.props.t("Exit")}
                </label>
                <Select
                  value={this.state.flgEntrataUscita}
                  isMulti={false}
                  isSearchable={false}
                  options={this.state.dropdownOptions}
                  onChange={(value) =>
                    this.setState({ flgEntrataUscita: value })
                  }
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-2">
              <button
                hidden={!this.state.isCorrectComune}
                className="btn btn-success ml-1"
                aria-label="Salva"
                onClick={() => {
                  this.handleRegister("OK");
                  this.setState({ loadingAccordion: false, pnr: "" });
                }}
              >
                {this.props.t("ControlOk")}
              </button>
              <button
                hidden={!this.state.isCorrectComune}
                className="btn btn-danger ml-1"
                aria-label="Salva"
                onClick={() => {
                  this.handleRegister("KO");
                  this.setState({ loadingAccordion: false, pnr: "" });
                }}
              >
                {this.props.t("ControlKo")}
              </button>
            </div>
          </>
        )}
        <QrCodeReader
          show={this.state.openScanner}
          onClose={() => {
            this.setState({
              openScanner: false,
            });
          }}
          onRead={(code) => {
            this.setState({ pnr: code, openScanner: false }, () => {
              this.handlepnrChange();
            });
          }}
        />
        <Modal
            isOpen={this.state.showModal}
            toggle={() => this.closeModal()}
            size="md"
        >
          <ModalHeader toggle={() => this.closeModal()}>
            {this.props.t("MODAL_CONTROLLO_LABEL")}
          </ModalHeader>
          <ModalBody>
            <div className="col-sm-12">
              <SelectPicker
                  values={this.state.selectedPnr}
                  options={this.state.arrayPnr}
                  displayValue={'label'}
                  onChange={(ev) => {
                    this.handleSelectChange(ev);
                  }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.handleModalConfirm()}>
              {this.props.t("OK")}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
const ControlloVarco = RxComponent({
  triggerGetPrenotazione,
  onError,
  onSuccess,
  triggerScanBarCodePrenotazione,
})(Component);
export default ControlloVarco;
