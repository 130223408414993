import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_AREA_DETAIL_PRENOTAZIONE = "generic/search3/prenotazione/area";
export default class AreaHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Area";
  }

  getAreaDetailPrenotazione(idArea) {
    return this.API.post(GET_AREA_DETAIL_PRENOTAZIONE, { idArea: idArea });
  }
}
