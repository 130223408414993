import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import QrReader from 'react-qr-reader'
import _ from 'lodash';

export default class QrCodeReader extends React.PureComponent{

    state={
        openModal:false,
        code:''
    }

    componentDidMount(){
        
    }

    componentDidUpdate(){
        this.setState({
            openModal:this.props.show
        })
    }

    closePicker(){
        this.setState({
            code:null
        },()=>{
            if(_.isFunction(this.props.onClose)){
                this.props.onClose(this.state.code);
            }
        })
        
    }


    handleScan(data){
        if(data && data.length){
            document.getElementsByTagName("BODY")[0].removeAttribute('class')
            this.setState({
            code:data,
            openModal:false
        },()=>{
            if(_.isFunction(this.props.onRead)){
                this.props.onRead(this.state.code);
            }
        });
        }
    }

    handleError(ex){
        
    }

    render(){
        return (
            <Modal
                style={this.props.style}
                isOpen={this.state.openModal}
                toggle={() => this.closePicker()}
                size="md"
            >
                <ModalHeader toggle={() => this.closePicker()}>
                    <h5>{this.state.code || "Reading..."}</h5>
                </ModalHeader>
                <ModalBody>
                    <QrReader
                        delay={300}
                        onError={this.handleError.bind(this)}
                        onScan={this.handleScan.bind(this)}
                        style={{ width: '100%' }}
                    />
                    
                </ModalBody>
        </Modal>
        )
    }

}