import { put, call, takeLatest, take } from "redux-saga/effects";
import LoginService from "../../pages/Login/Login.service";
import GestoreAreaService from "../../modules/AssegnaGestoreArea/AssegnaGestoreArea.service";
import {
  successLogin,
  successLoginTaal,
  errorLoginTaal,
  errorLogin,
  successRoles,
  errorRoles,
  successRegister,
  errorRegister,
  successOtp,
  onLoginTaal,
  successPasswordRecovery,
  errorPasswordRecovery,
  successChangePassword,
  errorChangePassword,
} from "./Auth.actions";
import {
  SUCCESS_GOOGLE_LOGOUT,
  ON_TAAL_LOGIN,
  ON_TAAL_LOGOUT,
  TRIGGER_REGISTER,
  TRIGGER_OTP,
  TRIGGER_SEND_OTP,
  SUCCESS_LOGOUT,
  TRIGGER_LOGOUT,
  TRIGGER_PASSWORD_RECOVERY,
  TRIGGER_CHANGE_PASSWORD,
  TRIGGER_REGISTER_VARCO,
  TRIGGER_CHANGE_PASSWORD_SAFE,
  TRIGGER_LOGOUT_NO_LOGIN,
} from "./Auth.types";
import { hist } from "../../App";
import { onError, onSuccess } from "../Shared/Shared.actions";
import { ROLES } from "../../constants/Roles";

const service = new LoginService();
const gestoreAreaService = new GestoreAreaService();

function* loginSaga(action) {
  const loggedTaal = yield* loginTaal(
    action.payload.username,
    action.payload.password
  );

  if (loggedTaal && !loggedTaal.flgEliminato) {
    if (loggedTaal.flgAttivo) {
      yield put(successLogin());
      if (loggedTaal.TipologiaProfilo.codiceProfilo === ROLES.UTENTE_GENERICO) {
        window.location.replace("/site/home");
        //hist.push("/site/home");
      } else {
        window.location.replace(
          "/dashboard/home"
        ); /*hist.push("/dashboard/home");*/
      }
    } else {
      yield* sendOtpSaga({
        payload: {
          idUtente: loggedTaal.idUtente,
          username: loggedTaal.username,
          token: loggedTaal.token,
        },
      });
      hist.push("/otp");
    }
  } else {
    yield put(errorLogin("BAD_CREDENTIAL"));
    yield put(onError("BAD_CREDENTIAL"));
  }
}

function* loginTaal(username, password) {
  try {
    const loginTaalData = yield call(() =>
      service.loginTaal(username, password).then((resp) => resp.data)
    );
    //const loginHrData= await service.loginHr(googleToken);
    if (loginTaalData.error) {
      throw new Error(loginTaalData.error);
    }

    // qui una volta chiamato pulsante reset pwd new
    const userData = yield call(() =>
      service
        .readUserData(loginTaalData.idUtente, loginTaalData.token)
        .then((resp) => resp.data)
    );
    if (!userData.result[0].FLGPASSWORDSAFE) {
      yield put(successLoginTaal({ ...loginTaalData, ...null }));
      hist.push("/passwordrecovery", { safe: true });
      return;
    } // se userdata ha il flag di fede redirect ad altra pagina)
    // poi una volta fatto il reset pwd se sono loggato...

    if (
      userData.result[0].TipologiaProfilo.codiceProfilo === ROLES.GESTORE_AREA
    ) {
      const gestoreCompleto = yield call(() =>
        gestoreAreaService.getRecordInLogin(
          loginTaalData.idUtente,
          loginTaalData.token
        )
      );

      userData.result[0].fkIdComune =
        gestoreCompleto.data.result[0].Area.Comune.idComune;
    }
    yield put(successLoginTaal({ ...loginTaalData, ...userData.result[0] }));
    return { ...loginTaalData, ...userData.result[0] };
  } catch (ex) {
    yield put(errorLoginTaal(ex.message));
    return false;
  }
}

function* readRoles(idUser, token) {
  try {
    const readRolesData = yield call(() =>
      service.readRoles(idUser, token).then((resp) => resp.data.result)
    );
    if (readRolesData.error) {
      throw new Error(readRolesData.error);
    }
    yield put(successRoles(readRolesData));
    return readRolesData;
  } catch (ex) {
    yield put(errorRoles(ex.message));
    return false;
  }
}

function* logoutGoogle() {
  yield put({ type: SUCCESS_GOOGLE_LOGOUT });
  //add other logout
}

function* registerSaga(registerData) {
  try {
    const registerRes = yield call(() =>
      service.register(registerData.payload).then((resp) => resp.data)
    );

    if (!registerRes.error) {
      if (
        registerRes.result[0].idOtp === 0 &&
        registerRes.result[0].idUtente === 0
      ) {
        yield put(onError("NUMBER_OR_DOC_ALREADY_EXIST"));
      } else {
        yield put(successRegister(registerRes.result));
        yield put(
          onLoginTaal(
            registerData.payload.USERNAME,
            registerData.payload.PASSWORD
          )
        );
      }
    } else {
      throw new Error("ERROR ON REGISTER");
    }
  } catch (ex) {
    yield put(errorRegister(ex.message));
    yield put(onError(ex.message));
  }
}

function* registerVarcoSaga(registerData) {
  try {
    const registerRes = yield call(() => {
      if (registerData.payload.fast) {
        return service.registerFast(registerData.payload.data).then((resp) => {
          return resp.data;
        });
      }
      return service.register(registerData.payload).then((resp) => {
        return resp.data;
      });
    });
    //"result":[{"idUtente":0,"errorMessage":"50001"}]
    if (!registerRes.error) {
      if (
        registerRes.result[0].idOtp === 0 &&
        registerRes.result[0].idUtente === 0
      ) {
        yield put(onError("NUMBER_OR_DOC_ALREADY_EXIST"));
      } else if (registerRes.result[0].idUtente === 0) {
        yield put(onError("NUMBER_OR_DOC_ALREADY_EXIST"));
      } else {
        yield put(successRegister(registerRes.result));
        yield put(onSuccess("REGISTRAZIONE_OK"));
      }
    } else {
      throw new Error("ERROR ON REGISTER");
    }
  } catch (ex) {
    yield put(errorRegister(ex.message));
    yield put(onError(ex.message));
  }
}

function* otpSaga(data) {
  const idUtente = data.payload.idUtente;
  const otp = data.payload.otp;
  const token = data.payload.token;
  const otpRes = yield call(() =>
    service.checkOtp(idUtente, otp, token).then((resp) => resp.data)
  );
  if (!otpRes.error && otpRes.result[0].esito) {
    yield put(successOtp());
    yield put(successLogin());
    yield put(onSuccess("REGISTRAZIONE_OK"));
    window.location.replace("/site/home"); //hist.push("/site/home");
  } else {
    yield put(errorRegister(otpRes.error || otpRes.result[0].errorMessage));
    yield put(onError(otpRes.error || otpRes.result[0].errorMessage));
  }
}

function* sendOtpSaga(action) {
  const idUtente = action.payload.idUtente;
  const username = action.payload.username;
  const token = action.payload.token;
  const otpRes = yield call(() =>
    service.sendOtp(idUtente, username, token).then((resp) => {
      return resp.data;
    })
  );
  if (!otpRes.error) {
    yield put(onSuccess("OTP_SEND"));
  } else {
    yield put(onError(otpRes.error || otpRes.result[0].errorMessage));
  }
}

function* logoutSaga() {
  yield put({ type: SUCCESS_LOGOUT });
  setTimeout(() => window.location.replace("/login"), 500);
}

function* logoutWithoutLoginSaga() {
  yield put({ type: SUCCESS_LOGOUT });
}

function* passwordRecoverySaga(action) {
  const serverResponse = yield call(() =>
    service.forgetPassword(action.payload)
  );

  if (!serverResponse.error) {
    yield put(successPasswordRecovery());
    yield takeLatest(TRIGGER_CHANGE_PASSWORD, changePasswordSaga);
  } else {
    yield put(
      errorPasswordRecovery(
        serverResponse.error || serverResponse.result[0].errorMessage
      )
    );
    yield put(
      onError(serverResponse.error || serverResponse.result[0].errorMessage)
    );
  }
}

function* changePasswordSafeSaga(action) {
  const username = action.payload.username;
  const password = action.payload.password;
  const otp = action.payload.otp;
  try {
    const changePasswordRes = yield call(() =>
      service
        .changePasswordByOtp(username, password, otp)
        .then((resp) => resp.data)
    );
    if (!changePasswordRes.error && changePasswordRes.result[0].esito) {
      yield put(successChangePassword());
      yield put(onSuccess("PASSWORD_CHANGED"));
      window.location.replace("/login");
    } else {
      throw new Error(
        changePasswordRes.error || changePasswordRes.result[0].errorMessage
      );
    }
  } catch (ex) {
    yield put(errorChangePassword(ex.message));
    yield put(onError(ex.message));
  }
}

//
// nuova saga di resetpwd
// invece di fare il replace con login, visto che sono già loggato
//

function* changePasswordSaga(action) {
  const username = action.payload.username;
  const password = action.payload.password;
  const otp = action.payload.otp;

  try {
    const changePasswordRes = yield call(() =>
      service
        .changePasswordByOtp(username, password, otp)
        .then((resp) => resp.data)
    );
    if (!changePasswordRes.error && changePasswordRes.result[0].esito) {
      yield put(successChangePassword());
      yield put(onSuccess("PASSWORD_CHANGED"));
      window.location.replace("/login");
    } else {
      throw new Error(
        changePasswordRes.error || changePasswordRes.result[0].errorMessage
      );
    }
  } catch (ex) {
    yield put(errorChangePassword(ex.message));
    yield put(onError(ex.message));
  }
}

export function* googlelogin() {
  //wait for login
  yield takeLatest(ON_TAAL_LOGIN, loginSaga);

  //wait for logout
  yield takeLatest(ON_TAAL_LOGOUT, logoutGoogle);

  //wait for register
  yield takeLatest(TRIGGER_REGISTER, registerSaga);

  //wait for registerVarco
  yield takeLatest(TRIGGER_REGISTER_VARCO, registerVarcoSaga);

  //wait for otp
  yield takeLatest(TRIGGER_OTP, otpSaga);

  //wait for send otp
  yield takeLatest(TRIGGER_SEND_OTP, sendOtpSaga);

  yield takeLatest(TRIGGER_LOGOUT, logoutSaga);

  yield takeLatest(TRIGGER_PASSWORD_RECOVERY, passwordRecoverySaga);

  yield takeLatest(TRIGGER_CHANGE_PASSWORD_SAFE, changePasswordSafeSaga);

  yield takeLatest(TRIGGER_LOGOUT_NO_LOGIN, logoutWithoutLoginSaga);
}
