import BaseServiceTaal from "../../Service/BaseServiceTaal";
import ComuneBanner from "./ComuneBanner.repo";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";

export default class ComuneBannerService extends BaseServiceTaal {
  getRepo = () => ComuneBanner.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) => {
      return repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        null
      );
    });

  getComuneBannerPrenotazione = (idComune) =>
    this.getRepo().then((repo) => repo.getComuneBannerPrenotazione(idComune));

  updateSettings = (newSettings, idComune) =>
    this.getRepo().then((repo) => {
      const filter = {
        [`${FILTER_NUMBER}&&&FkidComune`]: [idComune],
      };
      return repo.updateflds(
        { imgBanner: newSettings.imgBanner, formato: newSettings.formato },
        this.transformFilterToServer(filter)
      );
    });
}
