import StubRepo from "../../repos/StubRepo";
import TipologiaPrenotazioneMockRepo from "./repositories/TipologiaPrenotazione.mock";
import TipologiaPrenotazioneHttpRepo from "./repositories/TipologiaPrenotazione.http";

export default class TipologiaPrenotazione extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "TipologiaPrenotazione repo";
  }

  static getLocal() {
    if (TipologiaPrenotazione._localInstance == null) {
      TipologiaPrenotazione._localInstance = new TipologiaPrenotazioneMockRepo();
    }
    return TipologiaPrenotazione._localInstance;
  }

  static getService() {
    if (TipologiaPrenotazione._serviceInstance == null) {
      TipologiaPrenotazione._serviceInstance = new TipologiaPrenotazioneHttpRepo();
    }
    return TipologiaPrenotazione._serviceInstance;
  }
}
