import React, { Component } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import RxComponent from "../../redux/RxComponent";
import pinUmbrella from "../../template/site/pngs/pin.png";
import { PINS } from "./../../constants/pins";
import { PINSPARK } from "./../../constants/pinsParcheggi";
import { triggerSelectArea } from "../../redux/Prenotazione/Prenotazione.actions";
import { ROLES } from "../../constants/Roles";
import { AREE } from "./../../constants/Aree";
import { AREASTATUSES } from "../../constants/AreaStatus";

const containerStyle = {
  width: "100%",
  minHeight: "400px",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const mapStyles = require("../../template/site/jsonStyle/mapsStyle.json");

class Comp extends React.PureComponent {
  currentMap = null;

  state = {
    isOpen: false,
    markers: [],
  };

  pin = {
    url: PINS.RISTORANTE_GIALLO.pin,
  };

  pin2 = pinUmbrella;

  componentDidMount() {
    let initialCenter = this.findCenter();
    this.setState({
      center: initialCenter,
    });
  }

  setPin(point) {
    let pinToRet = [];

    var pins = Object.values(PINS);
    var pinsPark = Object.values(PINSPARK);
    let perc = 0;
    if (point.area) {
      perc =
        ((point.area.POSTI_TOTALI - point.area.POSTI_LIBERI) * 100) /
        point.area.POSTI_TOTALI;
    } else if (point.parcheggio) {
      perc =
        ((point.parcheggio.POSTI_TOTALI - point.parcheggio.POSTI_LIBERI) *
          100) /
        point.parcheggio.POSTI_TOTALI;
    }
    let percToCompare = perc > 69 ? -1 : perc > 35 ? 0 : 1;

    if (point.area) {
      pinToRet = pins.filter((el) => {
        return (
          el.areaCod === point.area.CODICETIPOLOGIAAREA &&
          percToCompare === el.stato
        );
      });
    } else if (point.parcheggio) {
      pinToRet = pinsPark.filter((el) => {
        return percToCompare === el.stato;
      });
    }

    if (!pinToRet[0]) {
      pinToRet = pins.filter((el) => {
        return (
          el.areaCod === AREE.SPIAGGIA_ATTREZZATA && percToCompare === el.stato
        );
      });
    }

    return pinToRet[0] ? pinToRet[0].pin : null;
  }

  findCenter(lat, lng) {
    if (lat && lng) {
      return {
        lat: lat,
        lng: lng,
      };
    }

    if (this.props.points && this.props.points.length) {
      const lats = this.props.points.map(function (point, index) {
        if(point) return point.lat;
      });
      const lons = this.props.points.map(function (point, index) {
        if(point) return point.lon;
      });

      const latMax = Math.max.apply(Math, lats);
      const latMin = Math.min.apply(Math, lats);
      const lonMax = Math.max.apply(Math, lons);
      const lonMin = Math.min.apply(Math, lons);

      const latDef = (latMax + latMin) / 2;
      const lonDef = (lonMax + lonMin) / 2;

      return { lat: latDef, lng: lonDef };
    } else {
      return {
        lat:
          this.props.Auth.user.Comune && this.props.Auth.user.Comune.latitudine
            ? this.props.Auth.user.Comune.latitudine
            : 41.4676,
        lng:
          this.props.Auth.user.Comune && this.props.Auth.user.Comune.longitudine
            ? this.props.Auth.user.Comune.longitudine
            : 12.9036,
      };
    }
  }

  onToggleOpen(point, index) {
    /*let center = {
      lat: point.lat,
      lng: point.lon,
    };*/

    this.setState({
      openedMarker: index === this.state.openedMarker ? null : index,
      //center: center,
    });
  }

  onAreaSelect(area) {
    if (window.location.href.indexOf("/dashboard") >= 0) {
      area.fromDashboard = true;
    }
    this.setState(
      {
        openedMarker: null,
        center: null,
      },
      () => this.props.triggerSelectArea(area)
    );
  }

  infoBoxGenerator(record) {
    return (
      <div
        style={{
          backgroundColor: `white`,
          padding: `12px`,
          borderRadius: "5px",
        }}
      >
        <div
          className="text-weight-500 card-title"
          style={{ fontSize: `16px`, fontColor: `#08233B` }}
        >
          {record.area
            ? record.area.DESCRIZIONE
            : record.parcheggio
            ? record.parcheggio.DESCRIZIONE
            : null}
          {record.parcheggio ? (
            <div>
              {this.props.t("POSTI_LIBERI") +
                ": " +
                record.parcheggio.POSTI_LIBERI}{" "}
            </div>
          ) : null}
        </div>
        {record.area &&
          record.area.POSTI_LIBERI > 0 &&
          record.area.CODICESTATO !== AREASTATUSES.CLOSED && (
            <button
              className="btn btn-secondary mt-2 ml-2"
              onClick={() => {
                if (record.area) {
                  this.onAreaSelect(record.area);
                }
              }}
            >
              {this.props.t("BOOK_NOW")}
            </button>
          )}
      </div>
    );
  }

  addPin(e) {
    const markers = [];
    if (
      this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
        ROLES.CENSORE_AREA ||
      this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
        ROLES.SUPER_ADMIN ||
      this.props.Auth.user.TipologiaProfilo.codiceProfilo ===
        ROLES.ADMIN_COMUNE ||
      this.props.Auth.user.TipologiaProfilo.codiceProfilo === ROLES.GESTORE_AREA
    ) {
      const mark = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      markers.push(mark);
      this.setState({
        markers,
      });

      this.props.onSelectPin(mark);
    }
  }

  onConfirmPin(marker) {}

  render() {
    const iconBase = "https://maps.google.com/mapfiles/kml/shapes/";
    const styl = mapStyles;
    const center = this.findCenter();
    return (
      <LoadScript
        onLoad={() => this.setState({ showMap: true })}
        googleMapsApiKey="AIzaSyAC7a4_2inuCBH660_VWpUlYFnAt4jQdxg"
      >
        {this.state.showMap && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            width={"100%"}
            height={"100%"}
            styles={mapStyles}
            onLoad={(map) => {
              this.currentMap = map;
              this.setState({
                showInfo: true,
              });
            }}
            onClick={(e) => this.addPin(e)}
            options={{ styles: mapStyles }}
          >
            {this.state.showInfo && (
              <>
                {this.props.points &&
                  this.props.points.map((point, index) => {
                    if (point) {
                      let pinn = this.setPin(point);
                      return (
                        <Marker
                          options={{
                            map: this.currentMap,
                          }}
                          key={index}
                          position={{
                            lat: point.lat,
                            lng: point.lon,
                          }}
                          icon={pinn}
                          //onMouseOver={() => this.onToggleOpen(point, index)}
                          //onMouseOut={() => this.onToggleOpen(point, index)}
                          onClick={() => this.onToggleOpen(point, index)}
                        >
                          {this.state.openedMarker === index && (
                            <InfoWindow
                              onCloseClick={() =>
                                this.onToggleOpen(point, index)
                              }
                              options={{
                                closeBoxURL: ``,
                                enableEventPropagation: true,
                              }}
                            >
                              {point.area || point.parcheggio
                                ? this.infoBoxGenerator(point)
                                : null}
                            </InfoWindow>
                          )}
                        </Marker>
                      );
                    }
                  })}

                {this.state.markers.map((marker, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                  >
                    <InfoWindow
                      options={{
                        closeBoxURL: ``,
                        enableEventPropagation: true,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: `white`,
                          padding: `12px`,
                          borderRadius: "5px",
                        }}
                      >
                        latidudine: {marker.lat} <br />
                        longitudine: {marker.lng}
                      </div>
                    </InfoWindow>
                  </Marker>
                ))}
              </>
            )}
          </GoogleMap>
        )}
      </LoadScript>
    );
  }
}
const MapComponent = RxComponent({ triggerSelectArea })(Comp);
export default MapComponent;
