import StubRepo from "../../repos/StubRepo";
import StatoAssegnazioneMockRepo from "./repositories/StatoAssegnazione.mock";
import StatoAssegnazioneHttpRepo from "./repositories/StatoAssegnazione.http";

export default class StatoAssegnazione extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "StatoAssegnazione repo";
  }

  static getLocal() {
    if (StatoAssegnazione._localInstance == null) {
      StatoAssegnazione._localInstance = new StatoAssegnazioneMockRepo();
    }
    return StatoAssegnazione._localInstance;
  }

  static getService() {
    if (StatoAssegnazione._serviceInstance == null) {
      StatoAssegnazione._serviceInstance = new StatoAssegnazioneHttpRepo();
    }
    return StatoAssegnazione._serviceInstance;
  }
}
