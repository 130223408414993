import StubRepo from "../../repos/StubRepo";
import EventoHttpRepo from "./repositories/Evento.http";
import EventoMockRepo from "./repositories/Evento.mock";

export default class EventoRepo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Evento repo";
  }

  static getLocal() {
    if (EventoRepo._localInstance == null) {
        EventoRepo._localInstance = new EventoMockRepo();
    }
    return EventoRepo._localInstance;
  }

  static getService() {
    if (EventoRepo._serviceInstance == null) {
        EventoRepo._serviceInstance = new EventoHttpRepo();
    }
    return EventoRepo._serviceInstance;
  }
}
