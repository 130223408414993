import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Prenotazione from "./Prenotazione.repo";
import moment from "moment";
import {
  FILTER_TEXT,
  FILTER_TEXT_EQUALS,
} from "../../components/TableServer/FilterItem";
import { STATUSES } from "../../constants/PrenotazioneStatuses";

const SEND_SMS = "fn_GetSMSParameters";
const SEND_MAIL_GESTORE = "fn_GetEmailInfo";

export default class PrenotazioneService extends BaseServiceTaal {
  getRepo = () => Prenotazione.getInstanceTaal();

  validateDisponibilitaForm(formField) {
    let errors = [];
    /* if (!formField.tipologiaPrenotazione) {
        errors.push("Seleziona una tipologia di prenotazione");
      }
      if (!formField.area) {
        errors.push("Seleziona un'area");
      }*/
    if (!formField.idComune) {
      errors.push("INVALID_MUNICIPALITY");
    }
    /* if (!formField.numeroOccupanti || formField.numeroOccupanti <= 0) {
      errors.push("il numero di occupanti deve essere maggiore di zero");
    }*/
    if (!moment(formField.dataPrenotazione).isValid()) {
      errors.push("INVALID_PREN_DATE");
    }
    if (errors.length) {
      return {
        error: errors,
      };
    }
    return {
      error: null,
    };
  }

  getStringaOspiti = (listaOspiti) => {
    let stringaDef = "";
    let listApp = listaOspiti.map((el) => {
      let a = el.tipologiaDocumento.idTipologiaDocumento.toString().concat(",");
      a = a.concat(el.numeroDocumento);
      a = a.concat(",");
      a = a.concat(el.nome);
      a = a.concat(",");
      a = a.concat(el.cognome);
      return a;
    });
    let str = listApp.join(";");
    stringaDef = str;
    return stringaDef;
  };

  getObjForPrenotazioneInsert = (prenotazione) => {
    let stringaOspiti = this.getStringaOspiti(prenotazione.anagrafiche);
    let og = {
      ISEVENTO:prenotazione.isEvento,
      IDUTENTEPRENOTATO: prenotazione.IDUTENTEPRENOTATO,
      IDAREA: prenotazione.idArea,
      IDTIPOLOGIAPRENOTAZIONE: prenotazione.selectedTipologiaPrenotazioneId || 0,
      IDFASCIAORARIA: prenotazione.selectedFasciaOrariaId || 0,
      DATAINIZIO: prenotazione.selectedDataInizio,
      DATAFINE: prenotazione.selectedDataFine,
      NUMEROOCCUPANTI: prenotazione.numeroOccupanti,
      IDTIPOLOGIAVEICOLO: prenotazione.selectedTipoVeicoloId,
      IDPARCHEGGIO: prenotazione.idParcheggio,
      TARGA: prenotazione.targaVeicolo,
      MARCA: null,
      MODELLO: null,
      COLORE: null,
      ARRAYOSPITI: stringaOspiti ? stringaOspiti : null,
    };
    return og;
  };

  getObjForPrenotazioneUpdate = (prenotazione) => {
    let stringaOspiti = this.getStringaOspiti(prenotazione.anagrafiche);
    let og = {
      IDUTENTE: prenotazione.idUtente,
      IDPRENOTAZIONE: prenotazione.idPrenotazione,
      NUMEROOCCUPANTI: prenotazione.numeroOccupanti,
      DATAINIZIO: prenotazione.dataInizioCompleta,
      IDVEICOLO: prenotazione.idVeicolo,
      IDTIPOLOGIAVEICOLO: prenotazione.selectedTipoVeicoloId,
      IDPARCHEGGIO: prenotazione.idParcheggio,
      TARGA: prenotazione.targaVeicolo,
      MARCA: null,
      MODELLO: null,
      COLORE: null,
      ARRAYOSPITI: stringaOspiti ? stringaOspiti : null,
    };
    return og;
  };

  validateParameterForPrenotazioneInsert = (prenotazione) => {
    let errors = [];
    /*if (!prenotazione.IDUTENTE) {
      errors.push(" is null");
    }*/
    if (!prenotazione.IDAREA) {
      errors.push("IDAREA is null");
    }
    if(!prenotazione.ISEVENTO) {
      if (!prenotazione.IDTIPOLOGIAPRENOTAZIONE) {
        errors.push("IDTIPOLOGIAPRENOTAZIONE is null");
      }
      if (!prenotazione.IDFASCIAORARIA) {
        errors.push("IDFASCIAORARIA is null");
      }
    }

    if (!prenotazione.DATAINIZIO) {
      errors.push("DATAINIZIO is null");
    }
    if (!prenotazione.DATAFINE) {
      errors.push(" is null");
    }
    if (!prenotazione.NUMEROOCCUPANTI) {
      errors.push("NUMEROOCCUPANTI is null");
    }
    /*if (!prenotazione.IDTIPOLOGIAVEICOLO) {
      errors.push("IDTIPOLOGIAVEICOLO is null");
    }*/
    if (prenotazione.IDTIPOLOGIAVEICOLO) {
      if (
        !prenotazione.TARGA &&
        (prenotazione.IDTIPOLOGIAVEICOLO === 1 ||
          prenotazione.IDTIPOLOGIAVEICOLO === 6 ||
          prenotazione.IDTIPOLOGIAVEICOLO === 7)
      ) {
        errors.push("TARGA is null");
      }
    }
    /*if (!prenotazione.MARCA) {
      errors.push("MARCA is null");    }
    if (!prenotazione.MODELLO) {
      errors.push("MODELLO is null");    }
    if (!prenotazione.COLORE) {
      errors.push("COLORE is null");    }
    if (!prenotazione.IDPARCHEGGIO) {
      errors.push("IDPARCHEGGIO is null");    }*/
    /*if (!prenotazione.ARRAYOSPITI) {
      errors.push("ARRAYOSPITI is null");
    }*/

    return errors;
  };

  validateParameterForPrenotazioneUpdate = (prenotazione) => {
    let errors = [];
    if (!prenotazione.IDUTENTE) {
      errors.push("IDUTENTE is null");
    }
    if (!prenotazione.NUMEROOCCUPANTI) {
      errors.push("NUMEROOCCUPANTI is null");
    }

    if (!prenotazione.IDPRENOTAZIONE) {
      errors.push("IDPRENOTAZIONE is null");
    }

    /*if (!prenotazione.IDVEICOLO) {
      errors.push("IDVEICOLO is null");
    }
    if (
      !prenotazione.TARGA &&
      (prenotazione.IDTIPOLOGIAVEICOLO === 1 ||
        prenotazione.IDTIPOLOGIAVEICOLO === 6 ||
        prenotazione.IDTIPOLOGIAVEICOLO === 7)
    ) {
      errors.push("TARGA is null");
    }*/

    if (prenotazione.IDVEICOLO && prenotazione.IDTIPOLOGIAVEICOLO) {
      if (
        !prenotazione.TARGA &&
        (prenotazione.IDTIPOLOGIAVEICOLO === 1 ||
          prenotazione.IDTIPOLOGIAVEICOLO === 6 ||
          prenotazione.IDTIPOLOGIAVEICOLO === 7)
      ) {
        errors.push("TARGA is null");
      }
    }
    /*if (!prenotazione.MARCA) {
      errors.push("MARCA is null");    }
    if (!prenotazione.MODELLO) {
      errors.push("MODELLO is null");    }
    if (!prenotazione.COLORE) {
      errors.push("COLORE is null");    }
    if (!prenotazione.IDPARCHEGGIO) {
      errors.push("IDPARCHEGGIO is null");    }*/
    /*if (!prenotazione.ARRAYOSPITI) {
      errors.push("ARRAYOSPITI is null");
    }*/

    return errors;
  };

  insertPrenotazionSP = (formField) =>
    this.getRepo().then((repo) =>
      repo.exec("sp_CreatePrenotazione", formField)
    );

  insertPrenotazionSPBO = (formField) =>
    this.getRepo().then((repo) =>
      repo.exec("sp_CreatePrenotazioneBO", formField)
    );

  updatePrenotazionSP = (formField) =>
    this.getRepo().then((repo) =>
      repo.exec("sp_UpdatePrenotazione", formField)
    );

  searchDisponibilita = (formField) => {
    if (formField.IDUTENTE) {
      return this.getRepo().then((repo) => repo.exec("fn_GetAreeComuneBO", formField));
    }
    formField = {...formField, checkUtente: 1}
    return this.getRepo().then((repo) => repo.exec("fn_GetAreeComune", formField));
  }

  searchTipologieArea = (formField) =>
    this.getRepo().then((repo) =>
      repo.exec("fn_GetTipologieComune", formField)
    );

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        [
          "Area",
          "FasciaOraria",
          "Veicolo",
          "Parcheggio",
          "TipologiaPrenotazione.RelTipoPrenotazioneTipoDurata_List.TipologiaDurata.RelTipoDurataFasciaOraria_List.FasciaOraria",
          "StatoPrenotazione",
          "Veicolo.TipologiaVeicolo",
          "OspitePrenotazione_List.Documento.TipologiaDocumento",
          "StatoPagamento",
        ]
      )
    );

  /*
    [fn_GetPrenotazioniUtente]
(
	@IDUTENTE							INT
)
RETURNS @ReturnTable TABLE (
	DESCLOCSTATOPRENOTAZIONE			VARCHAR(200),
	DESCAREA							VARCHAR(200),
	DESCLOCTIPOLOGIAPRENOTAZIONE		VARCHAR(200),
	DATAINIZIO							DATETIME,
	DESCLOCFASCIAORARIA					VARCHAR(200),
	PNR									VARCHAR(30)
)

    */

  getPrenotazioniUtente(idUtente, pageNum, itemsPerPage, ff, sort) {
    return this.getRepo().then((repo) =>
      repo.exec(
        "fn_GetPrenotazioniUtente",
        { IDUTENTE: idUtente },
        pageNum - 1,
        itemsPerPage,
        null,
        this.transformOrdersToServer(sort)
      )
    );
  }
  // getPrenotazioniUtente(idUtente,sort) {
  //   return this.getRepo().then(repo =>
  //   repo.search(1,-1,null, null,'fn_GetPrenotazioni'))
  // }

  getMatchBetweenComunePnr(idComune, pnr) {
    return this.getRepo().then((repo) =>
      repo.exec("fn_ValidatePnrTable", { IDCOMUNE: idComune, PNR: pnr })
    );
  }

  getPrenotazionePnrNewApi = (pnr) =>
    this.getRepo().then((repo) => repo.getPrenotazionePnrNewApi(pnr));

  getPrenotazioneByPnr(pnr) {
    return this.getPrenotazionePnrNewApi(pnr).then((res) => {
      if (res.data.result && res.data.result.length) {
        let data = res.data.result;
        let rex = {
          filter: {
            dataPrenotazione: moment(data[0].dataInizio).format("YYYYMMDD"),
            pnr: data[0].pnr,
            idParcheggio: data[0].Parcheggio
              ? data[0].Parcheggio.idParcheggio
              : null,
            descParcheggio: data[0].Parcheggio
              ? data[0].Parcheggio.descrizione
              : null,
            dataFine: data[0].dataFine,
            dataInizio: data[0].dataInizio,
            idComune: data[0].Area.fkIdComune,
            statoPagamento: data[0].StatoPagamento,
            numeroOccupanti: data[0].numeroOccupanti,
            tipologiaPrenotazione: [data[0].TipologiaPrenotazione],
            fasciaOraria: [data[0].FasciaOraria],
            veicolo: data[0].Veicolo,
            listaPasseggeri: data[0].OspitePrenotazione_List.map((el) => {
              return {
                nome: el.nome,
                cognome: el.cognome,
                numeroDocumento: el.Documento.numeroDocumento,
                tipologiaDocumento: el.Documento.TipologiaDocumento,
              };
            }),
            statoPrenotazione: data[0].StatoPrenotazione,
            idPrenotazione: data[0].idPrenotazione,
          },
          area: {
            ACCESSOANIMALI: data[0].Area.flgAnimaliAmmessi,
            ACCESSODISABILI: data[0].Area.flgAccessoDisabili,
            DESCRIZIONE: data[0].Area.descrizione,
            FORMATO: data[0].Area.formato,
            FOTO: null,
            IDAREA: data[0].Area.idArea,
            IDTIPOLOGIAAREA: data[0].Area.fkIdTipologiaArea,
            IDCOMUNE: data[0].Area.fkIdComune,
          },
        };
        res.data.result = rex;
      }
      return res.data;
    });
  }

  // getPnrByBarcode(barcode) {
  //   return this.getRepo().then((repo) =>
  //     repo.exec("fn_GetPNRVarco", {
  //       numeroDocumento: barcode,
  //       idTipoDocumento: null,
  //       targa: null,
  //     })
  //   );
  // }

  getPnrByBarcode(barcode) {
    return this.getRepo().then((repo) =>
        repo.exec("sp_GetAllPrenotazioniPnrVarco", {
          data: moment().format("YYYYMMDD"),
          numeroDocumento: barcode,
          idTipoDocumento: null,
          targa: null,
        })
    );
  }

  getPnrByTarga(payload) {
    return this.getRepo().then((repo) =>
      repo.exec("fn_GetPNRVarco", {
        //data: moment().format("YYYYMMDD"),
        numeroDocumento: null,
        targa: payload,
        idTipoDocumento: null,
      })
    );
    /*return new Promise((resolve, reject) => {
      resolve({
        data: {
          output: {
            return_value: "95462008192760",
          },
          rowCount: null,
          error: null,
          errorCode: null,
          message: "Ok",
          result: [],
          hash: "7deefa3c-1de2-420d-9af1-573a1a3e506",
        },
      });
    });*/
  }

  setFlgPrenotazione = (id, flag, flagSms) => {
    return this.getRepo()
      .then((repo) => {
        return repo.exec("sp_UpdateStatoPrenotazione", {
          IDPRENOTAZIONE: id,
          CODICESTATOPRENOTAZIONE: flag,
        });
      })
      .then(() => flag === "DEL" && flagSms && this.sendSms(id, "D"));
  };

  searchUserAssegnatoByCF(par) {
    return this.getRepo().then((repo) =>
      repo.exec("fn_GetIdUtenteFromDoc", par)
    );
  }

  sendSms(idPrenotazione, flagAction) {
    let params = { IDPRENOTAZIONE: idPrenotazione, FLAGSTATO: flagAction };
    //commento questa parte in attesa di gestione migliore da parte dei comuni

    //return this.getRepo().then((repo) => repo.sendSms(SEND_SMS, params));
  }

  //fn_GetEmailInfo
  sendEmailGestore(idPrenotazione) {
    return this.getRepo().then((repo) =>
      repo.sendMail(SEND_MAIL_GESTORE, { idPrenotazione })
    );
  }

  loadArea = (par) => {
    return this.getRepo().then((repo) => repo.exec("fn_GetAreaInfo", par));
  };
}
