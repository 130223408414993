import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_TIPOLOGIA_VEICOLO_LIST_PRENOTAZIONE =
  "generic/search3/prenotazione/tipologiaveicolo";
export default class TipologiaVeicoloHttpRepo extends HttprepoTAAL {
  getTable() {
    return "TipologiaVeicolo";
  }

  getTipologiaVeicoloListPrenotazione() {
    return this.API.post(GET_TIPOLOGIA_VEICOLO_LIST_PRENOTAZIONE);
  }
}
