import BaseServiceTaal from "../../Service/BaseServiceTaal";
import RelTipoDurataFasciaOraria from "./RelTipoDurataFasciaOraria.repo";

export default class RelTipoDurataFasciaOrariaService extends BaseServiceTaal {
  getRepo = () => RelTipoDurataFasciaOraria.getInstanceTaal();

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search2(
        page - 1,
        size,
        this.transformFilterToServer(filters),
        orders,
        ["FasciaOraria", "TipologiaDurata"]
      )
    );

  getRefFieldMap = ()=>{
    return {
      fkIdTipologiaDurata:'descLocalizzata',
      fkIdFasciaOraria:'descLocalizzata'
    }
  }

  tableDefinition = (isTable) =>
    this.getRepo()
      .then((repo) => repo.tabledef())
      .then((res) => {
        if (!res.data.error) {
          //res.data.result.columns 
          if(isTable){
            res.data.result.columns=res.data.result.columns.filter(c=>c.name.indexOf('fk')<0);
            res.data.result.columns.push(
              { 
                name:'TipologiaDurata.descLocalizzata',
                type:'String'
              }
            );
            res.data.result.columns.push(
              { 
                name:'FasciaOraria.descLocalizzata',
                type:'String'
              }
            );
          }
        }
        return res;
      });


  
}
