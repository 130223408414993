import React from 'react';
import {onError, onSuccess} from "../../redux/Shared/Shared.actions";
import CrudNetTable from '../../components/CrudNet/CrudNetTable';
import RxComponent from "../../redux/RxComponent";
import {FILTER_NUMBER} from "../../components/TableServer/FilterItem";
import EventoService from './Evento.service';
import Resizer from "react-image-file-resizer";
import Switch from "react-switch";
import moment from 'moment-timezone';

const MAX_WIDTH_LARGE_IMAGE = 1920;
const MAX_WIDTH_SMALL_IMAGE = 300;
const MAX_HEIGHT_LARGE_IMAGE = 1080;
const MAX_HEIGHT_SMALL_IMAGE = 200;

class Page extends React.Component{


    service = new EventoService();

    columns = [
        {
          label: this.props.t("Description"),
          data: "Area.descrizione",
        },
        {
          label: this.props.t("Municipality"),
          data: "Area.Comune.descrizione",
        },
        {
          label: this.props.t("Tipology"),
          data: "Area.TipologiaArea.descLocalizzata",
        },
        {
          label: this.props.t("Latitude"),
          data: "Area.latitudine",
        },
        {
          label: this.props.t("Longitude"),
          data: "Area.longitudine",
        },
        {
          label: "Servizi",
          data: "Area.idArea",
          type: "custom",
          render: (cellData, row) => {
            return (
              <>
                {row.Area.flgAccessoDisabili && <i className="fas fa-wheelchair"></i>}
                {row.Area.flgAnimaliAmmessi && <i className="fas fa-dog"></i>}
              </>
            );
          },
        },
        {
            label: this.props.t("State"),
            type: "custom",
            render: (celldata, row) => {
              return (
                <React.Fragment>
                  <Switch
                    disabled
                    onChange={() => this.handleSwitch(row)}
                    checked={row.Area.flgAttivo}
                  />
                </React.Fragment>
              );
            },
          },
    ];

    filters = {
       
    };

    async handleSubmit(data,viewMode,closeModal) {
      data.dataFineEvento=moment(data.dataFineEvento).format('YYYYMMDDHHmm');
      data.dataFinePrenotazioni=moment(data.dataFinePrenotazioni).format('YYYYMMDDHHmm');
      data.dataInizioEvento=moment(data.dataInizioEvento).format('YYYYMMDDHHmm');
      data.dataInizioPrenotazioni=moment(data.dataInizioPrenotazioni).format('YYYYMMDDHHmm');
        data.idComune = this.props.Auth.user.fkIdComune
          ? this.props.Auth.user.fkIdComune
          : this.props.fkIdComune;
        if (data.foto) {
          data.fotoOriginale = await this.resizeImage(
            data.foto,
            MAX_WIDTH_LARGE_IMAGE,
            MAX_HEIGHT_LARGE_IMAGE
          );
          data.foto = await this.resizeImage(
            data.foto,
            MAX_WIDTH_SMALL_IMAGE,
            MAX_HEIGHT_SMALL_IMAGE
          );
          let splitted = data.fotoOriginale.split(";base64,");
          data.formato = splitted[0].split("data:")[1];
          data.fotoOriginale = splitted[1];
    
          splitted = data.foto.split(";base64,");
          data.foto = splitted[1];
        }
        if (this.props.formato) {
          data.formato = this.props.formato;
        }
        if (data.maps) {
          data.latitudine = data.maps.latitudine;
          data.longitudine = data.maps.longitudine;
          delete data.maps;
        }
        let promise = null;
        if (viewMode === "insert") {
          promise = this.service.create(data);
        } else if (viewMode === "edit") {
          promise = this.service.edit(data);
        } else {
          data.flgAttivo = false;
         
          promise = this.service.remove({idEvento:data.idEvento});
        }
        promise
          .then((resp) => {
            this.props.onSuccess("SuccessedOperation");
            closeModal();
            
          })
          .catch((ex) => {
            this.props.onError(ex);
          });
      }
    
      resizeImage = (file, maxWidth, maxHeight) =>
        new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            "JPEG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "base64"
          );
        });
    

        getDataComune(pageNum, itemsPerPage, ff, sort) {
            const filter = {
              [`${FILTER_NUMBER}&&&Area.fkIdComune`]: [this.props.Auth.user.fkIdComune],
            };
            return this.service.paginate(pageNum,itemsPerPage,filter,sort);
          }


    render(){
        return(
            <CrudNetTable
                title={this.props.t("Evento")}
                refFieldMap={{ fkIdTipologiaArea: "descLocalizzata" }}
                columns={this.columns}
                getData={
                    this.getDataComune.bind(this)
                  }
                filters={this.filters}
                defaultFilters={this.filters}
                service={this.service}
                onSubmit={this.handleSubmit.bind(this)}
                ref={"crudNetEvento"}
            />
        );
    }

}

const EventoPage = RxComponent({onSuccess, onError})(Page);
export default EventoPage;
