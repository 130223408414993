import React from "react";
import { hist } from "../../App";
import { NavLink } from "react-router-dom";
import RxComponent from "../../redux/RxComponent";
import { triggerLogout } from "../../redux/Auth/Auth.actions";

class Comp extends React.Component {
  logout() {
    this.props.triggerLogout();
  }

  render() {
    return (
      <nav className="menuOverlay">
        <div className="container">
          <input id="responsive-menu" type="checkbox" />

          <label htmlFor="responsive-menu">
            <a
              className=""
              href="/"
              style={{
                height: 60,
                width: 100,
                display: "block",
                backgroundImage: `url(${this.props.logo})`,
                backgroundSize: 60,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                color:'transparent'
              }}
            >
              &nbsp;
            </a>
            <span id="menu-icon"></span>
          </label>

          <div id="overlay"></div>
          <ul>
            {this.props.menu &&
              this.props.menu.map((item, index) => {
                return (
                  <li key={item.name + "-" + index}>
                    <NavLink
                      onClick={() =>
                        document.getElementById("responsive-menu").click()
                      }
                      to={`${item.path}`}
                      activeClassName="active"
                    >
                      {item.icon && (
                        <i className={"fas fa-fw fa-" + item.icon}></i>
                      )}
                      {this.props.t(item.name)}
                    </NavLink>
                  </li>
                );
              })}
          </ul>

          <ul id="social-media-mod">
            <li>
              <a href="#" onClick={this.logout.bind(this)}>
                <i className="fas fa-sign-out-alt"></i>
                Logout
                <span className="screen-reader-text">Logout</span>
              </a>
            </li>
            {/*
              
              <li>
                  <a href="http://codepen.io/erinesullivan/">
                      <i  className="fab fa-codepen"></i>
                      <span  className="screen-reader-text">CodePen</span>
                  </a>
              </li>
              <li>
                  <a href="https://twitter.com/erin_e_sullivan">
                      <i  className="fab fa-twitter"></i>
                      <span  className="screen-reader-text">Twitter</span>
                  </a>
              </li>
              <li>
                  <a href="https://www.linkedin.com/in/erinesullivan1">
                      <i  className="fab fa-linkedin"></i>
                      <span  className="screen-reader-text">LinkedIn</span>
                  </a>
              </li>
              <li>
                  <a href="https://www.instagram.com/erin_e_sullivan/">
                      <i  className="fab fa-instagram"></i>
                      <span  className="screen-reader-text">Instagram</span>
                  </a>
              </li>
              */}
          </ul>
        </div>
      </nav>
    );
  }
}
const NavBarOverlay = RxComponent({ triggerLogout })(Comp);
export default NavBarOverlay;
