import React from "react";
import RxComponent from "../../../redux/RxComponent";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import TipologiaDocumentoService from "../../TipologiaDocumento/TipologiaDocumento.sevice";
import _ from "lodash";
import { onError, onSuccess } from "../../../redux/Shared/Shared.actions";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numeroPasseggeri:
        (this.props.numeroPasseggeri ? this.props.numeroPasseggeri : 1) - 1,
      tipologiaDocumentoService: new TipologiaDocumentoService(),
      listTipologiaDocumento: null,
      loading: true,
      maxOccupanti: this.props.maxOccupanti ? this.props.maxOccupanti : null,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.state.tipologiaDocumentoService.getAllNewApi().then((res) => {
      this.setState({
        listTipologiaDocumento: res.data.result,
        loading: !this.state.loading,
        listData: this.props.data || [],
      });
    });
  }

  validate() {
    let errors = [];
    if (
      this.state.listData.length &&
      this.state.listData.length === this.state.numeroPasseggeri
    ) {
      this.state.listData.forEach((el, index) => {
        // if(!el.nome){
        //   errors.push(
        //     this.props.t(this.props.t("Name") + this.props.t("notValidPassengerN°")+ (index + 1))
        //   );
        // }
        // if(!el.cognome){
        //   errors.push(
        //     this.props.t(this.props.t("Surname") + this.props.t("notValidPassengerN°")+ (index + 1))
        //   );
        // }
        if (
          !el.tipologiaDocumento ||
          !el.tipologiaDocumento.idTipologiaDocumento
        ) {
          errors.push(
            this.props.t(
              this.props.t("TIPOLOGIA_DOCUMENTO") +
                this.props.t("notValidPassengerN°") +
                (index + 1)
            )
          );
        }
        if (!el.numeroDocumento) {
          errors.push(
            this.props.t(
              this.props.t("NUMERO_DOCUMENTO") +
                this.props.t("notValidPassengerN°") +
                (index + 1)
            )
          );
        }
      });
    } else {
      if (this.state.numeroPasseggeri !== 0) {
        errors.push(this.props.t("INSERT_PERSONAL_DATA"));
      }
    }
    return errors;
  }

  getData = () => {
    return {
      listPax: this.state.listData,
      numeroOccupanti: this.state.numeroPasseggeri + 1,
    };
  };

  setList(nomeEntity, ev, index) {
    let listToPass = this.state.listData;
    let ob = {
      nome: "",
      cognome: "",
      tipologiaDocumento: {},
      numeroDocumento: "",
    };
    ob[nomeEntity] = ev;
    if (listToPass[index]) {
      listToPass[index][nomeEntity] = ob[nomeEntity];
    } else {
      listToPass[index] = {
        nome: "",
        cognome: "",
        tipologiaDocumento: "",
        numeroDocumento: "",
      };
      listToPass[index][nomeEntity] = ob[nomeEntity];
    }

    this.setState({
      listData: listToPass,
    });
  }

  addPaxSlot = () => {
    if (this.state.numeroPasseggeri + 1 < this.state.maxOccupanti) {
      let listToPass = this.state.listData;
      let ob = {
        nome: "",
        cognome: "",
        tipologiaDocumento: {},
        numeroDocumento: "",
      };
      listToPass.push(ob);
      this.setState({
        numeroPasseggeri: this.state.numeroPasseggeri + 1,
        listData: listToPass,
      });
    } else {
      this.props.onError("EXCEEDED_PAX_LIMIT");
    }
  };

  render() {
    if (this.state.loading) {
      return <p>{this.props.t("Loading")} ...</p>;
    }
    let listToPass = this.state.listData;
    return (
      <React.Fragment>
        <h5>{this.props.t("WHO_COMES_WITH_YOU")}</h5>
        <br />
        <Form>
          {_.times(this.state.numeroPasseggeri, (index) => {
            let ob = {
              nome: "",
              cognome: "",
              tipoDocumento: {},
              numeroDocumento: "",
            };
            return (
              <React.Fragment key={index}>
                <h5>
                  {this.props.t("ENTER_DETAILS_FOR_PASSENGER_N°")}
                  {index + 1}
                </h5>

                <div className="d-flex wrap-cds align-items-center">
                  <FormGroup className="col-md-3">
                    <label htmlFor="Nome">{this.props.t("Name")}:</label>
                    <br></br>
                    <input
                      type="text"
                      id="Nome"
                      name="Nome"
                      className="form-control"
                      placeholder={this.props.t("Name")}
                      onChange={(ev) => {
                        this.setList("nome", ev.target.value, index);
                      }}
                      value={
                        this.state.listData.length && this.state.listData[index]
                          ? this.state.listData[index].nome
                          : ""
                      }
                      readOnly={this.props.readOnly}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <label htmlFor="Cognome">{this.props.t("Surname")}:</label>
                    <br></br>
                    <input
                      type="text"
                      id="Cognome"
                      name="Cognome"
                      placeholder={this.props.t("Surname")}
                      className="form-control"
                      onChange={(ev) => {
                        this.setList("cognome", ev.target.value, index);
                      }}
                      value={
                        this.state.listData.length && this.state.listData[index]
                          ? this.state.listData[index].cognome
                          : null
                      }
                      readOnly={this.props.readOnly}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <label htmlFor="TipologiaDocumento">
                      {this.props.t("TIPOLOGIA_DOCUMENTO")}*:
                    </label>
                    <SelectPicker
                      options={this.state.listTipologiaDocumento}
                      displayValue="descLocalizzata"
                      onChange={(ev) => {
                        this.setList("tipologiaDocumento", ev[0], index);
                      }}
                      required
                      values={
                        this.state.listData.length && this.state.listData[index]
                          ? [this.state.listData[index].tipologiaDocumento]
                          : null
                      }
                      readOnly={this.props.readOnly}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <label htmlFor="NumeroDocumento">
                      {this.props.t("NUMERO_DOCUMENTO")}*:
                    </label>
                    <br></br>
                    <input
                      maxLength={
                        this.state.listData.length &&
                        this.state.listData[index] &&
                        this.state.listData[index].tipologiaDocumento &&
                        this.state.listData[index].tipologiaDocumento
                          .codiceTipologiaDocumento === "CF"
                          ? 16
                          : null
                      }
                      type="text"
                      id="NumeroDocumento"
                      name="NumeroDocumento"
                      placeholder={this.props.t("NUMERO_DOCUMENTO")}
                      className="form-control"
                      onChange={(ev) => {
                        this.setList("numeroDocumento", ev.target.value, index);
                      }}
                      value={
                        this.state.listData.length && this.state.listData[index]
                          ? this.state.listData[index].numeroDocumento
                          : null
                      }
                      readOnly={this.props.readOnly}
                    />
                  </FormGroup>
                  <button
                    type="button"
                    className="border-0 trash-button p-0"
                    title={this.props.t("DeleteData") + " ..."}
                    hidden={!this.props.isEdit || this.props.readOnly}
                  >
                    <i
                      className="fas fa-trash-alt mt-3"
                      onClick={() => {
                        let lis = this.state.listData.filter(
                          (el, ind) => ind !== index
                        );
                        this.setState({
                          listData: lis,
                          numeroPasseggeri: this.state.numeroPasseggeri - 1,
                        });
                      }}
                    ></i>
                    <span className="sr-only">
                      {this.props.t("DeleteData")} ...
                    </span>
                  </button>
                  <div className="hr-br"></div>
                </div>
              </React.Fragment>
            );
          })}
        </Form>
        <button
          className="fas fa-plus background-transparent btn-out Line border-0"
          hidden={!this.props.isEdit || this.props.readOnly}
          onClick={() => this.addPaxSlot()}
        ></button>
      </React.Fragment>
    );
  }
}
const PrenotazioneInsertAnagrafica = RxComponent({ onError })(Component);
export default PrenotazioneInsertAnagrafica;
