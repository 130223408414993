import React from "react";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";
import RxComponent from "../../redux/RxComponent";
import RegistroSanitarioService from "./RegistroSanitario.service";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import Switch from "react-switch";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
} from "reactstrap";
import { Trans } from "react-i18next";
import BarcodeScanner from "../../components/Content/BarcodeReader";
import TipologiaDocumentoService from "../TipologiaDocumento/TipologiaDocumento.sevice";
import { SelectPicker } from "../../components/Content/SelectPicker";
import GestoreAreaService from "../AssegnaGestoreArea/AssegnaGestoreArea.service";
import { TIPOLOGIEDOC } from "../../constants/TipologieDocumento";
import moment from "moment";

class Page extends React.Component {
  state = {
    showModal: false,
    loading: true,
  };

  registroSanitarioService = new RegistroSanitarioService();
  tipologiaDocumentoService = new TipologiaDocumentoService();
  gestoreAreaService = new GestoreAreaService();

  columns = [
    {
      label: this.props.t("NumberDocument"),
      data: "Documento.numeroDocumento",
    },
    {
      label: this.props.t("Date"),
      data: "dataRegistrazione",
      type: "custom",
      render: (celldata) => moment(celldata).format("DD/MM/YYYY"),
      //sortable: true
    },
    /*{
      label: ,
      data: ,
    },
    {
      label: this.props.t("Area"),
      data: "Area.descrizione",
    },
    {
      label:,
      data: ,
    },
    {
      label: ,
      data: ,
    },
    {
      label: ,
      data: ,
      type: "custom",
      render: (cellData, row) => {
        return (
         
        );
      },
    },
    {
      label: this.props.t("State"),
      type: "custom",
      render: (celldata, row) => {
        return (
          <React.Fragment>
            <Switch
              onChange={() => this.handleSwitch(row)}
              checked={row.flgAttivo}
            />
          </React.Fragment>
        );
      },
    },*/
  ];

  filters = [];

  componentDidMount() {
    if (this.tipologiaDocumentoService) {
      this.tipologiaDocumentoService.getAll().then((res) => {
        this.gestoreAreaService
          .getByIdUser(this.props.Auth.user.idUtente)
          .then((resIdArea) => {
            this.setState({
              tipologiaDocumentoList: res.data.result,
              idArea: resIdArea.data.result[0].fkIdArea,
              loading: false,
            });
          });
      });
    }
  }

  renderHeader() {
    return () => (
      <h1 className="h3 mb-4 text-gray-800 text-left page-header">
        {this.props.t("REGISTRO_PRESENZE")}
        <Button
          color="light"
          outline
          className="ml-2 border-0"
          size="sm"
          onClick={() => this.setState({ showModal: true })}
        >
          <i className="fas fa-plus"></i>
        </Button>
      </h1>
    );
  }

  closeModal() {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.refreshTable();
      }
    );
  }

  refreshTable() {
    this.refs.crudNetRegistroSanitario.refreshTable();
  }

  handleBarCodeScan(barcode) {
    if (!barcode || !barcode.length) {
      this.props.onError("BARCODE_REQUIRED");
    } else {
      if (
        this.state.selectedTipologiaDocumento === TIPOLOGIEDOC.CODICE_FISCALE &&
        barcode.length < 16
      ) {
        this.props.onError("WrongDocNumber");
        return;
      }
      if (barcode.length < 2) {
        this.props.onError("WrongDocNumber");
        return;
      }

      this.registroSanitarioService
        .create({
          IDAREA: this.state.idArea,
          CODICETIPOLOGIADOCUMENTO: this.state.selectedTipologiaDocumento,
          NUMERODOCUMENTO: barcode,
        })
        .then(() => {
          this.refs.crudNetRegistroSanitario.refreshTable();
          this.closeModal();
        });
    }
  }

  render() {
    if (this.state.loading) {
      return <p>{this.props.t("Loading")} ...</p>;
    }
    return (
      <>
        <CrudNetTable
          title={"Registro Sanitario"}
          //refFieldMap={{ fkIdTipologiaArea: "descLocalizzata" }}
          columns={this.columns}
          //filters={this.filters}
          defaultFilters={{
            [`${FILTER_NUMBER}&&&fkIdArea`]: [this.state.idArea],
          }}
          service={new RegistroSanitarioService()}
          actions={[]}
          ref="crudNetRegistroSanitario"
          customHeader={this.renderHeader()}
        ></CrudNetTable>
        <Modal
          isOpen={this.state.showModal}
          toggle={() => this.closeModal()}
          size="md"
        >
          <ModalHeader toggle={() => this.closeModal()}>
            {this.props.t("INSERT LABEL")}
          </ModalHeader>
          <ModalBody>
            <div className="col-sm-12">
              <SelectPicker
                values={this.state.areaValues}
                options={this.state.tipologiaDocumentoList}
                displayValue={"descLocalizzata"}
                onChange={(ev) =>
                  this.setState({
                    selectedTipologiaDocumento: ev[0].codiceTipologiaDocumento,
                  })
                }
              />
              <br />
              <BarcodeScanner
                onDetect={(barcode) => {
                  return this.state.selectedTipologiaDocumento
                    ? this.handleBarCodeScan(barcode)
                    : this.props.onError("SELECT_TIPOLOGIA_DOC");
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.closeModal()}>
              {this.props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const RegistroSanitario = RxComponent({ onSuccess, onError })(Page);
export default RegistroSanitario;
