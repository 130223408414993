import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../template/site/css/vendor.css";
//import x from "../../template/site/img/no-im.png";
import x from "../../template/site/img/sea.jpg";
import _ from "lodash";
import { Trans } from "react-i18next";
import RxComponent from "../../redux/RxComponent";

class Comp extends React.Component {
  state = {
    img: this.props.foto ? "data:image/jpeg;base64," + this.props.foto : x,
    title: this.props.descrizione || "descrizione di prova",
    subtitle: this.props.descrizione || "sottotitolo di prova",
    icons: [],
    paragraph: this.props.descrizione || "paragrafo di prova",
    animalsAllowed: this.props.accessoAnimali,
    handicapAllowed: this.props.accessoDisabili,
    idArea: this.props.id,
    codiceArea: this.props.codiceArea,
  };

  componentDidUpdate() {
    if (this.props.id !== this.state.idArea) {
      this.setState({
        img: this.props.foto ? "data:image/jpeg;base64," + this.props.foto : x,
        title: this.props.descrizione || "descrizione di prova",
        subtitle: this.props.descrizione || "sottotitolo di prova",
        icons: [],
        paragraph: this.props.descrizione || "paragrafo di prova",
        animalsAllowed: this.props.accessoAnimali,
        handicapAllowed: this.props.accessoDisabili,
        idArea: this.props.id,
        codiceArea: this.props.codiceArea,
        postiTotali: this.props.postiTotali || 0,
        postiLiberi: this.props.postiLiberi || 0,
        postiOccupati: this.props.postiTotali - this.props.postiLiberi,
      });
    }
  }

  onClick(el) {
    if (_.isFunction(this.props.onClick)) {
      this.props.onClick(_.cloneDeep(this.state));
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-3 pr-lg-2"></div>
        <div className="col-lg-4 pr-lg-3">
          <div className="owl-carousel owl-theme carousel_pic owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div className="owl-stage custom-1">
                <div className="owl-item active custom-2">
                  <div className="item">
                    <img
                      src={this.state.img}
                      alt=""
                      style={{ height: 250 }}
                      className="image-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 pr-lg-2">
          <h4 className="styled">{this.state.title}</h4>
          <h5> {this.state.subtitle}</h5>
          <p>{this.state.paragraph}</p>

          <ul className="additional_info">
            <li>
              <span
                className="list-allowed-icon"
                hidden={this.state.animalsAllowed}
              >
                <i className="fas fa-dog"></i>
              </span>
            </li>
            <li>
              <span
                className="list-allowed-icon"
                hidden={this.state.handicapAllowed}
              >
                <i className="fas fa-wheelchair"></i>
              </span>
            </li>
          </ul>
          <button
            className="btn_1 add_bottom_10 book-btn"
            onClick={this.onClick.bind(this)}
          >
            <Trans i18nKey="BookNow" />
          </button>
        </div>
        <div className="col-lg-2 pr-lg-1"></div>
      </div>
    );
  }
}
const ListItem = RxComponent({})(Comp);
export default ListItem;
