import React from "react";
import Comuneservice from "../../modules/Comune/Comune.service";
import _ from "lodash";
import dafaultImage from "../../template/site/img/slide_3.jpg";
import MapComponent from "../Content/MapsComp";
import {
  ON_FILTER_TIPOLOGIA_AREA,
  TRIGGER_SELECT_AREA,
  SELECT_AREA,
} from "../../redux/Prenotazione/Prenotazione.types";
import RxComponent from "../../redux/RxComponent";
import { onFilterTipologiaArea } from "../../redux/Prenotazione/Prenotazione.actions";
import ComuneBannerService from "../../modules/ComuneBanner/ComuneBanner.service";
import { FILTER_TEXT, FILTER_NUMBER } from "../TableServer/FilterItem";
import { BASE_URL_TAAL } from "../../constants/Settings";
import { hist } from "../../App";

class Component extends React.PureComponent {
  state = {
    image: dafaultImage,
    type: "image",
    //mock sottostante
    coordinates: [],
  };

  idComune = null;

  comuneservice = new Comuneservice();
  comuneBannerService = new ComuneBannerService();

  componentDidUpdate() {
    this.getSettings();
    let coord = [];

    if (
      this.props.Prenotazione.type === ON_FILTER_TIPOLOGIA_AREA ||
      this.props.Prenotazione.type === SELECT_AREA
    ) {
      coord.push(...this.getCoordinates());
      coord.push(...this.getListaCoordinateParcheggi());
      this.setState(
        {
          coordinates: coord || [],
          imgUrl: null,
        },
        () => this.props.onFilterTipologiaArea(null, false)
      );
    }
  }

  componentDidMount() {
    this.getSettings();
  }

  getCoordinates() {
    let lis = [];

    if (
      this.props.Prenotazione.fileteredAreaList &&
      this.props.Prenotazione.fileteredAreaList.length
    ) {
      lis = this.props.Prenotazione.fileteredAreaList.map((el) => {
        if (el.LATITUDINE && el.LONGITUDINE) {
          return {
            area: el,
            lat: el.LATITUDINE,
            lon: el.LONGITUDINE,
          };
        }
        return null;
      });
    } else if (
      this.props.Prenotazione.list &&
      this.props.Prenotazione.list.length
    ) {
      lis = this.props.Prenotazione.list
        .filter((el) => el.LATITUDINE && el.LONGITUDINE)
        .map((el) => {
          return {
            area: el,
            lat: el.LATITUDINE,
            lon: el.LONGITUDINE,
          };
        });
    }

    return lis;
  }

  getListaCoordinateParcheggi() {
    let lis = [];

    if (
      this.props.Prenotazione.listParcheggi &&
      this.props.Prenotazione.listParcheggi.length
    ) {
      lis = this.props.Prenotazione.listParcheggi
        .filter((el) => el.LATITUDINE && el.LONGITUDINE)
        .map((el) => {
          return {
            parcheggio: el,
            lat: el.LATITUDINE,
            lon: el.LONGITUDINE,
          };
        });
    }

    return lis;
  }

  getSettings() {
    if (!this.props.idComune && this.props.idComune !== this.idComune) {
      this.setState({
        type: "image",
        image: dafaultImage,
      });
      this.idComune = null;
    } else if (this.props.idComune && this.props.idComune !== this.idComune) {
      this.idComune = this.props.idComune;
      this.comuneservice.getById(this.props.idComune).then((res) => {
        if (res.data.result.settings) {
          const settings = JSON.parse(res.data.result.settings);
          let coord = [];
          let listCoordAree = this.getCoordinates();
          let listCoordPark = this.getListaCoordinateParcheggi();

          coord.push(...listCoordAree, ...listCoordPark);

          if (_.isObject(settings) && _.isObject(settings.banner)) {
            if (settings.banner.type === "image") {
              if (window.location.href.indexOf("/resultSearch") >= 0) {
                let filter = {
                  [`${FILTER_NUMBER}&&&FkIdComune`]: [this.props.idComune],
                };
                this.comuneBannerService
                  .getComuneBannerPrenotazione(this.props.idComune)
                  .then((res) => {
                    let imgp = `${BASE_URL_TAAL}/Generic/image/v_ComuneBanner/imgBanner/${res.data.result[0].idComuneBanner}/jpeg`;

                    this.setState({
                      type: settings.banner.type || "",
                      image: dafaultImage,
                      imgUrl: imgp,
                      coordinates: coord || null,
                    });
                  });
              } else {
                this.setState({
                  type: settings.banner.type || "",
                  image: dafaultImage,
                  coordinates: coord || null,
                  imgUrl: null,
                });
              }
            } else {
              this.setState({
                type: settings.banner.type || "",
                image: dafaultImage,
                coordinates: coord || null,
              });
            }
          }
        }
      });
    }
  }
  prenota() {}

  render() {
    if (
      this.state.type === "image" ||
      hist.location.pathname === "/site/home"
    ) {
      return (
        <div
          className="w-100 w-md-50 safariFix"
          style={{
            backgroundImage: this.state.imgUrl
              ? `url(${this.state.imgUrl})`
              : `url(${dafaultImage})`,
            backgroundSize: "cover",
            minHeight: 400,
            position: "relative",
          }}
        >
          {this.props.children}
        </div>
      );
    }
    if (this.state.type === "map") {
      //return <div>{this.props.children}</div>;
      return (
        <div
          className="w-100 w-md-50"
          style={{
            height: 400,
            position: "relative",
          }}
        >
          <MapComponent points={this.state.coordinates} />
        </div>
      );
    }
    return null;
  }
}

const SettingsBannerImage = RxComponent({ onFilterTipologiaArea })(Component);
export default SettingsBannerImage;

/*
let listToRet = [];
if (this.props.Prenotazione.selectedFields) {
  let idComune = this.props.Prenotazione.selectedFields.idComune;
  let dataSearch = this.props.Prenotazione.selectedFields.dataPrenotazione;
  this.comuneservice
    .getParcheggiAttivi(idComune, dataSearch)
    .then((res) => {
      res.data.result.map((el) => {

        listToRet.push({
          parcheggio: el,
          lat: el.LATITUDINE,
          lon: el.LONGITUDINE,
        });
      });
    })
    .then(() => {
      return listToRet;
    });
}
return listToRet;*/
