import moment from "moment-timezone";

let CFisc={}

CFisc.tavola_mesi=['A','B','C','D','E','H','L','M','P','R','S','T']

CFisc.tavola_omocodie=['L','M','N','P','Q','R','S','T','U','V']

CFisc.tavola_carattere_di_controllo_valore_caratteri_dispari={
  0:1,  1:0,  2:5,  3:7,  4:9,  5:13, 6:15, 7:17, 8:19,
  9:21, A:1,  B:0,  C:5,  D:7,  E:9,  F:13, G:15, H:17,
  I:19, J:21, K:2,  L:4,  M:18, N:20, O:11, P:3,  Q:6,
  R:8,  S:12, T:14, U:16, V:10, W:22, X:25, Y:24, Z:23
}

CFisc.tavola_carattere_di_controllo_valore_caratteri_pari={
  0:0,  1:1,   2:2,  3:3,   4:4,  5:5,  6:6,  7:7,  8:8,
  9:9,  A:0,   B:1,  C:2,   D:3,  E:4,  F:5,  G:6,  H:7,
  I:8,  J:9,   K:10, L:11,  M:12, N:13, O:14, P:15, Q:16,
  R:17, S:18,  T:19, U:20,  V:21, W:22, X:23, Y:24, Z:25
}

CFisc.tavola_carattere_omocodia={
  0:"L", 1:"M", 2:"N", 3:"P", 4:"Q", 5:"R", 6:"S",
  7:"T", 8:"U", 9:"V"
}

CFisc.tavola_carattere_di_controllo="ABCDEFGHIJKLMNOPQRSTUVWXYZ"

CFisc.calcola_carattere_di_controllo=function(codice_fiscale){
  let i,val=0
  for(i=0;i<15;i++){
    let c=codice_fiscale[i]
    if(i%2){
      val+=this.tavola_carattere_di_controllo_valore_caratteri_pari[c]
    }else{
      val+=this.tavola_carattere_di_controllo_valore_caratteri_dispari[c]
    }
  }
  val=val%26
  return this.tavola_carattere_di_controllo.charAt(val)
}

CFisc.ottieni_consonanti=function(str)
{
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi,'')
}

CFisc.ottieni_vocali=function(str)
{
  return str.replace(/[^AEIOU]/gi,'')
}

CFisc.calcola_codice_cognome=function(cognome)
{
  let codice_cognome=this.ottieni_consonanti(cognome)
  codice_cognome+=this.ottieni_vocali(cognome)
  codice_cognome+='XXX'
  codice_cognome=codice_cognome.substr(0,3)
  return codice_cognome.toUpperCase()
}

CFisc.calcola_codice_nome=function(nome)
{
  let codice_nome=this.ottieni_consonanti(nome)
  if(codice_nome.length>=4){
    codice_nome=
      codice_nome.charAt(0)+
      codice_nome.charAt(2)+
      codice_nome.charAt(3)
  }else{
    codice_nome+=this.ottieni_vocali(nome)
    codice_nome+='XXX'
    codice_nome=codice_nome.substr(0,3)
  }
  return codice_nome.toUpperCase()
}

CFisc.calcola_codice_data=function(data,sesso)
{
  let meseIndex = parseInt( moment(data).format('MM'))-1
  const mese = this.tavola_mesi[meseIndex]
  const anno = moment(data).format('YY')
  const giorno = sesso.toUpperCase()=='F' ? 40+ Number(moment(data).format('DD')): moment(data).format('DD')
  return ""+anno+mese+giorno
}

CFisc.trova_comune=function(pattern_comune)
{
  let codice,comune,ret=[]
  let quoted=pattern_comune.replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
  let re=new RegExp(quoted,'i')
  for(codice in this.codici_catastali)
  {
    comune=this.codici_catastali[codice]
    if(comune.match(re)) ret.push([comune,codice])
  }
  return ret
}

CFisc.calcola_codice_comune=function(pattern_comune){
  if(pattern_comune.match(/^[A-Z]\d\d\d$/i)) return pattern_comune;
  return this.trova_comune(pattern_comune)[0][1];
}

CFisc.calcola_codice=function(nome,cognome,sesso,data,luogo)
{
  let res=
    this.calcola_codice_cognome(cognome)+
    this.calcola_codice_nome(nome)+
    this.calcola_codice_data(data,sesso)+
    this.calcola_codice_comune(luogo)

  res+=this.calcola_carattere_di_controllo(res)
  res = this.omocodia(res)
  return res
}

CFisc.omocodia = function(cf) {
  let res = [];
  res.push(cf)

  for(let i in cf ){
    let patt = new RegExp("[0-9]");
    if (patt.test(cf[i])){
      cf = cf.split('');
      cf[i] = this.tavola_carattere_omocodia[cf[i]]
      cf = cf.join('')
      res.push(cf)
    }
  }
  return res
}

export function generateArrFiscalCodes(input)
{
  let res = CFisc.calcola_codice(
    input.nome,
    input.cognome,
    input.sesso,
    input.date,
    input.luogo
    )
    return res
}

export function isValidCF(array,input){
  let res = false
  for(let i in array){
    if(array[i].toUpperCase() === input.toUpperCase()){
      res = true
    }
  }

  return res
}
