import React from "react";
import { Table, Modal, ModalBody, ModalHeader } from "reactstrap";
import ColumnHeader from "./ColumnHeader";
import ColumnData from "./ColumnData";
import _ from "lodash";
import Filters from "./Filters";
import Paginator from "./Paginator";
import { ITEMS_PER_PAGE } from "../../constants/Settings";
import { Trans, Translation } from "react-i18next";

export default class Tableserver extends React.PureComponent {
  update = false;
  sort = [];
  selectedFilters = {};
  countUpdate = 0;

  constructor(props) {
    super(props);

    this.state = {
      id: this.generateId(),
      currentPage: 1,
      itemsPerPage: props.itemsPerPage || ITEMS_PER_PAGE,
      totalItems: 0,
      //updateData: this.defaultUpdate,
      columns: [],
      rows: [],
      filters: [],
      modalBody: null,
      modalVisible: false,
      exportExcel: null,
      exportPdf: null,
    };
  }

  generateId() {
    let ts = document.getElementsByTagName("table");
    let num = 0;
    if (ts.length > 0) {
      let max = 0;
      for (let i = 0; i < ts.length; i++) {
        let id = ts[i].getAttribute("id");
        if (id !== null) {
          num = _.toNumber(id.replace("table-", ""));
          if (!_.isNaN(num) && num > max) {
            max = num;
          }
        }
      }
      return "table-" + (max + 1) + "-" + new Date().getTime();
    }
    return "table-1-" + new Date().getTime();
  }

  defaultUpdate = (pageNum, filter, sort) => {
    return Promise.resolve([]);
  };

  onSort = (data, direction) => {
    let sort = this.sort.filter((s) => s.data !== data);
    if (direction) {
      sort.push({
        data: data,
        direction: direction,
      });
    }
    this.sort = sort;
    this.updateTable();
  };

  onFilter = (filters) => {
    this.selectedFilters = filters;
    this.updateTable();
  };

  componentDidUpdate(prevProps, prevState) {
    let newState = {};
    if (
      this.props.currentPage &&
      this.props.currentPage !== this.state.currentPage
    ) {
      newState.currentPage = this.props.currentPage;
      this.update = true;
    }
    if (
      this.props.itemsPerPage &&
      this.props.itemsPerPage !== this.state.itemsPerPage
    ) {
      newState.itemsPerPage = this.props.itemsPerPage;
      this.update = true;
    }
    if (this.props.columns && this.props.columns !== this.state.columns) {
      newState.columns = this.props.columns;
      newState.columnExpandable = this.props.columns.find(
        (c) => c.type === "expand"
      );
      this.update = true;
    }
    /*if (this.props.updateData && this.props.updateData !== this.state.updateData) {
      newState.updateData = this.props.updateData;
      this.update = true;
    }*/
    if (this.props.filters && this.props.filters !== this.state.filters) {
      newState.filters = this.props.filters;
      this.update = true;
    }
    if (
      this.props.totalItems &&
      this.props.totalItems !== this.state.totalItems
    ) {
      newState.totalItems = this.props.totalItems;
      this.update = true;
    }
    if (this.props.exportExcel && _.isFunction(this.props.exportExcel)) {
      if (
        !this.state.exportExcel ||
        this.props.exportExcel.toString() !== this.state.exportExcel.toString()
      ) {
        newState.exportExcel = this.props.exportExcel;
        this.update = true;
      }
    } else {
      newState.exportExcel = null;
      if (this.state.exportExcel) this.update = true;
    }

    if (this.props.exportPdf && _.isFunction(this.props.exportPdf)) {
      if (
        !this.state.exportPdf ||
        this.props.exportPdf.toString() !== this.state.exportPdf.toString()
      ) {
        newState.exportPdf = this.props.exportPdf;
        this.update = true;
      }
    } else {
      newState.exportPdf = null;
      if (this.state.exportPdf) this.update = true;
    }
    if (this.update) {
      this.update = false;
      this.setState(newState, () => {
        this.updateTable();
        //this.setState(newState,this.updateTable.bind(this));
      });
    }
  }

  waitUpdate = _.debounce(this.updateTable, 300);

  updateTable() {
    this.countUpdate++;
    this.props
      .updateData(
        this.state.currentPage,
        this.selectedFilters,
        this.sort,
        this.state.itemsPerPage
      )
      .then((tableData) => {
        let changes = {
          totalItems: tableData.totalItems,
          rows: tableData.rows.map((p, index) => {
            p.row_id = `${this.state.id}-tr-${index}-${this.countUpdate}`;
            return p;
          }),
        };
        this.setState(changes);
      });
  }

  OnModal(row, headerComp, bodyComponent) {
    this.setState({
      modalHeader: headerComp,
      modalBody: bodyComponent,
      modalVisible: true,
    });
  }

  OnExpand(row) {
    const rowsNew = this.state.rows.map((r) => {
      if (r.row_id === row.row_id) {
        r.expanded = !r.expanded;
      }
      return r;
    });
    this.setState({
      rows: rowsNew,
    });
  }

  componentDidMount() {
    //this.updateTable();
    this.setState({});
  }

  closeModal() {
    this.setState(
      {
        modalVisible: false,
      },
      () => {
        this.updateTable();
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.filters && this.state.filters.length ? (
          <Filters
            tableid={`filter-${this.state.id}`}
            filterlist={this.state.filters}
            onFilterChanged={this.onFilter.bind(this)}
          />
        ) : null}

        <Table
          size="sm"
          hover
          className="table-header responsivetable"
          responsive
          id={"table-" + this.state.id}
        >
          <thead>
            <tr>
              {this.state.columns.map((column) => {
                return (
                  <Translation>
                    {
                      t =>
                        <ColumnHeader
                          key={"th-" + column.label}
                          icon={column.icon}
                          label={t(column.label)}
                          type={column.type}
                          sortable={column.sortable}
                          filterable={column.filterable}
                          onSortChange={(direction) => {
                            this.onSort(column.data, direction);
                          }}
                        />
                    }
                  </Translation>
                  
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.rows &&
              this.state.rows.map((row, index) => {
                return (
                  <React.Fragment>
                    <tr key={row.row_id}>
                      {this.state.columns.map((column) => {
                        return (
                          <Translation>
                          {
                            t =>
                              <ColumnData
                                key={`td-${index}-${column.data}`}
                                data={column.data}
                                type={column.type}
                                label={t(column.label)}
                                row={row}
                                modalComponent={column.modalComponent}
                                modalHeader={column.modalHeader}
                                actions={column.actions}
                                render={column.render}
                                closeModal={this.closeModal.bind(this)}
                                OnModal={this.OnModal.bind(this)}
                                onExpand={this.OnExpand.bind(this)}
                                rowIndex={index}
                              />
                          }
                        </Translation>
                        );
                      })}
                    </tr>
                    {this.state.columnExpandable && row.expanded && (
                      <tr style={{ background: "#e2e2e2" }}>
                        <td colSpan="100">
                          {this.state.columnExpandable.render(row)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </Table>
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-4 justify-content-center justify-content-md-start">
            <div>
              {_.isFunction(this.state.exportExcel) && (
                <button
                  onClick={this.state.exportExcel}
                  type="button"
                  className="btn btn-default"
                >
                  <i className="fas fa-file-excel green"></i> <Trans i18nKey="ExportExcel" />
                </button>
              )}
              {_.isFunction(this.state.exportPdf) && (
                <button
                  onClick={this.state.exportPdf}
                  type="button"
                  className="btn btn-default ml-1"
                >
                  <i className="fas fa-file-pdf red"></i> <Trans i18nKey="ExportPDF" />
                </button>
              )}
            </div>
          </div>
          {!this.state.itemsPerPage || this.props.disablePaginate ? null : (
            <React.Fragment>
              <div className="col-12 col-sm-6 col-md-4 text-center">
                <p><Trans i18nKey="TotalElements" />: {this.state.totalItems}</p>
              </div>
              <div className="col-12 col-sm-6 col-md-4 d-flex justify-content-center justify-content-md-end">
                <Paginator
                  currentPage={this.state.currentPage}
                  itemsPerPage={this.state.itemsPerPage}
                  totalRecords={this.state.totalItems}
                  onPageChanged={(newPage) => {
                    this.update = true;
                    this.setState({
                      currentPage: newPage,
                    });
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <Modal
          isOpen={this.state.modalVisible}
          toggle={this.closeModal.bind(this)}
          size="xl"
        >
          <ModalHeader toggle={this.closeModal.bind(this)}>
            {this.state.modalHeader}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
