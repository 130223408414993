import BaseServiceTaal from "../../Service/BaseServiceTaal";
import Comune from "./Comune.repo";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import { data } from "jquery";

export default class ComuneService extends BaseServiceTaal {
  getRepo = () => Comune.getInstanceTaal();

  updateSettings = (newSettings, idComune) =>
    this.getRepo().then((repo) => {
      const filter = {
        [`${FILTER_NUMBER}&&&idComune`]: [idComune],
      };
      return repo.updateflds(
        { settings: newSettings },
        this.transformFilterToServer(filter)
      );
    });

  getDatesForUser = (idComune, idUser, date) =>
    this.getRepo().then((repo) => {
      return repo.exec("fn_GetDateAbilitatePrenotazione", {
        IDCOMUNE: idComune,
        IDUTENTE: idUser,
        DATAINIZIO: date,
      });
    });

  getDatesForUserBO = (idComune, idUser, date) =>
    this.getRepo().then((repo) => {
      return repo.exec("fn_GetDateAbilitatePrenotazioneBO", {
        IDCOMUNE: idComune,
        IDUTENTE: idUser,
        DATAINIZIO: date,
      });
    });

  searchComuniPrenotazione = (flagAbilitato) =>
    this.getRepo().then((repo) => repo.searchComuniPrenotazione(flagAbilitato));

  getParcheggiAttivi = (par) =>
    this.getRepo().then((repo) => {
      return repo.exec("fn_GetParcheggiComune", par);
    });
}
