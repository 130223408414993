import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_ALL_REGISTER = "generic/search3/register/lingua";
export default class LinguaHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Lingua";
  }

  getAllRegister() {
    return this.API.post(GET_ALL_REGISTER);
  }
}
