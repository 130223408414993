export const itLabels = {
  locIT: "Italiano",
  locEN: "Inglese",
  locSA: "Super Admin",
  locAC: "Admin Comunale",
  locUser: "Utente",
  locGestoreArea: "Gestore Area",
  locCensoreArea: "Censore Area",
  locCensorePark: "Censore Parcheggio",
  locAddetto: "Addetto Sicurezza",
  locCentrale: "Centrale Operativa Sicurezza",
  locRVS: "Responsabile Varchi Stradali",
  locRVA: "Responsabile Varchi Aree",
  locDA: "Dipendente Area",
  locAddettoInfo: "Addetto Infopoint",
  locVS: "Varco Stradale",
  locVA: "Varco Area",
  locCF: "Codice Fiscale",
  locCI: "Carta d'Identità",
  locPassport: "Passaporto",
  locAutoPrivata: "Automobile Privata",
  locAutoServizio: "Automobile di Servizio",
  locNavetta: "Navetta Pubblica",
  locPullman: "Pullman Privato",
  locVan: "Van Privato",
  locMoto: "Motoveicolo",
  locAmbulanza: "Ambulanza",
  locVigiliFuoco: "Vigili del Fuoco",
  locPolizia: "Polizia",
  locTaxi: "Taxi",
  locCarabinieri: "Carabinieri",
  locEsercito: "Esercito Italiano",
  locFinanza: "Guardia di Finanza",
  locPrenSpiaggiaMattina: "Mattina (8-13)",
  locPrenSpiaggiaPomeriggio: "Pomeriggio (14-19)",
  locPrenSpiaggiaDay: "Tutto il giorno (8-19)",
  locPrenRistorante: "Accesso Ristorante",
  locPrenHotel: "Accesso Hotel",
  locPrenAbbonamento: "Abbonamento Mensile",
  locPrenLavorativa: "Accesso Lavorativo",
  locPrenEvento: "Accesso Evento",
  locPrenAbitazione: "Accesso Abitazione Privata",
  locPrenPassaggio: "Accesso di Passaggio (sosta non prevista)",
  locPrenShopping: "Accesso Area Shopping",
  locDur5H: "5 ore",
  locDur11H: "11 ore",
  locDurPranzo: "Pranzo",
  locDurVariabileD: "Variabile (giorni)",
  locDurMensile: "Mensile (30 giorni)",
  locSpiaggiaAttrezzata: "Spiaggia Attrezzata",
  locSpiaggiaLibera: "Spiaggia Libera",
  locBattigia: "Battigia",
  locStradaLit: "Strada Litoranea",
  locRistorante: "Ristorante",
  locHotel: "Hotel",
  locAbitazione: "Abitazione Privata",
  locEvento: "Evento",
  locAttivitaLavorativa: "Attività CommercialeLavorativa",
  locAreaShopping: "Area Shopping",
  locStatoPrenCreata: "Creata",
  locStatoPrenConfermata: "Confermata",
  locStatoPrenEliminata: "Cancellata",
  locStatoPrenScaduta: "Scaduta",
  locStatoPrenUtilizzata: "Utilizzata",
  locStatoAssegnata: "Assegnata",
  locStatoLiberata: "Liberata",
  locStatoPrenotata: "Prenotata",
  locStatoEliminata: "Eliminata",
  locDurCena: "Cena",
  locDur1H: "1 ora",
  locFasciaMattina: "Mattina",
  locFasciaMensile: "Mese",
  locFasciaPranzo: "Pranzo",
  locFasciaPomeriggio: "Pomeriggio",
  locFasciaCena: "Cena",
  locFasciaSera: "Sera",
  locFasciaDay: "Tutto il giorno",
  locNotCreata: "Creata",
  locNotRead: "Letta",
  locNotDeleted: "Cancellata",
  locNotSent: "Inviata",
  locNotEvasa: "Evasa",
  locPrenMezzaGiornata: "Mezza Giornata",
  Login: "Accedi",
  User: "Utente",
  Password: "Password",
  ClearFilter: "Pulisci Filtri",
  SomethingWentWrong:
    "Attenzione! Qualcosa non ha funzionato correttamente riprovare",
  SelectLeastFile: "Selezionare almeno un file",
  SelectFile: "Seleziona un file",
  Ok: "OK",
  Province: "Provincia",
  City: "Città",
  ForgotPassword: "Password Dimenticata?",
  OTPDescription:
    "Inserisci il codice OTP inviato sul telefono qui sotto per accedere",
  RequestNewCode: "Richiedi un nuovo codice OTP",
  Send: "Invia",
  Registration: "Registrazione",
  NumberPhone: "Numero di telefono",
  isForeign: "Sei straniero?",
  Yes: "Si",
  No: "No",
  TypeDocument: "Tipo Documento",
  NumberDocument: "Numero di documento",
  RegistrationProblem: "Problemi con la registrazione?",
  BookNow: "Prenota ora",
  Loading: "Caricamento",
  Select: "Seleziona",
  Month: "Mese",
  NotAuthorizedLookPage:
    "Sembra tu non sia autorizzato ad accedere a questa pagina, contatta un Amministratore",
  BackHome: "Torna alla pagina iniziale",
  Year: "Anno",
  AreYouSureRemoveElement: "Sei sicuro di voler rimuovere l'elemento?",
  Confirm: "Conferma",
  Save: "Salva",
  Logout: "Esci",
  Week: "Settimana",
  Total: "Totale",
  SeeMoreDetails: "Ulteriori dettagli",
  EditData: "Modifica i dati",
  DeleteData: "Elimina i dati",
  FormatDateGG: "in formato (gg/mm/aaaa)",
  From: "Totale",
  To: "Totale",
  Filters: "Totale",
  ApplyFilters: "Applica i filtri",
  ExportExcel: "Esporta in Excel",
  ExportPDF: "Esporta in PDF",
  TotalElements: "Numero totale di elementi",
  Search: "Cerca",
  SearchFor: "Cerca per...",
  ActivityLog: "Log attività",
  Profile: "Profilo",
  Settings: "Impostazioni",
  REGISTER: "Registrati",
  descrizione: "descrizione",
  locPatente: "Patente",
  ENTER_YOUR_BOOKING_DETAILS: "Inserisci i dettagli della prenotazione",
  NUMERO_OCCUPANTI: "Numero di persone",
  PRENOTAZIONE_WIN_PARKING: "Congratulazioni! hai vinto il parcheggio",
  PRENOTAZIONE_NOTWIN_PARKING:
    "Ci dispiace non hai prenotato tra i primi 100! Non hai vinto il parcheggio",
  SUBMIT: "Cerca",
  SEARCH_TITLE: "Aree disponibili",
  BOOK_NOW: "PRENOTA",
  NUMERO_SPAZI_TOTALI: "Capienza totale",
  NUMERO_SPAZI_LIBERI: "Spazi disponibili",
  NUMERO_PERSONE_PRESENTI_IN_AREA: "Occupanti nell'area",
  "ENTER_DETAILS_FOR_PASSENGER_N°": "Ospite n° ",
  WHO_COMES_WITH_YOU: "Chi viene con te ?",
  BOOK_ON_TIME: "Prenota in tempo",
  CHOISE_MUNICIPALITIES_SECURE_RESERVATION:
    "la scelta dei comuni per le prenotazioni sicure",
  "404": "404 Pagina non trovata",
  Welcome: "Benvenuto",
  Email: "Email",
  Name: "Nome",
  Surname: "Cognome",
  Gender: "Sesso",
  BirthDate: "Data di Nascita",
  BirthPlace: "Luogo di Nascita",
  TypeMunicipality: "Digita il Comune",
  Language: "Lingua",
  Italiano: "Italiano",
  Inglese: "Inglese",
  "Codice Fiscale": "Codice Fiscale",
  "Carta d'Identità": "Carta d'Identità",
  Passaporto: "Passaporto",
  Patente: "Patente",
  WrongDocNumber: "Il Numero Documento è errato",
  RequireInsertFields: "Si richiede l'inserimento del/dei campi:",
  AuthorizePrivacy:
    "Autorizzo il trattamento dei miei dati personali ai sensi del Decreto Legislativo 30 giugno 2003, n. 196 “Codice in materia di protezione dei dati personali” e del GDPR (Regolamento UE 2016/679).\n" +
    "Dichiaro, altresì che per i dati personali inseriti dal sottoscritto relativi ad altri soggetti, ho ricevuto apposita e specifica autorizzazione sollevando l’Amministrazione Comunale e la ditta erogatrice del servizio telematico da qualsivoglia responsabilità",
  FieldRequired: "Questo campo è obbligatorio",
  EnterCodeMobile:
    "Inserisci il codice inviato al tuo telefono quì sotto per accedere!",
  SentedCodeEmail:
    "ti è stato inviato un codice presso la mail collegata all'utente inserito (controlla anche la cartella di spam)",
  ControllPassword: "Conferma Password",
  PasswordDontMatch: "Le password non coincidono",
  ChooseAree: "Seleziona Aree",
  AreaAssignment: "Assegnazione Area",
  Description: "Descrizione",
  DoorType: "Tipologia Varco",
  Municipality: "Comune",
  Latitude: "Latitudine",
  Longitude: "Longitudine",
  State: "Stato",
  Door: "Varco",
  Cancel: "Annulla",
  Actions: "Azioni",
  TimeSlot: "Fascia Oraria",
  Area: "Area",
  Date: "Data",
  Tipology: "Tipologia",
  EnterBookingDetails: "Inserisci i dettagli della prenotazione",
  BookTipology: "Tipologia Prenotazione",
  NumberOccupants: "Numero Occupanti",
  ORARIO_ARRIVO: "Orario di Arrivo",
  LicensePlateRequired: "Targa richiesta",
  Payed: "Pagato",
  TotalSlot: "Slot totali",
  SuccessedOperation: "Operazione effettuata con successo",
  ParkingArea: "Parcheggio",
  Lookups: "Lookups",
  Lookup: "Lookup",
  ChooseLookup: "Seleziona un lookup",
  Entrance: "Ingresso",
  Exit: "Uscita",
  Notes: "Note",
  ControlOk: "Controllo Ok",
  ControlKo: "Controllo Ko",
  ControlLicensePlate: "Controllo Targa",
  NotificationCentral: "Notifiche alla centrale",
  NotAuthorized: "Non Autorizzato",
  TOTALE_PRENOTATI: "Totale Posti Prenotati",
  TOTALE_POSTI: "Posti Totali",
  TOTALE_OCCUPATI: "Totale Posti Occupati",
  TOTALE_DISPONIBILI: "Totale Posti disponibili",
  DISPLAY_TEXT_INFO_F:
    "Attenzione! Viene mostrata la maggiore disponibilità di posti per le aree ricercate.",
  DISPLAY_TEXT_INFO_L:
    "La disponibilità potrebbe variare in base alla fascia oraria selezionata nelle fasi successive della prenotazione.",
  REQUEST_SUPPORT: "Richiesta di supporto",
  SUBJECT: "Oggetto",
  EXPORT_DATA: "Esporta dati",
  ENTER_DOCUMENT_NUMBER: "Inserisci Numero Documento",
  MUNICIPALITY: "Comune di appartenenza",
  SEND_NOTIFICATION: "Invio di notifiche",
  BANNER_TYPE: "Tipo di banner",
  BANNER_IMAGE: "Immagine banner",
  PRENOTAZIONE_BLOCK: "Blocchi Prenotazione",
  DETAIL: "Dettaglio",
  OTP_MANDATORY: "OTP obbligatorio",
  USERNAME_MANDATORY: "USERNAME obbligatorio",
  USER_NOT_ASSIGNED: "Utente non assegnato",
  BARCODE_REQUIRED: "Barcode richiesto",
  EXCEEDED_PAX_LIMIT: "Superato limite persone",
  BAD_CREDENTIAL: "Credenziali errate",
  ERROR_DURING_SEARCH_AVAILABLE_TURN:
    "Errore durante la ricerca di un turno disponibile",
  NUMBER_NOT_FOUND: "Numero documento non trovato",
  ERROR_DURING_PRENOTAZIONE_INSERT_VALIDATION:
    "Errore durante la convalida dell' inserimento di prenotazione",
  ERROR_DURING_PRENOTAZIONE_INSERT:
    "Errore durante l'inserimento della prenotazione",
  ERROR_DURING_PRENOTAZIONE_UPDATE_VALIDATION:
    "Errore durante la convalida aggiornamento di prenotazione",
  ERROR_DURING_PRENOTAZIONE_UPDATE:
    "Errore durante l'aggiornamento della prenotazione",
  PRENOTAZIONE_NOT_FOUND: "Prenotazione non trovata",
  INVALID_BARCODE: "Barcode non valido",
  INVALID_TARGA: "Targa non valida",
  VARCO_CONFIRMED: "Varco confermato",
  VARCO_DENIED: "Anomalia segnalata",
  REGISTRAZIONE_OK: "Registrazione andata a buon fine",
  OTP_SEND: "OTP inviato",
  PASSWORD_CHANGED: "Password cambiata",
  PRENOTAZIONE_INSERT_SUCCESS: "Prenotazione inserita con successo",
  PRENOTAZIONE_UPDATE_SUCCESS: "Prenotazione aggiornata con successo",
  FORGETPASSWORD: "Password dimenticata",
  DETTAGLIO_AREA: "Dettaglio Area",
  dettaglioArea: "Dettaglio Area",
  DETTAGLIO_PRENOTAZIONE: "Dettaglio Prenotazione",
  dettaglioPrenotazione: "Dettaglio Prenotazione",
  DETTAGLIO_VEICOLO: "Dettaglio Veicolo",
  dettaglioVeicolo: "Dettaglio Veicolo",
  DETTAGLIO_ANAGRAFICA: "Dettaglio Anagrafica",
  dettaglioAnagrafica: "Dettaglio Anagrafica",
  TIPOLOGIA_PRENOTAZIONE: "Tipologia prenotazione",
  FASCIA_ORARIA: "Fascia oraria",
  TIPO_VEICOLO: "Tipo veicolo",
  PARCHEGGIO: "Parcheggio",
  NUMERO_DOCUMENTO: "Numero Documento",
  TIPOLOGIA_DOCUMENTO: "Tipologia documento",
  LABEL_WRONG_COMUNE: "Prenotazione non valida",
  LEGEND: "Legenda",
  NOTIFICATION_DETAIL: "Dettaglio Notifica",
  PRENOTAZIONI: "Prenotazioni",
  DELETE_MESSAGE: "Sei sicuro di voler cancellare la tua prenotazione?",
  USER_BANNED: "Utente bannato",
  EDIT: "Modifica",
  ORA_DI_INIZIO_PRENOTAZIONE: "Ora di inizio prenotazione",
  DURATA_PRENOTAZIONE: "Durata prenotazione",
  TARGA: "Targa",
  FORGOTPASSWORD: "Password Dimenticata",
  NEWPASSWORD: "Nuova Password",
  RESERVATION: "Prenotazione",
  "Fk id tipologia profilo": "Tipologia profilo",
  "Fk id lingua": "Lingua",
  Username: "Username",
  "Data creazione": "Data creazione",
  "Data ultima modifica": "Data ultima modifica",
  "Flg attivo": "Attivo",
  "Flg eliminato": "Eliminato",
  "Fk id comune": "Comune",
  EVADI_NOTIFICA: "Evadi notifica",
  GENERAL_SETTINGS: "Impostazioni generali",
  image: "Immagine",
  map: "Mappa",
  SELEZIONA_PARCHEGGI: "Seleziona Parcheggi",
  SELEZIONA_FASCE_ORARIE: "Seleziona Fasce Orarie",
  SELEZIONA_VARCHI: "Seleziona Varchi",
  DATI_AREA: "Dati Area",
  ASSEGNAZIONE_PARCHEGGI: "Assegnazione Parcheggi",
  ASSEGNAZIONE_FASCE_ORARIE: "Assegnazione Fasce Orarie",
  ASSEGNAZIONE_VARCO: "Assegnazione Varco",
  DATI_VARCO: "Dati Varco",
  "Fk id tipologia varco": "Tipologia Varco",
  BARCODE_DOC: "Codice a barre documento",
  SCAN: "Scansiona",
  READING: "In lettura...",
  "Register From Varco": "Registrazione al Varco",
  "Book From Varco": "Prenota al Varco",
  "Nuova prenotazione": "Nuova prenotazione",
  Varco: "Varco",
  "Controllo Varco": "Controllo Varco",
  "Controllo Targa": "Controllo Targa",
  Parcheggio: "Parcheggio",
  Utenti: "Utenti",
  Configurazione: "Configurazione",
  CentraleOperativa: "Centrale Operativa",
  Notifiche: "Notifiche",
  ResultSearch: "Risultati ricerca",
  "Area Details": "Dettagli Area",
  "Slot totali": "Slot totali",
  "Flg privato": "Privato",
  SELECT_FROM_MAPS: "Seleziona tramite maps",
  "Fk id tipologia area": "Tipologia area",
  "Flg accesso disabili": "Accesso disabili",
  "Flg animali ammessi": "Animali ammessi",
  StatoAssegnazione: "Stato Assegnazione",
  StatoPrenotazione: "Stato Prenotazione",
  TipologiaArea: "Tipologia Area",
  TipologiaDocumento: "Tipologia Documento",
  TipologiaPrenotazione: "Tipologia Prenotazione",
  TipologiaProfilo: "Tipologia Profilo",
  TipologiaVarco: "Tipologia Varco",
  TipologiaVeicolo: "Tipologia Veicolo",
  Comune: "Comune",
  Lingua: "Lingua",
  RisorsaLogica: "Risorsa Logica",
  RelTipoDurataFasciaOraria: "Tipo Durata Fascia Oraria",
  RelTipoPrenotazioneTipoDurata: "Tipo Prenotazione Tipo Durata",
  RelTipoAreaTipoPrenotazione: "Tipo Area Tipo Prenotazione",
  "Id stato assegnazione": "ID Stato Assegnazione",
  "Codice stato assegnazione": "Codice Stato Assegnazione",
  "Desc localizzata": "Descrizione Localizzata",
  Azioni: "Azioni",
  "Id stato prenotazione": "ID Stato Prenotazione",
  "Codice stato prenotazione": "Codice Stato Prenotazione",
  "Id tipologia area": "ID Tipologia Area",
  "Codice tipologia area": "Codice Tipologia Area",
  "Id tipologia documento": "ID Tipologia Documento",
  "Codice tipologia documento": "Codice Tipologia Documento",
  "Id tipologia prenotazione": "ID Tipologia Prenotazione",
  "Codice tipologia prenotazione": "Codice Tipologia Prenotazione",
  "Flg abilitata utente": "Abilitata all'utente",
  "Id tipologia profilo": "ID Tipologia Profilo",
  "Codice profilo": "Codice profilo",
  "Flg comunale": "Comunale",
  "Id tipologia varco": "ID Tipologia Varco",
  "Codice tipologia varco": "Codice Tipologia Varco",
  "Id tipologia veicolo": "ID Tipologia Veicolo",
  "Codice tipologia veicolo": "Codice Tipologia Veicolo",
  "Flg veicolo privato": "Veicolo Privato",
  "Id comune": "ID Comune",
  "Fk id provincia": "ID provincia",
  Descrizione: "Descrizione",
  "Giorni prenotazione": "Giorni Prenotazione",
  "Giorni blocco": "Giorni Blocco",
  "Minuti ritardo massimo": "Minuti Ritardo Massimo",
  "Flg abilitato": "Abilitato",
  "Max occupanti": "Max Occupanti",
  Impostazioni: "Impostazioni",
  "Park giornalieri": "Park Giornalieri",
  Latitudine: "Latitudine",
  Longitudine: "Longitudine",
  "Id lingua": "ID Lingua",
  "Codice lingua": "Codice Lingua",
  "Id risorsa logica": "ID Risorsa Logica",
  "Codice risorsa logica": "Codice Risorsa Logica",
  "Id rel tipo durata fascia oraria": "ID Tipo Durata Fascia Oraria",
  "Tipologia durata.desc localizzata": "Tipologia Durata Localizzata",
  "Fascia oraria.desc localizzata": "Fascia Oraria Localizzata",
  "Id rel tipo prenotazione tipo durata": "ID Tipo Prenotazione Tipo Durata",
  "Tipologia Durata Localizzata": "Tipologia Durata Localizzata",
  "Tipologia prenotazione.desc localizzata":
    "Tipologia Prenotazione Localizzata",
  "Id rel tipo area tipo prenotazione": "ID Tipo Area Tipo Prenotazione",
  "Tipologia area.desc localizzata": "Tipologia Area Localizzata",
  "Tipologia Prenotazione Localizzata": "Tipologia Prenotazione Localizzata",
  locDipendenteArea: "Dipendente Area",
  locAusiliareTraffico: "Ausiliario Traffico",
  Add: "Aggiungi",
  "notValidPassengerN°": " non valido per passeggero N° ",
  field: "il campo ",
  isNotValid: " non è valido",
  NO_ID_PRENOTAZIONE:
    "errore di sistema, riprovare più tardi, se persiste contattare l'assistenza",
  NO_VEHICLE_TYPE: "Nessun tipo veicolo selezionato",
  NO_PARCHEGGIO_SELEZIONATO: "Nessun parcheggio selezionato",
  INSERT_PERSONAL_DATA: "Inserisci i dati dei passeggero/i",
  DATA_NOT_AVAILABLE_FOR_USER: "Data non disponibile per l'utente, potresti già avere una prenotazione per questo giorno",
  VALIDATE_NOTE_ERROR:
    "Attenzione! Le note sono obbligatorie per segnalare un Transito non conforme",
  VALIDATE_INGRESSO_ERROR: "Attenzione! Selezionare entrata o uscita",
  NUMBER_OR_DOC_ALREADY_EXIST:
    "Numero di telefono o numero documento già presente nel sistema, contatta l'assistenza per maggiori informazioni",
  insert_password: "inserisci la nuova password",
  confirm_password: "conferma la password",
  CHANGE_PASSWORD: "CAMBIA LA PASSWORD",
  FULL: "PIENO",
  SELEZIONA_AREA: "Seleziona un'area",
  CODICE_FISCALE: "Codice Fiscale",
  RICONTROLLA_DATI:
    "Ti preghiamo di controllare i dati inseriti, se l'errore dovesse persistere contattare l'assistenza.",
  "Close Area From Varco": "Chiudi Area da Varco",
  confirm_operation:
    "CONFERMI DI VOLER PROCEDERE CON L'OPERAZIONE SELEZIONATA?",
  Close_Area: "Chiudi Area",
  Open_Area: "Apri Area",
  REGISTRAZIONE: "Registrazione",
  REGISTRAZIONE_RAPIDA: "Registrazione Rapida",
  SELECT_TIPOLOGIA_DOC: "Seleziona un tipologia di documento",
  GESTORE_AREA: "Gestore Area",
  "Fk id utente": "Utente",
  "Fk id area": "Area",
  ERROR_LOADIN_AREA_DETAILS:
    "Errore durante il caricamento dei dettagli dell'aera",
  LoginProblem: "Problemi con il login?",
  ERROR_DURING_HELPDESK: "Errore durante la segnalazione",
  HelpdeskSucces:
    "La tua segnalazione è stata presa in carico, ti risponderemo appena possibile",
  PASSWORD_PATTERN:
    "La password deve essere compresa tra 8-20 caratteri, contenere almeno 1 lettera, 1 numero e 1 carattere speciale e non iniziare con un carattere speciale (!@#$%^&*()_+)",
  PASSWORD_NOT_VALID: "Password non valida",
  DATE_INVALID: "Data non valida, per registarsi bisogna avere almeno 14 anni di età",
  INVALID_PREN_DATE:
    "Data di prenotazione non valida, verifica se hai già delle prenotazioni per la data corrente",
  INVALID_MUNICIPALITY: "Comune non valido",
  PLACEHOLDER_DATE: "GG/MM/AAAA",
  REGISTRO_PRESENZE: "Registro Presenze",
  RESETPASSWORD: "Reimposta Password",
    USER_NOT_FOUND: "Utente non trovato",
  SELECT_AREA: "Seleziona un'Area",
  DELETE_MESSAGE_GESTORE:
    "Sei Sicuro Di Voler Cancellare L'Elemento Selezionato ?",
  AREA_NOT_FOUND: "Area Non Trovata",
  MODIFICA_DATI_PRENOTAZIONE:"Modifica i dati della prenotazione",
  LOCKED:"Bloccato",
  UNLOCKED:"Sbloccato",
  THESE_FIELDS_ARE_REQUIRED: "I campi contrassegnati con (*) sono obbligatori",
  INSERT_PNR_OR_DOC:"Inserisci PNR o numero di documento",
  locFasciaEvento: "Evento",
  locFasciaOrariaEvento: "Evento",
  numeroPersone: "Numero Persone",
  MODAL_CONTROLLO_LABEL: "Seleziona la prenotazione:"
};
