import React from "react";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import CentraleOperativaService from "./CentraleOperativa.service";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import RxComponent from "../../redux/RxComponent";
import NotificationDetail from "./components/NotificationDetails";
import { Button, ModalBody, ModalHeader, Modal, ModalFooter } from "reactstrap";

import { NOTIFICASTATUSES } from "../../constants/NotificaStatuses";

class Page extends React.Component {
  state = {
    openModal: false,
    selectedPnr: null,
  };

  service = new CentraleOperativaService();

  columns = [
    {
      label: "Pnr",
      data: "Prenotazione.pnr",
    },
    {
      label: this.props.t("Door"),
      data: "Varco.descrizione",
    },
    {
      label: this.props.t("State"),
      data: "StatoNotifica.descLocalizzata",
    },
    {
      label: "",
      type: "custom",
      render: (celldata, row) =>
        row.StatoNotifica.codiceStatoNotifica !== NOTIFICASTATUSES.DELETED && (
            <>
                <Button
                    outline
                    size="sm"
                    onClick={() => {
                        if (
                            row.StatoNotifica.codiceStatoNotifica &&
                            row.StatoNotifica.codiceStatoNotifica ===
                            NOTIFICASTATUSES.CREATED
                        ) {
                            this.service.changeStatusNotifica(
                                row.idNotificaCentraleOperativa,
                                NOTIFICASTATUSES.READ
                            );
                        }
                        this.setState({
                            selectedRow: row,
                            openModal: true,
                        });
                    }}
                >
                    <i className="fas fa-pencil-alt"></i>
                </Button>
                <Button
                    outline
                    size="sm"
                    onClick={() => {
                        this.setState({
                            row: row,
                            openModalDelete: true,
                        });
                    }}
                >
                    <i className="fas fa-trash-alt"></i>
                </Button>
            </>
        ),
    },
  ];

  filters = [];

  closeModal() {
    this.setState(
      {
        openModal: false,
        selectedPnr: null,
        selectedPrenotazione: null,
      },
      () => {
        this.refreshTable();
      }
    );
  }

  closeModalDelete() {
    this.setState(
      {
        openModalDelete: false,
      },
      () => {
        this.refreshTable();
      }
    );
  }

  refreshTable() {
    this.refs.crudNetNotifica.refreshTable();
  }

  renderHeader() {
    return () => (
      <h1 className="h3 mb-4 text-gray-800 text-left page-header">
        {this.props.t("NotificationCentral")}
      </h1>
    );
  }

  render() {
    return (
      <>
        <CrudNetTable
          customHeader={this.renderHeader()}
          title={this.props.t("NotificationCentral")}
          refFieldMap={{
            fkIdStatoNotifica: "descLocalizzata",
            fkIdLingua: "descLocalizzata",
          }}
          defaultFilters={{
            [`${FILTER_NUMBER}&&&Varco.fkIdComune`]: [
              this.props.Auth.user.fkIdComune,
            ],
          }}
          actions={[]}
          columns={this.columns}
          filters={this.filters}
          service={new CentraleOperativaService()}
          ref="crudNetNotifica"
        />
        <Modal isOpen={this.state.openModal} toggle={() => this.closeModal()}>
          <ModalHeader toggle={() => this.closeModal()}>
            <h5>{this.props.t("NOTIFICATION_DETAIL")}</h5>
          </ModalHeader>
          <ModalBody>
            <NotificationDetail
              row={this.state.selectedRow}
              onClose={() => this.closeModal()}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.openModalDelete}
          toggle={this.closeModalDelete.bind(this)}
          size="md"
        >
          <ModalHeader toggle={this.closeModalDelete.bind(this)}>
            HeaderUpdate
          </ModalHeader>
          <ModalBody>{this.props.t("DELETE_MESSAGE")}</ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                await this.service.changeStatusNotifica(
                  this.state.row.idNotificaCentraleOperativa,
                  NOTIFICASTATUSES.DELETED
                );
                this.closeModalDelete();
              }}
            >
              {this.props.t("Ok")}
            </Button>
            <Button onClick={this.closeModalDelete.bind(this)}>
              {this.props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const NotificationCOPage = RxComponent()(Page);
export default NotificationCOPage;
