import { put, call, takeLatest, take } from "redux-saga/effects";
import {
  successSearch,
  errorSearch,
  errorValidate,
  successTipologieAreaSearch,
  selectArea,
  triggerInsertPrenotazione,
  triggerSelectArea,
  onSuccessInsertPrenotazione,
  onSuccessUpdatePrenotazione,
  successScanBarCodePrenotazione,
  successTargaPrenotazione,
  successUserByDocSearch,
  successParcheggiSearch, successScanBarCodePrenotazioneMultiple, prenotazioneNonTrovata,
} from "./Prenotazione.actions";
import { onError, onSuccess, onInfo } from "../Shared/Shared.actions";
import {
  TRIGGER_SEARCH,
  TRIGGER_SELECT_AREA,
  TRIGGER_INSERT_PRENOTAZIONE,
  TRIGGER_GET_PRENOTAZIONE,
  TRIGGER_UPDATE_PRENOTAZIONE,
  TRIGGER_SCAN_BARCODE_PRENOTAZIONE,
  TRIGGER_TARGA,
  TRIGGER_SEARCH_USER_BY_DOC,
  TRIGGER_SEARCH_USER_BY_DOC_GESTORE,
} from "./Prenotazione.types";
import PrenotazioneService from "../../modules/Prenotazione/Prenotazione.service";
import UtenteService from "../../modules/Utente/Utente.service";
import { hist } from "../../App";
import _ from "lodash";
import ComuneService from "../../modules/Comune/Comune.service";
import { ROLES } from "../../constants/Roles";

const service = new PrenotazioneService();
const utenteService = new UtenteService();
const comuneService = new ComuneService();

function* validateSearch(paramAction) {
  const formField = paramAction.payload;
  //chiamo metodo del service che controlla il form
  const validationResult = yield call(() =>
    service.validateDisponibilitaForm(formField)
  );

  //se la validazione è ok
  if (!validationResult.error) {
    // ricerco le aree disponibili
    const searchResult = yield call(() => {
      let formFieldToPass = {
        IDUTENTE: formField.IDUTENTE,
        idComune: formField.idComune,
        data: formField.dataPrenotazione,
        numeroOccupanti: formField.numeroOccupanti,
      };
      return service.searchDisponibilita(formFieldToPass);
    });
    //ricerco i parcheggi disponibili
    const parcheggiResult = yield call(() => {
      let formFieldToPass = {
        idComune: formField.idComune,
        data: formField.dataPrenotazione,
      };
      return comuneService.getParcheggiAttivi(formFieldToPass);
    });

    // ricerco le tipologie di aree
    const TipologieAreaResult = yield call(() =>
      service.searchTipologieArea(formField)
    );
    // se l'utenza è di tiipo gestoreArea si salta la scelta dell'area e si procede direttamente al dettaglio ( l'area è già stata selezionata nella prima form )
    if (formField.fromGestoreArea) {
      let payload = {
        idArea: formField.idArea,
        data: formField.dataPrenotazione,
        numeroOccupanti: formField.numeroOccupanti,
      };
      const area = yield call(() =>
        service.loadArea(payload).then((res) => res.data.result)
      );
      if (!area.error) {
        yield put(successSearch(area, formField));
        yield put(successParcheggiSearch(parcheggiResult.data.result));
        yield put(successTipologieAreaSearch(TipologieAreaResult.data.result));
        yield put(triggerSelectArea(area));
        //hist.push("/dashboard/insertDetailFromDashboard");
      } else {
        yield put(errorSearch(area.error));
        yield put(onError("ERROR_LOADIN_AREA_DETAILS"));
      }
    } else {
      if (!searchResult.error) {
        yield put(successSearch(searchResult.data.result, formField));
        if (!parcheggiResult.error)
          yield put(successParcheggiSearch(parcheggiResult.data.result));
        if (!TipologieAreaResult.error)
          yield put(
            successTipologieAreaSearch(TipologieAreaResult.data.result)
          );
        if (paramAction.payload.fromDashboard) {
          hist.push("/dashboard/resultSearch");
        } else {
          hist.push("/site/resultSearch");
        }
      } else {
        yield put(errorSearch(searchResult.error));
        yield put(onError("ERROR_DURING_SEARCH_AVAILABLE_TURN"));
      }
    }
  } else {
    yield put(errorValidate(validationResult.error));
    yield put(onError(validationResult.error.join("\n")));
  }
}

function* searchUtenteByDocFromVarcoSaga(cf) {
  const searchResult = yield call(() =>
    service.searchUserAssegnatoByCF(cf.payload)
  );
  if (
    !searchResult.error &&
    searchResult.data.output &&
    searchResult.data.output.return_value &&
    searchResult.data.output.return_value > 0
  ) {
    yield put(successUserByDocSearch(searchResult.data.output.return_value));
    hist.push("/dashboard/prenotazioneFromVarco");
  } else {
    yield put(onError("NUMBER_NOT_FOUND"));
  }
}

function* searchUtenteByDocFromGestoreSaga(cf) {
  const searchResult = yield call(() =>
    service.searchUserAssegnatoByCF(cf.payload)
  );
  if (
    !searchResult.error &&
    searchResult.data.output &&
    searchResult.data.output.return_value &&
    searchResult.data.output.return_value > 0
  ) {
    yield put(successUserByDocSearch(searchResult.data.output.return_value));
    hist.push("/dashboard/prenotazioneFromGestore");
  } else {
    yield put(onError("NUMBER_NOT_FOUND"));
  }
}

function* selectAreaSaga(area) {
  yield put(selectArea(area.payload));
  if (area.payload.fromDashboard) {
    hist.push("/dashboard/insertDetailFromDashboard");
  } else {
    hist.push("/site/insertdetail");
  }
}

function* insertPrenotazioneSaga(prenotazione) {
  const objToPass = yield call(() =>
    service.getObjForPrenotazioneInsert(prenotazione.payload)
  );

  const errors = yield call(() =>
    service.validateParameterForPrenotazioneInsert(objToPass)
  );
  if (errors && errors.length > 0) {
    yield put(onError("ERROR_DURING_PRENOTAZIONE_INSERT_VALIDATION"));
  } else if (objToPass) {
    const res = objToPass.IDUTENTEPRENOTATO
      ? yield call(() => service.insertPrenotazionSPBO(objToPass))
      : yield call(() => service.insertPrenotazionSP(objToPass));
    if (res.data.result[0].idPrenotazione && !res.data.result[0].errorMessage) {
      const settings = yield call(() =>
        comuneService
          .getById(prenotazione.payload.idComune)
          .then((res) => JSON.parse(res.data.result.settings))
      );

      if (settings.sendNotification) {
        yield call(() =>
          service.sendSms(res.data.result[0].idPrenotazione, "I")
        );

        if (
          prenotazione.payload.emailArea &&
          prenotazione.payload.emailArea.length
        )
          yield call(() =>
            service.sendEmailGestore(res.data.result[0].idPrenotazione)
          );
      }

      yield put(onSuccessInsertPrenotazione());
      yield put(onSuccess("PRENOTAZIONE_INSERT_SUCCESS"));
      if (res.data.result[0].statoVincita === 2) {
        yield put(onInfo("PRENOTAZIONE_WIN_PARKING"));
      }
      if (res.data.result[0].statoVincita === 1) {
        yield put(onInfo("PRENOTAZIONE_NOTWIN_PARKING"));
      }
      hist.push("/site/prenotazioni");
    } else {
      if (res.data.result[0].errorMessage) {
        yield put(onError(res.data.result[0].errorMessage));
      } else {
        yield put(onError("ERROR_DURING_PRENOTAZIONE_INSERT"));
      }
    }
  }
}

function* updatePrenotazioneSaga(prenotazione) {
  const objToPass = yield call(() =>
    service.getObjForPrenotazioneUpdate(prenotazione.payload)
  );
  const errors = yield call(() =>
    service.validateParameterForPrenotazioneUpdate(objToPass)
  );
  if (errors && errors.length > 0) {
    yield put(onError("ERROR_DURING_PRENOTAZIONE_UPDATE_VALIDATION"));
  } else if (objToPass) {
    const res = yield call(() => service.updatePrenotazionSP(objToPass));
    if (!res.data.error && !res.data.result[0].errorMessage) {
      const settings = yield call(() =>
        comuneService
          .getById(prenotazione.payload.idComune)
          .then((res) => JSON.parse(res.data.result.settings))
      );

      if (settings.sendNotification) {
        yield call(() => service.sendSms(objToPass.IDPRENOTAZIONE, "U"));
      }

      yield put(onSuccessUpdatePrenotazione());
      yield put(onSuccess("PRENOTAZIONE_UPDATE_SUCCESS"));
      //
    } else {
      if (res.data.result[0].errorMessage) {
        yield put(onError(res.data.result[0].errorMessage));
      } else {
        yield put(onError("ERROR_DURING_PRENOTAZIONE_UPDATE"));
      }
    }
  }
}

function* getPrenotazioneSaga(action) {
  const res = yield call(() => service.getPrenotazioneByPnr(action.payload));
  if (!res.error && _.isObject(res.result) && !_.isEmpty(res.result)) {
    yield put(successSearch(null, res.result.filter));
    yield put(selectArea(res.result.area));
  } else {
    yield put(onError("PRENOTAZIONE_NOT_FOUND"));
  }

  //
}

function* scanBarCodePrenotazioneSaga(action) {
  const res = yield call(() => service.getPnrByBarcode(action.payload));

  if (!res.error && res.data.result.length === 1) {
    yield put(successScanBarCodePrenotazione(res.data.result[0].PNR));
  } else if (!res.error && res.data.result.length > 1) {
    yield put(successScanBarCodePrenotazioneMultiple(res.data.result));
  } else if (!res.error && res.data.result.length === 0) {
    yield put(prenotazioneNonTrovata())
    yield put(onError("PRENOTAZIONE_NOT_FOUND"));
  } else {
    yield put(onError("INVALID_BARCODE"));
  }
}

function* handleTargaSaga(action) {
  const res = yield call(() => service.getPnrByTarga(action.payload));
  if (!res.error && res.data.result.length === 1) {
    yield put(successTargaPrenotazione(res.data.result[0].PNR));

    const results = yield call(() =>
      service.getPrenotazioneByPnr(res.data.result[0].PNR)
    );
    if (
      !results.error &&
      _.isObject(results.result) &&
      !_.isEmpty(results.result)
    ) {
      yield put(successSearch(null, results.result.filter));
      yield put(selectArea(results.result.area));
    } else {
      yield put(prenotazioneNonTrovata())
      yield put(onError("PRENOTAZIONE_NOT_FOUND"));
    }
  } else {
    yield put(onError("INVALID_TARGA"));
  }
}

export function* prenotazioneSaga() {
  //wait for search
  yield takeLatest(TRIGGER_SEARCH, validateSearch);

  yield takeLatest(TRIGGER_SELECT_AREA, selectAreaSaga);

  yield takeLatest(TRIGGER_INSERT_PRENOTAZIONE, insertPrenotazioneSaga);

  yield takeLatest(TRIGGER_GET_PRENOTAZIONE, getPrenotazioneSaga);

  yield takeLatest(TRIGGER_UPDATE_PRENOTAZIONE, updatePrenotazioneSaga);

  yield takeLatest(
    TRIGGER_SCAN_BARCODE_PRENOTAZIONE,
    scanBarCodePrenotazioneSaga
  );

  yield takeLatest(TRIGGER_TARGA, handleTargaSaga);

  yield takeLatest(TRIGGER_SEARCH_USER_BY_DOC, searchUtenteByDocFromVarcoSaga);
  yield takeLatest(
    TRIGGER_SEARCH_USER_BY_DOC_GESTORE,
    searchUtenteByDocFromGestoreSaga
  );
}
