import React from "react";
import "../../../template/dashboard/css/custom.css";
import RxComponent from "../../../redux/RxComponent";
import { triggerRegister } from "../../../redux/Auth/Auth.actions";
import {onSuccess, onError} from "../../../redux/Shared/Shared.actions";
import LoginService from "../../Login/Login.service";

class Component extends React.PureComponent {
  loginService = new LoginService();

  constructor(props) {
    super(props);
    this.state = {
        submitted: false,
    };
  }

  componentDidMount() {

  }

  sendInfo(ev) {
    ev.preventDefault();
    this.setState({
      submitted: true,
    });

    /* next implementation
      @NOME: string,
      @COGNOME: string,
      @EMAIL: string,
      @CELLULARE: int,
      @DESCRIPTION: string,
      @SUBJECT: string,

      @emailSender				VARCHAR(100),
	@nomeSender					VARCHAR(100),
	@cognomeSender				VARCHAR(100),
	@cellulareSender			VARCHAR(100),
	@subject					VARCHAR(100),
	@descrizione
    */

    const data = {
      nomeSender: this.state.nome,
      cognomeSender: this.state.cognome,
      emailSender: this.state.email,
      cellulareSender: this.state.number,
      descrizione: this.state.descrizione,
      subject: this.state.oggetto,
    };
    this.loginService.contactUs(data).then(res => {
      if (res.data.result[0].Esito === "Ok"){
        this.props.onSuccess("HelpdeskSucces")
      } else {
        this.props.onError("ERROR_DURING_HELPDESK")
      }
    });
  }
/*
if (!registerRes.error) {
      if (
        registerRes.result[0].idOtp === 0 &&
        registerRes.result[0].idUtente === 0
      ) {
        yield put(onError("NUMBER_OR_DOC_ALREADY_EXIST"));
      } else if (registerRes.result[0].idUtente === 0) {
        yield put(onError("NUMBER_OR_DOC_ALREADY_EXIST"));
      } else {
        yield put(successRegister(registerRes.result));
        yield put(onSuccess("REGISTRAZIONE_OK"));
      }
    }
 */

  templateHTML() {
    return (
        <div className="bg-gradient">
            <div className="Login-wrapper fadeInDown">
            <div className="Login-form-Content">
              <div className="fadeIn first">
                <h1 className="Login-form--title">{this.props.t("REQUEST_SUPPORT")}</h1>
              </div>
              <form onSubmit={(ev)=>this.sendInfo(ev)}>
                <div className="Login-form-cont">
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("Name")}</label>
                  </div>
                  <input
                    required
                    type="text"
                    id="nome"
                    className="form--input fadeIn first"
                    onChange={(ev) =>
                        this.setState({ nome: ev.target.value })
                    }
                    name="nome"
                  />
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("Surname")}</label>
                  </div>
                  <input
                    required
                    type="text"
                    id="cognome"
                    className="form--input fadeIn first"
                    onChange={(ev) =>
                        this.setState({ cognome: ev.target.value })
                    }
                    name="cognome"
                  />
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("NumberPhone")}</label>
                  </div>
                  <input
                    required
                    type="number"
                    id="number"
                    className="form--input fadeIn first"
                    onChange={(ev) =>
                        this.setState({ number: ev.target.value })
                    }
                    name="number"
                  />
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("Email")}</label>
                  </div>
                  <input
                    type="email"
                    id="email"
                    required
                    className="form--input fadeIn"
                    onChange={(ev) =>
                        this.setState({ email: ev.target.value })
                    }
                    name="email"
                  />
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("SUBJECT")}</label>
                  </div>
                  <input
                    required
                    type="text"
                    id="oggetto"
                    className="form--input fadeIn first"
                    onChange={(ev) =>
                        this.setState({ oggetto: ev.target.value })
                    }
                    name="oggetto"
                  />
                  <div className="form-input-label-cont py-0">
                    <label>{this.props.t("Description")}</label>
                  </div>
                  <textarea
                    required
                    type="text"
                    id="descrizione"
                    className="form--input fadeIn first"
                    onChange={(ev) =>
                        this.setState({ descrizione: ev.target.value })
                    }
                    name="descrizione"
                  />
                  </div>
                  <input
                    type="submit"
                    className="fadeIn fourth"
                    value={this.props.t("Send")}
                  />
                  </form>
                  <div className="Login-form-Footer d-flex justify-content-between">
                    <a className="Login-form--underlineHover" href="/login">
                      {this.props.t("Login")}
                    </a>
                    <a className="Login-form--underlineHover" href="/register">
                      {this.props.t("Registration")}
                    </a>
                </div>
              </div>
          </div>
        </div>
    );
  }

  render() {
      return this.templateHTML();
  }
}

const RegisterPage = RxComponent({ triggerRegister, onSuccess, onError })(Component);
export default RegisterPage;
