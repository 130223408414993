import React from "react";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import RxComponent from "../../redux/RxComponent";
import moment from "moment";
import _ from "lodash";
import { onError, onSuccess } from "../../redux/Shared/Shared.actions";
import GestoreAreaService from "./AssegnaGestoreArea.service";
import { FILTER_NUMBER } from "../../components/TableServer/FilterItem";
import AreaService from "../Area/Area.service";
import TipologiaDocumentoService from "../TipologiaDocumento/TipologiaDocumento.sevice";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import { Trans } from "react-i18next";
import { SelectPicker } from "../../components/Content/SelectPicker";

class Comp extends React.Component {
  state = {
    showModal: false,
    idComune: this.props.Auth.user ? this.props.Auth.user.fkIdComune : null,
    idsAree: [],
  };

  INITIAL_STATE = null;

  columns = [
    {
      label: this.props.t("Area"),
      data: "Area.descrizione",
    },
    {
      label: this.props.t("Fk id tipologia area"),
      data: "Area.TipologiaArea.descLocalizzata",
    },
    {
      label: "",
      type: "custom",
      render: (celldata, row) => (
        <Button
          outline
          size="sm"
          onClick={() => {
            this.setState({
              row: row,
              openModalDelete: true,
            });
          }}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      ),
    },
  ];

  columnsArea = [
    {
      label: this.props.t("Nome"),
      data: "Utente.AnagraficaUtente_List[0].nome",
    },
    {
      label: this.props.t("Cognome"),
      data: "Utente.AnagraficaUtente_List[0].cognome",
    },
    {
      label: this.props.t("Cognome"),
      data: "Utente.AnagraficaUtente_List[0].email",
    },
    {
      label: "",
      type: "custom",
      render: (celldata, row) => (
        <Button
          outline
          size="sm"
          onClick={() => {
            this.setState({
              row: row,
              openModalDelete: true,
            });
          }}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      ),
    },
  ];

  service = new GestoreAreaService();
  areaService = new AreaService();
  tipologiaDocumentoService = new TipologiaDocumentoService();

  componentDidMount() {
    if (this.state.idComune) {
      let filter = {
        [`${FILTER_NUMBER}&&&FkIdComune`]: [this.props.idComune],
      };
      this.areaService
        .paginate(0, -1, filter)
        .then((res) => {
          if (res.data.result && res.data.result.length) {
            this.setState({
              areaList: res.data.result,
            });
          }
        })
        .then(() => {
          this.tipologiaDocumentoService.getAllNewApi().then((res) => {
            this.setState({
              listTipologiaDocumento: res.data.result,
            });
          });
        })
        .then(() => {
          this.INITIAL_STATE = this.state;
        });
    } else {
      this.props.onError(this.props.t("INVALID_MUNICIPALITY"));
    }
  }

  onSubmit(formData, viewMode) {
    const data = {
      IDUTENTE: formData.fkIdUtente,
      IDAREA: formData.fkIdArea,
    };
    if (viewMode === "edit") {
    }
    if (viewMode === "delete") {
      const removeData = {
        IDGESTOREAREA: formData.idGestoreArea,
      };
      return this.service
        .removeGestoreArea(removeData)
        .then((res) => {
          if (res.data.result.ESITO === 0) {
            return this.props.onError(res.data.result.ERROR_MESSAGE);
          }
          return this.props.onSuccess("SuccessedOperation");
        })
        .catch((err) => this.props.onError(err.message));
    }
    if (viewMode === "insert") {
      return this.service
        .setGestoreArea(data)
        .then((res) => {
          if (res.data.result.ESITO === 0) {
            return this.props.onError(res.data.result.ERROR_MESSAGE);
          }
          return this.props.onSuccess("SuccessedOperation");
        })
        .catch((err) => this.props.onError(err.message));
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectArea: false,
      cognomeSearch: null,
      nomeSearch: null,
      cfSearch: null,
      idsAree: [],
    });
  }

  handleSelect() {
    /*let setArrayGestoreArea = [];

    this.state.idsAree.forEach((el) => {
      setArrayGestoreArea.push(
        this.service.setGestoreArea({
          IDUTENTE: this.state.idUserFound,
          IDAREA: el.idArea,
        })
      );
    });
    Promise.all(setArrayGestoreArea).then(() => {
      this.closeModal();
    });*/
    if (
      this.state.idUserFound &&
      this.state.idsAree &&
      this.state.idsAree.length
    ) {
      let idsArray = this.state.idsAree.map((el) => el.idArea);
      let stringArray = idsArray.join(";");
      this.service
        .setGestoreArea({
          IDUTENTE: this.state.idUserFound,
          IDSAREA: stringArray,
        })
        .then(() => {
          this.closeModal();
          this.refreshTable();
        });
    } else {
      this.props.onError("Error");
      this.closeModal();
    }
  }
  handleSearch(type) {
    if (type === "user") {
      if (
        this.state.tipoDocumentoSearch &&
        _.isObject(this.state.tipoDocumentoSearch) &&
        this.state.numeroDocumentoSearch &&
        this.state.numeroDocumentoSearch.length &&
        this.state.nomeSearch &&
        this.state.nomeSearch.length &&
        this.state.cognomeSearch &&
        this.state.cognomeSearch.length
      ) {
        this.service
          .GetUserByNameSurnameTipoDocNumDoc({
            Name: this.state.nomeSearch,
            Surname: this.state.cognomeSearch,
            CodiceTipologiaDocumento: this.state.tipoDocumentoSearch
              .codiceTipologiaDocumento,
            numeroDocumento: this.state.numeroDocumentoSearch,
          })
          .then((res) => {
            if (!res.data.result[0]) {
              this.props.onError(this.props.t("USER_NOT_FOUND"));
            } else {
              this.setState({
                idUserFound: res.data.result[0].IDUTENTE,
                userFound: res.data.result[0],
                isSearch: true,
                searchType: "user",
              });
            }
          });
      } else {
        this.props.onError(this.props.t("AllFieldsRequired"));
      }
    } else if (type === "area") {
      if (
        this.state.areaChosen &&
        _.isObject(this.state.areaChosen) &&
        this.state.areaChosen.idArea
      ) {
        this.areaService.getById(this.state.areaChosen.idArea).then((res) => {
          if (!res.data.result) {
            this.props.onError(this.props.t("AREA_NOT_FOUND"));
          } else {
            this.setState({
              idAreaFound: res.data.result.idArea,
              areaFound: res.data.result,
              isSearch: true,
              searchType: "area",
            });
          }
        });
      }
    }
  }
  resetPage() {
    this.closeModal();
    this.setState({
      isSearch: false,
    });
  }

  renderHeader() {
    return () => (
      <h1 className="h3 mb-4 text-gray-800 text-center page-header">
        <Button
          color="light"
          outline
          className="ml-2 border-0 float-left"
          size="m"
          onClick={() => this.resetPage()}
        >
          <i className="fas fa-arrow-circle-left"></i>
        </Button>
        {this.state.searchType === "user"
          ? this.props.t("locUser") +
            ": " +
            this.state.userFound.NOME +
            " " +
            this.state.userFound.COGNOME
          : this.props.t("Area") + ": " + this.state.areaFound.descrizione}

        <Button
          color="light"
          outline
          className="ml-2 border-0"
          size="sm"
          onClick={() => this.setState({ showModal: true })}
        >
          <i className="fas fa-plus"></i>
        </Button>
      </h1>
    );
  }

  getData() {
    return this.service.getUtenteDistinct().then((res) => {
      return res;
    });
  }

  closeModalDelete() {
    this.setState(
      {
        openModalDelete: false,
      },
      () => {
        this.refreshTable();
      }
    );
  }

  refreshTable() {
    this.refs.crudNetGestore.refreshTable();
  }

  render() {
    return (
      <>
        {!this.state.isSearch && (
          <>
            <h2> Ricerca Per Utente</h2>
            <Form>
              <div className="row w-100 d-flex justify-content-center">
                <FormGroup className="col-md-3">
                  <label htmlFor="Nome">{this.props.t("Name")}:</label>
                  <br></br>
                  <input
                    type="text"
                    id="Nome"
                    name="Nome"
                    className="form-control"
                    placeholder={this.props.t("Name")}
                    onChange={(ev) => {
                      this.setState({ nomeSearch: ev.target.value });
                    }}
                    readOnly={this.props.readOnly}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <label htmlFor="Cognome">{this.props.t("Surname")}:</label>
                  <br></br>
                  <input
                    type="text"
                    id="Cognome"
                    name="Cognome"
                    placeholder={this.props.t("Surname")}
                    className="form-control"
                    onChange={(ev) => {
                      this.setState({ cognomeSearch: ev.target.value });
                    }}
                    readOnly={this.props.readOnly}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <label htmlFor="TipologiaDocumento">
                    {this.props.t("TIPOLOGIA_DOCUMENTO")}:
                  </label>
                  <SelectPicker
                    options={this.state.listTipologiaDocumento}
                    displayValue="descLocalizzata"
                    onChange={(ev) => {
                      this.setState({ tipoDocumentoSearch: ev[0] });
                    }}
                    required
                    values={
                      this.state.listData && this.state.listData.length
                        ? [this.state.listData.tipologiaDocumento]
                        : null
                    }
                    readOnly={this.props.readOnly}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <label htmlFor="NumeroDocumento">
                    {this.props.t("NUMERO_DOCUMENTO")}:
                  </label>
                  <input
                    maxLength={
                      this.state.listData &&
                      this.state.listData.length &&
                      this.state.listData.tipologiaDocumento &&
                      this.state.listData.tipologiaDocumento
                        .codiceTipologiaDocumento === "CF"
                        ? 16
                        : null
                    }
                    type="text"
                    id="NumeroDocumento"
                    name="NumeroDocumento"
                    placeholder={this.props.t("NUMERO_DOCUMENTO")}
                    className="form-control"
                    onChange={(ev) => {
                      this.setState({ numeroDocumentoSearch: ev.target.value });
                    }}
                  />
                </FormGroup>
              </div>
              <Button onClick={this.handleSearch.bind(this, "user")}>
                {this.props.t("Search")}
              </Button>
            </Form>
            <br />
            <br />
            <br />
            <br />

            <h2> Ricerca Per Area</h2>

            <Form>
              <div className="row w-100 d-flex justify-content-center">
                <FormGroup className="col-md-4">
                  <label htmlFor={"Area"}>{this.props.t("SELECT_AREA")}</label>
                  <SelectPicker
                    //options={[{ id: 1 }, { id: 2 }, { id: 3 }]}
                    options={this.state.areaList}
                    displayValue={"descrizione"}
                    onChange={(ev) => {
                      this.setState({
                        areaChosen: ev[0],
                      });
                    }}
                  />
                </FormGroup>
              </div>
              <Button onClick={this.handleSearch.bind(this, "area")}>
                {this.props.t("Search")}
              </Button>
            </Form>
          </>
        )}
        {this.state.isSearch && this.state.searchType === "user" && (
          <CrudNetTable
            //getData={this.getData.bind(this)}
            customHeader={this.renderHeader()}
            onSubmit={this.onSubmit.bind(this)}
            title={this.props.t("GESTORE_AREA")}
            lookupFilters={{
              Utente: {
                [`${FILTER_NUMBER}&&&fkIdTipologiaProfilo`]: [3],
              },
            }}
            refFieldMap={{ fkIdUtente: "username" }}
            columns={this.columns}
            filters={this.filters}
            actions={[]}
            defaultFilters={{
              [`${FILTER_NUMBER}&&&fkIdUtente`]: [this.state.idUserFound],
            }}
            service={this.service}
            disablePaginate={true}
            ref="crudNetGestore"
          />
        )}
        {this.state.isSearch && this.state.searchType === "area" && (
          <CrudNetTable
            //getData={this.getData.bind(this)}
            customHeader={this.renderHeader()}
            onSubmit={this.onSubmit.bind(this)}
            title={this.props.t("GESTORE_AREA")}
            lookupFilters={{
              Utente: {
                [`${FILTER_NUMBER}&&&fkIdTipologiaProfilo`]: [3],
              },
            }}
            refFieldMap={{ fkIdUtente: "username" }}
            columns={this.columnsArea}
            filters={this.filters}
            actions={[]}
            defaultFilters={{
              [`${FILTER_NUMBER}&&&fkIdArea`]: [this.state.idAreaFound],
            }}
            service={this.service}
            disablePaginate={true}
            ref="crudNetGestore"
          />
        )}
        {
          // inizio modal delete
          <Modal
            isOpen={this.state.openModalDelete}
            toggle={this.closeModalDelete.bind(this)}
            size="md"
          >
            <ModalHeader toggle={this.closeModalDelete.bind(this)}>
              HeaderDelete
            </ModalHeader>
            <ModalBody>{this.props.t("DELETE_MESSAGE_GESTORE")}</ModalBody>
            <ModalFooter>
              <Button
                onClick={async () => {
                  await this.service.deleteGestore({
                    IDGESTOREAREA: this.state.row.idGestoreArea,
                  });
                  this.closeModalDelete();
                }}
              >
                {this.props.t("Ok")}
              </Button>
              <Button onClick={this.closeModalDelete.bind(this)}>
                {this.props.t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        }
        <Modal
          isOpen={this.state.showModal}
          toggle={this.closeModal.bind(this)}
          size="lg"
        >
          <ModalHeader toggle={this.closeModal.bind(this)}>
            {this.props.t("ADD_GESTORE_AREA")}
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className="row w-100 d-flex justify-content-center">
                <FormGroup className="col-md-4">
                  <label className="w-100 text-left" htmlFor={"Area"}>
                    {this.props.t("SELECT_AREA")}
                  </label>
                  <SelectPicker
                    //options={[{ id: 1 }, { id: 2 }, { id: 3 }]}
                    options={this.state.areaList}
                    displayValue={"descrizione"}
                    multiple
                    onChange={(ev) => {
                      let list = this.state.idsAree;
                      list = ev;
                      this.setState({
                        idsAree: list,
                      });
                    }}
                  />
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleSelect.bind(this)}>
              {this.props.t("Select")}
            </Button>

            <Button onClick={this.closeModal.bind(this)}>
              {this.props.t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const GestoreAreaPage = RxComponent({ onError, onSuccess })(Comp);
export default GestoreAreaPage;
