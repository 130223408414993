import React from "react";
import moment from "moment";
import RxComponent from "../../../redux/RxComponent";
import { onSuccessChangeAreaStatus } from "../../../redux/Prenotazione/Prenotazione.actions";
import { onSuccess, onError } from "../../../redux/Shared/Shared.actions";
import AreaService from "../../Area/Area.service";
import { FILTER_NUMBER } from "../../../components/TableServer/FilterItem";
import { SelectPicker } from "../../../components/Content/SelectPicker";
import { AREASTATUSES } from "../../../constants/AreaStatus";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Trans } from "react-i18next";
import { ON_SUCCESS_CHANGE_AREA_STATUS } from "../../../redux/Prenotazione/Prenotazione.types";

class Component extends React.Component {
  //TODO mock utente
  state = { areaSelected: null, loading: true };

  areaService = new AreaService();

  done = false;

  componentDidMount() {
    let params = {
      idComune: this.props.Auth.user.Comune.idComune,
      data: moment().format("YYYYMMDD"),
    };

    this.areaService.searchAreeComune(params).then((res) => {
      this.setState({
        areaList: res.data.result,
        loading: false,
      });
    });
  }

  componentDidUpdate() {
    if (
      this.done &&
      this.props.Prenotazione.type === ON_SUCCESS_CHANGE_AREA_STATUS
    ) {
      this.done = false;

      let params = {
        idComune: this.props.Auth.user.Comune.idComune,
        data: moment().format("YYYYMMDD"),
      };

      this.areaService.searchAreeComune(params).then((res) => {
        this.setState({
          showModal: false,
          action: null,
          areaSelected: null,
          loading: false,
          areaList: res.data.result,
        });
      });
    }
  }

  submitForm() {
    if (this.state.action && this.state.areaSelected) {
      this.areaService
        .closeAreaFromVarco({
          IDAREA: this.state.areaSelected.IDAREA,
          CODICESTATOCHIUSURA:
            this.state.action === "close"
              ? AREASTATUSES.CLOSED
              : AREASTATUSES.OPEN,
        })
        .then((res) => {
          this.props.onSuccess("SuccessedOperation");
          this.setState(
            {
              loading: true,
            },
            () => {
              this.done = true;
              this.props.onSuccessChangeAreaStatus(this.state.areaSelected);
            }
          );
        });
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  renderButtons() {
    if (
      this.state.areaStatus === AREASTATUSES.OPEN ||
      this.state.areaStatus === AREASTATUSES.MEDIUM
    ) {
      return (
        <Button
          className="form--button-cyan fourth row justify-content-md-center"
          onClick={() => this.setState({ showModal: true, action: "close" })}
        >
          {this.props.t("Close_Area")}
        </Button>
      );
    } else {
      return (
        <Button
          className="form--button-cyan fourth row justify-content-md-center"
          onClick={() => this.setState({ showModal: true, action: "open" })}
        >
          {this.props.t("Open_Area")}
        </Button>
      );
    }
  }

  render() {
    return this.state.loading ? (
      <p>Loading...</p>
    ) : (
      <div>
        <form className="container" onSubmit={this.submitForm.bind(this)}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-4 col-md-4">
              <label className="w-100" htmlFor={"Parcheggi"}>
                {this.props.t("SELEZIONA_AREA")}
              </label>
              <SelectPicker
                className="form-control"
                id="area"
                value={
                  this.state.areaSelected
                    ? this.state.areaSelected.DESCRIZIONE
                    : null
                }
                onChange={(ev) => {
                  this.setState({
                    areaSelected: ev[0],
                    areaStatus: ev[0].CODICESTATO || null,
                  });
                }}
                options={this.state.areaList}
                displayValue={"DESCRIZIONE"}
              />
            </div>
          </div>
          <Modal
            isOpen={this.state.showModal}
            toggle={this.closeModal.bind(this)}
            size="md"
          >
            <ModalHeader toggle={this.closeModal.bind(this)}></ModalHeader>
            <ModalBody>
              <div className="col-sm-12">
                <label className="w-100 text-left" htmlFor={this.state.id}>
                  <Trans i18nKey={"confirm_operation"} />
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.submitForm.bind(this)}>
                {this.props.t("Ok")}
              </Button>
              <Button onClick={this.closeModal.bind(this)}>
                {this.props.t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          {this.state.areaSelected && this.renderButtons()}
        </form>
      </div>
    );
  }
}
const ChiusuraAreaVarco = RxComponent({
  onSuccessChangeAreaStatus,
  onSuccess,
  onError,
})(Component);
export default ChiusuraAreaVarco;
