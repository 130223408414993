import React from "react";
import _ from "lodash";
import FilterItem, { FILTER_RANGE } from "./FilterItem";
import { Trans } from "react-i18next";

export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "filter-id",
      list: [],
      filters: {},
    };
  }

  componentDidMount() {
    let changed = {};
    if (this.state.id !== this.props.tableid) {
      changed.id = this.props.tableid;
    }
    /*if(this.state.list!==this.props.filterlist){
            if(!this.props.filterlist)
                changed.list=[];
            else
                changed.list=this.props.filterlist;
        }*/
    if (!_.isEmpty(changed)) this.setState(changed);
  }

  componentDidUpdate(prevProps, prevState) {
    let changed = {};
    if (this.state.id !== this.props.tableid) {
      changed.id = this.props.tableid;
    }
    if (this.props.filterlist && this.state.list !== this.props.filterlist) {
      changed.list = this.props.filterlist;
    }
    if (!_.isEmpty(changed)) this.setState(changed);
  }

  onFilterReceived(data, values) {
    let newFilters = this.state.filters;
    if (!values || !values.length) {
      delete newFilters[data];
    } else {
      newFilters[data] = values;
    }
    this.setState({
      filters: newFilters,
    });
  }

  sendFilter() {
    if (
      this.props.onFilterChanged &&
      _.isFunction(this.props.onFilterChanged)
    ) {
      this.props.onFilterChanged(this.state.filters);
    }
  }

  render() {
    return (
      <div className="card card-primary card-collapsible border-0">
        <div className="card-header  bg-white" style={{ height: "45px" }}>
          <h6 className="card-title">
            <a
              href={`#${this.state.id}`}
              className="card-toggler d-flex align-items-center collapsed text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls={this.state.id}
            >
              <Trans i18nKey="Filters" />
              <i className="fas fa-filter ml-auto"></i>
            </a>
          </h6>
        </div>

        <div id={this.state.id} className="collapse">
          <div className="card-body">
            <div className="row justify-content-sm-center">
              {this.state.list.map((filter) => {
                if (filter.type === FILTER_RANGE) {
                  return (
                    <div
                      className="col-sm-12"
                      key={this.state.id + "-" + filter.data}
                    >
                      <FilterItem
                        onChangeFilter={this.onFilterReceived.bind(this)}
                        label={filter.label}
                        type={filter.type}
                        values={filter.values}
                        data={filter.data}
                        options={filter.options}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className="col-sm-4"
                    key={this.state.id + "-" + filter.data}
                  >
                    <FilterItem
                      onChangeFilter={this.onFilterReceived.bind(this)}
                      label={filter.label}
                      type={filter.type}
                      values={filter.values}
                      data={filter.data}
                      options={filter.options}
                    />
                  </div>
                );
              })}
            </div>

            <div className="spaziatura-12"></div>

            <div className="row justify-content-sm-center">
              <button
                className="btn btn-dark mt-2"
                onClick={this.sendFilter.bind(this)}
              >
                <Trans i18nKey="ApplyFilters" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
