import StubRepo from "../../repos/StubRepo";
import LookupsMockRepo from "./repositories/Lookups.mock";
import LookupsHttpRepo from "./repositories/Lookups.http";

export default class LookupsRepo extends StubRepo {
  static _localInstance = null;
  static _serviceInstance = null;

  static getName() {
    return "Lookups repo";
  }

  static getLocal() {
    if (LookupsRepo._localInstance == null) {
      LookupsRepo._localInstance = new LookupsMockRepo();
    }
    return LookupsRepo._localInstance;
  }

  static getService() {
    if (LookupsRepo._serviceInstance == null) {
      LookupsRepo._serviceInstance = new LookupsHttpRepo();
    }
    return LookupsRepo._serviceInstance;
  }
}
