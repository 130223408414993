import HttprepoTAAL from "../../../repos/HttpRepoTaal";

const GET_BANNER_REGISTER = "generic/search3/prenotazione/comunebanner";
export default class ComuneBannerHttpRepo extends HttprepoTAAL {
  getTable() {
    return "ComuneBanner";
  }

  getComuneBannerPrenotazione(idComune) {
    return this.API.post(GET_BANNER_REGISTER, { fkIdComune: idComune });
  }
}
