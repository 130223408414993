import BaseServiceTaal from "../../Service/BaseServiceTaal";
import LookupsRepo from "./Lookups.repo";
import StatoAssegnazioneService from "../StatoAssegnazione/StatoAssegnazione.service";
import StatoPrenotazioneService from "../StatoPrenotazione/StatoPrenotazione.service";
import TipologiaAreaService from "../TipologiaArea/TipologiaArea.service";
import TipologiaDocumentoService from "../TipologiaDocumento/TipologiaDocumento.sevice";
import TipologiaPrenotazioneService from "../TipologiaPrenotazione/TipologiaPrenotazione.service";
import TipologiaProfiloService from "../TipologiaProfilo/TipologiaProfilo.service";
import TipologiaVarcoService from "../TipologiaVarco/TipologiaVarco.service";
import TipologiaVeicoloService from "../TipologiaVeicolo/TipologiaVeicolo.service";
import ComuneService from "../Comune/Comune.service";
import ProvinciaService from "../Provincia/Provincia.service";
import LinguaService from "../Lingua/Lingua.service";
import RisorsaLogicaService from "../RisorsaLogica/RisorsaLogica.service";
import RelTipoAreaTipoPrenotazioneService from "../RelazioneTipoAreaTipoPrenotazione/RelTipoAreaTipoPrenotazione.service";
import RelTipoPrenotazioneTipoDurataService from "../RelazioneTipoPrenotazioneTipoDurata/RelTipoPrenotazioneTipoDurata.service";
import RelTipoDurataFasciaOrariaService from "../RelazioneTipoDurataTipoFasciaOraria/RelTipoDurataFasciaOraria.service";

import {
  FIELD_TEXT,
  FIELD_INTEGER,
  FIELD_DECIMAL,
  FIELD_DATE,
  FIELD_SELECT,
  FIELD_BOOLEAN,
} from "../../components/DynamicForm/DynamicFormField";
import _ from "lodash";

export default class LookupsService extends BaseServiceTaal {
  getRepo = () => LookupsRepo.getInstanceTaal();

  getAll = () => {
    return this.getRepo().then((repo) => repo.getAll());
  };

  handleLookupService = (lookup) => {
    switch (lookup) {
      case "StatoAssegnazione":
        return new StatoAssegnazioneService();
      case "StatoPrenotazione":
        return new StatoPrenotazioneService();
      case "TipologiaArea":
        return new TipologiaAreaService();
      case "TipologiaDocumento":
        return new TipologiaDocumentoService();
      case "TipologiaPrenotazione":
        return new TipologiaPrenotazioneService();
      case "TipologiaProfilo":
        return new TipologiaProfiloService();
      case "TipologiaVarco":
        return new TipologiaVarcoService();
      case "TipologiaVeicolo":
        return new TipologiaVeicoloService();
      case "Comune":
        return new ComuneService();
      case "Provincia":
        return new ProvinciaService();
      case "Lingua":
        return new LinguaService();
      case "RisorsaLogica":
        return new RisorsaLogicaService();
      case "RelTipoDurataFasciaOraria":
        return new RelTipoDurataFasciaOrariaService();
      case "RelTipoPrenotazioneTipoDurata":
        return new RelTipoPrenotazioneTipoDurataService();
      case "RelTipoAreaTipoPrenotazione":
        return new RelTipoAreaTipoPrenotazioneService();
      default:
        return undefined;
    }
  };

  handleLookupColumns = (lookupserv) => {
    return new Promise((resolve, reject) => {
      lookupserv.tableDefinition(true).then((resp) => {
        let cols = this.filterColumns(resp.data.result.columns);
        resolve(cols);
      });
    });
  };

  filterColumns(list) {
    return list
      .filter(
        (col) =>
          col.type.indexOf("Boolean") >= 0 ||
          col.type.indexOf("String") >= 0 ||
          col.type.indexOf("Int") >= 0 ||
          col.type.indexOf("Decimal") >= 0 ||
          col.type.indexOf("Date") >= 0
      )
      .map((col) => {
        let type = FIELD_TEXT;
        if (col.type.indexOf("Int") >= 0) {
          type = FIELD_INTEGER;
          if (col.name.indexOf("fk") >= 0) {
            type = FIELD_SELECT;
          }
        } else if (col.type.indexOf("Boolean") >= 0) {
          type = FIELD_BOOLEAN;
        } else if (col.type.indexOf("Decimal") >= 0) {
          type = FIELD_DECIMAL;
        } else if (col.type.indexOf("Date") >= 0) {
          type = FIELD_DATE;
        }
        /*return {
          type: type,
          id: col.name,
          realId: col.+,
          label: this.camelCaseToString(col.name),
          required: col.required,
          precision: col.precision,
          readOnly: col.viewMode === "view",
          hideMe: col.hideMe
            ? (fd) =>
                col.hideMe(
                  this.stateToObject(fd),
                  fd,
                  this.state.externalFields
                )
            : null,
        };*/
        return {
          type: type,
          label: this.camelCaseToString(col.name),
          data: col.name,
        };
      });
  }

  camelCaseToString(s) {
    return _.capitalize(s.split(/(?=[A-Z])/).join(" "));
  }
}

// fare override di tabledef per decidere quali capmi mostrare, di base omstra tutto tranne l'id
