import HttprepoTAAL from "../../../repos/HttpRepoTaal";

export default class LookupsHttpRepo extends HttprepoTAAL {
  getTable() {
    return "Lookups";
  }

  getAll() {
    return new Promise((resolve, reject) => {
      let lookupsMockList = [
        {
          descrizione: "StatoAssegnazione",
          value: 1,
        },
        {
          descrizione: "StatoPrenotazione",
          value: 2,
        },
        {
          descrizione: "TipologiaArea",
          value: 3,
        },
        {
          descrizione: "TipologiaDocumento",
          value: 4,
        },
        {
          descrizione: "TipologiaPrenotazione",
          value: 5,
        },
        {
          descrizione: "TipologiaProfilo",
          value: 6,
        },
        {
          descrizione: "TipologiaVarco",
          value: 7,
        },
        {
          descrizione: "TipologiaVeicolo",
          value: 8,
        },
        {
          descrizione: "Comune",
          value: 9,
        },
        {
          descrizione: "Lingua",
          value: 10,
        },
        {
          descrizione: "RisorsaLogica",
          value: 10,
        },
        {
          descrizione: "RelTipoDurataFasciaOraria",
          value: 11,
        },
        {
          descrizione: "RelTipoPrenotazioneTipoDurata",
          value: 12,
        },
        {
          descrizione: "RelTipoAreaTipoPrenotazione",
          value: 13,
        },
      ];
      resolve(lookupsMockList);
    });
  }
}
