import React from "react";
import CrudNetTable from "../../components/CrudNet/CrudNetTable";
import moment from "moment";
import { FILTER_TEXT } from "../../components/TableServer/FilterItem";
import LookupsService from "./Lookups.service";
import LookupsCrud from "./Lookups.crud";
import LookupsPicker from "./components/LookupsPicker";
import RxComponent from "../../redux/RxComponent";

 class LookupsPageC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      filter: [],
      selectedLookup: null,
      LookupsService: new LookupsService(),
      selectedService: null,
      isLoaded: false,
    };
  }

  handleLookupChange = (lookup) => {
    if(lookup){
      let selServ = this.state.LookupsService.handleLookupService(lookup);
      this.state.LookupsService.handleLookupColumns(selServ).then((res) => {
        this.setState({
          selectedService: selServ,
          isLoaded: true,
          columns: res,
          selectedLookup: lookup,
        });
      });
    }else{
      this.setState({
        columns: [],
        filter: [],
        selectedLookup: null,
        selectedService: null,
        isLoaded: false,
      });
    }
  };

  render = () => {
    if (!this.state.isLoaded) {
      return (
        <React.Fragment>
          <h1 className="h3 mb-4 text-gray-800 text-left page-header">
            {this.props.t("Lookups")}
          </h1>
          <div className="row mb-3">
            <div className="col-sm-6 col-md-3">
              <LookupsPicker
                required
                onChange={(lookup) => this.handleLookupChange(lookup)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1 className="h3 mb-4 text-gray-800 text-left page-header">
            {this.props.t("Lookups")}
          </h1>
          <div className="row mb-3">
            <div className="col-sm-6 col-md-3">
              <LookupsPicker
                required
                onChange={(lookup) => this.handleLookupChange(lookup)}
              />
            </div>
          </div>
          <CrudNetTable
            title={this.props.t(this.state.selectedLookup)}
            columns={this.state.columns}
            filters={this.state.filters}
            service={this.state.selectedService}
          />
        </React.Fragment>
      );
    }
  };
}

const LookupsPage = RxComponent()(LookupsPageC);

export default LookupsPage;