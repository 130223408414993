import {
  FILTER_TEXT,
  FILTER_MULTIPLE,
  FILTER_NUMBER,
  FILTER_NOT_NULL,
  FILTER_NULL,
  FILTER_LIST,
  FILTER_TEXT_EQUALS,
} from "../components/TableServer/FilterItem";
import HttpRepoTAAL from "../repos/HttpRepoTaal";
import _ from "lodash";

export default class BaseServiceTaal {
  counterParams = 0;
  values = [];

  _resetCounter() {
    this.counterParams = 0;
  }

  _getIncrementCounter(v) {
    this.values.push({ value: v });
    return this.counterParams++;
  }

  _createTextFilter(key, val) {
    if (val && val[0] && val[0].length) {
      return `${key}.Contains(@${this._getIncrementCounter(val[0])})`;
    }
    return null;
  }

  _createTextFilterEquals(key, val) {
    if (val && val[0] && val[0].length) {
      return `${key}.Equals(@${this._getIncrementCounter(val[0])})`;
    }
    return null;
  }

  _createNumericFilter(key, val) {
    if (val && val[0]) {
      let valt = parseInt(val[0]);
      if (val[0] === "true") {
        valt = true;
      } else if (val[0] === "false") {
        valt = false;
      }

      return `${key}==@${this._getIncrementCounter(valt)}`;
    }
    return null;
  }

  _createMultipleFilter(key, val) {
    if (val && val.length) {
      return (
        ` (` +
        val
          .map((el) => `${key} == @${this._getIncrementCounter(el)}`)
          .join(` || `) +
        ` )`
      );
    }
    return null;
  }

  _createNotInFilter(key, val) {
    if (val && val.length) {
      return val
        .map((o) => `${key} != @${this._getIncrementCounter(o)}`)
        .join(" && ");
    }
    return null;
  }

  _createNotNullOrEmpty(key) {
    if (key.indexOf("_List") > 0)
      return ` ${key}.Count > @${this._getIncrementCounter(0)}`;
    else return `${key} != @${this._getIncrementCounter(null)}`;
  }

  _createNullOrEmpty(key) {
    if (key.indexOf("_List") > 0)
      return ` ${key}.Count == @${this._getIncrementCounter(0)}`;
    else return `${key} == @${this._getIncrementCounter(null)}`;
  }

  transformFilterToServer(filters) {
    let ret = [];
    this._resetCounter();
    this.values = [];
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((keyFilter) => {
        if (filters[keyFilter]) {
          const splitted = keyFilter.split("&&&");
          const type = splitted[0];
          const key = splitted[1];
          const correctValue = filters[keyFilter].filter((cv) => cv != null);

          let currentFilter = null;

          switch (type) {
            case "notIn":
              currentFilter = this._createNotInFilter(key, correctValue);
              break;
            case FILTER_TEXT:
              currentFilter = this._createTextFilter(key, correctValue);
              break;
            case FILTER_TEXT_EQUALS:
              currentFilter = this._createTextFilterEquals(key, correctValue);
              break;
            case FILTER_MULTIPLE:
              currentFilter = this._createMultipleFilter(key, correctValue);
              break;
            case FILTER_NUMBER:
              currentFilter = this._createNumericFilter(key, correctValue);
              break;
            case FILTER_NOT_NULL:
              currentFilter = this._createNotNullOrEmpty(key, correctValue);
              break;
            case FILTER_NULL:
              currentFilter = this._createNullOrEmpty(key);
              break;
            case FILTER_LIST:
              currentFilter = currentFilter = this._createNumericFilter(
                key,
                correctValue
              );
              break;
            default:
              currentFilter = this._createTextFilter(key);
              break;
          }

          if (currentFilter) ret.push(currentFilter);
        }
      });
    }
    return { expression: ret.join(" && "), expressionValues: this.values };
  }

  transformOrdersToServer(orders) {
    if (_.isArray(orders) && orders.length > 0) {
      return orders.map((order) => order.data + " " + order.direction);
    }
  }

  //This method must return a promise wich a repo stub instance
  getRepo() {
    return Promise.resolve(new HttpRepoTAAL());
  }

  getAll = (customTable) =>
    this.getRepo().then((repo) => repo.search(0, -1, null, null, customTable));

  paginate = (page, size, filters, orders) =>
    this.getRepo().then((repo) =>
      repo.search(page - 1, size, this.transformFilterToServer(filters), orders)
    );

  create = (objNew) => this.getRepo().then((repo) => repo.add(objNew));

  remove = (objRem) => this.getRepo().then((repo) => repo.del(objRem));

  edit = (objUpd) => this.getRepo().then((repo) => repo.update(objUpd));

  getById = (id) => this.getRepo().then((repo) => repo.find(id));

  tableDefinition = () => this.getRepo().then((repo) => repo.tabledef());
}
